import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const FaqModel = types
  .model('Faq')
  .props({
    faq_id: types.identifier,
    question: types.maybeNull(types.maybe(types.string)),
    answer: types.maybeNull(types.maybe(types.string))
  })

type FaqType = Instance<typeof FaqModel>
export interface Faq extends FaqType {}
type FaqSnapshotType = SnapshotOut<typeof FaqModel>
export interface FaqSnapshot extends FaqSnapshotType {}
