import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { SettingsApi } from "../../services/api/settings-api"
import { Platform } from "react-native"
import { api } from "app/services/api"

export const SettingsStoreModel = types
  .model("SettingsStore")
  .props({
    app_banner: types.optional(types.string, ""),
    master_registration_guide: types.optional(types.string, ""),
    sotong_usage_guide: types.optional(types.string, ""),
    hide_coin_ui: types.optional(types.boolean, true),
  })
  .views((self) => ({
    getAppBanner() {
      return self.app_banner
    },
    getMasterRegistrationGuide() {
      return self.master_registration_guide
    },
    getSotongUsageGuide() {
      return self.sotong_usage_guide
    },
    getHideCoinLogic() {
      return self.hide_coin_ui
    },
  }))
  .actions((self) => ({
    setAppBanner(value: string) {
      self.app_banner = value
    },
    setMasterRegistrationGuide(value: string) {
      self.master_registration_guide = value
    },
    setSotongUsageGuide(value: string) {
      self.sotong_usage_guide = value
    },
    setHideCoinUI(value: boolean) {
      if (Platform.OS === "ios") {
        self.hide_coin_ui = value
      } else {
        self.hide_coin_ui = false
      }
    },
  }))
  .actions((self) => ({
    fetchSettings: flow(function* () {
      const settingsApi = new SettingsApi(api)
      const result = yield settingsApi.getSettings()

      if (result.kind === "ok") {
        self.setAppBanner(result.settings.images?.app_banner)
        self.setMasterRegistrationGuide(result.settings.features?.master_registration_guide)
        self.setSotongUsageGuide(result.settings.features?.sotong_usage_guide)
        self.setHideCoinUI(result.settings.features?.hide_coin_ui)
      } else {
        if (result.kind === "not-found") {
          self.app_banner = ""
          self.master_registration_guide = ""
          self.sotong_usage_guide = ""
        }
      }
    }),
  }))

type SettingsStoreType = Instance<typeof SettingsStoreModel>
export interface SettingsStore extends SettingsStoreType {}
type SettingsStoreSnapshotType = SnapshotOut<typeof SettingsStoreModel>
export interface SettingsStoreSnapshot extends SettingsStoreSnapshotType {}
export const createSettingsStoreDefaultModel = () => types.optional(SettingsStoreModel, {})
