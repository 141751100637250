import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const KeywordModel = types
  .model('Keyword')
  .props({
    keyword: types.string,
    state: types.enumeration('state', ['up', 'down', 'keep']),
    is_suggested: types.boolean
  })

type KeywordType = Instance<typeof KeywordModel>
export interface Keyword extends KeywordType {}
type KeywordSnapshotType = SnapshotOut<typeof KeywordModel>
export interface KeywordSnapshot extends KeywordSnapshotType {}
