/* eslint-disable no-use-before-define */
import React from 'react'
import { StyleProp, TextInput, TextInputProps, TextStyle, View, ViewStyle } from 'react-native'
import { color, spacing, typography } from '../../theme'
import { translate, TxKeyPath } from '../../i18n'

const CONTAINER: ViewStyle = {
  padding: spacing[2]
}

const INPUT: TextStyle = {
  fontFamily: typography.primary,
  color: color.text,
  fontSize: 14,
  backgroundColor: color.palette.white,
  width: '100%'
}

const PRESETS: { [name: string]: ViewStyle } = {
  default: {
    borderWidth: 1,
    borderColor: color.border,
    borderRadius: 4
  }
}

export interface TextFieldProps extends TextInputProps {
  placeholderTx?: TxKeyPath
  placeholder?: string
  labelTx?: TxKeyPath
  label?: string
  style?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<TextStyle>
  preset?: keyof typeof PRESETS
  forwardedRef?: any
}

export function TextField (props: TextFieldProps) {
  const {
    placeholderTx,
    placeholder,
    preset = 'default',
    style: styleOverride,
    inputStyle: inputStyleOverride,
    forwardedRef,
    ...rest
  } = props

  const containerStyles = [CONTAINER, PRESETS[preset], styleOverride]
  const inputStyles = [INPUT, inputStyleOverride]
  const actualPlaceholder = placeholderTx ? translate(placeholderTx) : placeholder

  return (
    <View style={containerStyles}>
      <TextInput
        placeholder={actualPlaceholder || undefined}
        placeholderTextColor={color.palette.lighterGrey}
        underlineColorAndroid={color.transparent}
        {...rest}
        style={inputStyles}
        ref={forwardedRef}
      />
    </View>
  )
}
