/* eslint-disable camelcase */
import { Instance, SnapshotOut, types, flow } from "mobx-state-tree"
import { UserApi } from "../../services/api/user-api"
import { api } from "app/services/api"

export const NotificationSettingStoreModel = types
  .model("NotificationSettingStore")
  .props({
    notification_invite_from_follower: types.optional(types.boolean, true),
    notification_invite_from_following: types.optional(types.boolean, true),
    notification_wish_follow: types.optional(types.boolean, true),
    notification_message_chat: types.optional(types.boolean, true),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setNotificationSettings(value: any) {
      self.notification_invite_from_follower = value.notification_invite_from_follower
      self.notification_invite_from_following = value.notification_invite_from_following
      self.notification_wish_follow = value.notification_wish_follow
      self.notification_message_chat = value.notification_message_chat
    },
  }))
  .actions((self) => ({
    fetchNotificationSettings: flow(function* (token: string, account_id) {
      const userApi = new UserApi(api)
      const result = yield userApi.getNotificationSettings(token, account_id)

      if (result.kind === "ok") {
        self.setNotificationSettings(result.settings)
      }
    }),

    updateNotificationSettings: flow(function* (token: string, account_id, payload: any) {
      const userApi = new UserApi(api)
      const result = yield userApi.updateNotificationSettings(token, account_id, payload)

      if (result.kind === "ok") {
        self.setNotificationSettings(result.settings)
      }
    }),
  }))

type NotificationSettingStoreType = Instance<typeof NotificationSettingStoreModel>
export interface NotificationSettingStore extends NotificationSettingStoreType {}
type NotificationSettingStoreSnapshotType = SnapshotOut<typeof NotificationSettingStoreModel>
export interface NotificationSettingStoreSnapshot extends NotificationSettingStoreSnapshotType {}
