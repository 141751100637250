/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import { StyleProp, View, ViewStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { TextField } from "../text-field/text-field"
import SelectDropdown from "react-native-select-dropdown"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import uuid from "react-native-uuid"
import { Career } from "../../models/career/career"

export interface CareersInputProps {
  style?: StyleProp<ViewStyle>
  careers: Career[]
  setCareers: (value: Career[]) => void
}

const currentYear = new Date().getFullYear()
const yearOptions = () => {
  const yearArray: string[] = []
  for (let year = 1970; year <= currentYear; year++) {
    yearArray.push(`${year}`)
  }
  return yearArray
}

type CareerResultProps = {
  career: Career
}

export const CareersInput = observer(function CareersInput(props: CareersInputProps) {
  const { style, careers, setCareers } = props
  const styles = Object.assign({}, style)
  const [showInput, setShowInput] = useState(false)

  const [title, setTitle] = useState("")
  const [titleAndPosition, setTitleAndPosition] = useState("")
  const [startYear, setStartYear] = useState(`${currentYear}`)
  const [endYear, setEndYear] = useState(`${currentYear}`)

  const handleAddCareer = () => {
    const newCareer: Career = {
      careerId: `${uuid.v4()}`,
      title: title,
      titleAndPosition: titleAndPosition,
      startYear: startYear,
      endYear: endYear,
    }
    setCareers(careers.concat(newCareer))
    setShowInput(false)
  }

  const CareerResult = (props: CareerResultProps) => {
    return (
      <View
        style={{
          padding: spacing[4],
          borderWidth: 1,
          borderRadius: spacing[4],
          marginVertical: spacing[1],
        }}
      >
        <TouchableOpacity
          style={{ position: "absolute", right: 0, top: 0 }}
          onPress={() => {
            setCareers(careers.filter((career) => career.careerId !== props.career.careerId))
          }}
        >
          <MaterialIcons name="close" size={32} color={color.text} />
        </TouchableOpacity>
        <Text text={props.career.title || ""} preset={"bold"} />
        <Text
          text={`${props.career.startYear} ~ ${props.career.endYear} ${props.career.titleAndPosition}`}
        />
      </View>
    )
  }

  return (
    <View style={styles}>
      {careers?.map((item, index) => (
        <CareerResult career={item} key={index} />
      ))}
      {showInput && (
        <View
          style={{
            borderRadius: spacing[2],
            borderWidth: 1,
            borderColor: color.dim,
            padding: spacing[4],
            marginTop: spacing[4],
          }}
        >
          <TextField
            value={title}
            onChangeText={(text) => {
              setTitle(text)
            }}
            placeholder={"직장명"}
            style={{ padding: spacing[2], marginVertical: spacing[1] }}
            inputStyle={{ padding: spacing[0], borderRadius: spacing[2] }}
          />
          <TextField
            value={titleAndPosition}
            onChangeText={(text) => {
              setTitleAndPosition(text)
            }}
            placeholder={"직위/직책"}
            style={{ padding: spacing[2], marginVertical: spacing[1] }}
            inputStyle={{ padding: spacing[0], borderRadius: spacing[2] }}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginVertical: spacing[1],
            }}
          >
            <SelectDropdown
              defaultValue={startYear}
              data={yearOptions()}
              onSelect={(year) => setStartYear(year)}
              buttonTextAfterSelection={(selectedItem) => selectedItem}
              rowTextForSelection={(item) => item}
              defaultButtonText={"연도"}
              buttonStyle={{
                width: 120,
                backgroundColor: color.background,
                borderWidth: 1,
                borderColor: color.border,
                borderRadius: 4,
                height: 36,
              }}
              buttonTextStyle={{ fontSize: 14, textAlign: "left" }}
              dropdownStyle={{
                backgroundColor: color.background,
                borderRadius: 4,
              }}
              rowStyle={{ height: 36 }}
              rowTextStyle={{ fontSize: 14, textAlign: "center" }}
              renderDropdownIcon={() => (
                <MaterialIcons name="arrow-drop-down" size={24} color={color.text} />
              )}
            />
            <Text text={"  ~  "} />
            <SelectDropdown
              defaultValue={endYear}
              data={yearOptions()}
              onSelect={(year) => setEndYear(year)}
              buttonTextAfterSelection={(selectedItem) => selectedItem}
              rowTextForSelection={(item) => item}
              defaultButtonText={"연도"}
              buttonStyle={{
                width: 120,
                backgroundColor: color.background,
                borderWidth: 1,
                borderColor: color.border,
                borderRadius: 4,
                height: 36,
              }}
              buttonTextStyle={{ fontSize: 14, textAlign: "left" }}
              dropdownStyle={{
                backgroundColor: color.background,
                borderRadius: 4,
              }}
              rowStyle={{ height: 36 }}
              rowTextStyle={{ fontSize: 14, textAlign: "center" }}
              renderDropdownIcon={() => (
                <MaterialIcons name="arrow-drop-down" size={24} color={color.text} />
              )}
            />
          </View>
          <TouchableOpacity
            style={{
              alignSelf: "center",
              borderRadius: spacing[2],
              borderWidth: 1,
              borderColor: color.dim,
              paddingVertical: spacing[1],
              paddingHorizontal: spacing[4],
              marginVertical: spacing[1],
            }}
            onPress={handleAddCareer}
            disabled={
              title.length === 0 ||
              titleAndPosition.length === 0 ||
              startYear.length === 0 ||
              endYear.length === 0 ||
              parseInt(endYear, 10) < parseInt(startYear, 10)
            }
          >
            <Text text={"추가"} />
          </TouchableOpacity>
        </View>
      )}
      <TouchableOpacity
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: spacing[2],
          borderWidth: 1,
          borderColor: color.dim,
          paddingVertical: spacing[1],
          paddingHorizontal: spacing[4],
          marginVertical: spacing[2],
        }}
        onPress={() => {
          setTitle("")
          setTitleAndPosition("")
          setStartYear(`${currentYear}`)
          setEndYear(`${currentYear}`)
          setShowInput(true)
        }}
      >
        <MaterialIcons name="add" size={24} color={color.text} />
        <Text tx="careerInput.addCareer" />
      </TouchableOpacity>
    </View>
  )
})
