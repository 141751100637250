/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { ScrollView, View } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, PasswordInput, Text, Button } from "../../components"
import { color, spacing } from "../../theme"
import { Controller, useForm } from "react-hook-form"
import { useStores } from "../../models"
import Toast from "react-native-toast-message"

type FormData = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

export const PasswordManagementScreen: FC<
  StackScreenProps<NavigatorParamList, "passwordManagement">
> = observer(({ navigation }) => {
  const goBack = () => navigation.goBack()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>()
  const [loading, setLoading] = useState(false)
  const { authStore } = useStores()

  const onConfirm = async (data: FormData) => {
    if (data.newPassword === data.confirmPassword) {
      setLoading(true)
      const reason = await authStore.changePassword(data.oldPassword, data.newPassword)
      if (reason === "success") {
        alert("비밀번호 변경이 완료되었습니다")
        goBack()
      } else {
        Toast.show({
          type: "error",
          text1: "암호변경 실패",
          position: "bottom",
        })
      }
      setLoading(false)
    } else {
      setError("confirmPassword", { type: "confirm" })
    }
  }

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        <Header leftIcon="back" onLeftPress={goBack} headerText={"비밀번호 관리"} />
        <View style={{ flex: 1 }}>
          <View style={{ height: 1, backgroundColor: color.border }} />
          <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              paddingHorizontal: spacing[4],
            }}
          >
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,15}$/,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PasswordInput
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  placeholder={"현재 비밀번호 입력"}
                  style={{ marginVertical: spacing[2] }}
                />
              )}
              name="oldPassword"
              defaultValue=""
            />
            {errors.oldPassword && errors.oldPassword.type === "required" && (
              <Text preset="warning" text={"비밀번호를 입력해 주세요"} />
            )}
            {errors.oldPassword && errors.oldPassword.type === "pattern" && (
              <Text preset="warning" text={"비밀번호 형식이 올바르지 않습니다"} />
            )}
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,15}$/,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PasswordInput
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  placeholder={"변경할 비밀번호 입력"}
                  style={{ marginVertical: spacing[2] }}
                />
              )}
              name="newPassword"
              defaultValue=""
            />
            {errors.newPassword && errors.newPassword.type === "required" && (
              <Text preset="warning" text={"비밀번호를 입력해 주세요"} />
            )}
            {errors.newPassword && errors.newPassword.type === "pattern" && (
              <Text preset="warning" text={"비밀번호 형식이 올바르지 않습니다"} />
            )}
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,15}$/,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PasswordInput
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  placeholder={"변경할 비밀번호 확인"}
                  style={{ marginVertical: spacing[2] }}
                />
              )}
              name="confirmPassword"
              defaultValue=""
            />
            {errors.confirmPassword && errors.confirmPassword.type === "required" && (
              <Text preset="warning" text={"비밀번호를 입력해 주세요"} />
            )}
            {errors.confirmPassword && errors.confirmPassword.type === "pattern" && (
              <Text preset="warning" text={"비밀번호 형식이 올바르지 않습니다"} />
            )}
            {errors.confirmPassword && errors.confirmPassword.type === "confirm" && (
              <Text preset="warning" text={"변경할 비밀번호 확인 실패"} />
            )}
            <Text
              text={"* 영문 + 숫자 + 특수기호 조합 6 ~ 15자"}
              style={{ textAlign: "center", marginVertical: spacing[5] }}
            />
            <View style={{ justifyContent: "center" }}>
              <Button
                tx={"common.ok"}
                onPress={handleSubmit(onConfirm)}
                disabled={loading}
                loading={loading}
              />
            </View>
          </ScrollView>
        </View>
      </Screen>
    </View>
  )
})
