// eslint-disable-next-line no-use-before-define
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const AnnouncementModel = types
  .model('Announcement')
  .props({
    announcement_id: types.identifier,
    title: types.maybeNull(types.string),
    content: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    attached: types.maybeNull(types.string)
  })

type AnnouncementType = Instance<typeof AnnouncementModel>
export interface Announcement extends AnnouncementType {}
type AnnouncementSnapshotType = SnapshotOut<typeof AnnouncementModel>
export interface AnnouncementSnapshot extends AnnouncementSnapshotType {}
