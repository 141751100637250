/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react"
import { View, ViewStyle, ImageStyle, TouchableOpacity, ActivityIndicator } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { numberWithCommas } from "../../utils/number"
import { ProfileAvatar } from "../profile-avatar/profile-avatar"
import { Icon } from "../icon/icon"
import { AutoImage } from "../auto-image/auto-image"
import { Text } from "../text/text"
import { navigationRef } from "app/navigators"
import { DrawerActions } from "@react-navigation/native"

const appLogo = require("../../../assets/images/logo-small.png")

const CARD_ROW: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}
const ICON: ImageStyle = {
  width: 18,
  height: 18,
  margin: 1,
  marginRight: spacing[1],
}
const BALANCE_CONTAINER: ViewStyle = {
  borderRadius: spacing[1],
  backgroundColor: color.dim,
  paddingHorizontal: spacing[1],
}

export const PageHeader = observer(function PageHeader() {
  const { authStore, settingStore } = useStores()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (authStore.tongtongWalletConnected && authStore.isAuthenticated) {
      handleFetchingWallet()
    }
  }, [])

  const handleFetchingWallet = async () => {
    if (authStore.tongtongWalletConnected) {
      setLoading(true)
      authStore
        .getWalletBalance()
        .then((result) => {
          setLoading(false)
          if (result) {
            authStore.setTongTongWalletBalance(JSON.stringify(result))
            authStore.setTongTongWalletConnected(true)
          } else {
            authStore.setTongTongWalletBalance(null)
            authStore.setTongTongWalletAddr(null)
            authStore.setTongTongWalletConnected(false)
          }
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
          authStore.setTongTongWalletBalance(null)
          authStore.setTongTongWalletAddr(null)
          authStore.setTongTongWalletConnected(false)
        })
    }
  }

  const WalletButton = () => {
    if (authStore.tongtongWalletConnected) {
      return (
        <TouchableOpacity
          style={{
            backgroundColor: color.border,
            padding: spacing[1],
            borderRadius: spacing[1],
          }}
          onPress={handleFetchingWallet}
          disabled={loading}
        >
          <View style={CARD_ROW}>
            <Icon icon="ttc" style={ICON} />
            <View style={BALANCE_CONTAINER}>
              <Text
                text={
                  authStore.getWalletBalanceTTCOIN() !== null
                    ? "" + numberWithCommas(authStore.getWalletBalanceTTCOIN() as number) + " TTC"
                    : "-"
                }
                preset="smallCenter"
              />
            </View>
          </View>
          <View style={CARD_ROW}>
            <Icon icon="ttm" style={ICON} />
            <View style={BALANCE_CONTAINER}>
              <Text
                text={
                  authStore.getWalletBalanceTTM() !== null
                    ? "" + numberWithCommas(authStore.getWalletBalanceTTM() as number) + " TTM"
                    : "-"
                }
                preset="smallCenter"
              />
            </View>
          </View>
        </TouchableOpacity>
      )
    } else {
      return <></>
    }
  }

  return (
    <View
      style={{
        backgroundColor: color.background,
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: spacing[4],
      }}
    >
      <View
        style={{
          flex: 1,
          alignItems: "flex-start",
        }}
      >
        {authStore.isAuthenticated ? (
          <TouchableOpacity
            onPress={() => {
              navigationRef.dispatch(DrawerActions.openDrawer())
            }}
          >
            <ProfileAvatar
              avatar={authStore.profile?.avatar_render || ""}
              size={42}
              isMaster={authStore.profile?.is_master || false}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={{
              backgroundColor: color.border,
              padding: spacing[2],
              borderRadius: spacing[2],
            }}
            onPress={() => {
              navigationRef.navigate("auth-stack")
            }}
          >
            <Text text="로그인" preset="bold" />
          </TouchableOpacity>
        )}
      </View>
      <View
        style={{
          flex: 1,
          alignItems: "center",
        }}
      >
        <AutoImage source={appLogo} style={{ width: 60, height: 60 }} />
      </View>
      {settingStore.hide_coin_ui ? (
        <View style={{ flex: 1 }} />
      ) : (
        <View
          style={{
            flex: 1,
            alignItems: "flex-end",
            marginVertical: spacing[1],
          }}
        >
          {!loading ? <WalletButton /> : <ActivityIndicator />}
        </View>
      )}
    </View>
  )
})
