/* eslint-disable no-use-before-define */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import {
  ViewStyle,
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Pressable,
  Keyboard,
  ScrollView,
} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { TabView, TabBar } from "react-native-tab-view"
import { Screen, Text, GeneralUserBox, SpaceBox, Header, PageHeader } from "../../components"
import { color, spacing } from "../../theme"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { useStores } from "../../models"
import { Space } from "../../models/space/space"
import { User } from "../../models/user/user"
import { Searchbar } from "react-native-paper"
import { SCREEN_WIDTH } from "app/utils/screen"

const NO_SEARCH_RESULT_RELATED: ViewStyle = {
  marginTop: spacing[8],
  marginBottom: spacing[2],
}

let userTabVisited = false

export const HomeSearchScreen: FC<StackScreenProps<NavigatorParamList, "homeSearch">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()
    const [showSearchResult, setShowSearchResult] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [index, setIndex] = useState(0)
    const [routes] = useState([
      { key: "spaceTab", title: "스페이스" },
      { key: "userTab", title: "닉네임" },
    ])
    const initialLayout = { width: SCREEN_WIDTH }
    const { authStore, keywordStore, spaceStore, userStore } = useStores()
    const [spaceSearchResult, setSpaceSearchResult] = useState<Space[]>([])
    const [userSearchResult, setUserSearchResult] = useState<User[]>([])
    const [clearSpinnerVisibility, setClearSpinnerVisibility] = useState(false)

    const renderTabBar = (props) => (
      <TabBar
        {...props}
        indicatorStyle={{ backgroundColor: color.primary }}
        style={{ backgroundColor: color.background }}
        activeColor={color.primary}
        inactiveColor={color.dim}
      />
    )

    const SpaceRoute = (jumpTo) => {
      if (searchSpaceLoading) {
        return (
          <ActivityIndicator style={{ flex: 1, justifyContent: "center", alignItems: "center" }} />
        )
      } else {
        if (
          (spaceSearchResult === null || spaceSearchResult.length === 0) &&
          userSearchResult !== null &&
          userSearchResult.length !== 0 &&
          userTabVisited === false
        ) {
          jumpTo.jumpTo("userTab")
        }
        return (
          <View
            style={{
              flex: 1,
              margin: spacing[4],
            }}
          >
            {spaceSearchResult === null || spaceSearchResult.length === 0 ? (
              <View>
                <Text preset="center" style={NO_SEARCH_RESULT_RELATED}>
                  <Text text={searchText} />
                  <Text tx="common.whiteSpace" />
                  <Text tx={"searchScreen.noSearchResultRelated"} preset="secondary" />
                </Text>
                <View
                  style={{
                    marginVertical: spacing[2],
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <TouchableOpacity onPress={() => {}}>
                    <Text
                      tx="searchScreen.createNewSpace"
                      style={{
                        fontWeight: "bold",
                        color: color.primary,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <FlatList
                columnWrapperStyle={{ justifyContent: "space-between" }}
                keyboardShouldPersistTaps="handled"
                data={spaceSearchResult.slice()}
                style={{ height: 650 }}
                horizontal={false}
                numColumns={2}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item) => item.space_id}
                renderItem={({ item }) => (
                  <SpaceBox
                    isCenter={true}
                    space={item}
                    refetchSpaces={() => {
                      fetchSearchSpaceResult()
                    }}
                  />
                )}
              />
            )}
          </View>
        )
      }
    }

    const UserRoute = (jumpTo) => {
      if (searchUserLoading) {
        return (
          <ActivityIndicator style={{ flex: 1, justifyContent: "center", alignItems: "center" }} />
        )
      } else {
        return (
          <View style={{ flex: 1, paddingVertical: spacing[2] }}>
            {userSearchResult === null || userSearchResult.length === 0 ? (
              <View>
                <Text preset="center" style={NO_SEARCH_RESULT_RELATED} numberOfLines={2}>
                  <Text text={searchText} />
                  <Text tx="common.whiteSpace" />
                  <Text tx={"searchScreen.noSearchResultRelated"} preset="secondary" />
                </Text>
              </View>
            ) : (
              <FlatList
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps="handled"
                data={userSearchResult}
                keyExtractor={(item) => item.profile_id}
                renderItem={({ item }) => (
                  <View key={item.profile_id}>
                    <GeneralUserBox
                      user={item}
                      onPress={() => {
                        if (item.account_id === authStore.profile?.account_id) {
                          navigation.push(
                            authStore.profile.is_master ? "masterProfile" : "userProfile",
                          )
                        } else {
                          navigation.push(
                            "userDetail" as never,
                            { profileId: item.profile_id, accountId: item.account_id } as never,
                          )
                        }
                      }}
                    />
                  </View>
                )}
              />
            )}
          </View>
        )
      }
    }

    const renderScene = ({ route, jumpTo }) => {
      switch (route.key) {
        case "spaceTab":
          return <SpaceRoute jumpTo={jumpTo} />
        case "userTab":
          return <UserRoute jumpTo={jumpTo} />
        default:
          return <SpaceRoute jumpTo={jumpTo} />
      }
    }

    const handleIndexChange = (index: number) => {
      userTabVisited = true
      setIndex(index)
    }

    const [searchSpaceLoading, setSearchSpaceLoading] = useState(false)
    const [searchUserLoading, setSearchUserLoading] = useState(false)
    const fetchSearchSpaceResult = async () => {
      setSearchSpaceLoading(true)
      setSpaceSearchResult(await spaceStore.searchSpace(searchText, 1000, 0))
      setSearchSpaceLoading(false)
    }

    const fetchSearchUserResult = async () => {
      setSearchUserLoading(true)
      setUserSearchResult(await userStore.searchUsers(searchText, 1000, 0))
      setSearchUserLoading(false)
    }

    const handleSearchPress = () => {
      if (!showSearchResult) {
        userTabVisited = false
        setSpaceSearchResult([])
        setUserSearchResult([])
        if (!(searchText.length === 0)) {
          keywordStore.addRecentKeyword(searchText)
          fetchSearchSpaceResult()
          fetchSearchUserResult()
          setShowSearchResult(true)
        }
      }
    }

    const handleClearPress = () => {
      setShowSearchResult(false)
      setSpaceSearchResult([])
      setUserSearchResult([])
      setSearchText("")
    }

    const handleChangeText = (text: string) => {
      setSearchText(text)
      setShowSearchResult(false)
    }

    const handleClearRecentKeywords = async () => {
      setClearSpinnerVisibility(true)
      keywordStore.saveRecentKeywords([])
      setClearSpinnerVisibility(false)
    }

    return (
      <View style={{ flex: 1 }}>
        <Screen style={{ backgroundColor: color.background }}>
          <PageHeader />
          <Header leftIcon="back" onLeftPress={goBack} headerText={"검색"} />
          <Searchbar
            style={{
              margin: spacing[4],
              borderWidth: 1,
              borderColor: color.border,
              borderRadius: 4,
              backgroundColor: color.background,
            }}
            value={searchText}
            iconColor="#000000"
            placeholder="검색어를 입력해 주세요"
            onChangeText={handleChangeText}
            onIconPress={handleSearchPress}
            onClearIconPress={handleClearPress}
          />
          {!showSearchResult ? (
            <View style={{ flex: 1 }}>
              <Pressable
                style={{ flex: 1, paddingHorizontal: spacing[4] }}
                onPress={() => Keyboard.dismiss()}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginVertical: spacing[2],
                  }}
                >
                  <Text tx={"searchScreen.recentKeyword"} preset="bold" />
                  {clearSpinnerVisibility ? (
                    <ActivityIndicator style={{ justifyContent: "center", alignItems: "center" }} />
                  ) : (
                    <TouchableOpacity onPress={handleClearRecentKeywords}>
                      <Text tx="common.clear" />
                    </TouchableOpacity>
                  )}
                </View>
                <ScrollView style={{ flex: 1 }}>
                  {keywordStore.recentKeywords.length === 0 ? (
                    <Text tx={"searchScreen.noSearchResult"} preset={"secondary"} />
                  ) : (
                    keywordStore.recentKeywords.map((keyword, index) => {
                      return (
                        <TouchableOpacity
                          onPress={() => setSearchText(keyword.keyword)}
                          key={index}
                        >
                          <View
                            style={{
                              paddingVertical: spacing[1],
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Text text={keyword.keyword} numberOfLines={1} />
                            <TouchableOpacity
                              onPress={() => {
                                keywordStore.removeKeyword(keyword.keyword)
                              }}
                            >
                              <MaterialIcons name="close" size={24} color={color.primary} />
                            </TouchableOpacity>
                          </View>
                        </TouchableOpacity>
                      )
                    })
                  )}
                </ScrollView>
              </Pressable>
            </View>
          ) : (
            <TabView
              swipeEnabled={true}
              navigationState={{ index, routes }}
              renderScene={renderScene}
              renderTabBar={renderTabBar}
              onIndexChange={handleIndexChange}
              initialLayout={initialLayout}
            />
          )}
        </Screen>
      </View>
    )
  },
)
