/* eslint-disable no-use-before-define */
import React, { useRef } from 'react'
import { StyleProp, View, ViewStyle, TouchableOpacity, FlatList } from 'react-native'
import { observer } from 'mobx-react-lite'
import { color, spacing } from '../../theme'
import { Text } from '../text/text'
import { useStores } from '../../models'
import { Category } from '../../models/category/category'

export interface CategorySlideSelectionProps {
  style?: StyleProp<ViewStyle>,
  selectedCategory: Category,
  onPressCategory(category: Category): void,
  isForMaster: boolean
}

const ITEM_WIDTH = 65

export const CategorySlideSelection = observer(function CategorySlideSelection (props: CategorySlideSelectionProps) {
  const { style, selectedCategory, isForMaster, onPressCategory } = props
  const styles = Object.assign({ flex: 1 }, style)
  const { categoryStore } = useStores()
  const ref = useRef<FlatList>(null)
  const categories = isForMaster ? categoryStore.getMasterCategories() : categoryStore.getCommunityCategories()
  const initialIndex = categories.map(category => category.category_id).indexOf(selectedCategory.category_id)

  return (
    <View style={styles}>
      {categories.length === 0
        ? (<></>)
        : (
        <FlatList
          ref={ref}
          initialScrollIndex={initialIndex}
          getItemLayout={(_, index) => ({ length: ITEM_WIDTH + spacing[1] * 2, offset: (ITEM_WIDTH + spacing[1] * 2) * index, index })}
          keyboardShouldPersistTaps="handled"
          data={categories.slice()}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <TouchableOpacity
              onPress={() => { onPressCategory(item) }}
              style={{
                width: ITEM_WIDTH,
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: color.text,
                backgroundColor: item.category_id === selectedCategory.category_id ? color.text : color.background,
                borderWidth: 1,
                borderRadius: spacing[4],
                paddingVertical: spacing[1],
                paddingHorizontal: spacing[2],
                marginHorizontal: spacing[1]
              }}
            >
              <Text
                text={item.name || ''}
                preset='small'
                numberOfLines={1}
                style={{ color: item.category_id === selectedCategory.category_id ? color.background : color.text }}
              />
            </TouchableOpacity>
          )}
        />)}
    </View>
  )
})
