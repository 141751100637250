/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, FlatList, ActivityIndicator, TouchableOpacity } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Text, Screen, Header, PageHeader, SpaceBox } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { Space } from "../../models/space/space"
import { getMonthString } from "../../utils/date_time"

let isMounted = false

const getTitleFromType = (type: string) => {
  if (type === "brand") {
    return "브랜드관"
  } else if (type === "monthly") {
    return `${getMonthString()}의 인기 있는 스페이스를 모았어요!`
  } else return "현재 대화 중인 스페이스"
}

export const HomeCommunityAllScreen: FC<StackScreenProps<NavigatorParamList, "homeCommunityAll">> =
  observer(({ route, navigation }) => {
    const { type } = route.params
    const goBack = () => navigation.goBack()
    const { spaceStore, authStore } = useStores()

    const [spaces, setSpaces] = useState<Space[]>([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [noMore, setNoMore] = useState(false)
    let onEndReachedCalledDuringMomentum = false

    const fetchData = async () => {
      if (isMounted) {
        setLoading(true)
        let result = []
        result = await spaceStore.fetchSpaces(10, page)
        setNoMore(result.length === 0)
        setSpaces(spaces.concat(result))
        setPage(page + 1)
        setLoading(false)
      }
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      return () => {
        setLoading(true)
        isMounted = false
      }
    }, [])

    const handleLoadMore = () => {
      fetchData()
    }

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <PageHeader />
          <Header leftIcon="back" onLeftPress={goBack} headerText={getTitleFromType(type)} />
          <View style={{ flex: 1, paddingHorizontal: spacing[4] }}>
            <FlatList
              columnWrapperStyle={{ justifyContent: "space-between" }}
              keyboardShouldPersistTaps="handled"
              style={{ flex: 1 }}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              data={spaces.slice()}
              numColumns={2}
              keyExtractor={(item) => item.space_id}
              initialNumToRender={10}
              onMomentumScrollBegin={() => {
                onEndReachedCalledDuringMomentum = false
              }}
              onEndReached={() => {
                if (!onEndReachedCalledDuringMomentum) {
                  handleLoadMore()
                  onEndReachedCalledDuringMomentum = true
                }
              }}
              onEndReachedThreshold={0.1}
              renderItem={({ item }) => (
                <SpaceBox
                  isCenter={true}
                  space={item}
                  refetchSpaces={async () => {
                    setPage(0)
                    setSpaces([])
                    handleLoadMore()
                  }}
                />
              )}
              ListFooterComponent={
                loading && !noMore ? (
                  <ActivityIndicator />
                ) : (
                  <View
                    style={{
                      marginVertical: spacing[4],
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Text tx="categoryScreen.noWantedSpace" />
                    <Text tx="common.whiteSpace" />
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate("createSpace")
                      }}
                    >
                      <Text
                        tx="categoryScreen.spaceCreate"
                        style={{
                          fontWeight: "bold",
                          color: color.primary,
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                )
              }
            />
          </View>
        </Screen>
      </View>
    )
  })
