import moment from 'moment'

export function utcToKrTime (utcDateString: string | Date): string {
  moment.locale('ko')
  const localDate = moment.utc(utcDateString).local()
  const currentDate = moment().local()

  let formatedString = ''
  if (currentDate.day() - localDate.day() === 0) {
    // today
    formatedString = localDate.format('A hh시 mm분')
  } else if (currentDate.day() - localDate.day() === 1) {
    // yesterday
    formatedString = '어제 ' + localDate.format('A hh시 mm분')
  } else {
    // other
    if (localDate.year() === currentDate.year()) {
      formatedString = localDate.format('M월 D일 A hh시 mm분')
    } else {
      formatedString = localDate.format('YYYY년 M월 D일 A hh시 mm분')
    }
  }
  return formatedString
}

export function getMonthString (): string {
  moment.locale('ko')
  return moment().subtract(1, 'months').month() + 1 + '월'
}

export function getDayOfWeekIndex (day: string): number {
  switch (day) {
    case '일요일':
      return 0
    case '월요일':
      return 1
    case '화요일':
      return 2
    case '수요일':
      return 3
    case '목요일':
      return 4
    case '금요일':
      return 5
    case '토요일':
      return 6
    default:
      return 0
  }
}
