/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import { View, ViewStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { Button } from "../button/button"
import { Text } from "../text/text"
import { SCREEN_WIDTH } from "../../utils/screen"
import { TimeRange } from "app/types"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import uuid from "react-native-uuid"
import moment, { Moment } from "moment"
import Picker from "rc-picker"
import koKR from "rc-picker/lib/locale/ko_KR"
import generateConfig from "rc-picker/es/generate/moment"
import "rc-picker/assets/index.css"
import "moment/locale/ko"

export interface SelectedDay {
  uuid: string
  selected: boolean
  dayOfWeek: string
}

export interface SelectedTime {
  uuid: string
  start: moment.Moment
  end: moment.Moment
}

export interface CounselingDateTimePickerProps {
  days: string[]
  times: TimeRange[]
  setDays: (value: string[]) => void
  setTimes: (value: TimeRange[]) => void
}
const SELECTED_ROUND: ViewStyle = {
  borderWidth: 1,
  borderRadius: spacing[2],
  marginVertical: spacing[2],
  paddingHorizontal: spacing[2],
  paddingVertical: spacing[4],
  borderColor: color.primary,
  backgroundColor: color.primary,
}
const UNSELECTED_ROUND: ViewStyle = {
  borderWidth: 1,
  borderRadius: spacing[2],
  marginVertical: spacing[2],
  paddingHorizontal: spacing[2],
  paddingVertical: spacing[4],
  borderColor: color.dim,
  backgroundColor: color.background,
}

const getNextSevenDays = () => {
  const sevenDays: SelectedDay[] = []
  sevenDays.push({
    uuid: `${uuid.v4()}`,
    selected: false,
    dayOfWeek: "월요일",
  })
  sevenDays.push({
    uuid: `${uuid.v4()}`,
    selected: false,
    dayOfWeek: "화요일",
  })
  sevenDays.push({
    uuid: `${uuid.v4()}`,
    selected: false,
    dayOfWeek: "수요일",
  })
  sevenDays.push({
    uuid: `${uuid.v4()}`,
    selected: false,
    dayOfWeek: "목요일",
  })
  sevenDays.push({
    uuid: `${uuid.v4()}`,
    selected: false,
    dayOfWeek: "금요일",
  })
  sevenDays.push({
    uuid: `${uuid.v4()}`,
    selected: false,
    dayOfWeek: "토요일",
  })
  sevenDays.push({
    uuid: `${uuid.v4()}`,
    selected: false,
    dayOfWeek: "일요일",
  })
  return sevenDays
}

export const CounselingDateTimePicker = observer(function CounselingDateTimePicker(
  props: CounselingDateTimePickerProps,
) {
  const { setDays, setTimes, days, times } = props
  const [sevenDays, setSevenDays] = useState(getNextSevenDays())
  const [startTime, setStartTime] = useState<moment.Moment>(moment())
  const [endTime, setEndTime] = useState<moment.Moment>(moment())
  const [showTimePicker, setShowTimePicker] = useState(false)
  const [selectedTimes, setSelectedTimes] = useState<SelectedTime[]>([])
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState("")

  const handleAddTime = () => {
    let validBetween = true
    if (startTime.isBefore(endTime)) {
      selectedTimes.forEach((time) => {
        if (
          startTime.isBetween(time.start, time.end, undefined, "[]") ||
          endTime.isBetween(time.start, time.end, undefined, "[]")
        ) {
          validBetween = false
        }
      })
      if (validBetween) {
        setSelectedTimes(
          selectedTimes.concat({ uuid: `${uuid.v4()}`, start: startTime, end: endTime }),
        )
        setShowTimePicker(false)
      }
    }
  }

  const handleApply = () => {
    if (sevenDays.filter((day) => day.selected).length === 0) {
      setError("날짜 / 시간을 선택해 주세요.")
      return
    }
    if (selectedTimes.length === 0) {
      setError("시간을 선택해 주세요.")
      return
    }
    setError("")
    setDays(sevenDays.filter((day) => day.selected).map((day) => day.dayOfWeek))
    setTimes(selectedTimes.map((time) => ({ start: time.start, end: time.end })))
    setShowModal(false)
  }

  return (
    <View>
      <Button
        text={
          days.length === 0 && times.length === 0 ? "날짜 / 시간 선택하기" : "날짜 / 시간 수정하기"
        }
        style={{
          backgroundColor: color.background,
          borderWidth: 1,
          borderColor: color.dim,
          marginVertical: spacing[4],
        }}
        textStyle={{ color: color.text }}
        onPress={() => {
          setShowModal(true)
        }}
      />
      {showModal && (
        <View
          style={{
            backgroundColor: color.halfTransparency,
            padding: spacing[4],
            width: "100%",
            borderRadius: spacing[4],
          }}
        >
          <Text text={"상담 가능 요일"} preset={"boldCenter"} />
          <View style={{ flexDirection: "row", flexWrap: "wrap", marginVertical: spacing[4] }}>
            {sevenDays.map((item, index) => {
              return (
                <View
                  key={index}
                  style={{
                    flex: 1,
                    alignItems: "center",
                    minWidth: (SCREEN_WIDTH - spacing[4] * 4) / 4,
                  }}
                >
                  <TouchableOpacity
                    style={item.selected ? SELECTED_ROUND : UNSELECTED_ROUND}
                    onPress={() => {
                      setSevenDays(
                        [...sevenDays].map((object) => {
                          if (object.uuid === item.uuid) {
                            return {
                              ...object,
                              selected: !item.selected,
                            }
                          } else {
                            return object
                          }
                        }),
                      )
                    }}
                  >
                    <View>
                      <Text
                        text={item.dayOfWeek}
                        preset={"center"}
                        style={{ color: item.selected ? color.background : color.text }}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              )
            })}
          </View>
          <Text text={"상담 가능 시간"} preset={"boldCenter"} />
          <View
            style={{ justifyContent: "center", alignItems: "center", marginVertical: spacing[4] }}
          >
            {selectedTimes.map((time, index) => {
              return (
                <View
                  key={index}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginVertical: spacing[1],
                    marginLeft: spacing[4],
                  }}
                >
                  <Text
                    text={time.start.format("hh:mm a")}
                    style={{ borderWidth: 1, borderColor: color.text, padding: spacing[1] }}
                  />
                  <Text text={"~"} style={{ marginHorizontal: spacing[4] }} />
                  <Text
                    text={time.end.format("hh:mm a")}
                    style={{ borderWidth: 1, borderColor: color.text, padding: spacing[1] }}
                  />
                  <TouchableOpacity
                    style={{ marginLeft: spacing[4] }}
                    onPress={() => {
                      setSelectedTimes(selectedTimes.filter((item) => time.uuid !== item.uuid))
                    }}
                  >
                    <MaterialCommunityIcons
                      name="close-circle-outline"
                      size={32}
                      color={color.text}
                    />
                  </TouchableOpacity>
                </View>
              )
            })}
            {showTimePicker && (
              <View
                style={{
                  zIndex: 2000,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginVertical: spacing[2],
                  borderWidth: 1,
                  borderColor: color.dim,
                  padding: spacing[2],
                  borderRadius: spacing[2],
                }}
              >
                <Picker<Moment>
                  generateConfig={generateConfig}
                  locale={koKR}
                  picker="time"
                  value={startTime}
                  onSelect={(newValue: Moment) => {
                    setStartTime(newValue)
                  }}
                />
                <Text text={"~"} style={{ marginHorizontal: spacing[2] }} />
                <Picker<Moment>
                  generateConfig={generateConfig}
                  locale={koKR}
                  picker="time"
                  value={moment(endTime)}
                  onSelect={(newValue: Moment) => {
                    setEndTime(newValue)
                  }}
                />
                <Button text={"추가"} style={{ marginLeft: spacing[2] }} onPress={handleAddTime} />
              </View>
            )}
            <Button
              text={"시간 추가"}
              style={{
                borderWidth: 1,
                borderColor: color.text,
                backgroundColor: color.background,
                marginTop: spacing[2],
              }}
              textStyle={{ color: color.text }}
              onPress={() => {
                setShowTimePicker(true)
              }}
            />
          </View>
          {error.length !== 0 && (
            <Text text={error} preset="warningCenter" style={{ marginBottom: spacing[2] }} />
          )}
          <Button text={"적용"} onPress={handleApply} />
        </View>
      )}
    </View>
  )
})
