/* eslint-disable no-use-before-define */
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { ScrollView, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { NavigatorParamList } from '../../navigators'
import { Button, Header, Screen, Text, TextField } from '../../components'
import { color, spacing } from '../../theme'
import { useStores } from '../../models'
import Toast from 'react-native-toast-message'

type FormData = {
  otp: string
}

export const RegisterOTPScreen: FC<StackScreenProps<NavigatorParamList, 'registerOTP'>> = observer(
  ({ route, navigation }) => {
    const { userKey, phonenum, nation } = route.params
    const goBack = () => navigation.goBack()
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<FormData>()
    const { authStore } = useStores()

    const onVerifyCode = async (data: FormData) => {
      const res = await authStore.oneVerifyCode(userKey, data.otp)
      if (res && !res.message && res.result === true && res.value === true) {
        navigation.navigate('registerPassword', {
          userKey: userKey,
          phonenum: phonenum,
          nation: nation,
        })
      } else {
        Toast.show({
          type: 'error',
          text1: '인증번호가 일치하지 않습니다',
          position: 'bottom',
        })
      }
    }

    const onResendCode = async () => {
      const res = await authStore.oneJoinRecerti(userKey)
      if (res) {
        if (res.message && res.result === false && res.value === false) {
          const errorMessage = () => {
            if (res.message === 'INVALID_PHONE') return '유효하지 않은 전화번호입니다'
            else if (res.message === 'REGISTERED_PHONE') return '이미 회원가입 된 전화번호입니다'
            else if (res.message === 'TWILIO_DAY_LIMIT') return '제한 초과.'
            else return res.message
          }
          Toast.show({
            type: 'error',
            text1: errorMessage(),
            position: 'bottom',
          })
        } else {
          Toast.show({
            type: 'success',
            text1: '인증번호 재발송 성공',
            position: 'bottom',
          })
        }
      } else {
        Toast.show({
          type: 'error',
          text1: '인증번호 발송 실패',
          position: 'bottom',
        })
      }
    }

    return (
      <View style={{ flex: 1 }}>
        <Screen
          style={{
            flex: 1,
            backgroundColor: color.transparent,
          }}
          preset="fixed"
        >
          <Header leftIcon="back" onLeftPress={goBack} headerText={'회원가입'} />
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              paddingHorizontal: spacing[6],
              justifyContent: 'space-evenly',
            }}
          >
            <View style={{ alignItems: 'center' }}>
              <Text text="문자로 전송한 인증번호를 입력해주세요" />
              <View style={{ flex: 1, width: '100%' }}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      keyboardType="numeric"
                      placeholder="인증번호"
                      style={{ padding: spacing[2], marginVertical: spacing[4] }}
                      inputStyle={{ padding: spacing[0], textAlign: 'center' }}
                    />
                  )}
                  name="otp"
                  defaultValue=""
                />
                {errors.otp && errors.otp.type === 'required' && (
                  <Text
                    preset="warning"
                    text="인증번호를 입력해 주세요"
                    style={{ textAlign: 'center' }}
                  />
                )}
              </View>
            </View>
            <View style={{ alignItems: 'center' }}>
              <Button
                text={authStore.status === 'pending' ? '로딩중...' : '확인'}
                onPress={handleSubmit(onVerifyCode)}
                style={{ width: '100%', marginTop: spacing[4] }}
                disabled={authStore.status === 'pending'}
              />
              <Button
                text={authStore.status === 'pending' ? '로딩중...' : '인증번호 재발송'}
                onPress={onResendCode}
                style={{
                  width: '100%',
                  marginTop: spacing[4],
                  backgroundColor: color.background,
                  borderColor: color.primary,
                  borderWidth: 1,
                }}
                textStyle={{ color: color.primary }}
                disabled={authStore.status === 'pending'}
              />
            </View>
          </ScrollView>
        </Screen>
      </View>
    )
  },
)
