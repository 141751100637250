/* eslint-disable no-use-before-define */
import * as React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import { spacing } from '../../theme'
import { Text } from '../text/text'
import { ProfileAvatar } from '../profile-avatar/profile-avatar'

const CONTAINER: ViewStyle = {
  backgroundColor: '#F1F1F1',
  borderRadius: spacing[2],
  padding: spacing[2],
  marginVertical: spacing[1],
  flexDirection: 'row'
}

export interface ReviewBoxProps {
  style?: StyleProp<ViewStyle>,
  review: any
}

export const ReviewBox = observer(function ReviewBox (props: ReviewBoxProps) {
  const { style, review } = props
  const styles = Object.assign({}, CONTAINER, style)

  return (
    <View style={styles}>
      <View style={{ alignItems: 'center', justifyContent: 'center', width: '20%' }}>
        <ProfileAvatar avatar={review.buyer_avatar_render || ''} size={48}/>
        <Text text={review.buyer_nick_name || ''} numberOfLines={1} style={{ marginTop: spacing[2] }}/>
      </View>
      <View style={{ flex: 1, marginLeft: spacing[2] }}>
        <Text numberOfLines={1}>
          <Text text={`${Math.round(review.rating)} / 5`}/>
          <Text text={'  '}/>
          <Text text={review.counseling_title} preset={'bold'}/>
        </Text>
        <Text text={review.review} style={{ marginTop: spacing[1] }}/>
      </View>
    </View>
  )
})
