/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, ScrollView, Image, ImageStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, Text, Button } from "../../components"
import { color, spacing } from "../../theme"
import { Rating } from "react-native-ratings"
import { useStores } from "../../models"

const CATEGORY_IMAGE: ImageStyle = {
  width: 60,
  height: 60,
  marginBottom: spacing[1],
}

const placeHolder = require("../../../assets/images/place-holder.png")
const ttc = require("../../../assets/images/ttc.png")

export const CounselingDetailScreen: FC<StackScreenProps<NavigatorParamList, "counselingDetail">> =
  observer(({ route, navigation }) => {
    const goBack = () => navigation.goBack()
    const { counseling, masterCategory, master } = route.params
    const { authStore } = useStores()

    const handleBookCounseling = () => {
      // if (authStore.tongtongWalletConnected && authStore.tongtongWalletBalance) {
      //   const walletTTCOIN = JSON.parse(authStore.tongtongWalletBalance).TTCOIN
      //   if (walletTTCOIN <= !!counseling.price) {
      //     alert("잔고가 부족합니다.")
      //   } else {
      //     navigation.navigate("bookCounseling", {
      //       counseling: counseling,
      //       master_name: master.nick_name as string,
      //     })
      //   }
      // } else {
      //   alert(
      //     "통통지갑을 연동한 상태에서 사용할 수 있는 서비스입니다. [설정 / 통통지갑 연동]에서 지갑 연동을 해주십시오.",
      //   )
      // }
      navigation.navigate("bookCounseling", {
        counseling: counseling,
        master_name: master.nick_name as string,
      })
    }

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <Header
            leftIcon="back"
            onLeftPress={goBack}
            headerText={`${master?.nick_name} 마스터 상담`}
          />
          <View style={{ height: 1, backgroundColor: color.border }} />
          <ScrollView
            contentContainerStyle={{ padding: spacing[4] }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={{ alignItems: "center" }}>
              {masterCategory?.thumb ? (
                <Image source={{ uri: masterCategory?.thumb as string }} style={CATEGORY_IMAGE} />
              ) : (
                <Image source={placeHolder} style={CATEGORY_IMAGE} />
              )}
              <Text text={masterCategory?.name as string} preset={"bold"} />
            </View>
            <View>
              <Rating
                type={"custom"}
                ratingBackgroundColor={"#f1f1f1"}
                imageSize={32}
                readonly={true}
                fractions={0}
                startingValue={Math.round(counseling.ratings as number)}
                tintColor={color.background}
                style={{ marginVertical: spacing[4] }}
              />
              <View
                style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
              >
                <Text text={`${Math.round(counseling.ratings || 0)} / 5 (${counseling.count})`} />
                <Button
                  text={"자세히 보기"}
                  preset={"link"}
                  style={{ marginLeft: spacing[4] }}
                  textStyle={{ color: color.dim }}
                  onPress={() => {
                    authStore.setToReviewTab(true)
                    goBack()
                  }}
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginVertical: spacing[4],
                }}
              >
                <Image source={ttc} style={{ width: 18, height: 18 }} />
                <Text
                  text={`${counseling.price} / ${counseling.period}분`}
                  style={{ marginLeft: spacing[2] }}
                />
              </View>
              <View>
                <Text
                  text={counseling.title as string}
                  preset={"headerCenter1"}
                  style={{ marginVertical: spacing[4] }}
                />
                <Text
                  text={counseling.description as string}
                  style={{ marginVertical: spacing[4] }}
                  preset={"center"}
                />
              </View>
            </View>
            {!authStore.profile?.is_master && (
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <Button
                  text={"상담 신청하기"}
                  style={{ margin: spacing[4] }}
                  onPress={handleBookCounseling}
                />
              </View>
            )}
          </ScrollView>
        </Screen>
      </View>
    )
  })
