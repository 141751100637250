export const icons = {
  back: require('./arrow-left.png'),
  bullet: require('./bullet.png'),
  bug: require('./ladybug.png'),
  point: require('./point.png'),
  ttmile: require('./tongtongmile.png'),
  ttc: require('./ttc.png'),
  ttm: require('./ttm.png')
}

export type IconTypes = keyof typeof icons
