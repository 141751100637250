/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import { StyleProp, View, ViewStyle, TouchableOpacity, TextInput } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing, color, typography } from "../../theme"
import { Text } from "../text/text"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
}

export interface AddressTextFieldProps {
  style?: StyleProp<ViewStyle>
  masterInputAddress: string
  setMasterInputAddress: (value: string) => void
}

export const AddressTextField = observer(function AddressTextField(props: AddressTextFieldProps) {
  const { style, masterInputAddress, setMasterInputAddress } = props
  const styles = Object.assign({}, CONTAINER, style)

  return (
    <View style={styles}>
      <Text text={"사업장주소"} preset={"bold"} style={{ marginTop: spacing[2] }} />
      <View
        style={{
          flexDirection: "row",
          backgroundColor: color.background,
          borderWidth: 1,
          borderColor: color.border,
          borderRadius: spacing[1],
          padding: spacing[2],
          marginVertical: spacing[2],
        }}
      >
        <TextInput
          value={masterInputAddress}
          onChangeText={(text) => setMasterInputAddress(text)}
          placeholder={"상세주소 입력"}
          placeholderTextColor={color.palette.lighterGrey}
          underlineColorAndroid={color.transparent}
          style={{
            flex: 1,
            fontFamily: typography.primary,
            color: color.text,
            fontSize: 14,
            backgroundColor: color.background,
            padding: 0,
          }}
        />
        <TouchableOpacity
          style={{ marginLeft: spacing[2], justifyContent: "center" }}
          onPress={() => {
            alert("웹버전에서 지원하지 않는 기능입니다.")
          }}
        >
          <MaterialCommunityIcons name="map-marker" size={24} color={color.primary} />
        </TouchableOpacity>
      </View>
    </View>
  )
})
