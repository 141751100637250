/* eslint-disable camelcase */
// eslint-disable-next-line no-use-before-define
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View, ScrollView, TouchableOpacity, FlatList, Image } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { TabView, TabBar } from "react-native-tab-view"
import { Screen, Text, Button, ProfileAvatar, GeneralUserBox } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { User } from "../../models/user/user"
import { SCREEN_WIDTH } from "app/utils/screen"

const placeHolder = require("../../../assets/images/place-holder.png")

const TAB_BAR_INDICATOR: ViewStyle = {
  backgroundColor: color.primary,
}
const TAB_BAR_VIEW: ViewStyle = {
  backgroundColor: color.background,
}

let isMounted = false

export const UserProfileScreen: FC<StackScreenProps<NavigatorParamList, "userProfile">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()
    const { categoryStore, authStore } = useStores()
    const [likings, setLikings] = useState<User[]>([])
    const initialLayout = { width: SCREEN_WIDTH }
    const [index, setTabIndex] = useState(0)
    const [routes] = useState([
      { key: "interestsTab", title: "" },
      { key: "likesTab", title: "" },
    ])

    const renderTabBar = (props) => (
      <TabBar
        {...props}
        indicatorStyle={TAB_BAR_INDICATOR}
        style={TAB_BAR_VIEW}
        activeColor={color.primary}
        inactiveColor={color.dim}
        renderIcon={({ route }) =>
          route.key === "interestsTab" ? (
            <Text text={"관심사"} preset={"headerCenter1"} />
          ) : (
            <Text text={"Like"} preset={"headerCenter1"} />
          )
        }
        renderLabel={() => <></>}
      />
    )

    const InterestsRoute = (jumpTo) => {
      return (
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ flexGrow: 1, margin: spacing[4] }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
            <View style={{ flexDirection: "row" }}>
              {authStore.profile?.interests?.length !== 0 ? (
                authStore.profile?.interests?.map((item) => {
                  const selectedCategory = categoryStore.categories.find(
                    (category) => category.category_id === item,
                  )
                  if (selectedCategory !== undefined) {
                    return (
                      <View key={item} style={{ marginRight: spacing[5], alignItems: "center" }}>
                        {selectedCategory && selectedCategory.thumb ? (
                          <Image
                            source={{ uri: selectedCategory.thumb }}
                            style={{ width: 24, height: 24 }}
                          />
                        ) : (
                          <Image source={placeHolder} style={{ width: 24, height: 24 }} />
                        )}
                        <Text
                          text={selectedCategory?.name || ""}
                          style={{ marginVertical: spacing[2] }}
                          preset="small"
                        />
                      </View>
                    )
                  } else {
                    return <View key={item}></View>
                  }
                })
              ) : (
                <></>
              )}
            </View>
          </View>
        </ScrollView>
      )
    }

    const LikesRoute = (jumpTo) => {
      if (likings.length > 0) {
        return (
          <View
            style={{
              flex: 1,
            }}
          >
            <FlatList
              contentContainerStyle={{ marginVertical: spacing[2] }}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              keyboardShouldPersistTaps="handled"
              data={likings}
              initialNumToRender={10}
              keyExtractor={(item) => item.profile_id}
              renderItem={({ item }) => (
                <View key={item.profile_id}>
                  <GeneralUserBox
                    user={item}
                    onPress={() => {
                      if (item.account_id === authStore.profile?.account_id) {
                        navigation.push(
                          authStore.profile.is_master ? "masterProfile" : "userProfile",
                        )
                      } else {
                        navigation.push(
                          "userDetail" as never,
                          { profileId: item.profile_id, accountId: item.account_id } as never,
                        )
                      }
                    }}
                    needDelete={true}
                    onAfterDelete={() => {
                      setLikings(
                        likings.filter((following) => following.account_id !== item.account_id),
                      )
                    }}
                  />
                </View>
              )}
            />
          </View>
        )
      } else {
        return <View style={{ flex: 1 }} />
      }
    }

    const renderScene = ({ route, jumpTo }) => {
      switch (route.key) {
        case "interestsTab":
          return <InterestsRoute jumpTo={jumpTo} />
        case "likesTab":
          return <LikesRoute jumpTo={jumpTo} />
        default:
          return <InterestsRoute jumpTo={jumpTo} />
      }
    }

    const fetchData = async () => {
      if (isMounted) {
        await Promise.all([
          authStore.fetchUserLikingsAll(authStore.profile?.account_id as string),
          categoryStore.fetchCategories(),
        ]).then((results) => {
          setLikings(results[0])
        })
      }
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      return () => {
        isMounted = false
      }
    }, [])

    return (
      <View style={{ flex: 1 }}>
        <Screen>
          <View
            style={{
              flexDirection: "row",
              paddingHorizontal: spacing[4],
              justifyContent: "space-between",
              marginTop: spacing[2],
            }}
          >
            <Button preset="link" onPress={goBack}>
              <MaterialIcons name="arrow-back" size={32} color={color.text} />
            </Button>
            <ProfileAvatar avatar={authStore.profile?.avatar_render || ""} size={64} />
            <View style={{ width: 32 }} />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "flex-end", marginRight: spacing[4] }}
          >
            <Button
              text={"프로필 수정"}
              onPress={() => {
                setTabIndex(0)
                navigation.navigate("editProfile")
              }}
              style={{
                borderRadius: spacing[5],
                borderColor: color.primary,
                borderWidth: 1,
                backgroundColor: color.background,
              }}
              textStyle={{ color: color.primary }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: spacing[4],
              minHeight: 28,
            }}
          >
            <Text
              text={authStore.profile?.nick_name?.slice(0, 9) || ""}
              numberOfLines={1}
              preset={"header1"}
              style={{ marginLeft: spacing[4] }}
            />
          </View>
          <View style={{ alignItems: "flex-start" }}>
            <TouchableOpacity
              style={{
                flexDirection: "row",
                marginVertical: spacing[2],
                backgroundColor: "#F1F1F1",
                borderRadius: spacing[2],
                marginHorizontal: spacing[4],
                paddingHorizontal: spacing[4],
                paddingVertical: spacing[2],
              }}
              onPress={() => {
                navigation.navigate("followerFollowing", {
                  accountId: authStore.profile?.account_id as string,
                })
              }}
            >
              <Text
                preset="bold"
                text={authStore.profile?.follower ? authStore.profile?.follower + "" : "0"}
              />
              <Text text={" 팔로워 "} />
              <Text
                preset="bold"
                text={authStore.profile?.following ? authStore.profile?.following + "" : "0"}
              />
              <Text text={" 팔로잉 "} />
            </TouchableOpacity>
          </View>
          {authStore.profile?.intro ? (
            <View
              style={{
                flexDirection: "row",
                marginHorizontal: spacing[4],
                backgroundColor: "#F1F1F1",
                borderRadius: spacing[2],
                paddingHorizontal: spacing[4],
                paddingVertical: spacing[2],
                justifyContent: "space-between",
              }}
            >
              <Text
                text={authStore.profile?.intro?.replace(/\//g, "\n")}
                style={{ marginVertical: spacing[0] }}
              />
            </View>
          ) : (
            <></>
          )}
          <TabView
            swipeEnabled={true}
            navigationState={{ index, routes }}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            onIndexChange={(index) => {
              setTabIndex(index)
            }}
            initialLayout={initialLayout}
          />
        </Screen>
      </View>
    )
  },
)
