/* eslint-disable no-use-before-define */
import React, { useContext } from "react"
import { TouchableOpacity, View } from "react-native"
import { observer } from "mobx-react-lite"
import { Channel } from "stream-chat"
import { useStores } from "../../models"
import { ProfileAvatar } from "../profile-avatar/profile-avatar"
import { spacing } from "../../theme"
import { Text } from "../text/text"
import { navigationRef } from "../../navigators"
import { ChatContext } from "app/services/stream/chat-context"

export interface ChannelBoxProps {
  channel: Channel
  lastMessage: any
}

export const ChannelBox = observer(function ChannelBox(props: ChannelBoxProps) {
  const { channel, lastMessage } = props
  const { authStore } = useStores()
  const peer = Object.values(channel.state.members).filter(
    (member) => member.user_id !== authStore.profile?.account_id,
  )[0]
  const { setChannel } = useContext(ChatContext)

  const navigateToChatScreen = async () => {
    setChannel(channel)
    navigationRef.navigate("chat", { accountId: peer?.user_id } as never)
  }

  return (
    <TouchableOpacity
      style={{ flexDirection: "row", padding: spacing[4] }}
      onPress={navigateToChatScreen}
    >
      <ProfileAvatar avatar={(peer?.user?.image as string) || ""} size={48} />
      <View style={{ justifyContent: "center", marginLeft: spacing[4], flex: 1 }}>
        <Text text={peer?.user?.name} preset="bold" />
        <Text
          text={
            (lastMessage?.user?.id === authStore.profile?.account_id
              ? "나"
              : lastMessage?.user?.name
              ? lastMessage?.user?.name
              : "") +
            " : " +
            (lastMessage?.text ? lastMessage?.text : "")
          }
          numberOfLines={1}
          preset={"secondary"}
        />
      </View>
    </TouchableOpacity>
  )
})
