// eslint-disable-next-line no-use-before-define
import * as React from 'react'
import { TouchableOpacity, ActivityIndicator } from 'react-native'
import { Text } from '../text/text'
import { viewPresets, textPresets } from './button.presets'
import { ButtonProps } from './button.props'

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native one.
 */
export function Button (props: ButtonProps) {
  // grab the props
  const {
    preset = 'primary',
    tx,
    text,
    style: styleOverride,
    textStyle: textStyleOverride,
    children,
    loading = false,
    ...rest
  } = props

  const viewStyle = viewPresets[preset] || viewPresets.primary
  const viewStyles = [viewStyle, styleOverride]
  const textStyle = textPresets[preset] || textPresets.primary
  const textStyles = [textStyle, textStyleOverride]
  const content = children || <Text tx={tx} text={text} style={textStyles} />

  const renderContent = () => {
    if (loading) {
      return <ActivityIndicator />
    } else {
      return <>{content}</>
    }
  }

  return (
    <TouchableOpacity style={viewStyles} {...rest}>
      {renderContent()}
    </TouchableOpacity>
  )
}
