/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, ScrollView, RefreshControl, TouchableOpacity } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, PageHeader, CategoryBox, Text, MastersMore } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { User } from "../../models/user/user"

let isMounted = false

export const HomeMastersScreen: FC<StackScreenProps<NavigatorParamList, "homeMasters">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()
    const [refreshing, setRefreshing] = useState(false)
    const { authStore, categoryStore, userStore } = useStores()
    const [masters, setMasters] = useState<User[]>([])
    const masterCategories = categoryStore.getMasterCategories()

    const isCategoryInclusive = (master: User) => {
      if (master.interests === undefined) return true
      if (master.interests === undefined || (master.interests && master.interests.length === 0)) {
        return true
      }
      if (!authStore.isAuthenticated) return true
      return authStore.profile?.interests?.some((interest) => master.interests?.includes(interest))
    }

    const fetchData = async () => {
      if (isMounted) {
        setRefreshing(true)
        await Promise.all([categoryStore.fetchCategories(), userStore.fetchMasters(1000, 0)]).then(
          (results) => {
            setMasters(results[1])
          },
        )
        setRefreshing(false)
      }
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      return () => {
        setRefreshing(false)
        isMounted = false
      }
    }, [])

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <PageHeader />
          <Header leftIcon="back" onLeftPress={goBack} headerText={"마스터와 소통하기"} />
          <ScrollView
            style={{ flex: 1 }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={fetchData} />}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                backgroundColor: "#F1F1F1",
                borderRadius: spacing[2],
                margin: spacing[4],
              }}
            >
              {masterCategories.length > 0 ? (
                masterCategories.map((item, index) => {
                  return (
                    <CategoryBox
                      key={index}
                      onPressBox={() => {
                        navigation.navigate("homeMastersByCategory", { category: item })
                      }}
                      category={item}
                    />
                  )
                })
              ) : (
                <></>
              )}
            </View>
            <MastersMore
              users={masters.filter(isCategoryInclusive)}
              onPressAll={() => navigation.navigate("homeMastersAll", { type: "all" })}
              moreTitle={"이런 마스터는 어떠세요"}
            />
            <TouchableOpacity style={{ marginTop: spacing[6] }} onPress={() => {}}>
              <Text text="소통에 대해 더 알아보고 싶다면?" preset="boldCenter" />
            </TouchableOpacity>
          </ScrollView>
        </Screen>
      </View>
    )
  },
)
