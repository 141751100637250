/* eslint-disable camelcase */
import { ApiResponse } from 'apisauce'
import { Api } from './api'
import { getGeneralApiProblem } from './api-problem'
import {
  ChangePasswordResult,
  GeneralDeleteResult,
  GetUserResult,
  PatchMasterIntroResult,
  PostWalletResult,
} from './api.types'
import { convertUser } from './convert'

export class AuthApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  // eslint-disable-next-line camelcase
  async updateProfile(profile_id, token, payload): Promise<GetUserResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)

      const response: ApiResponse<any> = await this.api.apisauce.patch(
        `/sotong_profiles/${profile_id}`,
        JSON.stringify({ profile: payload }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const profile = convertUser(raw)

      return { kind: 'ok', user: profile }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async changePassword(token, oldPassword, newPassword): Promise<ChangePasswordResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)

      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/account/change_password',
        JSON.stringify({ oldPassword, newPassword }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', reason: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async oneJoinCerti(phonenum: string, nation: string, code: string): Promise<PostWalletResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/account/one_join_certi',
        JSON.stringify({ phonenum, nation, code }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', result: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async oneJoinRecerti(userKey: string): Promise<PostWalletResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/account/one_join_recerti',
        JSON.stringify({ userKey }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', result: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async oneJoin(userKey: string, phonenum: string, password: string): Promise<PostWalletResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/account/one_join_register',
        JSON.stringify({ userKey, phonenum, password }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', result: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async oneVerifyCode(userKey: string, code: string): Promise<PostWalletResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/account/one_verify_code',
        JSON.stringify({ userKey, code }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', result: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async registerMaster(profile_id, token, payload): Promise<GetUserResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)

      const response: ApiResponse<any> = await this.api.apisauce.patch(
        `/sotong_profiles/register_master/${profile_id}`,
        JSON.stringify({ payload: payload }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const profile = convertUser(raw)

      return { kind: 'ok', user: profile }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async updateMasterIntro(profile_id, token, payload): Promise<PatchMasterIntroResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)

      const response: ApiResponse<any> = await this.api.apisauce.patch(
        `/sotong_profiles/remove_and_add_master_intro/${profile_id}`,
        JSON.stringify({ payload: payload }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async updateWalletInfo(
    token: string,
    profile_id: string,
    payload: any,
  ): Promise<PostWalletResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)

      const response: ApiResponse<any> = await this.api.apisauce.patch(
        `/sotong_profiles/update_wallet_info/${profile_id}`,
        JSON.stringify({ payload: payload }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', result: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async getWalletBalance(token: string, profile_id: string): Promise<PostWalletResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)

      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_wallet/get_wallet_balance/${profile_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', result: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async deleteUser(token: string): Promise<GeneralDeleteResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)

      const response: ApiResponse<any> = await this.api.apisauce.get(`/sotong_profile_delete_user`)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (error) {
      return { kind: 'bad-data' }
    }
  }

  async checkOnePassword(token: string, password: string): Promise<GeneralDeleteResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)

      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/accounts_check_one_password`,
        JSON.stringify({ password }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (error) {
      return { kind: 'bad-data' }
    }
  }
}
