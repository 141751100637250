/* eslint-disable no-use-before-define */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, ScrollView, TextInput, Image } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, Text, Button } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import SelectDropdown from "react-native-select-dropdown"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"

const queryTypes = ["회원 정보", "상담 신청", "결제 방법", "장애 해결", "환불 신청"]
const appLogo = require("../../../assets/images/logo-small.png")

export const ServiceCenterScreen: FC<StackScreenProps<NavigatorParamList, "serviceCenter">> =
  observer(({ navigation }) => {
    const goBack = () => navigation.goBack()
    const [queryType, setQueryType] = useState("")
    const [queryContent, setQueryContent] = useState("")
    const [querySubmitted, setQuerySubmitted] = useState(false)
    const { authStore, queryStore } = useStores()
    const [loading, setLoading] = useState(false)

    const handleQuerySubmit = async () => {
      setLoading(true)
      await queryStore.addQuery(authStore.token, {
        content: queryContent,
        type: queryType,
        informant: authStore.profile?.nick_name,
        profile_id: authStore.profile?.profile_id,
      })
      setQuerySubmitted(true)
      setLoading(false)
    }

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <Header leftIcon="back" onLeftPress={goBack} headerText={"고객센터"} />
          <View style={{ height: 1, backgroundColor: color.border }} />
          <View style={{ paddingHorizontal: spacing[4], flex: 1 }}>
            {querySubmitted ? (
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  paddingVertical: spacing[6],
                }}
              >
                <Image source={appLogo} style={{ width: 64, height: 64 }} />
                {queryType !== "환불 신청" ? (
                  <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Text>
                      <Text text={authStore.profile?.nick_name || ""} />
                      <Text tx="common.queryAccepted" />
                    </Text>
                    <Text tx="common.willQuickProceed" />
                    <Text tx="common.thanksForSubmit" style={{ marginVertical: spacing[6] }} />
                  </View>
                ) : (
                  <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Text text="환불 신청이 완료되었습니다." style={{ marginBottom: spacing[5] }} />
                    <Text
                      text="1) 마스터의 미입장으로 상담이 이뤄지지 않은 경우"
                      style={{ marginVertical: spacing[3] }}
                    />
                    <Text
                      text="2) 시스템 오류로 인해 상담이 이뤄지지 않은 경우"
                      style={{ marginVertical: spacing[3] }}
                    />
                    <Text
                      text="3) 이외 협의된 시간 내 상담이 이뤄지지 않은 경우"
                      style={{ marginVertical: spacing[3] }}
                    />
                    <Text
                      text="위 경우 환불 신청이 반려될 수 있습니다."
                      style={{ marginTop: spacing[5] }}
                    />
                    <Text
                      text="환불 승인 시 2~3 영업일 내에"
                      style={{ marginTop: spacing[6], textAlign: "center" }}
                    />
                    <Text
                      text="회원님의 통통지갑으로 환불금액이 전송됩니다."
                      style={{ marginTop: spacing[2], textAlign: "center" }}
                    />
                  </View>
                )}
                <Button
                  tx="common.ok"
                  onPress={() => {
                    setQueryType("")
                    setQueryContent("")
                    setQuerySubmitted(false)
                  }}
                />
              </View>
            ) : (
              <ScrollView
                style={{ flex: 1 }}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
              >
                <Text
                  tx={"common.queryType"}
                  style={{ marginTop: spacing[2], marginBottom: spacing[2] }}
                />
                <SelectDropdown
                  data={queryTypes}
                  onSelect={(selectedItem) => {
                    setQueryType(selectedItem)
                  }}
                  buttonTextAfterSelection={(selectedItem) => selectedItem}
                  rowTextForSelection={(item) => item}
                  defaultButtonText={"문의 유형을 선택해 주세요"}
                  buttonStyle={{
                    width: "100%",
                    backgroundColor: color.background,
                    borderWidth: 1,
                    borderColor: color.border,
                    borderRadius: 4,
                    height: 36,
                  }}
                  buttonTextStyle={{ fontSize: 14, textAlign: "left" }}
                  dropdownStyle={{
                    backgroundColor: color.background,
                    borderRadius: 4,
                  }}
                  rowStyle={{ height: 36 }}
                  rowTextStyle={{ fontSize: 14, textAlign: "center" }}
                  renderDropdownIcon={() => (
                    <MaterialIcons name="arrow-drop-down" size={24} color={color.text} />
                  )}
                />

                <Text
                  tx={"common.queryContent"}
                  style={{ marginTop: spacing[4], marginBottom: spacing[2] }}
                />
                <TextInput
                  style={{
                    flex: 1,
                    backgroundColor: color.background,
                    borderWidth: 1,
                    borderColor: color.border,
                    borderRadius: 4,
                    paddingHorizontal: spacing[2],
                    height: 360,
                    textAlignVertical: "top",
                    color: color.text,
                  }}
                  underlineColorAndroid="transparent"
                  numberOfLines={36}
                  maxLength={1024}
                  multiline={true}
                  defaultValue={queryContent}
                  onChangeText={(text) => {
                    setQueryContent(text)
                  }}
                  placeholder={
                    "환불 신청인 경우 상담과 관련한 정보를 정확히 입력해주세요.\n\n본인 닉네임 :\n\n환불 희망 마스터 닉네임 :\n\n환불 희망 상담명 :\n\n신청 상담 시작 시간(일자)포함 :"
                  }
                  placeholderTextColor={color.dim}
                />
                {queryType === "" || queryContent === "" ? (
                  <Button
                    disabled={true}
                    tx="common.queryDone"
                    onPress={handleQuerySubmit}
                    style={{ marginVertical: spacing[6], backgroundColor: color.dim }}
                  />
                ) : (
                  <Button
                    disabled={loading}
                    tx="common.queryDone"
                    onPress={handleQuerySubmit}
                    style={{ marginVertical: spacing[6] }}
                  />
                )}
              </ScrollView>
            )}
          </View>
        </Screen>
      </View>
    )
  })
