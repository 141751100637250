import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { SlideApi } from "../../services/api/slide-api"
import { SlideModel, SlideSnapshot } from "../slide/slide"
import { api } from "app/services/api"

export const SlideStoreModel = types
  .model("SlideStore")
  .props({
    slides: types.optional(types.array(SlideModel), []),
  })
  .actions((self) => ({
    saveSlides: (slidesSnapshot: SlideSnapshot[]) => {
      self.slides.replace(slidesSnapshot)
    },
  }))
  .actions((self) => ({
    fetchSlides: flow(function* (token: string) {
      const slideApi = new SlideApi(api)
      const result = yield slideApi.getSlides(token)
      if (result.kind === "ok") {
        self.saveSlides(result.slides)
      } else {
        if (result.kind === "not-found") {
          self.slides.clear()
        }
      }
    }),
  }))

type SlideStoreType = Instance<typeof SlideStoreModel>
export interface SlideStore extends SlideStoreType {}
type SlideStoreSnapshotType = SnapshotOut<typeof SlideStoreModel>
export interface SlideStoreSnapshot extends SlideStoreSnapshotType {}
