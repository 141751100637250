/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { ScrollView, View, TextInput, ActivityIndicator, Alert } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, Button, Text } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { Rating } from "react-native-ratings"

export const TransactionReviewScreen: FC<
  StackScreenProps<NavigatorParamList, "transactionReview">
> = observer(({ route, navigation }) => {
  const { transaction } = route.params
  const goBack = () => navigation.goBack()
  const { authStore, transactionStore } = useStores()
  const [rating, setRating] = useState(0)
  const [review, setReview] = useState("")
  const [loading, setLoading] = useState(false)

  const handleUpdateReview = async () => {
    if (review.length !== 0) {
      setLoading(true)
      transactionStore
        .updateTransaction(authStore.token, transaction.transaction_id, {
          rating,
          review,
          is_reviewed: true,
        })
        .then((result) => {
          setLoading(false)
          if (result === true) {
            alert(`소중한 리뷰 감사합니다:)\n리뷰 지급 TTC는 매달 10일에\n일괄 지급됩니다.`)
            goBack()
          }
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
        })
    }
  }

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        <Header leftIcon="back" onLeftPress={goBack} headerText={"리뷰 작성"} />
        <View style={{ flex: 1 }}>
          <View style={{ height: 1, backgroundColor: color.border }} />
          <ScrollView
            style={{ flex: 1 }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{ flexGrow: 1, justifyContent: "center", padding: spacing[4] }}
          >
            <Rating
              imageSize={36}
              readonly={false}
              startingValue={0}
              fractions={0}
              onFinishRating={(rating: number) => setRating(rating)}
              style={{ marginBottom: spacing[4] }}
            />
            <TextInput
              style={{
                backgroundColor: color.background,
                borderWidth: 1,
                borderColor: color.border,
                borderRadius: 4,
                paddingHorizontal: spacing[2],
                height: 240,
                textAlignVertical: "top",
                color: color.text,
                marginVertical: spacing[4],
              }}
              underlineColorAndroid="transparent"
              numberOfLines={18}
              maxLength={512}
              multiline={true}
              placeholder={"리뷰 작성시 10 TTC 를 지급해 드립니다."}
              placeholderTextColor={color.dim}
              onChangeText={(text) => {
                setReview(text)
              }}
            />
            <Button onPress={handleUpdateReview} disabled={loading}>
              {loading ? (
                <ActivityIndicator />
              ) : (
                <Text text={"작성 완료"} style={{ color: color.background }} />
              )}
            </Button>
          </ScrollView>
        </View>
      </Screen>
    </View>
  )
})
