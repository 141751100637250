/* eslint-disable camelcase */
// eslint-disable-next-line no-use-before-define
import React, { FC, useState, useEffect, useContext } from "react"
import { observer } from "mobx-react-lite"
import {
  ViewStyle,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { TabView, TabBar } from "react-native-tab-view"
import {
  Screen,
  Text,
  Button,
  ProfileAvatar,
  CounselingBox,
  chatClient,
  GeneralUserBox,
  ReviewBox,
} from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { Counseling } from "../../models/counseling/counseling"
import { User } from "../../models/user/user"
import Toast from "react-native-toast-message"
import { Education } from "../../models/education/education"
import { Career } from "../../models/career/career"
import { ChatContext } from "app/services/stream/chat-context"
import { SCREEN_WIDTH } from "app/utils/screen"

const placeHolder = require("../../../assets/images/place-holder.png")

const TAB_BAR_INDICATOR: ViewStyle = {
  backgroundColor: color.primary,
}
const TAB_BAR_VIEW: ViewStyle = {
  backgroundColor: color.background,
}

let isMounted = false
const generalRoutes = [
  { key: "interestsTab", title: "" },
  { key: "aboutTab", title: "" },
  { key: "likesTab", title: "" },
]
const masterRoutes = [
  { key: "counselingTab", title: "" },
  { key: "introTab", title: "" },
  { key: "reviewTab", title: "" },
]

export const UserDetailScreen: FC<StackScreenProps<NavigatorParamList, "userDetail">> = observer(
  ({ route, navigation }) => {
    const { profileId, accountId } = route.params
    const goBack = () => navigation.goBack()
    const { categoryStore, authStore, counselingStore, transactionStore } = useStores()
    const [user, setUser] = useState<User>()
    const [counselings, setCounselings] = useState<Counseling[]>([])
    const [reviews, setReviews] = useState([])
    const [likings, setLikings] = useState<User[]>([])
    const [educations, setEducations] = useState<Education[]>([])
    const [careers, setCareers] = useState<Career[]>([])
    const initialLayout = { width: SCREEN_WIDTH }
    const [index, setTabIndex] = useState(0)
    const [routes, setRoutes] = useState(generalRoutes)
    const [updatingFollow, setUpdatingFollow] = useState(false)
    const [updatingLike, setUpdatingLike] = useState(false)
    const { setChannel } = useContext(ChatContext)

    const renderTabBar = (props) => (
      <TabBar
        {...props}
        indicatorStyle={TAB_BAR_INDICATOR}
        style={TAB_BAR_VIEW}
        activeColor={color.primary}
        inactiveColor={color.dim}
        renderIcon={({ route }) => {
          switch (route.key) {
            case "interestsTab":
              return <Text text={"관심사"} preset={"headerCenter1"} />
            case "aboutTab":
              return <Text text={"About Me"} preset={"headerCenter1"} />
            case "likesTab":
              return <Text text={"Like"} preset={"headerCenter1"} />
            case "counselingTab":
              return <Text text={"상담소개"} preset={"headerCenter1"} />
            case "introTab":
              return <Text text={"마스터소개"} preset={"headerCenter1"} />
            case "reviewTab":
              return <Text text={"리뷰"} preset={"headerCenter1"} />
            default:
              return <></>
          }
        }}
        renderLabel={() => <></>}
      />
    )

    const InterestsRoute = (jumpTo) => (
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ flexGrow: 1, margin: spacing[4] }}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ flexDirection: "row" }}>
            {user?.interests?.length !== 0 ? (
              user?.interests?.map((item) => {
                const selectedCategory = categoryStore.categories.find(
                  (category) => category.category_id === item,
                )
                if (selectedCategory !== undefined) {
                  return (
                    <View key={item} style={{ marginRight: spacing[5], alignItems: "center" }}>
                      {selectedCategory && selectedCategory.thumb ? (
                        <Image
                          source={{ uri: selectedCategory.thumb }}
                          style={{ width: 24, height: 24 }}
                        />
                      ) : (
                        <Image source={placeHolder} style={{ width: 24, height: 24 }} />
                      )}
                      <Text
                        text={selectedCategory?.name || ""}
                        style={{ marginVertical: spacing[2] }}
                        preset="small"
                      />
                    </View>
                  )
                } else {
                  return <View key={item}></View>
                }
              })
            ) : (
              <></>
            )}
          </View>
        </View>
      </ScrollView>
    )

    const AboutRoute = (jumpTo) => {
      return (
        <View
          style={{
            flex: 1,
            margin: spacing[4],
          }}
        ></View>
      )
    }

    const LikesRoute = (jumpTo) => {
      if (likings.length > 0) {
        return (
          <View
            style={{
              flex: 1,
              paddingVertical: spacing[2],
            }}
          >
            <FlatList
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              keyboardShouldPersistTaps="handled"
              data={likings}
              initialNumToRender={10}
              keyExtractor={(item) => item.profile_id}
              renderItem={({ item }) => (
                <View key={item.profile_id}>
                  <GeneralUserBox
                    user={item}
                    onPress={() => {
                      if (item.account_id === authStore.profile?.account_id) {
                        navigation.push(
                          authStore.profile.is_master ? "masterProfile" : "userProfile",
                        )
                      } else {
                        navigation.push(
                          "userDetail" as never,
                          { profileId: item.profile_id, accountId: item.account_id } as never,
                        )
                      }
                    }}
                  />
                </View>
              )}
            />
          </View>
        )
      } else {
        return (
          <View
            style={{
              flex: 1,
              margin: spacing[4],
            }}
          ></View>
        )
      }
    }

    const CounselingRoute = (jumpTo) => {
      const masterCategory = categoryStore.getCategory(user?.master_category as string)

      return (
        <View
          style={{
            flex: 1,
            marginHorizontal: spacing[4],
          }}
        >
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{ paddingVertical: spacing[2] }}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
          >
            {counselings.map((counseling, index) => (
              <CounselingBox
                counseling={counseling}
                onPress={() => {
                  navigation.navigate("counselingDetail", {
                    counseling: counseling,
                    masterCategory: masterCategory,
                    master: user as User,
                  })
                }}
                key={index}
                masterCategory={masterCategory}
              />
            ))}
          </ScrollView>
        </View>
      )
    }

    const IntroRoute = (jumpTo) => {
      return (
        <View
          style={{
            flex: 1,
            marginHorizontal: spacing[4],
          }}
        >
          <ScrollView
            style={{ flex: 1 }}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
          >
            <Text text={"학력"} preset={"header1"} style={{ marginTop: spacing[4] }} />
            {educations?.map((education, index) => {
              return (
                <View
                  key={index}
                  style={{ flexDirection: "row", alignItems: "center", marginVertical: spacing[2] }}
                >
                  <Text
                    text={`${education.startYear} ~ ${education.endYear}  ${education.school}  ${education.majorAndDegree}`}
                    numberOfLines={1}
                  />
                </View>
              )
            })}
            <Text text={"경력"} preset={"header1"} style={{ marginTop: spacing[4] }} />
            {careers?.map((careers, index) => {
              return (
                <View
                  key={index}
                  style={{ flexDirection: "row", alignItems: "center", marginVertical: spacing[2] }}
                >
                  <Text
                    text={`${careers.startYear} ~ ${careers.endYear}  ${careers.title}  ${careers.titleAndPosition}`}
                    numberOfLines={1}
                  />
                </View>
              )
            })}
          </ScrollView>
        </View>
      )
    }

    const ReviewRoute = (jumpTo) => {
      return (
        <View
          style={{
            flex: 1,
            marginHorizontal: spacing[4],
          }}
        >
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{ paddingVertical: spacing[2] }}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
          >
            {reviews.map((review, index) => (
              <ReviewBox review={review} key={index} />
            ))}
          </ScrollView>
        </View>
      )
    }

    const renderScene = ({ route, jumpTo }) => {
      switch (route.key) {
        case "interestsTab":
          return <InterestsRoute jumpTo={jumpTo} />
        case "aboutTab":
          return <AboutRoute jumpTo={jumpTo} />
        case "likesTab":
          return <LikesRoute jumpTo={jumpTo} />
        case "counselingTab":
          return <CounselingRoute jumpTo={jumpTo} />
        case "introTab":
          return <IntroRoute jumpTo={jumpTo} />
        case "reviewTab":
          return <ReviewRoute jumpTo={jumpTo} />
        default:
          return <></>
      }
    }

    const handleFollow = async () => {
      setUpdatingFollow(true)
      await authStore.userFollow(user?.account_id || "")
      await fetchData()
      setUpdatingFollow(false)
    }

    const handleUnFollow = async () => {
      setUpdatingFollow(true)
      await authStore.userUnfollow(user?.account_id || "")
      await fetchData()
      setUpdatingFollow(false)
    }

    const handleLike = async () => {
      setUpdatingLike(true)
      await authStore.userLike(user?.account_id || "")
      await fetchData()
      setUpdatingLike(false)
    }

    const handleUnlike = async () => {
      setUpdatingLike(true)
      await authStore.userUnLike(user?.account_id || "")
      await fetchData()
      setUpdatingLike(false)
    }

    const handleChat = async () => {
      if (
        user?.account_id &&
        user?.nick_name &&
        user?.avatar_render &&
        chatClient.userID &&
        authStore.isChatClientReady
      ) {
        const theChannel = chatClient.channel("messaging", {
          members: [authStore.profile?.account_id!, user?.account_id!],
        })
        theChannel
          ?.create()
          .then(() => {
            setChannel(theChannel)
            navigation.navigate("chat", { accountId: user?.account_id } as never)
          })
          .catch((error) => {
            if (error.code === 4) {
              Toast.show({
                type: "error",
                text1: `${user?.nick_name}님은 채팅서비스에 등록되지 않았습니다.`,
                position: "bottom",
              })
            } else {
              Toast.show({
                type: "error",
                text1: "채팅채널에 문제가 생겼습니다.",
                position: "bottom",
              })
            }
          })
      }
    }

    const fetchData = async () => {
      if (isMounted) {
        await Promise.all([
          authStore.fetchUserProfileById(profileId as string),
          authStore.fetchUserLikingsAll(accountId as string),
          counselingStore.fetchCounselings(authStore.token, accountId as string),
          transactionStore.fetchReviews(authStore.token, accountId as string),
          authStore.fetchEducationsAndCareers(accountId as string),
        ]).then((results) => {
          setUser(results[0])
          setRoutes(results[0]?.is_master ? masterRoutes : generalRoutes)
          setLikings(results[1])
          setCounselings(results[2])
          setReviews(results[3])
          setEducations(results[4].educations)
          setCareers(results[4].careers)
        })
      }
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      const unsubscribe = navigation.addListener("focus", () => {
        if (authStore.toReviewTab) {
          setRoutes(masterRoutes)
          setTabIndex(masterRoutes.length - 1)
          authStore.setToReviewTab(false)
        }
      })
      return () => {
        setUser(undefined)
        unsubscribe()
        isMounted = false
      }
    }, [])

    return (
      <View style={{ flex: 1 }}>
        <Screen>
          <View
            style={{
              flexDirection: "row",
              paddingHorizontal: spacing[4],
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: spacing[2],
            }}
          >
            <Button preset="link" onPress={goBack} style={{ marginRight: 15 }}>
              <MaterialIcons name="arrow-back" size={32} color={color.text} />
            </Button>
            <ProfileAvatar
              avatar={user?.avatar_render || ""}
              size={64}
              isMaster={user?.is_master as boolean}
            />
            <View style={{ justifyContent: "center", alignItems: "flex-end" }}>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  paddingHorizontal: spacing[3],
                  paddingVertical: spacing[1],
                  borderRadius: 18,
                  borderWidth: 1,
                  backgroundColor: user?.is_following ? color.text : color.primary,
                  borderColor: user?.is_following ? color.text : color.primary,
                  marginVertical: spacing[1],
                }}
                onPress={() => {
                  user?.is_following ? handleUnFollow() : handleFollow()
                }}
                disabled={updatingFollow || !user}
              >
                {updatingFollow ? (
                  <ActivityIndicator />
                ) : (
                  <Text
                    text={user?.is_following ? "팔로잉" : "팔로우"}
                    style={{ color: color.background }}
                  />
                )}
              </TouchableOpacity>
              <TouchableOpacity onPress={handleChat}>
                <MaterialCommunityIcons name="send-circle-outline" size={32} color={color.text} />
              </TouchableOpacity>
              {updatingLike ? (
                <ActivityIndicator />
              ) : user?.is_liking ? (
                <TouchableOpacity onPress={handleUnlike} disabled={updatingLike || !user}>
                  <MaterialCommunityIcons name="heart" size={32} color={color.primary} />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity onPress={handleLike} disabled={updatingLike || !user}>
                  <MaterialCommunityIcons name="heart-outline" size={32} color={color.text} />
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: spacing[4],
              minHeight: 28,
              marginLeft: spacing[4],
            }}
          >
            <Text text={user?.nick_name?.slice(0, 9) || ""} numberOfLines={1} preset={"header1"} />
          </View>
          <View style={{ alignItems: "flex-start" }}>
            <TouchableOpacity
              style={{
                flexDirection: "row",
                marginVertical: spacing[2],
                backgroundColor: "#F1F1F1",
                borderRadius: spacing[2],
                marginHorizontal: spacing[4],
                paddingHorizontal: spacing[4],
                paddingVertical: spacing[2],
              }}
              onPress={() => {
                navigation.navigate("followerFollowing", { accountId: accountId as string })
              }}
            >
              <Text preset="bold" text={user?.follower ? user?.follower + "" : "0"} />
              <Text text={" 팔로워 "} />
              <Text preset="bold" text={user?.following ? user?.following + "" : "0"} />
              <Text text={" 팔로잉 "} />
            </TouchableOpacity>
          </View>
          {!user?.intro ? (
            <></>
          ) : (
            <View
              style={{
                flexDirection: "row",
                marginHorizontal: spacing[4],
                backgroundColor: "#F1F1F1",
                borderRadius: spacing[2],
                paddingHorizontal: spacing[4],
                paddingVertical: spacing[2],
                justifyContent: "space-between",
              }}
            >
              <Text
                text={(user?.intro || "").replace(/\//g, "\n")}
                style={{ marginVertical: spacing[0] }}
              />
            </View>
          )}
          <TabView
            swipeEnabled={true}
            navigationState={{ index, routes }}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            onIndexChange={(index) => {
              setTabIndex(index)
            }}
            initialLayout={initialLayout}
          />
        </Screen>
      </View>
    )
  },
)
