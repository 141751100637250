/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import {
  StyleProp,
  TextInput,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
  TouchableOpacity,
} from "react-native"
import { color, spacing, typography } from "../../theme"
import { translate, TxKeyPath } from "../../i18n"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"

const CONTAINER: ViewStyle = {
  flexDirection: "row",
  backgroundColor: color.background,
  borderWidth: 1,
  borderColor: color.border,
  borderRadius: spacing[1],
  padding: spacing[2],
}
const INPUT: TextStyle = {
  flex: 1,
  fontFamily: typography.primary,
  color: color.text,
  fontSize: 14,
  backgroundColor: color.background,
  padding: 0,
}

export interface PasswordInputProps extends TextInputProps {
  placeholderTx?: TxKeyPath
  placeholder?: string
  labelTx?: TxKeyPath
  label?: string
  style?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<TextStyle>
  forwardedRef?: any
}

export function PasswordInput(props: PasswordInputProps) {
  const {
    placeholderTx,
    placeholder,
    style: styleOverride,
    inputStyle: inputStyleOverride,
    forwardedRef,
    ...rest
  } = props

  const containerStyles = [CONTAINER, styleOverride]
  const inputStyles = [INPUT, inputStyleOverride]
  const actualPlaceholder = placeholderTx ? translate(placeholderTx) : placeholder
  const [showPassword, setShowPassword] = useState(false)

  return (
    <View style={containerStyles}>
      <TextInput
        secureTextEntry={!showPassword}
        placeholder={actualPlaceholder || undefined}
        placeholderTextColor={color.palette.lighterGrey}
        underlineColorAndroid={color.transparent}
        {...rest}
        style={inputStyles}
        ref={forwardedRef}
      />
      <TouchableOpacity
        onPress={() => setShowPassword(!showPassword)}
        style={{ marginLeft: spacing[2], justifyContent: "center" }}
      >
        <MaterialCommunityIcons
          name={showPassword ? "eye-off" : "eye"}
          size={24}
          color={color.text}
        />
      </TouchableOpacity>
    </View>
  )
}
