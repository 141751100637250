import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const CategoryModel = types
  .model('Category')
  .props({
    category_id: types.identifier,
    name: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    thumb: types.maybeNull(types.string)
  })

type CategoryType = Instance<typeof CategoryModel>
export interface Category extends CategoryType {}
type CategorySnapshotType = SnapshotOut<typeof CategoryModel>
export interface CategorySnapshot extends CategorySnapshotType {}
