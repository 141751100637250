import "./i18n"
import "./utils/ignoreWarnings"
import { useFonts } from "expo-font"
import React, { useEffect, useState } from "react"
import * as storage from "./utils/storage"
import { initialWindowMetrics, SafeAreaProvider } from "react-native-safe-area-context"
import { AppNavigator, useNavigationPersistence } from "./navigators"
import Toast, { BaseToast, ErrorToast, InfoToast } from "react-native-toast-message"
import { customFontsToLoad } from "./theme"
import { ChatContext } from "./services/stream/chat-context"
import { Channel } from "stream-chat"
import { RootStore, RootStoreProvider, setupRootStore } from "./models"
import Notification from "./components/notification/notification"
import { View } from "react-native"
import { SCREEN_WIDTH } from "./utils/screen"

const toastConfig = {
  success: (props) => <BaseToast {...props} text1NumberOfLines={2} text2NumberOfLines={2} />,
  error: (props) => <ErrorToast {...props} text1NumberOfLines={2} text2NumberOfLines={2} />,
  info: (props) => <InfoToast {...props} text1NumberOfLines={2} text2NumberOfLines={2} />,
}

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"

interface AppProps {
  hideSplashScreen: () => Promise<void>
}

function App(props: AppProps) {
  const { hideSplashScreen } = props
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)
  const [channel, setChannel] = useState<Channel>()
  const [areFontsLoaded] = useFonts(customFontsToLoad)

  useEffect(() => {
    ;(async () => {
      setupRootStore().then(setRootStore)
    })()

    setTimeout(hideSplashScreen, 500)
  }, [])

  if (!rootStore || !isNavigationStateRestored || !areFontsLoaded) return null

  return (
    <RootStoreProvider value={rootStore}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <ChatContext.Provider value={{ channel, setChannel }}>
          <View style={{ flex: 1, alignItems: "center", backgroundColor: "#dddddd" }}>
            <View style={{ flex: 1, width: SCREEN_WIDTH, overflow: "hidden" }}>
              <AppNavigator
                initialState={initialNavigationState}
                onStateChange={onNavigationStateChange}
              />
              <Toast config={toastConfig} />
              <Notification />
            </View>
          </View>
        </ChatContext.Provider>
      </SafeAreaProvider>
    </RootStoreProvider>
  )
}

export default App
