/* eslint-disable camelcase */
import { Instance, SnapshotOut, types, flow } from "mobx-state-tree"
import { CounselingApi } from "../../services/api/counseling-api"
import { api } from "app/services/api"

export const CounselingStoreModel = types
  .model("CounselingStore")
  .props({})
  .views((self) => ({}))
  .actions((self) => ({
    fetchCounselings: flow(function* (
      token: string,
      account_id: string,
      limit: number = 1000,
      page: number = 0,
    ) {
      const counselingApi = new CounselingApi(api)
      const result = yield counselingApi.getCounselings(token, limit, page, account_id)

      if (result.kind === "ok") {
        return result.counselings
      } else {
        return []
      }
    }),

    createCounseling: flow(function* (token: string, payload: any) {
      const counselingApi = new CounselingApi(api)
      const result = yield counselingApi.insertCounseling(token, payload)

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),

    updateCounseling: flow(function* (token: string, counseling_id: string, payload: any) {
      const counselingApi = new CounselingApi(api)
      const result = yield counselingApi.updateCounseling(token, counseling_id, payload)

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),

    fetchCounseling: flow(function* (token: string, counseling_id: string) {
      const counselingApi = new CounselingApi(api)
      const result = yield counselingApi.getCounseling(token, counseling_id)

      if (result.kind === "ok") {
        return result.counseling
      } else {
        return null
      }
    }),

    deleteCounseling: flow(function* (token: string, counseling_id: string) {
      const counselingApi = new CounselingApi(api)
      const result = yield counselingApi.deleteCounseling(token, counseling_id)

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),
  }))

type CounselingStoreType = Instance<typeof CounselingStoreModel>
export interface CounselingStore extends CounselingStoreType {}
type CounselingStoreSnapshotType = SnapshotOut<typeof CounselingStoreModel>
export interface CounselingStoreSnapshot extends CounselingStoreSnapshotType {}
export const createCounselingStoreDefaultModel = () => types.optional(CounselingStoreModel, {})
