module.exports = function () {
  return `
  <!DOCTYPE html>
  <html>
  
  <body>
      <p style="font-size:12pt;color:black;">
        마스터 이용 약관은 여기에....
      </p>
  </body>
  
  </html>
  `
}
