import { ApiResponse } from 'apisauce'
import { Api } from './api'
import { GetSlidesResult } from './api.types'
import { getGeneralApiProblem } from './api-problem'
import { convertSlide } from './convert'
import { Slide } from '../../models/slide/slide'
import { BASE_HOST } from './api-config'

export class SlideApi {
  private api: Api

  constructor (api: Api) {
    this.api = api
  }

  async getSlides (token: string): Promise<GetSlidesResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/sotong_slides'
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const rawSlides = response.data

      const modifiedSlides = rawSlides.map((rawSlide) => {
        return {
          slide_id: rawSlide.slide_id,
          url: rawSlide.url,
          illustration: rawSlide.illustration_owned_file && rawSlide.illustration_owned_file.owned_file_uuid
            ? `${BASE_HOST}/files/${rawSlide.illustration_owned_file.owned_file_uuid}`
            : null
        }
      })

      const slides: Slide[] = modifiedSlides.map(convertSlide)
      return { kind: 'ok', slides: slides }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
