import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { GetTagsResult, InsertTagResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { Tag } from "../../models/tag/tag"
import { convertTag } from "./convert"

export class TagApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getTags(): Promise<GetTagsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_tags")

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const tags: Tag[] = response.data.map(convertTag)
      return { kind: "ok", tags: tags }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async insertTag(token: string, tag: string): Promise<InsertTagResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.post("/sotong_tags", { tag: tag })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", tag_id: response.data.tag_id }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
