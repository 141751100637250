import { palette } from './palette'

/**
 * Roles for colors.  Prefer using these over the palette.  It makes it easier
 * to change things.
 *
 * The only roles we need to place in here are the ones that span through the app.
 *
 * If you have a specific use-case, like a spinner color.  It makes more sense to
 * put that in the <Spinner /> component.
 */
export const color = {
  palette,
  transparent: 'rgba(0, 0, 0, 0)',
  background: palette.white,
  primary: palette.red,
  primaryDarker: palette.orangeDarker,
  line: palette.offWhite,
  text: palette.black,
  dim: palette.lightGrey,
  error: palette.angry,

  // custom colors
  border: palette.grey,
  orange: palette.orange,
  halfTransparency: 'rgba(0, 0, 0, 0.3)',
  blue: palette.blue,
  redTransparency: 'rgba(203, 32, 38, 0.5)',
  deepPurple: palette.deepPurple,
  pinkBackground: palette.lightPink,
  green: palette.green,

  // message
  rightBubble: palette.darkBlue,
  leftBubble: palette.darkGray,
  rightText: palette.white,
  leftText: palette.black
}
