/* eslint-disable no-use-before-define */
import React from "react"
import { View, ViewStyle, TouchableOpacity, ActivityIndicator } from "react-native"
import { HeaderProps } from "./header.props"
import { Button } from "../button/button"
import { Text } from "../text/text"
import { ProfileAvatar } from "../profile-avatar/profile-avatar"
import { color, spacing } from "../../theme"
import { translate } from "../../i18n/"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"

// static styles
const ROOT: ViewStyle = {
  flexDirection: "row",
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[2],
  alignItems: "center",
}

export function Header(props: HeaderProps) {
  const {
    onLeftPress,
    leftIcon,
    headerText,
    headerTx,
    headerType,
    style,
    peerName,
    peerAvatarRender,
    onPeerPress,
    shareIcon,
    onShareIconPress,
    settingsIcon,
    onSetingsIconPress,
    removeCounseling,
    onPressRemoveCounseling,
    questionIcon,
    onPressQuestionIcon,
    updating,
  } = props
  const header = headerText || (headerTx && translate(headerTx)) || ""

  const formattedHeader = () => {
    switch (headerType) {
      case "userChatProfile":
        return (
          <View style={{ alignItems: "center" }}>
            <TouchableOpacity
              onPress={onPeerPress}
              style={{
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ProfileAvatar avatar={peerAvatarRender || ""} size={32} />
              <Text text={peerName} style={{ marginLeft: spacing[4] }} />
            </TouchableOpacity>
          </View>
        )
      default:
        return (
          <Text style={{ textAlign: "center" }}>
            <Text preset="bold" text={header} />
          </Text>
        )
    }
  }

  return (
    <View style={[ROOT, style]}>
      {leftIcon ? (
        <Button preset="link" onPress={onLeftPress}>
          <MaterialIcons name="arrow-back" size={32} color={color.text} />
        </Button>
      ) : (
        <></>
      )}
      <View style={{ flex: 1, justifyContent: "center" }}>{formattedHeader()}</View>
      {shareIcon ? (
        <Button preset="link" onPress={onShareIconPress}>
          <MaterialIcons name="share" size={32} color={color.text} />
        </Button>
      ) : (
        <></>
      )}
      {settingsIcon ? (
        <Button preset="link" onPress={onSetingsIconPress}>
          <MaterialIcons name="settings" size={32} color={color.text} />
        </Button>
      ) : (
        <></>
      )}
      {questionIcon ? (
        <Button preset="link" onPress={onPressQuestionIcon}>
          <MaterialCommunityIcons name="progress-question" size={32} color={color.text} />
        </Button>
      ) : (
        <></>
      )}
      {removeCounseling ? (
        <Button preset="link" onPress={onPressRemoveCounseling}>
          {updating === false ? (
            <Text text={"삭제"} style={{ color: color.primary }} />
          ) : (
            <ActivityIndicator />
          )}
        </Button>
      ) : (
        <></>
      )}
      {leftIcon && !shareIcon && !settingsIcon && !removeCounseling && !questionIcon && (
        <View style={{ width: 24 }} />
      )}
    </View>
  )
}
