/* eslint-disable camelcase */
import { ApiResponse } from 'apisauce'
import { Counseling, CounselingSnapshot } from '../../models/counseling/counseling'
import { Api } from './api'
import { getGeneralApiProblem } from './api-problem'
import { GetCounselingsResult, PostCounselingResult, GetCounselingResult } from './api.types'
import { convertCounseling } from './convert'

export class CounselingApi {
  private api: Api

  constructor (api: Api) {
    this.api = api
  }

  async getCounselings (token: string, limit: number = 6, page: number = 0, account_id: string): Promise<GetCounselingsResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(`/sotong_counselings/${account_id}/${limit}/${page * limit}`)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const counselings: Counseling[] = response.data.map(convertCounseling)
      return { kind: 'ok', counselings: counselings }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async insertCounseling (token: string, payload: any): Promise<PostCounselingResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.post('/sotong_counselings', { counseling: payload })
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async updateCounseling (token: string, counseling_id: string, payload: any): Promise<PostCounselingResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.patch(`/sotong_counselings/${counseling_id}`, { counseling: payload })
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async getCounseling (token: string, counseling_id: string): Promise<GetCounselingResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(`/sotong_counselings/${counseling_id}`)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const counseling: CounselingSnapshot = convertCounseling(response.data)
      return { kind: 'ok', counseling: counseling }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async deleteCounseling (token: string, counseling_id: string): Promise<PostCounselingResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.delete(`/sotong_counselings/${counseling_id}`)
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
