const ko = {
  common: {
    ok: "확인",
    close: "닫기",
    confirming: "확인중...",
    cancel: "취소",
    back: "Back",
    next: "다음",
    start: "시작!",
    loading: "로딩중...",
    needPhone: "휴대폰 번호를 입력해 주세요",
    needPassword: "비밀번호를 입력해 주세요",
    invalidPhone: "전화번호는 10~11 자리 숫자로 입력해 주세요",
    invalidPassword: "비밀번호는 최소 6자리입니다",
    needNickName: "닉네임을 입력해주세요",
    nickName: "닉네임",
    more: "더보기",
    all: "전체",
    popular: "인기 있는",
    trainer: "전문가",
    space: "스페이스",
    nowOnChat: "대화 중인",
    clear: "지우기",
    update: "업데이트",
    whiteSpace: " ",
    search: "검색",
    done: "완료",
    refund: "환불",
    ttmi: "TTMI",
    currency: "원",
    open: "만들기",
    oneId: "ONE ID",
    announcement: "공지 사항",
    contactUs: "소통에 문의하기",
    logOut: "로그아웃",
    withdraw: "회원 탈퇴",
    notificationSettings: "알림 설정",
    modification: "수정",
    interest: "관심사",
    intro: "소개",
    pleaseIntroYourSelf: "다른 사용자에게 \n 본인을 소개해 주세요",
    faq: "FAQ",
    queryType: "문의 유형",
    title: "제목",
    queryContent: "문의 사항",
    queryDone: "문의 완료",
    queryTitlePlaceHolder: "2 ~ 50 자 이상 작성해 주세요",
    willQuickProceed: "빠르게 처리해드리도록 하겠습니다.",
    thanksForSubmit: "소중한 의견 감사드립니다.",
    queryAccepted: " 님의 문의가 접수되었습니다.",
    logoutSure: "로그아웃 하시겠어요?",
    continue: "계속하기",
    reasonToDeleteAccount: "계정을 삭제하시려는 이유가 무엇인가요?",
    notificationsCenter: "알림센터",
    notCorrectPassword: "잘못된 비밀번호 입니다.",
    exceedSpaceCapacity: "입장 인원이 초과되었습니다 :(",
  },
  loginScreen: {
    phone: "휴대폰번호",
    password: "비밀번호",
    tomatoOneID: "Tomato One ID",
    noAccount: "계정이 없으신가요? ",
    login: "회원가입",
  },
  nickNameScreen: {
    setNickName: "소통에서 사용할 닉네임을 설정해주세요",
    welcome: "님 반가워요:)",
    askConfirnNickName: " 닉네임을 사용할까요?",
    nickNameDuplicated: "동일한 닉네임이 있어요 :(",
  },
  errors: {
    invalidEmail: "Invalid email address.",
  },
  welcomeScreen: {
    poweredBy: "POWERED BY IGNITE",
    readyForLaunch: "Ready for launch.",
    continue: "계속하기",
  },
  demoScreen: {
    howTo: "HOW TO",
    title: "What’s In This Stack?",
    tagLine:
      "Congratulations, you’ve got a very advanced React Native app template here.  Take advantage of this boilerplate!",
    reactotron: "Demo Reactotron",
    demoList: "Demo List",
    androidReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running, run adb reverse tcp:9090 tcp:9090 from your terminal, and reload the app.",
    iosReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running and reload app.",
    macosReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running and reload app.",
    webReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running and reload app.",
    windowsReactotronHint:
      "If this doesn't work, ensure the Reactotron desktop app is running and reload app.",
  },
  demoListScreen: {
    title: "Demo List",
  },
  errorScreen: {
    title: "Something went wrong!",
    friendlySubtitle:
      "This is the screen that your users will see in production when an error is thrown. You'll want to customize this message (located in `app/i18n/en.json`) and probably the layout as well (`app/screens/error`). If you want to remove this entirely, check `app/app.tsx` for the <ErrorBoundary> component.",
    traceTitle: "Error from %{name} stack",
    reset: "RESET APP",
  },
  homeScreen: {
    popularTrainers: "요즘 인기 많은 전문가",
    suggestedTrainers: "이런 전문가는 어떠세요?",
    favouriteSpaces: "사용자님이 좋아할만한 스페이스",
    popularSpacs: "실시간 인기 있는 스페이스",
  },
  categoryScreen: {
    noWantedSpace: "원하는 스페이스가 없나요?",
    spaceCreate: "만들기!",
  },
  searchScreen: {
    enterKeywords: "검색어를 입력하세요",
    recentKeyword: "최근 검색어",
    clear: "검색어를 입력하세요",
    recommendedKeywords: "소통 추천 검색어",
    noSearchResult: "검색내역이 없습니다",
    youSearchingthisKeyword: "이 키워드를 찾고 계신가요?",
    noSearchSpace: "찾으시는 스페이스가 없나요?",
    createNewSpace: "스페이스 만들기!",
    noSearchResultRelated: "에 대한 검색결과가 없습니다.",
  },
  spaceScreen: {
    categorySpaceEntering: "카테고리별 입장",
    quickEntering: "빠른 입장",
    suggestedSpace: "추천하는 스페이스",
    specialSpace: "소개 스페이스",
    createSpaceHeader: "스페이스 만들기",
    spaceTitle: "방제",
    spaceTab: "태그",
    spaceMaxVolumn: "최대인원",
    spaceSetPassCode: "비밀번호 설정",
    spaceType: "스페이스 스타일",
    inputSpaceTitle: "방제를 입력해 주세요",
    boring: "심심해요",
    directTag: "태그를 입력해 주세요",
  },
  shopScreen: {
    ttmShop: "통통마일 상점",
    buyNotice: "구매시 주의 사항!",
    buying: "구매하기",
    laterAlaram: "추후 포인트/통통마일 간 전환 기능이 제공될 예정입니다.",
    notice1: "환불 금액은 매주 0요일/0요일 오후 6시 전 입금됩니다.",
    notice2: "예금주 성명 미상 등 오기로 인해 환불이 잘못 되는 경우 소통은 책임지지 않습니다.",
    notice3: "환불 일정의 경우 회사 사정에 의해 변경될수 있습니다.",
    walletConfirmText: "통통마일 구매를 위해선 통통지갑 개설이 필요합니다",
    ttmiPurchased: "통통마일이 결제되었습니다.",
    amountOPayment: "결제금액 : ",
    ttmi: "통통마일 : ",
    currentBalance: "현재잔액 : ",
    ttmiBalance: "통통마일 잔액",
    ttmiToRefund: "환불 받은 통통마일을 입력해주세요",
    inputAccountNumber: "계좌번호를 입력해주세요",
    inputHolderName: "예금주 성함을 정확히 적어주세요",
    selectBank: "은행 선택",
    holderName: "예금주 성명",
    holderNameNotice:
      " * 예금주 성명 미일치 등 오기로 인해 환불이 잘못될 경우 소통은 책임지지 않습니다.",
    refundNoticeAlert: " * 환불 후 잔여 통통마일 ",
    refundConfirmAgainAlert: "통통마일 환불 시 복구할수 없습니다. 정말로 환불하시겠습니까?",
  },
  profileScreen: {
    areSureDeleteAccount: "정말 회원 탈퇴를 할까요?",
    deleteDescription: "회원님의 프로필이 영구적으로 삭제됩니다",
    deleteAccount: "계정 삭제",
    createAvatar: "아바타 만들기",
  },
  "avatar-preview": {
    "loading-failed": "아바타 로그 실패",
  },
  "avatar-url-modal": {
    "avatar-url-label": "아바타 URL",
    "custom-avatar-docs-link": "커스톰 아바타 URL 에 대하여",
  },
  attachmentDocument: {
    limitation: "* jpg, png, jpeg 파일만 첨부할수 있습니다.",
  },
  careerInput: {
    addCareer: "경력 추가",
  },
}

export default ko
export type Translations = typeof ko
