/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React from "react"
import { View, ActivityIndicator, FlatList } from "react-native"
import { spacing } from "../../theme"
import { Text, GeneralUserBox } from "../../components"

function FollowerRoute({
  followersResult,
  fetchData,
  navigation,
  followerLoading,
  followerNoMore,
}) {
  let onEndReachedCalledDuringMomentumFollower = false
  return (
    <View
      style={{
        flex: 1,
        paddingVertical: spacing[2],
      }}
    >
      {followersResult === null || followersResult.length === 0 ? (
        <View>
          <Text
            preset="center"
            style={{
              marginTop: spacing[8],
              marginBottom: spacing[2],
            }}
            numberOfLines={2}
          >
            <Text text={"팔로워가 없습니다"} preset="secondary" />
          </Text>
        </View>
      ) : (
        <FlatList
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
          data={followersResult}
          initialNumToRender={10}
          onMomentumScrollBegin={() => {
            onEndReachedCalledDuringMomentumFollower = false
          }}
          onEndReached={() => {
            if (!onEndReachedCalledDuringMomentumFollower) {
              fetchData(0)
              onEndReachedCalledDuringMomentumFollower = true
            }
          }}
          onEndReachedThreshold={0.1}
          keyExtractor={(item) => item.profile_id}
          renderItem={({ item }) => (
            <View key={item.profile_id}>
              <GeneralUserBox
                user={item}
                onPress={() => {
                  navigation.push(
                    "userDetail" as never,
                    { profileId: item.profile_id, accountId: item.account_id } as never,
                  )
                }}
              />
            </View>
          )}
          ListFooterComponent={
            followerLoading && !followerNoMore ? (
              <ActivityIndicator
                style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
              />
            ) : (
              <></>
            )
          }
        />
      )}
    </View>
  )
}

export const MemoFollowerRoute = React.memo(FollowerRoute)
