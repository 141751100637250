/* eslint-disable no-use-before-define */
import React from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  TouchableOpacity,
  Image,
  ImageStyle,
} from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { useStores } from "../../models"
import { SCREEN_WIDTH } from "../../utils/screen"

const placeHolder = require("../../../assets/images/place-holder.png")
const CONTAINER: ViewStyle = {
  flexDirection: "row",
  flexWrap: "wrap",
  backgroundColor: "#F1F1F1",
  borderRadius: spacing[2],
  marginBottom: spacing[2],
}
const CATEGORY_IMAGE: ImageStyle = {
  width: 32,
  height: 32,
  marginBottom: spacing[1],
}
const CATEGORY_UNCHECKED_TEXT_STYLE: TextStyle = {
  fontWeight: "normal",
  fontSize: 12,
  color: color.text,
}
const CATEGORY_CHECKED_TEXT_STYLE: TextStyle = {
  fontWeight: "bold",
  fontSize: 16,
  color: color.primary,
}

export interface MasterCategorySelectProps {
  style?: StyleProp<ViewStyle>
  masterCategory: string
  setMasterCategory: (value: string) => void
}

export const MasterCategorySelect = observer(function MasterCategorySelect(
  props: MasterCategorySelectProps,
) {
  const { style, masterCategory, setMasterCategory } = props
  const styles = Object.assign({}, CONTAINER, style)
  const { categoryStore } = useStores()
  const masterCategories = categoryStore.getMasterCategories()

  return (
    <View style={styles}>
      {masterCategories.length > 0 ? (
        masterCategories.map((item) => {
          return (
            <TouchableOpacity
              key={item.category_id}
              style={{
                flex: 1,
                alignItems: "center",
                minWidth: (SCREEN_WIDTH - spacing[4] * 2) / 4,
                marginVertical: spacing[2],
              }}
              onPress={() =>
                masterCategory === item.category_id
                  ? setMasterCategory("")
                  : setMasterCategory(item.category_id)
              }
            >
              {item.thumb ? (
                <Image source={{ uri: item.thumb || "" }} style={CATEGORY_IMAGE} />
              ) : (
                <Image source={placeHolder} style={CATEGORY_IMAGE} />
              )}
              <Text
                text={item.name || ""}
                preset="smallCenter"
                style={
                  masterCategory === item.category_id
                    ? CATEGORY_CHECKED_TEXT_STYLE
                    : CATEGORY_UNCHECKED_TEXT_STYLE
                }
              />
            </TouchableOpacity>
          )
        })
      ) : (
        <></>
      )}
    </View>
  )
})
