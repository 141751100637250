/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ScrollView, View, TouchableOpacity, Image } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import {
  Screen,
  Header,
  Text,
  TextField,
  MasterCategorySelect,
  AttachDocuments,
  Button,
  AddressTextField,
} from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { SCREEN_WIDTH } from "../../utils/screen"
import { upload } from "../../services/api/media-api"
import { Education } from "../../models/education/education"
import { Career } from "../../models/career/career"
import { EducationsInput } from "../../components/educations-input/educations-input"
import { CareersInput } from "../../components/careers-input/careers-input"
import Clipboard from "@react-native-clipboard/clipboard"
import { Checkbox } from "react-native-paper"
import RenderHtml from "react-native-render-html"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"

interface UploadMeta {
  access_token: string
  expected_content_type: string
  promotion_token: string
}
interface UploadResult {
  file_id: string
  meta: UploadMeta
  origin: string
}
const coloredLogo = require("../../../assets/images/logo.png")
const validPhoneNumber = (number: string) => {
  const regex: RegExp = /^\d+$/
  return regex.test(number)
}
const validEmailAddress = (email: string) => {
  const regex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  return regex.test(email)
}

const noneSpec = ["등록증", "사업자 등록증", "임직원 증명"]

const attachmentsSpec = [
  {
    title: "투자상담",
    specs: [
      "- 투자자문업 등록증",
      "- 투자자문업 사업자 등록증",
      "- 투자자문업자(법인) 소속 임직원을 증명할 수 있는 재직증명서",
    ],
  },
  {
    title: "금융",
    specs: [
      "- 경영/경제 관련 학과 학사 학위 이상 학위증명서",
      "- TESAT 혹은 매경TEST",
      "- 금융관련자격증",
    ],
  },
  {
    title: "무역",
    specs: ["- 관세사 자격증"],
  },
  {
    title: "보험",
    specs: ["- 보험설계사 자격증", "- 손해사정사 등록증"],
  },
  {
    title: "타로",
    specs: ["- 관련 사업자 등록증"],
  },
  {
    title: "관상",
    specs: ["- 관련 사업자 등록증"],
  },
  {
    title: "운세",
    specs: ["- 관련 사업자 등록증"],
  },
  {
    title: "연애",
    specs: [
      "- 임상심리사 1, 2급",
      "- 임상심리전문가",
      "- 상담심리전문가",
      "- 정신건강임상심리사 1, 2급",
    ],
  },
  {
    title: "자격증",
    specs: [
      "- 유튜브 실버버튼 이상 창작자 (전체 영상 내 50% 이상 관련 컨텐츠)",
      "- 자격증 시험 관련 학원 및 교육 서비스 관련 사업자 등록증",
    ],
  },
  {
    title: "반려동물",
    specs: [
      "- 반려견 지도사(구.훈련사)",
      "- 반려견 행동상담사",
      "- 반려동물 종합관리사",
      "- 핸들러",
      "- 훈련사",
    ],
  },
  {
    title: "골프",
    specs: [
      "- KLPGA 발급자격증",
      "- PGA 발급자격증",
      "- LPGA 발급자격증",
      "- 골프 관련 학원 및 교육 서비스 관련 사업자 등록증",
    ],
  },
  {
    title: "요리",
    specs: [
      "- 조리기능사",
      "- 조리산업기사",
      "- 조리기능장",
      "- 제과/제빵 기능사",
      "- 제과 기능장",
    ],
  },
  {
    title: "다이어트",
    specs: ["- 영양사"],
  },
  {
    title: "헬스",
    specs: ["- 생활스포츠지도사(보디빌딩)", "- NSCA-CPT / NSCA-CSCS"],
  },
  {
    title: "약료",
    specs: ["- 약사 면허증"],
  },
  {
    title: "법무/세무",
    specs: ["- 변호사 신분증", "- 세무사 등록증"],
  },
]

export const MasterRegistrationScreen: FC<
  StackScreenProps<NavigatorParamList, "masterRegistration">
> = observer(({ navigation }) => {
  const goBack = () => navigation.goBack()
  const { authStore, categoryStore } = useStores()
  const [isAgree, setIsAgree] = useState(false)
  const [masterWalletInfo, setMasterWalletInfo] = useState(authStore.profile?.wallet_address || "")
  const [masterCategory, setMasterCategory] = useState(authStore.profile?.master_category || "")
  const [attachments, setAttachments] = useState<any[]>([])
  const [educations, setEducations] = useState<Education[]>(authStore.profile?.educations || [])
  const [careers, setCareers] = useState<Career[]>(authStore.profile?.careers || [])
  const [masterInputAddress, setMasterInputAddress] = useState(
    authStore.profile?.master_address || "",
  )
  const [masterServiceNumber, setMasterServiceNumber] = useState(
    authStore.profile?.master_phone_number || "",
  )
  const [masterServiceEmail, setMasterServiceEmail] = useState(
    authStore.profile?.master_email || "",
  )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [sent, setSent] = useState(false)

  useEffect(() => {}, [])

  const getProperSpecs = () => {
    if (masterCategory.length === 0) {
      return []
    } else {
      const selectedCategory = categoryStore.getCategory(masterCategory)
      if (selectedCategory) {
        const selectedSpec = attachmentsSpec.filter((spec) => spec.title === selectedCategory.name)
        if (selectedSpec.length > 0) return selectedSpec[0].specs
        else return noneSpec
      } else {
        return noneSpec
      }
    }
  }

  const handleSubmit = async () => {
    if (isAgree === false) {
      setError("소통 마스터 약관에 동의해 주세요.")
      return
    }
    if (masterWalletInfo.trim().length === 0) {
      setError("통통지갑 정보를 입력해 주세요.")
      return
    }
    if (masterCategory.length === 0) {
      setError("마스터 카테고리를 선택해 주세요.")
      return
    }
    if (attachments.length === 0) {
      setError("증빙서류를 첨부해 주세요.")
      return
    }
    if (masterInputAddress.trim().length === 0) {
      setError("사업장 주소를 입력해 주세요.")
      return
    }
    if (masterServiceNumber.trim().length === 0) {
      setError("고객센터 전화번호를 입력해 주세요.")
      return
    }
    if (!validPhoneNumber(masterServiceNumber)) {
      setError("고객센터 전화번호 형식이 올바르지 않습니다.")
      return
    }
    if (masterServiceEmail.trim().length === 0) {
      setError("고객센터 이메일을 입력해 주세요.")
      return
    }
    if (!validEmailAddress(masterServiceEmail)) {
      setError("고객센터 이메일 형식이 올바르지 않습니다.")
      return
    }
    setLoading(true)
    setError("")
    const uploadResults: UploadResult[] = []
    for (const attachment of attachments) {
      try {
        const result = await upload(attachment.file)
        if (result) {
          uploadResults.push(result)
        }
      } catch (error) {}
    }
    const payload = {
      wallet_address: masterWalletInfo,
      master_category: masterCategory,
      master_address: masterInputAddress,
      master_phone_number: masterServiceNumber,
      master_email: masterServiceEmail,
      master_status: "submitted",
      careers: careers,
      educations: educations,
      attachments: uploadResults,
    }

    setSent(true)
    await authStore.registerMaster(payload)
    setLoading(false)
  }

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        {!sent && <Header leftIcon="back" onLeftPress={goBack} headerText={"마스터 등록"} />}

        <View style={{ flex: 1 }}>
          <View style={{ height: 1, backgroundColor: color.border }} />
          {sent ? (
            <View style={{ flex: 1, justifyContent: "space-evenly", alignItems: "center" }}>
              <Image
                source={coloredLogo}
                style={{
                  width: 160,
                  height: 101.75,
                }}
              />
              <View>
                <Text text={"마스터 신청이 완료 되었습니다."} preset="center" />
                <Text text={"2-3 영업일 내로 신청 결과를 알려드립니다."} preset="center" />
              </View>
              <Button
                text={"홈으로 이동"}
                textStyle={{ fontSize: 18 }}
                onPress={() => {
                  navigation.navigate("home")
                }}
              />
            </View>
          ) : (
            <ScrollView
              style={{ flex: 1 }}
              nestedScrollEnabled={true}
              contentContainerStyle={{ padding: spacing[4] }}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              keyboardShouldPersistTaps="handled"
              overScrollMode={"never"}
            >
              <Text text={"마스터 정보 입력"} preset="header1" />
              <View
                style={{ flexDirection: "row", alignItems: "center", marginVertical: spacing[4] }}
              >
                <MaterialIcons name="circle" size={18} color={color.primary} />
                <Text text="필수 기입 정보입니다." style={{ marginHorizontal: spacing[2] }} />
              </View>

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text
                  text={"마스터 약관 동의"}
                  preset="header1"
                  style={{ marginVertical: spacing[4], marginRight: spacing[2] }}
                />
                <MaterialIcons name="circle" size={12} color={color.primary} />
              </View>
              <Text text="소통 마스터 약관" style={{ marginLeft: spacing[4] }} />
              <ScrollView
                nestedScrollEnabled={true}
                style={{
                  flex: 1,
                  marginVertical: spacing[2],
                  height: 360,
                  borderWidth: 1,
                  borderColor: color.dim,
                  paddingHorizontal: spacing[4],
                }}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
              >
                <RenderHtml
                  contentWidth={SCREEN_WIDTH * spacing[4] * 4}
                  source={{ html: require("./html-loader.js")() }}
                />
              </ScrollView>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                  status={isAgree ? "checked" : "unchecked"}
                  onPress={() => {
                    setIsAgree(!isAgree)
                  }}
                />
                <Text
                  text={"안내사항을 모두 확인했으며 이에 동의합니다"}
                  style={{ marginLeft: spacing[2] }}
                  onPress={() => {
                    setIsAgree(!isAgree)
                  }}
                />
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", marginTop: spacing[4] }}>
                <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
                  <Text
                    text={"통통지갑 정보 입력"}
                    preset="header1"
                    style={{ marginVertical: spacing[4], marginRight: spacing[2] }}
                  />
                  <MaterialIcons name="circle" size={12} color={color.primary} />
                  <TouchableOpacity
                    onPress={() => {
                      alert("통통지갑이 아직 없으시다면 설치 후에 본인 지갑 주소를 입력해주세요.")
                    }}
                    style={{ marginLeft: spacing[2] }}
                  >
                    <MaterialCommunityIcons name="progress-question" size={24} color={color.text} />
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  onPress={async () => {
                    setMasterWalletInfo(await Clipboard.getString())
                  }}
                >
                  <MaterialCommunityIcons name="content-copy" size={24} color={color.text} />
                </TouchableOpacity>
              </View>
              <TextField
                value={masterWalletInfo}
                onChangeText={(text) => {
                  setMasterWalletInfo(text)
                }}
                placeholder={"지갑주소 입력"}
                style={{ padding: spacing[2] }}
                inputStyle={{ padding: spacing[0], borderRadius: spacing[2] }}
              />
              <Text
                text={"* 통통머니 수익을 정산받을 통통지갑 주소를 입력해주세요."}
                style={{ marginTop: spacing[4], color: color.dim }}
                preset="small"
              />
              <Text
                text={"* 오기입으로 인한 손해는 소통에서 보장하지 않습니다."}
                style={{ marginVertical: spacing[2], color: color.dim }}
                preset="small"
              />

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text
                  text={"마스터 카테고리 선택"}
                  preset="header1"
                  style={{ marginVertical: spacing[4], marginRight: spacing[2] }}
                />
                <MaterialIcons name="circle" size={12} color={color.primary} />
              </View>

              <MasterCategorySelect
                masterCategory={masterCategory}
                setMasterCategory={setMasterCategory}
              />
              {masterCategory.length === 0 ? (
                <View />
              ) : (
                <View>
                  <Text
                    text={`* ${
                      categoryStore?.getCategory(masterCategory)?.name
                    } 분야의 인증 자격 중 하나를 첨부해주세요.`}
                    style={{ marginVertical: spacing[4], color: color.dim }}
                    preset="small"
                  />
                  <View
                    style={{
                      backgroundColor: color.dim,
                      borderRadius: spacing[2],
                      padding: spacing[4],
                    }}
                  >
                    {getProperSpecs().map((spec, index) => {
                      return <Text text={spec} key={index} />
                    })}
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginVertical: spacing[4],
                    }}
                  >
                    <Text
                      text={"증빙서류 첨부"}
                      preset="header1"
                      style={{ marginRight: spacing[2] }}
                    />
                    <MaterialIcons name="circle" size={12} color={color.primary} />
                  </View>
                  <AttachDocuments attachments={attachments} setAttachments={setAttachments} />
                </View>
              )}
              <View
                style={{ flexDirection: "row", alignItems: "center", marginVertical: spacing[4] }}
              >
                <Text text={"학력 / 경력 추가"} preset="header1" />
                <Text
                  text={"(선택사항)"}
                  preset="small"
                  style={{ color: color.dim, marginHorizontal: spacing[2] }}
                />
              </View>
              <Text
                text={"* 마스터 님의 프로필에 노출됩니다."}
                preset="small"
                style={{ color: color.dim, marginBottom: spacing[2] }}
              />
              <EducationsInput educations={educations} setEducations={setEducations} />
              <CareersInput careers={careers} setCareers={setCareers} />

              <View
                style={{ flexDirection: "row", alignItems: "center", marginVertical: spacing[4] }}
              >
                <Text text={"판매자 정보"} preset="header1" style={{ marginRight: spacing[2] }} />
                <MaterialIcons name="circle" size={12} color={color.primary} />
              </View>
              <Text
                text={"* 전자상거래법 준수 등을 위해 프로필 화면에 노출됩니다."}
                preset="small"
                style={{ color: color.dim, marginBottom: spacing[2] }}
              />

              <AddressTextField
                masterInputAddress={masterInputAddress}
                setMasterInputAddress={setMasterInputAddress}
              />

              <Text text={"고객센터 전화번호"} preset={"bold"} style={{ marginTop: spacing[2] }} />
              <TextField
                value={masterServiceNumber}
                onChangeText={(text) => {
                  setMasterServiceNumber(text)
                }}
                placeholder={"하이픈(-) 없이 입력해주세요"}
                style={{ padding: spacing[2], marginVertical: spacing[2] }}
                inputStyle={{ padding: spacing[0], borderRadius: spacing[2] }}
                keyboardType={"number-pad"}
              />

              <Text text={"고객센터 이메일"} preset={"bold"} style={{ marginTop: spacing[2] }} />
              <TextField
                value={masterServiceEmail}
                onChangeText={(text) => {
                  setMasterServiceEmail(text)
                }}
                placeholder={"이메일"}
                style={{ padding: spacing[2], marginVertical: spacing[2] }}
                inputStyle={{ padding: spacing[0], borderRadius: spacing[2] }}
                keyboardType={"email-address"}
              />

              {error.length !== 0 && (
                <Text
                  text={`${error}`}
                  preset={"warningCenter"}
                  style={{ marginTop: spacing[4] }}
                />
              )}
              <Button
                text={"신청 완료!"}
                style={{ marginTop: spacing[6] }}
                onPress={handleSubmit}
                disabled={loading}
                loading={loading}
              />
            </ScrollView>
          )}
        </View>
      </Screen>
    </View>
  )
})
