import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { KeywordModel, KeywordSnapshot } from "../keyword/keyword"

export const KeywordStoreModel = types
  .model("KeywordStore")
  .props({
    recentKeywords: types.optional(types.array(KeywordModel), []),
  })
  .actions((self) => ({
    saveRecentKeywords: (keywordSnapshots: KeywordSnapshot[]) => {
      self.recentKeywords.replace(keywordSnapshots)
    },
    addRecentKeyword: (keyword: string) => {
      if (self.recentKeywords.find((element) => element.keyword === keyword) === undefined) {
        self.recentKeywords.push({ keyword: keyword, state: "keep", is_suggested: false })
      }
    },
    removeKeyword: (keyword: string) => {
      const index = self.recentKeywords.map((keyword) => keyword.keyword).indexOf(keyword)
      if (index > -1) {
        self.recentKeywords.splice(index, 1)
      }
    },
  }))
  .actions((self) => ({}))

type KeywordStoreType = Instance<typeof KeywordStoreModel>
export interface KeywordStore extends KeywordStoreType {}
type KeywordStoreSnapshotType = SnapshotOut<typeof KeywordStoreModel>
export interface KeywordStoreSnapshot extends KeywordStoreSnapshotType {}
