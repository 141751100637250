/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, ScrollView, RefreshControl, TouchableOpacity } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, PageHeader, CategoryBox, Text } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { Space } from "../../models/space/space"
import { compareCount } from "../../utils/sort"
import { SpacesMore } from "../../components/spaces-more/spaces-more"

let isMounted = false

export const HomeCommunityScreen: FC<StackScreenProps<NavigatorParamList, "homeCommunity">> =
  observer(({ navigation }) => {
    const goBack = () => navigation.goBack()
    const [refreshing, setRefreshing] = useState(false)
    const { authStore, categoryStore, spaceStore } = useStores()
    const [recentSpaces, setRecentSpaces] = useState<Space[]>([])
    const communityCategories = categoryStore.getCommunityCategories()

    const fetchData = async () => {
      if (isMounted) {
        setRefreshing(true)
        await Promise.all([categoryStore.fetchCategories(), spaceStore.fetchSpaces(10, 0)]).then(
          (results) => {
            setRecentSpaces(results[1])
          },
        )
        setRefreshing(false)
      }
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      return () => {
        setRefreshing(false)
        isMounted = false
      }
    }, [])

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <PageHeader />
          <Header leftIcon="back" onLeftPress={goBack} headerText={"친구와 소통하기"} />
          <ScrollView
            style={{ flex: 1 }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={fetchData} />}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                backgroundColor: "#F1F1F1",
                borderRadius: spacing[2],
                margin: spacing[4],
              }}
            >
              {communityCategories.length > 0 ? (
                communityCategories.map((item) => {
                  return (
                    <CategoryBox
                      onPressBox={() => {
                        navigation.navigate("homeSpacesByCategory", { category: item })
                      }}
                      category={item}
                      key={item.category_id}
                    />
                  )
                })
              ) : (
                <></>
              )}
            </View>
            <SpacesMore
              spaces={recentSpaces.slice(0, 3)}
              moreTitle={"현재 대화 중인 스페이스"}
              onPressAll={() => navigation.navigate("homeCommunityAll", { type: "all" })}
              onRefreshSpaces={async () => {
                setRefreshing(true)
                await Promise.all([spaceStore.fetchSpaces()]).then((results) => {
                  setRecentSpaces(results[0].sort((a, b) => compareCount(b, a)))
                })
                setRefreshing(false)
              }}
            />
            <TouchableOpacity style={{ marginTop: spacing[6] }} onPress={() => {}}>
              <Text text="소통에 대해 더 알아보고 싶다면?" preset="boldCenter" />
            </TouchableOpacity>
          </ScrollView>
        </Screen>
      </View>
    )
  })
