/* eslint-disable node/handle-callback-err */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, FlatList, ActivityIndicator, RefreshControl } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, PageHeader, NotificationBox } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { Notification } from "../../models/notification/notification"
import Toast from "react-native-toast-message"
import moment from "moment"
import "moment/locale/ko"

let isMounted = false

export const NotificaitionListScreen: FC<StackScreenProps<NavigatorParamList, "notificaitonList">> =
  observer(({ navigation }) => {
    const goBack = () => navigation.goBack()
    const { authStore, notificationStore, transactionStore, spaceStore } = useStores()
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [page, setPage] = useState(0)
    const [noMore, setNoMore] = useState(false)
    const [notifications, setNotifications] = useState<Notification[]>([])
    let onEndReachedCalledDuringMomentum = false

    const fetchData = async (refresh = false) => {
      if (isMounted) {
        let result = []
        if (refresh) {
          if (!loading) {
            setRefreshing(true)
            result = await notificationStore.fetchNotifications(authStore.token, 10, 0)
            setNoMore(result.length === 0)
            setPage(1)
            setNotifications(result)
            setRefreshing(false)
          }
        } else {
          if (!refreshing) {
            setLoading(true)
            result = await notificationStore.fetchNotifications(authStore.token, 10, page)
            setNoMore(result.length === 0)
            setPage(page + 1)
            setNotifications(notifications.concat(result))
            setLoading(false)
          }
        }
      }
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      return () => {
        setLoading(false)
        setRefreshing(false)
        isMounted = false
      }
    }, [])

    const handleLoadMore = () => {
      fetchData()
    }

    const handleRefresh = () => {
      fetchData(true)
    }

    const handleDeleteNotificaiton = async (notification: Notification) => {
      setUpdating(true)
      notificationStore
        .deleteNotification(authStore.token, notification.notification_id)
        .then(() => {
          setUpdating(false)
          setNotifications(
            notifications.filter((item) => item.notification_id !== notification.notification_id),
          )
        })
        .catch((error) => {
          setUpdating(false)
        })
    }

    const updateNotification = async (notification_id: string, refresh = false) => {
      setUpdating(true)
      notificationStore
        .updateNotification(authStore.token, notification_id)
        .then(() => {
          setUpdating(false)
          if (!refresh) {
            setNotifications(
              notifications.map((item) => {
                if (item.notification_id === notification_id) {
                  return {
                    ...item,
                    is_read: true,
                  }
                } else {
                  return item
                }
              }),
            )
          } else {
            handleRefresh()
          }
        })
        .catch((error) => {
          setUpdating(false)
        })
    }

    const openLink = async (url) => {
      window.open(url, "_blank").focus()
    }

    const handleOnPressNotification = async (notification: Notification) => {
      if (!notification.is_read) {
        await updateNotification(notification.notification_id)
      }
      if (notification.type === "notification.master_approve" && authStore.profile?.is_master) {
        navigation.navigate("masterProfile")
      }
      if (notification.type === "notification.query_answer") {
        navigation.navigate("questionAnswer")
      }
      if (notification.type === "notification.counseling_accepted" && authStore.isChatClientReady) {
        navigation.navigate("chatList")
      }
      if (
        notification.type === "notification.counseling_pre_alter" &&
        authStore.isChatClientReady
      ) {
        navigation.navigate("chatList")
      }
      if (notification.type === "notification.invite") {
        const content = JSON.parse(notification.content as string)
        spaceStore
          .fetchSpaceByHubId(authStore.token, content.hub_id)
          .then((result) => {
            if (result === true) {
              const theURL = encodeURI(
                `${content.url}?default_mobile_material_quality=high&token=${authStore.token}`,
              )
              openLink(theURL)
            } else {
              alert("이미 삭제된 스페이스입니다.")
            }
          })
          .catch((error) => {})
      }
      if (notification.type === "notification.wish_follow") {
        const content = JSON.parse(notification.content as string)
        const profileId = content.profileId
        const accountId = content.accountId
        if (
          profileId &&
          profileId.trim().length !== 0 &&
          accountId &&
          accountId.trim().length !== 0
        ) {
          navigation.navigate("userDetail", { profileId: profileId, accountId: accountId })
        }
      }
      if (notification.type === "notification.be_reported") {
        const content = JSON.parse(notification.content as string)
        const reasonString = () => {
          switch (content.reason) {
            case "commercial":
              return "상업적 광고"
            case "pornography":
              return "음란물"
            case "violent":
              return "폭력성"
            default:
              return "기타"
          }
        }
        const strContent = `회원님께서는 [${moment(content.inserted_at).format(
          "M월 D일 dddd a hh:mm",
        )}]\n[${reasonString()}] 로 신고되었습니다.\n\n총 누적 신고횟수 ${
          content.reported_count
        }회인 상태이며 신고횟수 3회 시 소통 서비스 이용이 불가하며 자세한 내용은 소통 커뮤니티 가이드에서 더 알아보세요.`
        if (confirm(strContent)) {
          navigation.navigate("guide")
        }
      }
    }

    const handelOnCounselingReject = async (notification: Notification) => {
      setUpdating(true)
      transactionStore
        .updateTransaction(
          authStore.token,
          JSON.parse(notification.content as string).transaction_id,
          {
            status: "rejected",
          },
        )
        .then((result) => {
          setUpdating(false)
          if (result === true) {
            updateNotification(notification.notification_id, true)
          }
        })
        .catch((error) => {
          setUpdating(false)
        })
    }

    const handleOnCounselingAccept = async (notification: Notification) => {
      setUpdating(true)
      transactionStore
        .updateTransaction(
          authStore.token,
          JSON.parse(notification.content as string).transaction_id,
          {
            status: "accepted",
          },
        )
        .then((result) => {
          setUpdating(false)
          if (result === true) {
            updateNotification(notification.notification_id, true)
          } else {
            Toast.show({
              type: "error",
              text1: "코인 전송 실패",
              position: "bottom",
            })
          }
        })
        .catch((error) => {
          setUpdating(false)
        })
    }

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <PageHeader />
          <Header
            leftIcon="back"
            onLeftPress={goBack}
            headerText={"알림"}
            settingsIcon={true}
            onSetingsIconPress={() => {
              navigation.navigate("notificationSettings")
            }}
          />
          <View style={{ height: 1, backgroundColor: color.border }} />
          <FlatList
            contentContainerStyle={{ flexGrow: 1 }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            data={notifications}
            keyExtractor={(item) => item.notification_id}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
            initialNumToRender={10}
            onMomentumScrollBegin={() => {
              onEndReachedCalledDuringMomentum = false
            }}
            onEndReached={() => {
              if (!onEndReachedCalledDuringMomentum) {
                handleLoadMore()
                onEndReachedCalledDuringMomentum = true
              }
            }}
            onEndReachedThreshold={0.1}
            renderItem={({ item, index }) => {
              if (item.type === "notification.book_counseling") {
                return (
                  <NotificationBox
                    notification={item}
                    key={index}
                    onDelete={handleDeleteNotificaiton}
                    onPress={handleOnPressNotification}
                    onCounselingAccept={handleOnCounselingAccept}
                    onCounselingReject={handelOnCounselingReject}
                    loading={updating}
                  />
                )
              } else {
                return (
                  <NotificationBox
                    notification={item}
                    key={index}
                    onDelete={handleDeleteNotificaiton}
                    onPress={handleOnPressNotification}
                    loading={updating}
                  />
                )
              }
            }}
            ListFooterComponent={
              loading && !noMore ? (
                <ActivityIndicator
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    marginVertical: spacing[4],
                  }}
                />
              ) : (
                <></>
              )
            }
          />
        </Screen>
      </View>
    )
  })
