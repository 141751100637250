import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { AuthStoreModel } from "../auth-store/auth-store"
import { CategoryStoreModel } from "../category-store/category-store"
import { AnnouncementStoreModel } from "../announcement-store/announcement-store"
import { FaqStoreModel } from "../faq-store/faq-store"
import { UserStoreModel } from "../user-store/user-store"
import { SpaceStoreModel } from "../space-store/space-store"
import { SlideStoreModel } from "../slide-store/slide-store"
import { KeywordStoreModel } from "../keyword-store/keyword-store"
import { TagStoreModel } from "../tag-store/tag-store"
import { NotificationStoreModel } from "../notification-store/notification-store"
import { NotificationSettingStoreModel } from "../notification-setting-store/notification-setting-store"
import { SpaceTypeStoreModel } from "../space-type-store/space-type-store"
import { TtmiStoreModel } from "../ttmi-store/ttmi-store"
import { TopicStoreModel } from "../topic-store/topic-store"
import { SettingsStoreModel } from "../settings-store/settings-store"
import { TransactionStoreModel } from "../transaction-store/transaction-store"
import { CounselingStoreModel } from "../counseling-store/counseling-store"
import { WalletStoreModel } from "../wallet-store/wallet-store"
import { QueryStoreModel } from "../query-store/query-store"

export const RootStoreModel = types.model("RootStore").props({
  authStore: types.optional(AuthStoreModel, {} as any),
  announcementStore: types.optional(AnnouncementStoreModel, {} as any),
  faqStore: types.optional(FaqStoreModel, {} as any),
  userStore: types.optional(UserStoreModel, {} as any),
  categoryStore: types.optional(CategoryStoreModel, {} as any),
  spaceStore: types.optional(SpaceStoreModel, {} as any),
  slideStore: types.optional(SlideStoreModel, {} as any),
  keywordStore: types.optional(KeywordStoreModel, {} as any),
  tagStore: types.optional(TagStoreModel, {} as any),
  notificationStore: types.optional(NotificationStoreModel, {} as any),
  spaceTypeStore: types.optional(SpaceTypeStoreModel, {} as any),
  ttmiStore: types.optional(TtmiStoreModel, {} as any),
  topicStore: types.optional(TopicStoreModel, {} as any),
  settingStore: types.optional(SettingsStoreModel, {} as any),
  notificationSettingStore: types.optional(NotificationSettingStoreModel, {} as any),
  transactionStore: types.optional(TransactionStoreModel, {} as any),
  counselingStore: types.optional(CounselingStoreModel, {} as any),
  walletStore: types.optional(WalletStoreModel, {} as any),
  queryStore: types.optional(QueryStoreModel, {} as any),
})

export interface RootStore extends Instance<typeof RootStoreModel> {}

export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
