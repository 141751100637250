/* eslint-disable camelcase */
import { ApiResponse } from 'apisauce'
import { Api } from './api'
import { PostWalletResult } from './api.types'
import { getGeneralApiProblem } from './api-problem'

export class WalletApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async generateWallet(token: string, seed_string: string): Promise<PostWalletResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/sotong_wallet/wallet_register',
        { payload: { seed_string: seed_string } },
      )
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      return { kind: 'ok', result: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
