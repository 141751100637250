/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { TagApi } from "../../services/api/tag-api"
import { TagModel, TagSnapshot } from "../tag/tag"
import { api } from "app/services/api"

export const TagStoreModel = types
  .model("TagStore")
  .props({
    tags: types.optional(types.array(TagModel), []),
  })
  .views((self) => ({
    getTagNameFromId: (tag_id: string) => self.tags.find((tag) => tag.tag_id === tag_id)?.tag || "",
  }))
  .actions((self) => ({
    saveTags: (tagSnapshots: TagSnapshot[]) => {
      self.tags.replace(tagSnapshots)
    },
  }))
  .actions((self) => ({
    fetchTags: flow(function* () {
      const tagApi = new TagApi(api)
      const result = yield tagApi.getTags()

      if (result.kind === "ok") {
        self.saveTags(result.tags)
      } else {
        if (result.kind === "not-found") {
          self.tags.clear()
        }
      }
    }),
    insertTag: flow(function* (token: string, tag: string) {
      const tagApi = new TagApi(api)
      const result = yield tagApi.insertTag(token, tag)

      if (result.kind === "ok") {
        return result.tag_id
      } else {
        if (result.kind === "not-found") {
          self.tags.clear()
        }
        return ""
      }
    }),
  }))

type TagStoreType = Instance<typeof TagStoreModel>
export interface TagStore extends TagStoreType {}
type TagStoreSnapshotType = SnapshotOut<typeof TagStoreModel>
export interface TagStoreSnapshot extends TagStoreSnapshotType {}
