/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { View, FlatList, ActivityIndicator, RefreshControl } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { NavigatorParamList } from '../../navigators'
import { Screen, Header, TransactionBox } from '../../components'
import { color, spacing } from '../../theme'
import { useStores } from '../../models'
import { useIsFocused } from '@react-navigation/native'
import { Transaction } from '../../models/transaction/transaction'

let isMounted = false

export const TransactionHistoryScreen: FC<
  StackScreenProps<NavigatorParamList, 'transactionHistory'>
> = observer(({ navigation }) => {
  const goBack = () => navigation.goBack()
  const { authStore, transactionStore } = useStores()
  const isFocused = useIsFocused()
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [refreshing, setRefreshing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [noMore, setNoMore] = useState(false)
  let onEndReachedCalledDuringMomentum = false

  const fetchData = async (refresh = false) => {
    if (isMounted && isFocused) {
      let result = []
      if (refresh) {
        if (!loading) {
          setRefreshing(true)
          result = await transactionStore.fetchTransactions(authStore.token, 6, 0)
          setNoMore(result.length === 0)
          setTransactions(result)
          setPage(1)
          setRefreshing(false)
        }
      } else {
        if (!refreshing) {
          setLoading(true)
          result = await transactionStore.fetchTransactions(authStore.token, 6, page)
          setNoMore(result.length === 0)
          setTransactions(transactions.concat(result))
          setPage(page + 1)
          setLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    isMounted = true
    setLoading(false)
    setRefreshing(false)
    setNoMore(false)
    setTransactions([])
    setPage(0)
    fetchData(true)
    return () => {
      setLoading(false)
      setRefreshing(false)
      isMounted = false
    }
  }, [isFocused])

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        <Header leftIcon="back" onLeftPress={goBack} headerText={'상담내역'} />
        <View style={{ flex: 1 }}>
          <View style={{ height: 1, backgroundColor: color.border }} />
          <FlatList
            contentContainerStyle={{ paddingVertical: spacing[2] }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            data={transactions}
            initialNumToRender={6}
            onMomentumScrollBegin={() => {
              onEndReachedCalledDuringMomentum = false
            }}
            onEndReached={() => {
              if (!onEndReachedCalledDuringMomentum) {
                fetchData()
                onEndReachedCalledDuringMomentum = true
              }
            }}
            onEndReachedThreshold={0.1}
            keyExtractor={(item) => item.transaction_id}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={() => {
                  fetchData(true)
                }}
              />
            }
            renderItem={({ item, index }) => (
              <View key={index}>
                <TransactionBox
                  transaction={item}
                  onPressReview={() => {
                    navigation.navigate('transactionReview', { transaction: item })
                  }}
                  afterCancel={() => {
                    fetchData(true)
                  }}
                />
              </View>
            )}
            ListFooterComponent={
              loading && !noMore ? (
                <ActivityIndicator
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginVertical: spacing[4],
                  }}
                />
              ) : (
                <></>
              )
            }
          />
        </View>
      </Screen>
    </View>
  )
})
