/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { Linking, TouchableOpacity, View } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, Text, Button, AutoImage } from "../../components"
import { color, spacing } from "../../theme"
import { ScrollView } from "react-native-gesture-handler"

const logoMexcGlobal = require("../../../assets/images/logo_mexc_global.png")
const logoHotBit = require("../../../assets/images/logo_hotbit.png")

export const ShopWebScreen: FC<StackScreenProps<NavigatorParamList, "shopWeb">> = observer(
  ({ navigation }) => {
    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <Header headerText={"상점"} />
          <View style={{ flex: 1, backgroundColor: color.border }}>
            <ScrollView
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              keyboardShouldPersistTaps="handled"
              contentContainerStyle={{ flexGrow: 1, padding: spacing[4] }}
            >
              <View
                style={{
                  backgroundColor: color.background,
                  padding: spacing[4],
                  borderRadius: spacing[4],
                }}
              >
                <Text
                  text={"통통코인이란?"}
                  preset={"boldCenter"}
                  style={{ marginBottom: spacing[4] }}
                />
                <Text style={{ lineHeight: spacing[6] }}>
                  <Text text={"통통코인"} preset={"bold"} />
                  <Text
                    text={
                      "은 기존 지불결제 및 송금 수단 대비 수수료를 1/10 수준으로 낮출 수 있는 암호 화폐입니다."
                    }
                  />
                  <Text text={" 통통지갑"} preset={"bold"} />
                  <Text
                    text={
                      " 생성 후에 거래가 가능하며 비트코인, 이더리움 등 다양한 암호화폐 관리도 가능합니다."
                    }
                  />
                </Text>
                <View style={{ marginTop: spacing[4], display: "flex", alignItems: "flex-end" }}>
                  <Button
                    preset={"link"}
                    onPress={() => {
                      Linking.openURL("https://tongtongcoin.io/?lang=KR")
                    }}
                  >
                    <Text text={"자세히 보기"} />
                  </Button>
                </View>
              </View>
              <View
                style={{
                  backgroundColor: color.background,
                  padding: spacing[4],
                  borderRadius: spacing[4],
                  marginTop: spacing[4],
                  alignItems: "center",
                }}
              >
                <Text
                  text={"통통코인 거래하기"}
                  preset={"boldCenter"}
                  style={{ marginBottom: spacing[4] }}
                />
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("https://www.mexc.com/ko-KR/exchange/TTC_USDT?_from=header")
                  }}
                >
                  <AutoImage source={logoMexcGlobal} />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("https://www.hotbit.io/exchange?symbol=TTC_USDT")
                  }}
                  style={{ marginTop: spacing[8] }}
                >
                  <AutoImage source={logoHotBit} />
                </TouchableOpacity>
              </View>
            </ScrollView>
          </View>
        </Screen>
      </View>
    )
  },
)
