import { useStores } from "app/models"
import { navigationRef } from "app/navigators"
import { onMessageListener, requestForToken } from "app/services/firebase/firebase"
import React, { useState, useEffect } from "react"
import Toast from "react-native-toast-message"
import { chatClient } from "../chat-client/chat-client"

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" })
  const { authStore, notificationSettingStore } = useStores()

  useEffect(() => {
    if (notification?.title || notification?.body) {
      Toast.show({
        type: "info",
        text1: notification?.title,
        text2: notification?.body,
        position: "top",
      })
    }
  }, [notification])

  requestForToken()
    .then((currentToken) => {
      authStore.setDeviceToken(currentToken)
    })
    .catch((_) => {
      authStore.setDeviceToken(null)
    })

  onMessageListener()
    .then(async (payload: any) => {
      if (payload?.notification) {
        setNotification({ title: payload?.notification?.title, body: payload?.notification?.body })
      }
      if (
        payload?.data?.type?.includes("message.new") &&
        notificationSettingStore.notification_message_chat === true
      ) {
        const currentRoute = navigationRef.getCurrentRoute()
        if (currentRoute && currentRoute.name !== "chat") {
          const filter = { id: { $in: [payload?.data?.channel_id] } }
          const channels = await chatClient.queryChannels(filter)

          if (channels.length > 0) {
            chatClient
              .getMessage(payload?.data?.id)
              .then((result) => {
                setNotification({
                  title: result?.message?.user?.name,
                  body: result?.message?.text || "[첨부 파일이 있습니다.]",
                })
              })
              .catch((error) => {
                console.log("error", error)
              })
          }
        }
      }
    })
    .catch((err) => console.log("failed: ", err))

  return <></>
}

export default Notification
