/* eslint-disable no-use-before-define */
import * as React from "react"
import { View, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { User } from "../../models/user/user"
import { FlatGrid } from "react-native-super-grid"
import { navigationRef } from "../../navigators"
import { GeneralUserBoxSquare } from "../general-user-box-square/general-user-box-square"

export interface MastersMoreProps {
  onPressAll?(): void
  moreTitle?: string
  users: User[]
}

export const MastersMore = observer(function MastersMore(props: MastersMoreProps) {
  const { onPressAll, moreTitle, users } = props

  return (
    <View>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottomColor: color.border,
          borderBottomWidth: 1,
          padding: spacing[4],
        }}
      >
        <Text text={moreTitle} />
        <TouchableOpacity onPress={onPressAll}>
          <Text text={"더보기"} preset="secondaryLabel" />
        </TouchableOpacity>
      </View>
      {users.length > 0 ? (
        <FlatGrid
          keyboardShouldPersistTaps="handled"
          data={users}
          style={{ height: 120 + spacing[4], marginBottom: spacing[4] }}
          horizontal={true}
          spacing={spacing[4]}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <GeneralUserBoxSquare
              user={item}
              onPress={() => {
                navigationRef.navigate("userDetail", {
                  profileId: item.profile_id,
                  accountId: item.account_id,
                } as never)
              }}
            />
          )}
        />
      ) : (
        <></>
      )}
    </View>
  )
})
