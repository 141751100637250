/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View, TextStyle, ActivityIndicator } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, Text } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { AvatarCreatorViewer } from "@readyplayerme/rpm-react-sdk"

const FULL: ViewStyle = {
  flex: 1,
}
const CONTAINER: ViewStyle = {
  backgroundColor: color.transparent,
}
const HEADER: TextStyle = {
  paddingHorizontal: spacing[4],
}

export const AvatarCreateScreen: FC<StackScreenProps<NavigatorParamList, "avatarCreate">> =
  observer(({ navigation }) => {
    const goBack = () => navigation.goBack()
    const { authStore } = useStores()

    const ReadyPlayerMeView = () => {
      const [loading, setLoading] = useState(false)

      useEffect(() => {
        return () => {
          setLoading(false)
        }
      }, [])

      const onAvatarExported = async (url: string) => {
        if (url.includes(".glb")) {
          authStore.setAvatarFromRPM(url)
          const fetchAvatarRenderURL = async () => {
            setLoading(true)
            const response = await fetch("https://render.readyplayer.me/render", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                model: url,
                scene: "halfbody-portrait-v1-transparent",
              }),
            })
            const jsonResponse = await response.json()
            authStore.setAvatarRenderFromRPM(jsonResponse.renders[0])
          }

          await fetchAvatarRenderURL()
        } else {
          authStore.setAvatarFromRPM("")
          authStore.setAvatarRenderFromRPM("")
        }
        goBack()
      }

      return (
        <>
          {loading ? (
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <ActivityIndicator style={{ marginBottom: spacing[4] }} />
              <Text
                text={"아바타 이미지를 생성하고 있습니다.\n 잠시만 기다려 주십시오."}
                preset="headerCenter1"
              />
            </View>
          ) : (
            <AvatarCreatorViewer
              subdomain="sotong-hub"
              editorConfig={{
                clearCache: true,
                bodyType: "halfbody",
                language: "kr",
              }}
              onAvatarExported={onAvatarExported}
            />
          )}
        </>
      )
    }

    return (
      <View style={FULL}>
        <Screen style={CONTAINER} preset="fixed">
          <Header
            headerTx={"profileScreen.createAvatar"}
            leftIcon="back"
            onLeftPress={goBack}
            style={HEADER}
          />
          <ReadyPlayerMeView />
        </Screen>
      </View>
    )
  })
