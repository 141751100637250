/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, FlatList, ActivityIndicator } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, PageHeader, MasterBox, Text } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { User } from "../../models/user/user"
import { getMonthString } from "../../utils/date_time"
import { compareRating } from "../../utils/sort"

let isMounted = false

const getTitleFromType = (type: string) => {
  if (type === "weekly") {
    return "현재 가장 인기 있는 마스터는?"
  } else if (type === "monthly") {
    return `${getMonthString()}의 후기 좋은 마스터`
  } else return "이런 마스터는 어떠세요"
}
const getDescriptionFromType = (type: string) => {
  if (type === "weekly") {
    return "* 최근 7일 동안 상담 횟수가 가장 많은 마스터입니다"
  } else if (type === "monthly") {
    return "* 이번 달 후기 평점이 가장 높은 마스터입니다"
  } else return ""
}

export const HomeMastersAllScreen: FC<StackScreenProps<NavigatorParamList, "homeMastersAll">> =
  observer(({ route, navigation }) => {
    const { type } = route.params
    const goBack = () => navigation.goBack()
    const { userStore } = useStores()

    const [users, setUsers] = useState<User[]>([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [noMore, setNoMore] = useState(false)
    let onEndReachedCalledDuringMomentum = false

    const fetchData = async () => {
      if (isMounted) {
        setLoading(true)
        let result = []
        result = await userStore.fetchMasters(10, page, type)
        setNoMore(result.length === 0)
        setUsers(users.concat(result))
        setPage(page + 1)
        setLoading(false)
      }
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      return () => {
        setLoading(false)
        isMounted = false
      }
    }, [])

    const handleLoadMore = () => {
      fetchData()
    }

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <PageHeader />
          <Header leftIcon="back" onLeftPress={goBack} headerText={getTitleFromType(type)} />
          <View style={{ flex: 1, marginTop: spacing[2] }}>
            {(type === "weekly" || type === "monthly") && (
              <Text
                text={getDescriptionFromType(type)}
                style={{ marginHorizontal: spacing[4], marginBottom: spacing[2] }}
                preset={"warningCenter"}
              />
            )}
            <FlatList
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              keyboardShouldPersistTaps="handled"
              data={users.sort((a, b) => compareRating(b, a))}
              initialNumToRender={10}
              onMomentumScrollBegin={() => {
                onEndReachedCalledDuringMomentum = false
              }}
              onEndReached={() => {
                if (!onEndReachedCalledDuringMomentum) {
                  handleLoadMore()
                  onEndReachedCalledDuringMomentum = true
                }
              }}
              onEndReachedThreshold={0.1}
              keyExtractor={(item) => item.profile_id}
              renderItem={({ item, index }) => (
                <View key={item.profile_id}>
                  {type === "weekly" || type === "monthly" ? (
                    <MasterBox
                      onPress={() => {
                        navigation.navigate("userDetail", {
                          profileId: item.profile_id,
                          accountId: item.account_id,
                        } as never)
                      }}
                      master={item}
                      rank={index + 1}
                    />
                  ) : (
                    <MasterBox
                      onPress={() => {
                        navigation.navigate("userDetail", {
                          profileId: item.profile_id,
                          accountId: item.account_id,
                        } as never)
                      }}
                      master={item}
                    />
                  )}
                </View>
              )}
              ListFooterComponent={
                loading && !noMore ? (
                  <ActivityIndicator
                    style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
                  />
                ) : (
                  <></>
                )
              }
            />
          </View>
        </Screen>
      </View>
    )
  })
