/* eslint-disable camelcase */
import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { CreateSpaceResult, GetSpacesResult, PostQueryResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { SpaceSnapshot } from "../../models/space/space"
import { convertSpace } from "./convert"

export class SpaceApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getSpaces(limit: number, page: number): Promise<GetSpacesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_spaces", {
        limit: limit,
        offset: page * limit,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const spaces: SpaceSnapshot[] = raw.map(convertSpace)

      return { kind: "ok", spaces: spaces }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getSpacesByCategory(
    token: string,
    category: string,
    limit: number,
    page: number,
  ): Promise<GetSpacesResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_spaces", {
        category: category,
        limit: limit,
        offset: page * limit,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const spaces: SpaceSnapshot[] = raw.map(convertSpace)

      return { kind: "ok", spaces: spaces }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getSpacesByTopic(
    token: string,
    topic: string,
    limit: number,
    page: number,
  ): Promise<GetSpacesResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_spaces", {
        topic: topic,
        limit: limit,
        offset: page * limit,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const spaces: SpaceSnapshot[] = raw.map(convertSpace)

      return { kind: "ok", spaces: spaces }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async searchSpaces(
    keyword: string,
    limit: number = 6,
    page: number = 0,
  ): Promise<GetSpacesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_spaces", {
        keyword: keyword,
        limit: limit,
        offset: page * limit,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const spaces: SpaceSnapshot[] = raw.map(convertSpace)

      return { kind: "ok", spaces: spaces }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async createSpace(
    token: string,
    title: string,
    capacity: number,
    password_protected: boolean = false,
    password: string = "",
    category: string,
    type: string,
    tags: Array<string>,
  ): Promise<CreateSpaceResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.post("/sotong_spaces", {
        sotong_space: {
          title: title,
          capacity: capacity,
          protected: password_protected,
          category: category,
          type: type,
          tags: tags,
          password: password,
        },
      })

      if (response.status === 422) {
        return { kind: "exceed" }
      }

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const space: SpaceSnapshot = convertSpace(raw)

      return { kind: "ok", space: space }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getSpaceByHubId(token: string, hub_id: string): Promise<PostQueryResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        "/sotong_spaces/get_space_by_hub_id",
        {
          hub_id: hub_id,
        },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok" }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }
}
