import { ApiResponse } from 'apisauce'
import { TopicSnapshot } from '../../models/topic/topic'
import { Api } from './api'
import { getGeneralApiProblem } from './api-problem'
import { GetTopicsResult } from './api.types'
import { convertTopic } from './convert'

export class TopicApi {
  private api: Api

  constructor (api: Api) {
    this.api = api
  }

  async getTopics (token: string): Promise<GetTopicsResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get('/sotong_topics')

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const topics: TopicSnapshot[] = response.data.map(convertTopic)
      return { kind: 'ok', topics: topics }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
