/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect, useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Dimensions, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { NavigatorParamList } from '../../navigators'
import { Screen, Header } from '../../components'
import { color, spacing } from '../../theme'
import { TabView, TabBar } from 'react-native-tab-view'
import { User } from '../../models/user/user'
import { useStores } from '../../models'
import { MemoFollowerRoute } from './memo-follower-route'
import { MemoFollowingRoute } from './memo-following-route'

let isMounted = false

export const FollowerFollowingScreen: FC<
  StackScreenProps<NavigatorParamList, 'followerFollowing'>
> = observer(({ route, navigation }) => {
  const { accountId } = route.params
  const goBack = () => navigation.goBack()
  const initialLayout = { width: Dimensions.get('window').width }
  const [index, setIndex] = useState(0)
  const [routes] = useState([
    { key: 'followerTab', title: '팔로워' },
    { key: 'followingTab', title: '팔로잉' },
  ])
  const { authStore } = useStores()
  const [followersResult, setFollowersResult] = useState<User[]>([])
  const [followingsResult, setFollowingsResult] = useState<User[]>([])

  const [followerLoading, setFollowerLoading] = useState(false)
  const [followerPage, setFollowerPage] = useState(0)
  const [followerNoMore, setFollowerNoMore] = useState(false)

  const [followingLoading, setFollowingLoading] = useState(false)
  const [followingPage, setFollowingPage] = useState(0)
  const [followingNoMore, setFollowingNoMore] = useState(false)

  const fetchData = async (tabIndex: number = 0) => {
    if (isMounted) {
      if (tabIndex === 0) {
        // followers
        setFollowerLoading(true)
        const result = await authStore.fetchFollwers(accountId, 10, followerPage)
        setFollowerNoMore(result.length === 0)
        setFollowersResult(followersResult.concat(result))
        setFollowerPage(followerPage + 1)
        setFollowerLoading(false)
      } else {
        // followings
        setFollowingLoading(true)
        const result = await authStore.fetchFollowings(accountId, 10, followingPage)
        setFollowingNoMore(result.length === 0)
        setFollowingsResult(followingsResult.concat(result))
        setFollowingPage(followingPage + 1)
        setFollowingLoading(false)
      }
    }
  }

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: color.primary }}
      style={{ backgroundColor: color.background }}
      activeColor={color.primary}
      inactiveColor={color.dim}
    />
  )

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'followerTab':
        return (
          <MemoFollowerRoute
            followersResult={followersResult}
            fetchData={fetchData}
            navigation={navigation}
            followerLoading={followerLoading}
            followerNoMore={followerNoMore}
          />
        )
      case 'followingTab':
        return (
          <MemoFollowingRoute
            followingsResult={followingsResult}
            fetchData={fetchData}
            navigation={navigation}
            followingLoading={followingLoading}
            followingNoMore={followingNoMore}
            setFollowingsResult={setFollowingsResult}
          />
        )
      default:
        return null
    }
  }

  const handleIndexChange = (index: number) => {
    setIndex(index)
    fetchData(index)
  }

  useEffect(() => {
    isMounted = true
    return () => {
      isMounted = false
    }
  }, [])

  useLayoutEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchData()
    })
    return unsubscribe
  }, [navigation])

  return (
    <View style={{ flex: 1 }}>
      <Screen style={{ backgroundColor: color.background }}>
        <Header leftIcon="back" onLeftPress={goBack} style={{ paddingHorizontal: spacing[4] }} />
        <TabView
          navigationState={{ index, routes }}
          renderScene={renderScene}
          renderTabBar={renderTabBar}
          onIndexChange={handleIndexChange}
          initialLayout={initialLayout}
        />
      </Screen>
    </View>
  )
})
