import { Instance, SnapshotOut, types } from 'mobx-state-tree'

/**
 * Model description here for TypeScript hints.
 */
export const SlideModel = types
  .model('Slide')
  .props({
    slide_id: types.identifier,
    url: types.maybeNull(types.string),
    illustration: types.maybeNull(types.string)
  })

type SlideType = Instance<typeof SlideModel>
export interface Slide extends SlideType {}
type SlideSnapshotType = SnapshotOut<typeof SlideModel>
export interface SlideSnapshot extends SlideSnapshotType {}
