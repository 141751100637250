import { TextStyle } from 'react-native'
import { color, typography } from '../../theme'

const BASE: TextStyle = {
  fontFamily: typography.primary,
  color: color.text,
  fontSize: 15
}

const BASE_BOLD: TextStyle = {
  fontFamily: typography.secondary,
  color: color.text,
  fontSize: 15
}

export const presets = {
  default: BASE,
  bold: { ...BASE_BOLD } as TextStyle,
  header: { ...BASE_BOLD, fontSize: 24 } as TextStyle,
  header1: { ...BASE_BOLD, fontSize: 18 } as TextStyle,
  headerCenter1: { ...BASE_BOLD, fontSize: 18, textAlign: 'center' } as TextStyle,
  center: { ...BASE, textAlign: 'center' } as TextStyle,
  small: { ...BASE, fontSize: 12 } as TextStyle,
  medium: { ...BASE, fontSize: 14 } as TextStyle,
  boldCenter: { ...BASE_BOLD, textAlign: 'center' } as TextStyle,
  smallCenter: { ...BASE, fontSize: 12, textAlign: 'center' } as TextStyle,
  smallRight: { ...BASE, fontSize: 12, textAlign: 'right' } as TextStyle,
  smallLef: { ...BASE, fontSize: 12, textAlign: 'left' } as TextStyle,
  headerCenter: { ...BASE_BOLD, fontSize: 24, textAlign: 'center' } as TextStyle,
  fieldLabel: { ...BASE, fontSize: 12, color: color.dim } as TextStyle,
  secondary: { ...BASE, color: color.dim } as TextStyle,
  secondaryLabel: { ...BASE, fontSize: 12, color: color.dim } as TextStyle,
  warning: { ...BASE, fontSize: 12, color: color.primary } as TextStyle,
  warningCenter: { ...BASE, fontSize: 12, color: color.primary, textAlign: 'center' } as TextStyle
}

export type TextPresets = keyof typeof presets
