/* eslint-disable no-use-before-define */
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ScrollView, View } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, PageHeader, TermsOfUse, PrivacyPolicy, Header } from "../../components"
import { color } from "../../theme"

export const TermsAndPolicyScreen: FC<StackScreenProps<NavigatorParamList, "termsAndPolicy">> =
  observer(({ route, navigation }) => {
    const { isTerms } = route.params
    const goBack = () => navigation.goBack()

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
        <PageHeader />
          <Header
            leftIcon="back"
            onLeftPress={goBack}
            headerText={isTerms ? "이용약관" : "개인정보처리방침"}
          />
          <ScrollView
            style={{ flex: 1 }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
          >
          {isTerms ? <TermsOfUse /> : <PrivacyPolicy />}
          </ScrollView>
        </Screen>
      </View>
    )
  })
