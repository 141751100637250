import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { CareerModel } from '../career/career'
import { EducationModel } from '../education/education'

export const UserModel = types.model('User').props({
  profile_id: types.identifier,
  account_id: types.string,
  phone: types.maybeNull(types.string),
  one_id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  nick_name: types.maybeNull(types.string),
  avatar: types.maybeNull(types.string),
  avatar_render: types.maybeNull(types.string),
  interests: types.maybeNull(types.array(types.string)),
  intro: types.maybeNull(types.string),
  point: types.maybeNull(types.integer),
  follower: types.maybeNull(types.integer),
  following: types.maybeNull(types.integer),
  is_following: types.maybeNull(types.boolean),
  is_liking: types.maybeNull(types.boolean),
  is_master: types.maybeNull(types.boolean),
  master_category: types.maybeNull(types.string),
  master_status: types.maybeNull(types.string),
  master_address: types.maybeNull(types.string),
  master_phone_number: types.maybeNull(types.string),
  master_email: types.maybeNull(types.string),
  wallet_id: types.maybeNull(types.string),
  wallet_address: types.maybeNull(types.string),
  wallet_name: types.maybeNull(types.string),
  wallet_password: types.maybeNull(types.string),
  user_key: types.maybeNull(types.string),
  ratings: types.optional(types.number, 0),
  count: types.maybeNull(types.number),
  educations: types.maybeNull(types.array(EducationModel)),
  careers: types.maybeNull(types.array(CareerModel)),
  last_review: types.maybeNull(types.string),
  banned: types.optional(types.boolean, false),
  reported_count: types.optional(types.number, 0),
})

type UserType = Instance<typeof UserModel>
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>
export interface UserSnapshot extends UserSnapshotType {}
