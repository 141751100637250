/* eslint-disable no-use-before-define */
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { ScrollView, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { NavigatorParamList } from '../../navigators'
import { Button, Header, Screen, Text, PasswordInput } from '../../components'
import { color, spacing } from '../../theme'
import { useStores } from '../../models'
import Toast from 'react-native-toast-message'

type FormData = {
  password: string
  confirmPassword: string
}

export const RegisterPasswordScreen: FC<StackScreenProps<NavigatorParamList, 'registerPassword'>> =
  observer(({ navigation, route }) => {
    const { userKey, phonenum, nation } = route.params
    const goBack = () => navigation.goBack()
    const {
      control,
      handleSubmit,
      formState: { errors },
      setError,
    } = useForm<FormData>()
    const { authStore } = useStores()

    const onSetPassword = async (data: FormData) => {
      if (data.password === data.confirmPassword) {
        const res = await authStore.oneJoin(userKey, phonenum, data.password)
        if (res && !res.message && res.result === true) {
          navigation.navigate('registerSuccess', {
            phone: phonenum,
            password: data.password,
            nation: nation,
          })
        } else {
          Toast.show({
            type: 'error',
            text1: '회원 가입 실패',
            position: 'bottom',
          })
        }
      } else {
        setError('confirmPassword', { type: 'confirm' })
      }
    }

    return (
      <View style={{ flex: 1 }}>
        <Screen
          style={{
            flex: 1,
            backgroundColor: color.transparent,
          }}
          preset="fixed"
        >
          <Header leftIcon="back" onLeftPress={goBack} headerText={'회원가입'} />
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              paddingHorizontal: spacing[6],
              justifyContent: 'space-evenly',
            }}
          >
            <View style={{ alignItems: 'center' }}>
              <Text text="사용할 비밀번호를 입력해주세요" style={{ marginBottom: spacing[4] }} />
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,15}$/,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PasswordInput
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    placeholder={'비밀번호 입력'}
                    style={{ marginVertical: spacing[2] }}
                  />
                )}
                name="password"
                defaultValue=""
              />
              {errors.password && errors.password.type === 'required' && (
                <Text preset="warning" text={'비밀번호를 입력해 주세요'} />
              )}
              {errors.password && errors.password.type === 'pattern' && (
                <Text preset="warning" text={'비밀번호 형식이 올바르지 않습니다'} />
              )}
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,15}$/,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PasswordInput
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    placeholder={'비밀번호 확인'}
                    style={{ marginVertical: spacing[2] }}
                  />
                )}
                name="confirmPassword"
                defaultValue=""
              />
              {errors.confirmPassword && errors.confirmPassword.type === 'required' && (
                <Text preset="warning" text={'비밀번호를 입력해 주세요'} />
              )}
              {errors.confirmPassword && errors.confirmPassword.type === 'pattern' && (
                <Text preset="warning" text={'비밀번호 형식이 올바르지 않습니다'} />
              )}
              {errors.confirmPassword && errors.confirmPassword.type === 'confirm' && (
                <Text preset="warning" text={'비밀번호 확인 실패'} />
              )}
              <Text
                text={'* 영문 + 숫자 + 특수기호 조합 6 ~ 15자'}
                style={{ textAlign: 'center', marginVertical: spacing[5] }}
              />
            </View>
            <View style={{ alignItems: 'center' }}>
              <Button
                text={authStore.status === 'pending' ? '로딩중...' : '확인'}
                onPress={handleSubmit(onSetPassword)}
                style={{ width: '100%', marginTop: spacing[4] }}
                disabled={authStore.status === 'pending'}
              />
            </View>
          </ScrollView>
        </Screen>
      </View>
    )
  })
