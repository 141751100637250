/* eslint-disable no-use-before-define */
import * as React from "react"
import { StyleProp, ViewStyle, TouchableOpacity, Image, ImageStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing } from "../../theme"
import { Text } from "../text/text"
import { Category } from "../../models/category/category"
import { SCREEN_WIDTH } from "../../utils/screen"

const CONTAINER: ViewStyle = {
  flex: 1,
  alignItems: "center",
  minWidth: (SCREEN_WIDTH - spacing[4] * 2) / 4,
  marginVertical: spacing[2],
}
const CATEGORY_IMAGE: ImageStyle = {
  width: 32,
  height: 32,
  marginBottom: spacing[1],
}

export interface CategoryBoxProps {
  style?: StyleProp<ViewStyle>
  category: Category
  onPressBox(): void
}
const placeHolder = require("../../../assets/images/place-holder.png")

export const CategoryBox = observer(function CategoryBox(props: CategoryBoxProps) {
  const { style, category, onPressBox } = props
  const styles = Object.assign({}, CONTAINER, style)

  return (
    <TouchableOpacity style={styles} onPress={onPressBox}>
      {category.thumb ? (
        <Image source={{ uri: category.thumb || "" }} style={CATEGORY_IMAGE} />
      ) : (
        <Image source={placeHolder} style={CATEGORY_IMAGE} />
      )}
      <Text text={category.name || ""} preset="smallCenter" />
    </TouchableOpacity>
  )
})
