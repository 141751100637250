/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, Image, ImageStyle, TextInput } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { Picker } from "@react-native-picker/picker"
import Toast from "react-native-toast-message"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, Text, TextField, CounselingDateTimePicker, Button } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { SCREEN_WIDTH } from "../../utils/screen"
import moment from "moment"
import "moment/locale/ko"
import { TimeRange } from "app/types"

const CATEGORY_IMAGE: ImageStyle = {
  width: 32,
  height: 32,
  marginBottom: spacing[1],
}
const placeHolder = require("../../../assets/images/place-holder.png")
const PeroidOptions = () => {
  const options = [15, 30, 45, 60]
  return options.map((option, index) => (
    <Picker.Item label={`${option} 분`} value={option} key={index} />
  ))
}
const PriceOptions = () => {
  const options = [5, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
  return options.map((option, index) => (
    <Picker.Item label={`${option}`} value={option} key={index} />
  ))
}

export const AddCounselingScreen: FC<StackScreenProps<NavigatorParamList, "addCounseling">> =
  observer(({ route, navigation }) => {
    const goBack = () => navigation.goBack()
    const counseling = route.params?.counseling
    const { categoryStore, authStore, counselingStore } = useStores()
    const masterCategory = categoryStore.getCategory(authStore.profile?.master_category as string)

    const [title, setTitle] = useState(counseling ? (counseling.title as string) : "")
    const [days, setDays] = useState<string[]>(counseling ? counseling!.days! : [])
    const [times, setTimes] = useState<TimeRange[]>(
      counseling
        ? counseling!
            .times!.map((time) => ({
              start: moment(new Date(JSON.parse(time).start)),
              end: moment(new Date(JSON.parse(time).end)),
            }))
            .sort((a, b) => {
              return a.start.diff(b.start)
            })
        : [],
    )
    const [period, setPeriod] = useState(counseling ? counseling.period : 15)
    const [price, setPrice] = useState(counseling ? counseling.price : 3000)
    const [description, setDescription] = useState(
      counseling ? (counseling.description as string) : "",
    )
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)

    const submitCounseling = async () => {
      if (title.trim().length === 0) {
        setError("상담 제목을 입력해 주세요.")
        return
      }
      if (days.length === 0 || times.length === 0) {
        setError("상담 가능한 날짜와 시간을 입력해 주세요.")
        return
      }
      if (description.trim().length === 0) {
        setError("상세 소개를 입려해 주세요.")
        return
      }
      setError("")
      setLoading(true)
      const payload = {
        title: title,
        days: days.map((day) => day.toString()),
        times: times
          .sort((a, b) => {
            return a.start.diff(b.start)
          })
          .map((time) => JSON.stringify(time)),
        period: period,
        price: price,
        description: description,
      }
      const result = counseling
        ? await counselingStore.updateCounseling(authStore.token, counseling.counseling_id, payload)
        : await counselingStore.createCounseling(authStore.token, payload)
      if (result) {
        Toast.show({
          type: "success",
          text1: "상담 추가 성공.",
          position: "bottom",
        })
        goBack()
      } else {
        Toast.show({
          type: "error",
          text1: "상담 추가 실패.",
          position: "bottom",
        })
      }
      setLoading(false)
    }

    const handleRemoveCounseling = async () => {
      setUpdating(true)
      await counselingStore.deleteCounseling(authStore.token, counseling?.counseling_id as string)
      goBack()
      setUpdating(false)
    }

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen preset="scroll">
          <Header
            leftIcon="back"
            onLeftPress={goBack}
            headerText={counseling ? "상담 수정하기" : "상담 추가하기"}
            removeCounseling={counseling?.counseling_id !== undefined}
            onPressRemoveCounseling={handleRemoveCounseling}
            updating={updating}
          />
          <View style={{ height: 1, backgroundColor: color.border }} />
          <View style={{ padding: spacing[4] }}>
            <Text text={"상담분야"} preset="header1" />
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "flex-start",
                marginVertical: spacing[4],
              }}
            >
              {masterCategory?.thumb ? (
                <View
                  style={{
                    padding: spacing[2],
                    borderRadius: spacing[4],
                    borderWidth: 1,
                    borderColor: color.dim,
                  }}
                >
                  <Image source={{ uri: masterCategory?.thumb as string }} style={CATEGORY_IMAGE} />
                </View>
              ) : (
                <View>
                  <Image source={placeHolder} style={CATEGORY_IMAGE} />
                </View>
              )}
              <Text
                text={masterCategory?.name as string}
                preset="bold"
                style={{ marginVertical: spacing[2] }}
              />
            </View>
            <Text text={"상담제목"} preset="header1" />
            <TextField
              value={title}
              onChangeText={(text) => {
                setTitle(text)
              }}
              placeholder={"노출될 상담 제목을 적어주세요"}
              style={{ padding: spacing[2], marginVertical: spacing[2] }}
              inputStyle={{ padding: spacing[0], borderRadius: spacing[2] }}
            />
            <Text
              text={"상담 가능한 날짜 / 시간"}
              preset="header1"
              style={{ marginVertical: spacing[4] }}
            />
            <View style={{ flexDirection: "row", flexWrap: "wrap", marginVertical: spacing[4] }}>
              {days.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      flex: 1,
                      alignItems: "center",
                      minWidth: (SCREEN_WIDTH - spacing[4] * 2) / 4,
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 1,
                        borderRadius: spacing[2],
                        marginVertical: spacing[2],
                        paddingHorizontal: spacing[2],
                        paddingVertical: spacing[4],
                        borderColor: color.dim,
                        backgroundColor: color.background,
                      }}
                    >
                      <Text text={item} preset={"center"} style={{ color: color.text }} />
                    </View>
                  </View>
                )
              })}
            </View>
            {times.map((time, index) => {
              return (
                <View
                  key={index}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginVertical: spacing[1],
                    marginLeft: spacing[4],
                  }}
                >
                  <Text
                    text={time.start.format("hh:mm a")}
                    style={{ borderWidth: 1, borderColor: color.text, padding: spacing[1] }}
                  />
                  <Text text={"~"} style={{ marginHorizontal: spacing[4] }} />
                  <Text
                    text={time.end.format("hh:mm a")}
                    style={{ borderWidth: 1, borderColor: color.text, padding: spacing[1] }}
                  />
                </View>
              )
            })}
            <CounselingDateTimePicker
              setDays={setDays}
              setTimes={setTimes}
              days={days}
              times={times}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: spacing[4],
              }}
            >
              <View style={{ flex: 1, marginRight: spacing[4] }}>
                <Text text={"상담 시간"} preset="header1" />
                <Picker
                  selectedValue={period}
                  onValueChange={(itemValue, itemIndex) => setPeriod(itemValue)}
                  mode={"dropdown"}
                >
                  {PeroidOptions()}
                </Picker>
              </View>
              <View style={{ flex: 1, marginLeft: spacing[4] }}>
                <Text text={"상담 가격"} preset="header1" />
                <Picker
                  selectedValue={price}
                  onValueChange={(itemValue, itemIndex) => setPrice(itemValue)}
                  mode={"dropdown"}
                >
                  {PriceOptions()}
                </Picker>
              </View>
            </View>
            <Text text={"상세 소개"} preset="header1" style={{ marginVertical: spacing[4] }} />
            <TextInput
              style={{
                backgroundColor: color.background,
                borderWidth: 1,
                borderColor: color.border,
                borderRadius: 4,
                padding: spacing[2],
                color: color.text,
                marginVertical: spacing[4],
                textAlignVertical: "top",
                height: 420,
              }}
              underlineColorAndroid="transparent"
              numberOfLines={18}
              multiline={true}
              placeholder={"상담에 대한 상세 소개를 적어주세요"}
              placeholderTextColor={color.dim}
              defaultValue={description}
              onChangeText={(text) => setDescription(text)}
            />
            {error.length !== 0 && <Text text={error} preset={"warningCenter"} />}
            <Button
              text={"완료"}
              onPress={submitCounseling}
              style={{ marginVertical: spacing[4] }}
              disabled={loading}
            />
          </View>
        </Screen>
      </View>
    )
  })
