/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
  View,
  TextInput,
  Image,
  ScrollView,
  TouchableOpacity,
  FlatList,
  ViewStyle,
} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Button, Text, ProfileAvatar, TextField } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { Modal } from "react-native-paper"

const SELECTED_INTEREST_STYLE: ViewStyle = {
  flex: 1,
  alignItems: "center",
  borderWidth: 1,
  borderColor: color.primary,
  borderRadius: spacing[1],
  marginHorizontal: spacing[3],
  marginVertical: spacing[1],
  padding: spacing[1],
}
const UNSELECTED_INTEREST_STYLE: ViewStyle = {
  flex: 1,
  alignItems: "center",
  borderWidth: 1,
  borderColor: color.border,
  borderRadius: spacing[1],
  marginHorizontal: spacing[3],
  marginVertical: spacing[1],
  padding: spacing[1],
}

const placeHolder = require("../../../assets/images/place-holder.png")

export const EditProfileScreen: FC<StackScreenProps<NavigatorParamList, "editProfile">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()
    const { authStore, categoryStore } = useStores()
    const [updating, setUpdating] = useState(false)
    const [avatar, setAvatar] = useState(authStore.profile?.avatar)
    const [avatarRender, setAvatarRender] = useState(authStore.profile?.avatar_render)
    const [intro, setIntro] = useState(authStore.profile?.intro)
    const [nickName, setNickName] = useState(authStore.profile?.nick_name)
    const [interests, setInterests] = useState<string[]>(
      authStore.profile?.interests ? authStore.profile?.interests : [],
    )
    const [visibleEditInterest, setVisibleEditInterest] = useState(false)

    const toggleSelectedInterests = (categoryId: string): void => {
      if (interests?.includes(categoryId)) {
        setInterests(interests.filter((item) => item !== categoryId))
      } else {
        if (interests && interests?.length < 3) {
          setInterests([...interests, categoryId])
        }
      }
    }

    const handleCancel = () => {
      goBack()
    }

    const handleEditInterests = () => {
      setVisibleEditInterest(true)
    }

    const handleEdit = async () => {
      setUpdating(true)
      await authStore.updateProfile({
        avatar: avatar,
        avatar_render: avatarRender,
        nick_name: nickName,
        intro: intro,
        interests: interests,
      })
      setUpdating(false)
      goBack()
    }

    useEffect(() => {
      authStore.setAvatarFromRPM("")
      authStore.setAvatarRenderFromRPM("")
      const unsubscribe = navigation.addListener("focus", () => {
        authStore.avatarFromRPM === ""
          ? setAvatar(authStore.profile?.avatar)
          : setAvatar(authStore.avatarFromRPM)
        authStore.avatarRenderFromRPM === ""
          ? setAvatarRender(authStore.profile?.avatar_render)
          : setAvatarRender(authStore.avatarRenderFromRPM)
      })
      const blurHandler = navigation.addListener("blur", () => {
        authStore.setAvatarFromRPM("")
        authStore.setAvatarRenderFromRPM("")
      })
      return () => {
        unsubscribe()
        blurHandler()
      }
    }, [navigation])

    return (
      <View style={{ flex: 1 }}>
        <Screen>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              margin: spacing[4],
            }}
          >
            <Button preset="link" onPress={handleCancel} text={"취소"} />
            <Text text={"프로필 수정"} preset={"bold"} />
            <Button
              preset="link"
              onPress={handleEdit}
              text={"완료"}
              textStyle={{ color: color.primary }}
              loading={updating}
            />
          </View>
          <View style={{ height: 1, backgroundColor: color.border }} />
          <ScrollView style={{ flex: 1 }} contentContainerStyle={{ padding: spacing[4] }}>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <ProfileAvatar
                avatar={avatarRender || ""}
                size={64}
                isMaster={authStore.profile?.is_master as boolean}
              />
              <Button
                style={{ marginTop: spacing[2] }}
                text={"수정"}
                textStyle={{ color: color.primary }}
                preset={"link"}
                onPress={() => navigation.navigate("avatarCreate")}
              />
            </View>
            <View>
              <View style={{ flexDirection: "row", alignItems: "center", marginTop: spacing[4] }}>
                <Text text={"이름   "} />
                <TextField
                  keyboardType="default"
                  placeholderTx="common.needNickName"
                  style={{ padding: spacing[2], marginLeft: spacing[4], flex: 1 }}
                  inputStyle={{ padding: spacing[0] }}
                  value={nickName || ""}
                  onChangeText={(text) => setNickName(text)}
                  maxLength={9}
                />
              </View>
              <View style={{ flexDirection: "row", marginTop: spacing[4] }}>
                <Text text={"소개   "} />
                <TextInput
                  style={{
                    backgroundColor: color.background,
                    borderWidth: 1,
                    borderColor: color.border,
                    borderRadius: 4,
                    padding: spacing[2],
                    height: 180,
                    textAlignVertical: "top",
                    color: color.text,
                    marginLeft: spacing[4],
                    flex: 1,
                  }}
                  underlineColorAndroid="transparent"
                  numberOfLines={18}
                  maxLength={1024}
                  multiline={true}
                  placeholder={
                    '첫 문장은 다른 사용자에게\n "한줄 소개"로 노출 돼요:)\n\n("/" 로 첫 문장을 구분해 주세요!)'
                  }
                  placeholderTextColor={color.palette.lighterGrey}
                  defaultValue={intro || ""}
                  onChangeText={(text) => setIntro(text)}
                />
              </View>
              <View style={{ flexDirection: "row", marginTop: spacing[4] }}>
                <Text text={"관심사"} />
                <View>
                  <Button
                    style={{ marginLeft: spacing[4] }}
                    text={interests.length === 0 ? "추가" : "수정"}
                    textStyle={{ color: color.primary }}
                    preset={"link"}
                    onPress={handleEditInterests}
                  />
                  <View style={{ flexDirection: "row" }}>
                    {interests?.length !== 0 ? (
                      interests?.map((item) => {
                        const selectedCategory = categoryStore.categories.find(
                          (category) => category.category_id === item,
                        )
                        if (selectedCategory !== undefined) {
                          return (
                            <View
                              key={item}
                              style={{
                                marginTop: spacing[4],
                                marginLeft: spacing[4],
                                alignItems: "center",
                              }}
                            >
                              {selectedCategory && selectedCategory.thumb ? (
                                <Image
                                  source={{ uri: selectedCategory.thumb }}
                                  style={{ width: 24, height: 24 }}
                                />
                              ) : (
                                <Image source={placeHolder} style={{ width: 24, height: 24 }} />
                              )}
                              <Text
                                text={selectedCategory?.name || ""}
                                style={{ marginVertical: spacing[2] }}
                                preset="small"
                              />
                            </View>
                          )
                        } else {
                          return <View key={item} />
                        }
                      })
                    ) : (
                      <></>
                    )}
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </Screen>
        <Modal
          visible={visibleEditInterest}
          onDismiss={() => {
            setVisibleEditInterest(visibleEditInterest)
          }}
          contentContainerStyle={{
            backgroundColor: color.background,
            padding: spacing[4],
            margin: spacing[4],
          }}
        >
          <Text preset="center">
            <Text tx={"spaceScreen.spaceTab"} />
            <Text text={"(" + interests?.length.toString() + "/3)"} />
          </Text>
          <FlatList
            numColumns={4}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{ marginVertical: spacing[4] }}
            data={categoryStore.categories}
            keyExtractor={(item) => item.category_id}
            renderItem={({ item }) => (
              <TouchableOpacity
                key={item.category_id}
                onPress={() => toggleSelectedInterests(item.category_id)}
                style={
                  interests?.includes(item.category_id)
                    ? SELECTED_INTEREST_STYLE
                    : UNSELECTED_INTEREST_STYLE
                }
              >
                {item.thumb ? (
                  <Image source={{ uri: item.thumb }} style={{ width: 24, height: 24 }} />
                ) : (
                  <Image source={placeHolder} style={{ width: 24, height: 24 }} />
                )}
                <Text
                  text={item.name || ""}
                  preset="smallCenter"
                  style={{ marginTop: spacing[2] }}
                />
              </TouchableOpacity>
            )}
          />
          <View style={{ alignItems: "center" }}>
            <Button
              onPress={() => {
                setVisibleEditInterest(false)
              }}
              tx="common.ok"
            />
          </View>
        </Modal>
      </View>
    )
  },
)
