// eslint-disable-next-line no-use-before-define
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, ScrollView, TouchableOpacity, RefreshControl, Image } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import {
  Screen,
  Text,
  PageHeader,
  Button,
  HomeFooter,
  MastersMore,
  SpacesMore,
  HomeFooterBanner,
} from "../../components"
import { color, spacing } from "../../theme"
import { SCREEN_WIDTH } from "../../utils/screen"
import { useStores } from "../../models"
import { Space } from "../../models/space/space"
import { User } from "../../models/user/user"
import { compareCount } from "../../utils/sort"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { getMonthString } from "../../utils/date_time"
import Carousel from "react-native-reanimated-carousel"

let isMounted = false

export const HomeScreen: FC<StackScreenProps<NavigatorParamList, "home">> = observer(
  ({ navigation }) => {
    const [refreshing, setRefreshing] = useState(false)
    const {
      authStore,
      announcementStore,
      categoryStore,
      spaceStore,
      userStore,
      spaceTypeStore,
      tagStore,
    } = useStores()
    const [spaces, setSpaces] = useState<Space[]>([])
    const [users, setUsers] = useState<User[]>([])
    const [weeklyMasters, setWeeklyMasters] = useState<User[]>([])
    const [monthlyMasters, setMonthlyMasters] = useState<User[]>([])

    const fetchData = async () => {
      if (isMounted) {
        setRefreshing(true)
        await Promise.all([
          announcementStore.fetchAnnouncements(),
          categoryStore.fetchCategories(),
          spaceStore.fetchSpaces(),
          userStore.fetchUsersByInterests(authStore.token, 3, 0),
          userStore.fetchMasters(3, 0, "weekly"),
          userStore.fetchMasters(3, 0, "monthly"),
          spaceTypeStore.fetchSpaceTypes(),
          tagStore.fetchTags(),
        ]).then((results) => {
          setSpaces(results[2].sort((a, b) => compareCount(b, a)))
          setUsers(results[3])
          setWeeklyMasters(results[4])
          setMonthlyMasters(results[5])
        })
        setRefreshing(false)
      }
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      return () => {
        setRefreshing(false)
        setSpaces([])
        setUsers([])
        setWeeklyMasters([])
        setMonthlyMasters([])
        isMounted = false
      }
    }, [])

    return (
      <View style={{ flex: 1 }}>
        <Screen style={{ backgroundColor: color.background }}>
          <PageHeader />
          <ScrollView
            style={{ flex: 1 }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={fetchData} />}
          >
            {announcementStore.announcements.length > 0 && (
              <View style={{ flex: 1 }}>
                <Carousel
                  loop
                  width={SCREEN_WIDTH}
                  height={SCREEN_WIDTH * (3 / 4)}
                  autoPlay={true}
                  data={announcementStore.announcements}
                  scrollAnimationDuration={3000}
                  renderItem={({ item }) => (
                    <View style={{ flex: 1 }}>
                      <TouchableOpacity
                        onPress={() => {
                          navigation.navigate("announcementDetail", {
                            announcement: item,
                          } as any)
                        }}
                      >
                        <Image
                          source={{ uri: item.attached }}
                          style={{
                            width: SCREEN_WIDTH,
                            height: SCREEN_WIDTH * (3 / 4),
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                />
              </View>
            )}
            <View
              style={{
                flexDirection: "row",
                paddingHorizontal: spacing[4],
                marginTop: spacing[4],
                marginBottom: spacing[4],
              }}
            >
              <Button
                text="마스터"
                style={{ flex: 1, marginRight: spacing[2] }}
                onPress={() => {
                  navigation.navigate("homeMasters")
                }}
              />
              <Button
                text="커뮤니티"
                style={{ flex: 1, marginLeft: spacing[2] }}
                onPress={() => {
                  navigation.navigate("homeCommunity")
                }}
              />
            </View>
            <TouchableOpacity
              style={{ margin: spacing[4] }}
              onPress={() => {
                navigation.navigate("homeSearch")
              }}
            >
              <View
                style={{
                  borderRadius: spacing[2],
                  borderWidth: 1,
                  borderColor: color.dim,
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  paddingHorizontal: spacing[2],
                  paddingVertical: spacing[1],
                }}
              >
                <MaterialIcons name="search" size={24} color={color.dim} />
                <Text text={"스페이스, 사용자를 검색하세요!"} style={{ marginLeft: spacing[2] }} />
              </View>
            </TouchableOpacity>
            <MastersMore
              users={weeklyMasters.slice(0, 3)}
              onPressAll={() => navigation.navigate("homeMastersAll", { type: "weekly" })}
              moreTitle={"현재 가장 인기 있는 마스터는?"}
            />
            <SpacesMore
              spaces={spaces.slice(0, 3)}
              moreTitle={"브랜드관"}
              onPressAll={() => navigation.navigate("homeCommunityAll", { type: "brand" })}
              onRefreshSpaces={async () => {
                setRefreshing(true)
                await Promise.all([spaceStore.fetchSpaces()]).then((results) => {
                  setSpaces(results[0].sort((a, b) => compareCount(b, a)))
                })
                setRefreshing(false)
              }}
            />
            <SpacesMore
              spaces={spaces.slice(0, 3)}
              moreTitle={`${getMonthString()}의 인기 있는 스페이스를 모았어요!`}
              onPressAll={() => navigation.navigate("homeCommunityAll", { type: "monthly" })}
              onRefreshSpaces={async () => {
                setRefreshing(true)
                await Promise.all([spaceStore.fetchSpaces()]).then((results) => {
                  setSpaces(results[0].sort((a, b) => compareCount(b, a)))
                })
                setRefreshing(false)
              }}
            />
            <MastersMore
              users={monthlyMasters.slice(0, 3)}
              onPressAll={() => navigation.navigate("homeMastersAll", { type: "monthly" })}
              moreTitle={`${getMonthString()}의 후기 좋은 마스터`}
            />
            {authStore.isAuthenticated && (
              <MastersMore
                users={users.slice(0, 3)}
                onPressAll={() => navigation.navigate("homeUsersByCategory")}
                moreTitle={`${authStore.profile?.nick_name} 님께 추천하는 친구`}
              />
            )}
            <HomeFooterBanner
              onPressBanner={() => {
                navigation.navigate("homeRegistrationHelp")
              }}
            />
            <HomeFooter
              onTermsPress={() => {
                navigation.navigate("termsAndPolicy", { isTerms: true })
              }}
              onPrivacyPress={() => {
                navigation.navigate("termsAndPolicy", { isTerms: false })
              }}
            />
          </ScrollView>
        </Screen>
        <TouchableOpacity
          style={{
            position: "absolute",
            bottom: spacing[4],
            right: spacing[4],
            backgroundColor: color.primary,
            borderRadius: spacing[5],
          }}
          onPress={() => {
            if (authStore.isAuthenticated) {
              navigation.navigate("createSpace")
            } else {
              if (confirm("해당 기능 사용을 위해 로그인이 필요합니다.")) {
                navigation.navigate("auth-stack")
              }
            }
          }}
        >
          <MaterialIcons name="add-circle" size={48} color={color.background} />
        </TouchableOpacity>
      </View>
    )
  },
)
