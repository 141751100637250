/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
  ViewStyle,
  TextStyle,
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  ImageStyle,
  ImageBackground,
  FlatList,
  RefreshControl,
  ActivityIndicator,
} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { FlatGrid } from "react-native-super-grid"
import { Switch, Modal } from "react-native-paper"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { Screen, Header, Text, Button, TextField } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import Toast from "react-native-toast-message"
import { Api } from "../../services/api"
import { ApiResponse } from "apisauce"
import { Tag } from "../../models/tag/tag"
import { SCREEN_WIDTH } from "../../utils/screen"

const FULL: ViewStyle = {
  flex: 1,
}
const CONTAINER: ViewStyle = {
  backgroundColor: color.background,
}
const HEADER: TextStyle = {
  paddingHorizontal: spacing[4],
}
const CATEGORY_IMAGE: ImageStyle = {
  width: 32,
  height: 32,
  marginBottom: spacing[1],
}
const CATEGORY_UNCHECKED_TEXT_STYLE: TextStyle = {
  fontWeight: "normal",
  fontSize: 12,
  color: color.text,
}
const CATEGORY_CHECKED_TEXT_STYLE: TextStyle = {
  fontWeight: "bold",
  fontSize: 16,
  color: color.primary,
}
const SPACES_MORE: ViewStyle = {
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginVertical: spacing[2],
}
const TYPES_GRID: ViewStyle = {
  height: 220,
  alignSelf: "center",
}
const TYPES_GRID_ITEM: ViewStyle = {
  backgroundColor: color.background,
}
const TYPES_IMAGE: ImageStyle = {
  width: 120,
  height: 90,
  alignItems: "center",
  justifyContent: "center",
}
const TYPE_TEXT: TextStyle = {
  backgroundColor: color.primary,
  padding: spacing[2],
  color: color.background,
}
const SPACE_CREATE_BUTTON_ENABLE: ViewStyle = {
  margin: spacing[4],
  backgroundColor: color.primary,
  marginBottom: spacing[6],
}
const SPACE_TYPE_UNCHECKED_TEXT_CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  backgroundColor: color.halfTransparency,
}
const SPACE_TYPE_CHECKED_TEXT_CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  backgroundColor: color.redTransparency,
}
const TEXT_FIELD: ViewStyle = {
  marginBottom: spacing[4],
  backgroundColor: color.background,
  borderRadius: spacing[0],
  borderWidth: spacing[0],
  width: "100%",
  padding: spacing[0],
}
const CENTER_INPUT: TextStyle = {
  textAlign: "center",
  padding: spacing[2],
}
const TITLE_MODAL_CONFIRM_BUTTON: ViewStyle = {
  backgroundColor: color.orange,
}
const TAGS_TOP_VIEW: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const FLAT_LIST: ViewStyle = {
  marginTop: spacing[4],
}
const TAGS_CHECKED_TEXT_STYLE: TextStyle = {
  color: color.background,
  backgroundColor: color.primary,
  padding: spacing[2],
  margin: spacing[1],
}
const TAGS_UNCHECKED_TEXT_STYLE: TextStyle = {
  color: color.primary,
  backgroundColor: color.background,
  padding: spacing[2],
  margin: spacing[1],
}
const TAGS_TEXT_CONTAINER: ViewStyle = {
  flex: 1,
}
const placeHolder = require("../../../assets/images/place-holder.png")
let isMounted = false

export const CreateSpaceScreen: FC<StackScreenProps<NavigatorParamList, "createSpace">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()

    const [spaceCategory, setSpaceCategory] = useState("")
    const [spaceTags, setSpaceTags] = useState<string[]>([])
    const [spaceType, setSpaceType] = useState("")
    const [spaceTitle, setSpaceTitle] = useState("심심한 사람 들어와라")
    const [spaceCapacity, setSpaceCapacity] = useState("")
    const [passwordProtected, setPasswordProtected] = useState(false)
    const [password, setPassword] = useState("")
    const [directTag, setDirectTag] = useState("")

    const [isTagsModalVisible, setIsTagsModalVisible] = useState(false)
    const [isTitleEditModalVisible, setIsTitleEditModalVisible] = useState(false)
    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false)
    const [isDirectModalVisible, setIsDirectModalVisible] = useState(false)

    const [refreshing, setRefreshing] = useState(false)
    const { authStore, tagStore, spaceTypeStore, categoryStore, spaceStore } = useStores()

    const [passwordVisible, setPasswordVisible] = useState(false)
    const [creating, setCreating] = useState(false)
    const [editingDirectTag, setEditingDirectTag] = useState("")
    const communityCategories = categoryStore.getCommunityCategories()

    const toggleTagsSelection = (tag: Tag): void => {
      if (tag.type === "direct") {
        if (spaceTags.includes(tag.tag_id)) {
          setSpaceTags(spaceTags.filter((item) => item !== tag.tag_id))
          setIsDirectModalVisible(false)
          setEditingDirectTag("")
        } else {
          if (spaceTags.length < 3) {
            setIsDirectModalVisible(true)
            setEditingDirectTag(tag.tag_id)
          }
        }
      } else {
        if (spaceTags.includes(tag.tag_id)) {
          setSpaceTags(spaceTags.filter((item) => item !== tag.tag_id))
        } else {
          if (spaceTags.length < 3) {
            setSpaceTags([...spaceTags, tag.tag_id])
          }
        }
      }
    }

    const handleConfirmDirectTag = () => {
      setIsDirectModalVisible(false)
      if (directTag.trim().length === 0) {
        setDirectTag("")
        setEditingDirectTag("")
      } else {
        setSpaceTags([...spaceTags, editingDirectTag])
      }
    }

    const handleToggleProtected = () => {
      setPasswordProtected(!passwordProtected)
      setIsPasswordModalVisible(!passwordProtected)
    }

    const handleCreateSpace = async () => {
      if (
        spaceCategory !== "" &&
        spaceTags.length >= 1 &&
        spaceType !== "" &&
        spaceTitle !== "" &&
        spaceCapacity !== ""
      ) {
        setCreating(true)
        let dynamicTagId = ""
        if (directTag.trim().length !== 0 && editingDirectTag) {
          dynamicTagId = await tagStore.insertTag(authStore.token, directTag)
        }

        const result = await spaceStore.createSpace(
          authStore.token,
          spaceTitle,
          spaceCapacity,
          passwordProtected,
          password,
          spaceCategory,
          spaceType,
          spaceTags,
          dynamicTagId,
          editingDirectTag,
        )
        if (result) {
          if (result === "exceed") {
            Toast.show({
              type: "error",
              text1: "최대 인원은 1000명 이하로 설정해야해요!",
              position: "bottom",
            })
            setCreating(false)
          } else {
            Toast.show({
              type: "success",
              text1: "새로 만든 스페이스에 입장합니다.",
              position: "bottom",
            })

            getSpaceUrl(result.space_id as string)
            setCreating(true)
          }
        } else {
          Toast.show({
            type: "error",
            text1: "스페이스 만들기 실패",
            position: "bottom",
          })
          setCreating(false)
        }
      } else {
        if (spaceCategory === "") {
          Toast.show({
            type: "info",
            text1: "스페이스 종류를 선택해주세요",
            position: "bottom",
          })
        } else if (spaceTags.length === 0) {
          Toast.show({
            type: "info",
            text1: "스페이스 태그를 선택해주세요",
            position: "bottom",
          })
        } else if (spaceType === "") {
          Toast.show({
            type: "info",
            text1: "스페이스 스타일을 선택해 주세요",
            position: "bottom",
          })
        } else if (spaceTitle === "") {
          Toast.show({
            type: "info",
            text1: "방제를 입력해 주세요",
            position: "bottom",
          })
        } else if (spaceCapacity === "") {
          Toast.show({
            type: "info",
            text1: "최대 인원수를 입력해 주세요",
            position: "bottom",
          })
        }
      }
    }

    const openLink = async (url) => {
      window.open(url, "_blank").focus()
    }

    const getSpaceUrl = async (space_id) => {
      setCreating(true)
      try {
        const hubsApi = new Api()
        hubsApi.apisauce.setHeader("Authorization", `Bearer ${authStore.token}`)

        const response: ApiResponse<any> = await hubsApi.apisauce.get(`/sotong_spaces/${space_id}`)
        if (response.ok) {
          setCreating(false)
          await openLink(
            encodeURI(
              `${response.data?.url}?default_mobile_material_quality=high&token=${authStore.token}`,
            ),
          )
        } else {
          setCreating(false)
        }
      } catch (error) {
        setCreating(false)
      }
    }

    const fetchData = async () => {
      if (isMounted) {
        setRefreshing(true)
        await Promise.all([
          tagStore.fetchTags(),
          categoryStore.fetchCategories(),
          spaceTypeStore.fetchSpaceTypes(),
        ])
        setRefreshing(false)
      }
    }

    useEffect(() => {
      isMounted = true
      setIsTagsModalVisible(false)
      setIsTitleEditModalVisible(false)
      fetchData()
      return () => {
        setRefreshing(false)
        isMounted = false
      }
    }, [])

    return (
      <View style={FULL}>
        <Screen style={CONTAINER}>
          <Header
            headerTx={"spaceScreen.createSpaceHeader"}
            leftIcon="back"
            onLeftPress={goBack}
            style={HEADER}
          />
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{
              padding: spacing[4],
            }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={fetchData} />}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                backgroundColor: "#F1F1F1",
                borderRadius: spacing[2],
                marginBottom: spacing[2],
              }}
            >
              {communityCategories.length > 0 ? (
                communityCategories.map((item) => {
                  return (
                    <TouchableOpacity
                      key={item.category_id}
                      style={{
                        flex: 1,
                        alignItems: "center",
                        minWidth: (SCREEN_WIDTH - spacing[4] * 2) / 4,
                        marginVertical: spacing[2],
                      }}
                      onPress={() =>
                        spaceCategory === item.category_id
                          ? setSpaceCategory("")
                          : setSpaceCategory(item.category_id)
                      }
                    >
                      {item.thumb ? (
                        <Image source={{ uri: item.thumb || "" }} style={CATEGORY_IMAGE} />
                      ) : (
                        <Image source={placeHolder} style={CATEGORY_IMAGE} />
                      )}
                      <Text
                        text={item.name || ""}
                        preset="smallCenter"
                        style={
                          spaceCategory === item.category_id
                            ? CATEGORY_CHECKED_TEXT_STYLE
                            : CATEGORY_UNCHECKED_TEXT_STYLE
                        }
                      />
                    </TouchableOpacity>
                  )
                })
              ) : (
                <></>
              )}
            </View>
            <View style={SPACES_MORE}>
              <Text tx={"spaceScreen.spaceTitle"} preset="bold" />
              <TouchableOpacity onPress={() => setIsTitleEditModalVisible(true)}>
                <Text text={spaceTitle} />
              </TouchableOpacity>
            </View>

            <View style={SPACES_MORE}>
              <Text tx={"spaceScreen.spaceTab"} preset="bold" />
              <TouchableOpacity
                onPress={() => setIsTagsModalVisible(true)}
                style={{ flexDirection: "row" }}
              >
                {spaceTags.length === 0 ? (
                  <Text tx={"spaceScreen.boring"} />
                ) : (
                  spaceTags.map((item, index) => {
                    return (
                      <View key={index}>
                        <Text>
                          <Text tx={"common.whiteSpace"} />
                          {item === editingDirectTag ? (
                            <Text text={directTag} />
                          ) : (
                            <Text
                              text={tagStore.tags.find((tag) => tag.tag_id === item)?.tag || ""}
                            />
                          )}
                        </Text>
                      </View>
                    )
                  })
                )}
              </TouchableOpacity>
            </View>

            <View style={SPACES_MORE}>
              <Text tx={"spaceScreen.spaceMaxVolumn"} preset="bold" />
              <TextField
                value={spaceCapacity.replace(/^0+/, "")}
                keyboardType={"number-pad"}
                style={{ borderWidth: 0, backgroundColor: color.primary, padding: 0 }}
                inputStyle={{ fontSize: 18, padding: 0, textAlign: "right" }}
                caretHidden={true}
                contextMenuHidden={true}
                maxLength={3}
                onChangeText={(text) => {
                  setSpaceCapacity(text)
                }}
                placeholder={"최대인원"}
              />
            </View>

            <View style={SPACES_MORE}>
              <Text tx={"spaceScreen.spaceSetPassCode"} preset="bold" />
              <Switch value={passwordProtected} onValueChange={handleToggleProtected} />
            </View>

            <View style={SPACES_MORE}>
              <Text tx={"spaceScreen.spaceType"} preset="bold" />
            </View>
            {spaceTypeStore.types && spaceTypeStore.types.length > 0 ? (
              <FlatGrid
                keyboardShouldPersistTaps="handled"
                data={spaceTypeStore.types}
                style={TYPES_GRID}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                itemDimension={90}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    onPress={() =>
                      spaceType === item.type_id ? setSpaceType("") : setSpaceType(item.type_id)
                    }
                  >
                    <View style={TYPES_GRID_ITEM}>
                      <ImageBackground
                        source={{ uri: item.thumb }}
                        style={TYPES_IMAGE}
                        resizeMode="cover"
                      >
                        <View
                          style={
                            spaceType === item.type_id
                              ? SPACE_TYPE_CHECKED_TEXT_CONTAINER
                              : SPACE_TYPE_UNCHECKED_TEXT_CONTAINER
                          }
                        >
                          <Text text={item.name!} style={TYPE_TEXT} preset="smallCenter" />
                        </View>
                      </ImageBackground>
                    </View>
                  </TouchableOpacity>
                )}
              />
            ) : (
              <></>
            )}
            <Button style={SPACE_CREATE_BUTTON_ENABLE} onPress={handleCreateSpace}>
              {creating ? (
                <ActivityIndicator
                  style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
                />
              ) : (
                <Text tx={"categoryScreen.spaceCreate"} style={{ color: color.background }} />
              )}
            </Button>
          </ScrollView>
          <Modal
            visible={isTitleEditModalVisible}
            onDismiss={() => {
              setIsTitleEditModalVisible(isTitleEditModalVisible)
            }}
            contentContainerStyle={{
              backgroundColor: color.background,
              padding: spacing[4],
              margin: spacing[4],
            }}
          >
            <TextField
              value={spaceTitle}
              placeholderTx="spaceScreen.inputSpaceTitle"
              style={TEXT_FIELD}
              inputStyle={CENTER_INPUT}
              onChangeText={(newText: string) => setSpaceTitle(newText)}
            />
            <Button
              tx="common.ok"
              onPress={() => {
                if (spaceTitle === "") setSpaceTitle("심심한 사람 들어와라")
                setIsTitleEditModalVisible(false)
              }}
              style={TITLE_MODAL_CONFIRM_BUTTON}
            />
          </Modal>
          <Modal
            visible={isPasswordModalVisible}
            onDismiss={() => {
              setIsPasswordModalVisible(isPasswordModalVisible)
            }}
            contentContainerStyle={{
              backgroundColor: color.background,
              padding: spacing[4],
              margin: spacing[4],
            }}
          >
            <TextField
              value={password}
              placeholderTx="common.needPassword"
              style={TEXT_FIELD}
              secureTextEntry={!passwordVisible}
              inputStyle={CENTER_INPUT}
              onChangeText={(newText: string) => setPassword(newText)}
            />
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <Button
                tx="common.ok"
                onPress={() => {
                  setPasswordProtected(!(password === ""))
                  setIsPasswordModalVisible(false)
                }}
                style={TITLE_MODAL_CONFIRM_BUTTON}
              />
              <TouchableOpacity
                onPress={() => setPasswordVisible(!passwordVisible)}
                style={{ marginLeft: spacing[5] }}
              >
                <MaterialIcons name="remove-red-eye" size={32} color={color.text} />
              </TouchableOpacity>
            </View>
          </Modal>
          <Modal
            visible={isTagsModalVisible}
            onDismiss={() => {
              setIsTagsModalVisible(isTagsModalVisible)
            }}
            contentContainerStyle={{
              backgroundColor: color.dim,
              padding: spacing[4],
              margin: spacing[4],
            }}
          >
            <View style={TAGS_TOP_VIEW}>
              <Text tx="common.done" preset="bold" style={{ color: color.dim }} />
              <Text preset="center">
                <Text tx={"spaceScreen.spaceTab"} />
                <Text text={"(" + spaceTags.length.toString() + "/3)"} />
              </Text>
              <TouchableOpacity onPress={() => setIsTagsModalVisible(false)}>
                <Text tx="common.done" preset="bold" />
              </TouchableOpacity>
            </View>
            {tagStore.tags && tagStore.tags.length > 0 ? (
              <FlatList
                numColumns={4}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={FLAT_LIST}
                data={tagStore.tags
                  .filter((tag) => tag.type === "static" || tag.type === "direct")
                  .slice()}
                keyExtractor={(item) => item.tag_id}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    key={index}
                    style={TAGS_TEXT_CONTAINER}
                    onPress={() => toggleTagsSelection(item)}
                  >
                    <Text
                      style={
                        spaceTags.includes(item.tag_id)
                          ? TAGS_CHECKED_TEXT_STYLE
                          : TAGS_UNCHECKED_TEXT_STYLE
                      }
                      text={item.tag ? (item.type === "direct" ? "+" + item.tag : item.tag) : ""}
                      preset="center"
                    />
                  </TouchableOpacity>
                )}
              />
            ) : (
              <View></View>
            )}
            <Modal
              visible={isDirectModalVisible}
              onDismiss={() => {
                setIsDirectModalVisible(isDirectModalVisible)
              }}
              contentContainerStyle={{
                backgroundColor: color.background,
                padding: spacing[4],
                margin: spacing[4],
              }}
            >
              <TextField
                value={directTag}
                placeholderTx="spaceScreen.directTag"
                style={TEXT_FIELD}
                inputStyle={CENTER_INPUT}
                onChangeText={(newText: string) => setDirectTag(newText)}
              />
              <Button
                tx="common.ok"
                onPress={handleConfirmDirectTag}
                style={TITLE_MODAL_CONFIRM_BUTTON}
              />
            </Modal>
          </Modal>
        </Screen>
      </View>
    )
  },
)
