/* eslint-disable camelcase */
import { flow, Instance, types } from "mobx-state-tree"
import { WalletApi } from "../../services/api/wallet-api"
import { api } from "app/services/api"

export const WalletStoreModel = types
  .model("WalletStore")
  .props({})
  .actions((self) => ({
    generateWallet: flow(function* (token: string, seed_string: string) {
      const walletApi = new WalletApi(api)
      const result = yield walletApi.generateWallet(token, seed_string)

      if (result.kind === "ok") {
        return result.result
      } else {
        return null
      }
    }),
  }))

type WalletStoreType = Instance<typeof WalletStoreModel>
export interface WalletStore extends WalletStoreType {}
