/* eslint-disable no-use-before-define */
import * as React from 'react'
import { StyleProp, View, ViewStyle, Image, ImageStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import { color, spacing } from '../../theme'
import { Counseling } from '../../models/counseling/counseling'
import { Category } from '../../models/category/category'
import { Text } from '../text/text'
import { Button } from '../button/button'
import { useStores } from '../../models'

const CONTAINER: ViewStyle = {
  backgroundColor: '#F1F1F1',
  borderRadius: spacing[2],
  padding: spacing[2],
  marginVertical: spacing[1],
}
const CATEGORY_IMAGE: ImageStyle = {
  width: 48,
  height: 48,
  marginBottom: spacing[1],
}

export interface CounselingBoxProps {
  style?: StyleProp<ViewStyle>
  counseling: Counseling
  onPress: () => void
  masterCategory?: Category
  myCounseling?: boolean
}

const placeHolder = require('../../../assets/images/place-holder.png')
const ttc = require('../../../assets/images/ttc.png')

export const CounselingBox = observer(function CounselingBox(props: CounselingBoxProps) {
  const { style, counseling, masterCategory, onPress, myCounseling = false } = props
  const styles = Object.assign({}, CONTAINER, style)
  const { settingStore } = useStores()

  return (
    <View style={styles}>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ alignItems: 'center' }}>
          {masterCategory?.thumb ? (
            <Image source={{ uri: masterCategory?.thumb as string }} style={CATEGORY_IMAGE} />
          ) : (
            <Image source={placeHolder} style={CATEGORY_IMAGE} />
          )}
          <View style={{ flex: 1 }}>
            <Text text={masterCategory?.name as string} />
          </View>
        </View>
        <Text text={counseling.title as string} style={{ flex: 1, marginLeft: spacing[4] }} />
      </View>
      {settingStore.hide_coin_ui ? (
        <></>
      ) : (
        <View
          style={{
            flexDirection: 'row',
            marginTop: spacing[0],
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image source={ttc} style={{ width: 18, height: 18 }} />
            <Text
              text={`${counseling.price} / ${counseling.period}분`}
              style={{ marginLeft: spacing[2] }}
            />
          </View>
          {myCounseling ? (
            <Button
              text={'수정'}
              style={{
                borderRadius: spacing[5],
                borderColor: color.primary,
                borderWidth: 1,
                backgroundColor: color.background,
              }}
              textStyle={{ color: color.primary }}
              onPress={onPress}
            />
          ) : (
            <Button
              text={'자세히 보기'}
              style={{ backgroundColor: color.blue, borderRadius: spacing[4] }}
              onPress={onPress}
            />
          )}
        </View>
      )}
    </View>
  )
})
