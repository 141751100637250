/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ScrollView, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { NavigatorParamList } from '../../navigators'
import { Screen, Header, Text, Button } from '../../components'
import { color, spacing } from '../../theme'
import { useStores } from '../../models'

export const QuestionAnswerScreen: FC<StackScreenProps<NavigatorParamList, 'questionAnswer'>> =
  observer(({ navigation }) => {
    const goBack = () => navigation.goBack()
    const { authStore, queryStore } = useStores()
    const [queries, setQueries] = useState([])

    const fetchData = async () => {
      await queryStore.fetchQueries(authStore.token)
        .then(queries => setQueries(queries))
        .catch(() => setQueries([]))
    }

    useEffect(() => {
      fetchData()
    }, [])

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen >
        <Header
          leftIcon="back"
          onLeftPress={goBack}
          headerText={'고객센터'}
        />
        <View style={{ height: 1, backgroundColor: color.border }}/>
        <ScrollView style={{ flex: 1 }}>
        {queries.map((query) => {
          return (
            <View key={query.query_id} style={{ padding: spacing[4], borderBottomWidth: 1, borderBottomColor: color.border }}>
              <Text text={`Q: ${query.question}`}/>
              <Text text={`A: ${query.answer}`} style={{ marginTop: spacing[4] }}/>
            </View>
          )
        })}
        </ScrollView>
        <Button text={'추가 질문 하기'} style={{ marginHorizontal: spacing[4] }} onPress={() => {
          navigation.navigate('serviceCenter')
        }}/>
      </Screen>
      </View>
    )
  })
