/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, FlatList, ActivityIndicator, TouchableOpacity } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import {
  Screen,
  PageHeader,
  SpaceBox,
  Button,
  CategorySlideSelection,
  Text,
} from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { Category } from "../../models/category/category"
import { Space } from "../../models/space/space"

let isMounted = false

export const HomeSpacesByCategoryScreen: FC<
  StackScreenProps<NavigatorParamList, "homeSpacesByCategory">
> = observer(({ route, navigation }) => {
  const { category } = route.params
  const goBack = () => navigation.goBack()
  const { spaceStore } = useStores()
  const [currentCategory, setCurrentCategory] = useState<Category>(category)
  const [spaces, setSpaces] = useState<Space[]>([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [noMore, setNoMore] = useState(false)
  let onEndReachedCalledDuringMomentum = false

  const fetchData = async () => {
    if (isMounted) {
      setLoading(true)
      let result = []
      result = await spaceStore.fetchSpaces(10, page)
      setNoMore(result.length === 0)
      setSpaces(spaces.concat(result))
      setPage(page + 1)
      setLoading(false)
    }
  }

  useEffect(() => {
    isMounted = true
    fetchData()
    return () => {
      setLoading(false)
      isMounted = false
    }
  }, [])

  const handleLoadMore = () => {
    fetchData()
  }

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        <PageHeader />
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: spacing[4],
          }}
        >
          <Button preset="link" onPress={goBack}>
            <MaterialIcons name="arrow-back" size={32} color={color.text} />
          </Button>
          <CategorySlideSelection
            onPressCategory={(category) => {
              setCurrentCategory(category)
            }}
            selectedCategory={currentCategory}
            isForMaster={false}
          />
        </View>
        <View
          style={{
            flex: 1,
            paddingHorizontal: spacing[4],
            paddingVertical: spacing[2],
            marginTop: spacing[2],
          }}
        >
          <FlatList
            columnWrapperStyle={{ justifyContent: "space-between" }}
            keyboardShouldPersistTaps="handled"
            style={{ flex: 1 }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            data={spaces.filter((space) => space.category === currentCategory.category_id)}
            numColumns={2}
            keyExtractor={(item) => item.space_id}
            initialNumToRender={10}
            onMomentumScrollBegin={() => {
              onEndReachedCalledDuringMomentum = false
            }}
            onEndReached={() => {
              if (!onEndReachedCalledDuringMomentum) {
                handleLoadMore()
                onEndReachedCalledDuringMomentum = true
              }
            }}
            onEndReachedThreshold={0.1}
            renderItem={({ item }) => (
              <SpaceBox
                isCenter={true}
                space={item}
                refetchSpaces={async () => {
                  setPage(0)
                  setSpaces([])
                  handleLoadMore()
                }}
              />
            )}
            ListFooterComponent={
              loading && !noMore ? (
                <ActivityIndicator />
              ) : (
                <View
                  style={{
                    marginVertical: spacing[4],
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text tx="categoryScreen.noWantedSpace" />
                  <Text tx="common.whiteSpace" />
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate("createSpace")
                    }}
                  >
                    <Text
                      tx="categoryScreen.spaceCreate"
                      style={{
                        fontWeight: "bold",
                        color: color.primary,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              )
            }
          />
        </View>
      </Screen>
    </View>
  )
})
