/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, TextStyle, TouchableOpacity } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, TextField, Button, Text } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { CountryPicker } from "react-native-country-codes-picker"
import { SCREEN_HEIGHT, SCREEN_WIDTH } from "app/utils/screen"

const TEXT_FIELD: ViewStyle = {
  marginVertical: spacing[4],
  padding: spacing[2],
}
const CENTER_INPUT: TextStyle = {
  textAlign: "center",
  padding: spacing[0],
}

type FormData = {
  phone: string
  password: string
}

export const LoginScreen: FC<StackScreenProps<NavigatorParamList, "login">> = observer(
  ({ navigation }) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<FormData>()
    const { authStore } = useStores()
    const [showCode, setShowCode] = useState(false)
    const [countryCode, setCountryCode] = useState("KR")
    const [countryDialCode, setCountryDialCode] = useState("+82")

    const onLogin = async (data: FormData) => {
      await authStore.login(data.phone, data.password, countryCode)
    }

    useEffect(() => {
      return () => {
        authStore.resetStatus()
      }
    }, [authStore])

    return (
      <View style={{ flex: 1 }}>
        <Screen
          style={{
            flex: 1,
            backgroundColor: color.transparent,
            paddingHorizontal: spacing[6],
            justifyContent: "space-evenly",
          }}
          preset="scroll"
        >
          <View>
            <Text
              preset="headerCenter"
              tx="loginScreen.tomatoOneID"
              style={{ marginBottom: spacing[4] }}
            />
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                onPress={() => {
                  setShowCode(true)
                }}
                style={{
                  marginRight: spacing[4],
                  borderWidth: 1,
                  padding: 6,
                  borderRadius: 5,
                }}
              >
                <Text text={countryDialCode} preset="boldCenter" />
              </TouchableOpacity>
              <View style={{ flex: 1 }}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^\d{10}$|^\d{11}$/,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      keyboardType="numeric"
                      placeholderTx="loginScreen.phone"
                      style={TEXT_FIELD}
                      inputStyle={CENTER_INPUT}
                      maxLength={11}
                    />
                  )}
                  name="phone"
                  defaultValue=""
                />
                {errors.phone && errors.phone.type === "required" && (
                  <Text preset="warning" tx="common.needPhone" />
                )}
                {errors.phone && errors.phone.type === "pattern" && (
                  <Text preset="warning" tx="common.invalidPhone" />
                )}
              </View>
            </View>

            <Controller
              control={control}
              rules={{
                required: true,
                minLength: 6,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  secureTextEntry={true}
                  placeholderTx="loginScreen.password"
                  style={TEXT_FIELD}
                  inputStyle={CENTER_INPUT}
                />
              )}
              name="password"
              defaultValue=""
            />
            {errors.password && errors.password.type === "required" && (
              <Text preset="warning" tx="common.needPassword" />
            )}
            {errors.password && errors.password.type === "minLength" && (
              <Text preset="warning" tx="common.invalidPassword" />
            )}
          </View>

          <View>
            <Button
              tx={authStore.status === "pending" ? "common.loading" : "common.next"}
              onPress={handleSubmit(onLogin)}
              disabled={authStore.status === "pending"}
            />
            <Text preset="center" style={{ marginTop: spacing[4] }}>
              <Text tx="loginScreen.noAccount" />
              <Text
                tx={"loginScreen.login"}
                style={{ color: color.primary }}
                onPress={() => {
                  navigation.navigate("registerPhone")
                }}
              />
            </Text>
          </View>

          <CountryPicker
            lang="en"
            show={showCode}
            pickerButtonOnPress={(item) => {
              setCountryDialCode(item.dial_code)
              setCountryCode(item.code)
              setShowCode(false)
            }}
            style={{
              // Styles for whole modal [View]
              modal: {
                height: (SCREEN_HEIGHT * 4) / 7,
                width: SCREEN_WIDTH,
                alignSelf: "center",
              },
              // Styles for modal backdrop [View]
              backdrop: {},
              // Styles for bottom input line [View]
              line: {},
              // Styles for list of countries [FlatList]
              itemsList: {},
              // Styles for input [TextInput]
              textInput: {},
              // Styles for country button [TouchableOpacity]
              countryButtonStyles: {},
              // Styles for search message [Text]
              searchMessageText: {},
              // Styles for search message container [View]
              countryMessageContainer: {},
              // Flag styles [Text]
              flag: {},
              // Dial code styles [Text]
              dialCode: {},
              // Country name styles [Text]
              countryName: {},
            }}
            onBackdropPress={() => {
              setShowCode(false)
            }}
          />
        </Screen>
      </View>
    )
  },
)
