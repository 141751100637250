/* eslint-disable no-use-before-define */
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import RenderHTML from "react-native-render-html"
import { NavigatorParamList } from "app/navigators"
import { Screen, PageHeader, Header } from "app/components"
import { color, spacing } from "app/theme"

export const GuideScreen: FC<StackScreenProps<NavigatorParamList, "guide">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()
    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen preset="scroll">
          <PageHeader />
          <Header leftIcon="back" onLeftPress={goBack} headerText={"소통 커뮤니티 가이드"} />
          <View style={{ flex: 1 }}>
            <View
              style={{
                flex: 1,
                backgroundColor: color.background,
                paddingHorizontal: spacing[4],
              }}
            >
              <RenderHTML source={{ html: require("./guide-loader.js")() }} />
            </View>
          </View>
        </Screen>
      </View>
    )
  },
)
