/* eslint-disable camelcase */
import { ApiResponse } from 'apisauce'
import { Api } from './api'
import { getGeneralApiProblem } from './api-problem'
import { PostTransactionResult, GetReviewsResult, GetCustomArray } from './api.types'

export class TransactionApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getTransactionHistory(
    token: string,
    limit: number = 6,
    page: number = 0,
  ): Promise<GetCustomArray> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_transactions/${limit}/${page * limit}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', transactions: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async updateTransaction(
    token: string,
    transaction_id: string,
    payload: any,
  ): Promise<PostTransactionResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.patch(
        `/sotong_transactions/${transaction_id}`,
        JSON.stringify({ transaction: payload }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async addTransaction(token: string, payload: any): Promise<PostTransactionResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.post('/sotong_transactions', {
        transaction: payload,
      })
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async getReviews(token: string, account_id: string): Promise<GetReviewsResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_transactions_reviews/get_reviews/${account_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', reviews: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async getMyTransactions(token: string): Promise<GetCustomArray> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/sotong_transactions_my_accepted',
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', transactions: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async getMasterHoldTransactions(
    token: string,
    limit: number = 6,
    page: number = 0,
  ): Promise<GetCustomArray> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_transactions_master_hold/${limit}/${page * limit}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', transactions: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async getMasterCompleteTransactions(
    token: string,
    limit: number = 6,
    page: number = 0,
  ): Promise<GetCustomArray> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_transactions_master_complete/${limit}/${page * limit}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', transactions: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
