import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const SpaceTypeModel = types
  .model('SpaceType')
  .props({
    type_id: types.identifier,
    name: types.optional(types.string, ''),
    thumb: types.optional(types.string, '')
  })

type SpaceTypeType = Instance<typeof SpaceTypeModel>
export interface SpaceType extends SpaceTypeType {}
type SpaceTypeSnapshotType = SnapshotOut<typeof SpaceTypeModel>
export interface SpaceTypeSnapshot extends SpaceTypeSnapshotType {}
