// eslint-disable-next-line no-use-before-define
import { ApisauceInstance, create } from "apisauce"
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config"

export class Api {
  apisauce: ApisauceInstance

  config: ApiConfig

  constructor(config = DEFAULT_API_CONFIG) {
    this.config = config
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
  }
}

export const api = new Api()
