import { ApiResponse } from "apisauce"
import { Category } from "../../models/category/category"
import { Api } from "./api"
import { getGeneralApiProblem } from "./api-problem"
import { GetCategoriesResult } from "./api.types"
import { convertCategory } from "./convert"
import { BASE_HOST } from "./api-config"

export class CategoryApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getCategories(): Promise<GetCategoriesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_categories")

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const rawCategories = response.data

      const modifiedSlides = rawCategories.map((rawCategory) => {
        return {
          category_id: rawCategory.category_id,
          name: rawCategory.name,
          type: rawCategory.type,
          thumb:
            rawCategory.thumb_owned_file && rawCategory.thumb_owned_file.owned_file_uuid
              ? `${BASE_HOST}/files/${rawCategory.thumb_owned_file.owned_file_uuid}`
              : null,
        }
      })

      const categories: Category[] = modifiedSlides.map(convertCategory)
      return { kind: "ok", categories: categories }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
