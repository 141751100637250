import { Socket } from 'phoenix'
import { SOCKET_URL } from '../../services/api/api-config'

export async function connectToReticulum (debug = false, params = null, socketClass = Socket) {
  const socketUrl = SOCKET_URL

  const socketSettings = {}

  if (params) {
    socketSettings.params = params
  }

  // eslint-disable-next-line new-cap
  const socket = new socketClass(`${socketUrl}/socket`, socketSettings)
  socket.connect()
  socket.onError(async () => {
    const endPointPath = new URL(socket.endPoint).pathname
    const newSocketUrl = SOCKET_URL
    const newEndPoint = `${newSocketUrl}${endPointPath}`
    socket.endPoint = newEndPoint
  })

  return socket
}
