/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Dimensions, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { NavigatorParamList } from '../../navigators'
import { TabView, TabBar } from 'react-native-tab-view'
import { Screen, Header } from '../../components'
import { color } from '../../theme'
import { useStores } from '../../models'
import { Transaction } from '../../models/transaction/transaction'
import { MemoTransactoinMasterRoute } from './memo-transactoin-master-route'

export const TransactionHistoryMasterScreen: FC<
  StackScreenProps<NavigatorParamList, 'transactionHistoryMaster'>
> = observer(({ navigation }) => {
  const goBack = () => navigation.goBack()
  const { authStore, transactionStore } = useStores()
  const [index, setIndex] = useState(0)
  const [routes] = useState([
    { key: 'holdTab', title: '대기' },
    { key: 'completeTab', title: '완료' },
  ])

  const [refreshing, setRefreshing] = useState(false)
  const [loading, setLoading] = useState(false)

  // tab transactions
  const [holdTransactions, setHoldTransactions] = useState<Transaction[]>([])
  const [pageHold, setPageHold] = useState(0)
  const [noMoreHold, setNoMoreHold] = useState(false)

  // complete transactions
  const [completeTransactions, setCompleteTransactions] = useState<Transaction[]>([])
  const [pageComplete, setPageComplete] = useState(0)
  const [noMoreComplete, setNoMoreComplete] = useState(false)

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: color.primary }}
      style={{ backgroundColor: color.background }}
      activeColor={color.primary}
      inactiveColor={color.dim}
    />
  )

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'holdTab':
        return (
          <MemoTransactoinMasterRoute
            transactions={holdTransactions}
            fetchTransactions={fetchHoldTransactions}
            refreshing={refreshing}
            loading={loading}
            noMore={noMoreHold}
            hold={true}
          />
        )
      case 'completeTab':
        return (
          <MemoTransactoinMasterRoute
            transactions={completeTransactions}
            fetchTransactions={fetchCompleteTransactions}
            refreshing={refreshing}
            loading={loading}
            noMore={noMoreComplete}
          />
        )
      default:
        return null
    }
  }

  const fetchHoldTransactions = async (refresh = false) => {
    let result = []
    if (refresh) {
      if (!loading) {
        setRefreshing(true)
        result = await transactionStore.fetchMasterHoldTransactions(authStore.token, 6, 0)
        setNoMoreHold(result.length === 0)
        setHoldTransactions(result)
        setPageHold(1)
        setRefreshing(false)
      }
    } else {
      if (!refreshing) {
        setLoading(true)
        result = await transactionStore.fetchMasterHoldTransactions(authStore.token, 6, pageHold)
        setNoMoreHold(result.length === 0)
        setHoldTransactions(holdTransactions.concat(result))
        setPageHold(pageHold + 1)
        setLoading(false)
      }
    }
  }

  const fetchCompleteTransactions = async (refresh = false) => {
    let result = []
    if (refresh) {
      if (!loading) {
        setRefreshing(true)
        result = await transactionStore.fetchMasterCompleteTransactions(authStore.token, 6, 0)
        setNoMoreComplete(result.length === 0)
        setCompleteTransactions(result)
        setPageComplete(1)
        setRefreshing(false)
      }
    } else {
      if (!refreshing) {
        setLoading(true)
        result = await transactionStore.fetchMasterCompleteTransactions(
          authStore.token,
          6,
          pageComplete,
        )
        setNoMoreComplete(result.length === 0)
        setCompleteTransactions(completeTransactions.concat(result))
        setPageComplete(pageComplete + 1)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (index === 0) {
      fetchHoldTransactions()
    } else {
      fetchCompleteTransactions()
    }
    return () => {
      setLoading(false)
      setRefreshing(false)
    }
  }, [])

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        <Header leftIcon="back" onLeftPress={goBack} headerText={'상담내역'} />
        <View style={{ height: 1, backgroundColor: color.border }} />
        <TabView
          swipeEnabled={true}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          renderTabBar={renderTabBar}
          onIndexChange={(index) => {
            setIndex(index)
            if (index === 0) {
              fetchHoldTransactions(true)
            } else {
              fetchCompleteTransactions(true)
            }
          }}
          initialLayout={{ width: Dimensions.get('window').width }}
        />
      </Screen>
    </View>
  )
})
