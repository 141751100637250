/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import { View, TouchableOpacity, Image } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { Notification } from "../../models/notification/notification"
import { ProfileAvatar } from "../profile-avatar/profile-avatar"
import { Button } from "../button/button"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import moment from "moment"
import "moment/locale/ko"

export interface NotificationBoxProps {
  notification: Notification
  onPress: (notification: Notification) => void
  onDelete: (notification: Notification) => void
  onCounselingReject?: (notification: Notification) => void
  onCounselingAccept?: (notification: Notification) => void
  loading: boolean
}

const ttc = require("../../../assets/images/ttc.png")

export const NotificationBox = observer(function NotificationBox(props: NotificationBoxProps) {
  const { notification, onPress, onDelete, onCounselingReject, onCounselingAccept, loading } = props
  const [showDelete, setShowDelete] = useState(false)
  const [visibleCounselingDetail, setVisibleCounselingDetail] = useState(false)

  return (
    <TouchableOpacity
      style={{
        padding: spacing[4],
        paddingVertical: spacing[2],
        backgroundColor: notification.is_read ? color.background : color.border,
        borderColor: color.dim,
        borderBottomWidth: 1,
      }}
      onPress={() => {
        if (notification.type === "notification.book_counseling") {
          setVisibleCounselingDetail(!visibleCounselingDetail)
        } else {
          onPress(notification)
        }
      }}
      onLongPress={() => {
        setShowDelete(!showDelete)
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ProfileAvatar
            avatar={notification.source_avatar as string}
            size={48}
            isNotification={true}
          />
          <Text
            style={{ marginHorizontal: spacing[4], flex: 1 }}
            text={
              notification.type === "notification.invite"
                ? JSON.parse(notification.title as string).title
                : JSON.parse(notification.title as string).body
            }
          />
        </View>
        {showDelete && (
          <Button
            onPress={() => {
              onDelete(notification)
              setShowDelete(false)
            }}
            loading={loading}
            preset={"link"}
          >
            <MaterialIcons name="delete" size={32} color={color.primary} />
          </Button>
        )}
      </View>
      {visibleCounselingDetail && (
        <View style={{ marginTop: spacing[4], alignItems: "center" }}>
          <Text text={"* 24시간 이내에 수락/거절을 결정해 주세요."} preset={"warning"} />
          <Text
            text={JSON.parse(notification.content as string).counseling_title}
            style={{ marginTop: spacing[2] }}
          />
          <View style={{ flexDirection: "row", alignItems: "center", marginTop: spacing[2] }}>
            <Image source={ttc} style={{ width: 18, height: 18 }} />
            <Text
              text={`${JSON.parse(notification.content as string).counseling_price} / ${
                JSON.parse(notification.content as string).counseling_period
              }분`}
              style={{ marginLeft: spacing[2] }}
            />
          </View>
          <Text
            text={`상담희망일자 : ${moment(
              JSON.parse(notification.content as string).transaction_start_time,
            ).format("M월 D일 dddd")}`}
            style={{ marginTop: spacing[4] }}
          />
          <Text
            text={`상담희망시간 : ${moment(
              JSON.parse(notification.content as string).transaction_start_time,
            ).format("a hh:mm")}`}
            style={{ marginTop: spacing[4] }}
          />
          <View style={{ flexDirection: "row", marginTop: spacing[4] }}>
            <Button
              text={"거절하기"}
              style={{
                flex: 1,
                marginRight: spacing[2],
                backgroundColor: color.background,
                borderWidth: 1,
                borderColor: color.dim,
              }}
              textStyle={{ color: color.text }}
              onPress={() => {
                if (onCounselingReject) onCounselingReject(notification)
              }}
              loading={loading}
              disabled={loading || notification.is_read}
            />
            <Button
              text={"수락하기"}
              style={{
                flex: 1,
                marginLeft: spacing[2],
                borderWidth: 1,
                borderColor: color.primary,
              }}
              onPress={() => {
                if (
                  moment(JSON.parse(notification.content as string).transaction_start_time).isAfter(
                    moment(),
                  )
                ) {
                  if (onCounselingAccept) onCounselingAccept(notification)
                } else {
                  alert("상담 시간이 이미 지났습니다.")
                }
              }}
              loading={loading}
              disabled={loading || notification.is_read}
            />
          </View>
        </View>
      )}
    </TouchableOpacity>
  )
})
