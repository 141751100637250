/* eslint-disable no-use-before-define */
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, Image, ScrollView } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, PageHeader } from "../../components"
import { color, spacing } from "../../theme"
import { SCREEN_WIDTH } from "../../utils/screen"
import RenderHTML from "react-native-render-html"

export const AnnouncementDetailScreen: FC<
  StackScreenProps<NavigatorParamList, "announcementDetail">
> = observer(({ route, navigation }) => {
  const { announcement } = route.params
  const goBack = () => navigation.goBack()

  const onShare = async () => {}

  return (
    <View style={{ flex: 1 }}>
      <Screen style={{ backgroundColor: color.background }}>
        <PageHeader />
        <Header
          leftIcon="back"
          onLeftPress={goBack}
          style={{ paddingHorizontal: spacing[4] }}
          headerText={announcement.title + ""}
          shareIcon={true}
          onShareIconPress={onShare}
        />
        <ScrollView
          style={{ flex: 1, marginTop: spacing[2] }}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <Image
            source={{ uri: announcement?.attached || "" }}
            style={{
              height: (SCREEN_WIDTH * 3) / 4,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <RenderHTML contentWidth={SCREEN_WIDTH} source={{ html: announcement?.content || "" }} />
        </ScrollView>
      </Screen>
    </View>
  )
})
