/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { TopicApi } from "../../services/api/topic-api"
import { TopicModel, TopicSnapshot } from "../topic/topic"
import { api } from "app/services/api"

/**
 * Model description here for TypeScript hints.
 */
export const TopicStoreModel = types
  .model("TopicStore")
  .props({
    topics: types.optional(types.array(TopicModel), []),
  })
  .views((self) => ({
    getTitleFromId: (topic_id: string) =>
      self.topics.find((topic) => topic.topic_id === topic_id)?.title,
  }))
  .actions((self) => ({
    saveTopics: (topicSnapshots: TopicSnapshot[]) => {
      self.topics.replace(topicSnapshots)
    },
  }))
  .actions((self) => ({
    fetchTopics: flow(function* (token: string) {
      const topicApi = new TopicApi(api)
      const result = yield topicApi.getTopics(token)

      if (result.kind === "ok") {
        self.saveTopics(result.topics)
      } else {
        if (result.kind === "not-found") {
          self.topics.clear()
        }
      }
    }),
  }))

type TopicStoreType = Instance<typeof TopicStoreModel>
export interface TopicStore extends TopicStoreType {}
type TopicStoreSnapshotType = SnapshotOut<typeof TopicStoreModel>
export interface TopicStoreSnapshot extends TopicStoreSnapshotType {}
export const createTopicStoreDefaultModel = () => types.optional(TopicStoreModel, {})
