/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { SpaceTypeApi } from "../../services/api/space-type-api"
import { SpaceTypeModel, SpaceTypeSnapshot } from "../space-type/space-type"
import { api } from "app/services/api"

export const SpaceTypeStoreModel = types
  .model("SpaceTypeStore")
  .props({
    types: types.optional(types.array(SpaceTypeModel), []),
  })
  .views((self) => ({
    getThumbFromId: (type_id: string) => self.types.find((type) => type.type_id === type_id)?.thumb,
  }))
  .actions((self) => ({
    saveSpaceTypes: (spaceTypeSnapshots: SpaceTypeSnapshot[]) => {
      self.types.replace(spaceTypeSnapshots)
    },
  }))
  .actions((self) => ({
    fetchSpaceTypes: flow(function* () {
      const spaceTypeApi = new SpaceTypeApi(api)
      const result = yield spaceTypeApi.getSpaceTypes()

      if (result.kind === "ok") {
        self.saveSpaceTypes(result.types)
      } else {
        if (result.kind === "not-found") {
          self.types.clear()
        }
      }
    }),
  }))

type SpaceTypeStoreType = Instance<typeof SpaceTypeStoreModel>
export interface SpaceTypeStore extends SpaceTypeStoreType {}
type SpaceTypeStoreSnapshotType = SnapshotOut<typeof SpaceTypeStoreModel>
export interface SpaceTypeStoreSnapshot extends SpaceTypeStoreSnapshotType {}
