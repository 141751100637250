/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { UserApi } from "../../services/api/user-api"
import { withStatus } from "../extensions/with-status"
import { api } from "app/services/api"

export const UserStoreModel = types
  .model("UserStore")
  .props({})
  .extend(withStatus)
  .actions((self) => ({}))
  .actions((self) => ({
    searchUsers: flow(function* (keyword: string, limit: number = 6, page: number = 0) {
      const userApi = new UserApi(api)
      const result = yield userApi.searchUsers(keyword, limit, page)
      if (result.kind === "ok") {
        return result.users
      } else {
        return []
      }
    }),
    fetchMastersByCategory: flow(function* (
      token: string,
      category: string,
      limit: number = 6,
      page: number = 0,
      condition: string = "all",
    ) {
      const userApi = new UserApi(api)
      const result = yield userApi.getMastersByCategory(token, category, limit, page, condition)
      if (result.kind === "ok") {
        return result.users
      } else {
        return []
      }
    }),
    fetchUsersByInterests: flow(function* (token: string, limit: number = 6, page: number = 0) {
      const userApi = new UserApi(api)
      const result = yield userApi.getUsersByInterests(token, limit, page)

      if (result.kind === "ok") {
        return result.users
      } else {
        return []
      }
    }),
    fetchMasters: flow(function* (limit: number = 6, page: number = 0, condition: string = "all") {
      const userApi = new UserApi(api)
      const result = yield userApi.getUsers(limit, page, "master", condition)

      if (result.kind === "ok") {
        return result.users
      } else {
        return []
      }
    }),
  }))

type UserStoreType = Instance<typeof UserStoreModel>
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
