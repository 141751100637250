import { ApiResponse } from "apisauce"
import { AnnouncementSnapshot } from "../../models/announcement/announcement"
import { Api } from "./api"
import { getGeneralApiProblem } from "./api-problem"
import { GetAnnouncementsResult } from "./api.types"
import { convertAnnouncement } from "./convert"
import { BASE_HOST } from "./api-config"

export class AnnouncementApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getAnnouncements(): Promise<GetAnnouncementsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_announcements")
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const rawAnnouncements = response.data

      const modifiedAnnouncements = rawAnnouncements.map((rawAnnouncement) => {
        return {
          announcement_id: rawAnnouncement.announcement_id,
          title: rawAnnouncement.title,
          content: rawAnnouncement.content,
          type: rawAnnouncement.type,
          attached:
            rawAnnouncement.attached_owned_file &&
            rawAnnouncement.attached_owned_file.owned_file_uuid
              ? `${BASE_HOST}/files/${rawAnnouncement.attached_owned_file.owned_file_uuid}`
              : null,
        }
      })

      const announcements: AnnouncementSnapshot[] = modifiedAnnouncements.map(convertAnnouncement)
      return { kind: "ok", announcements: announcements }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
