/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { SpaceApi } from "../../services/api/space-api"
import { compareCount } from "../../utils/sort"
import { api } from "app/services/api"

export const SpaceStoreModel = types
  .model("SpaceStore")
  .props({})
  .actions((self) => ({
    fetchSpaces: flow(function* (limit: number = 6, page: number = 0) {
      const spaceApi = new SpaceApi(api)
      const result = yield spaceApi.getSpaces(limit, page)

      if (result.kind === "ok") {
        return result.spaces
      } else {
        return []
      }
    }),
    searchSpace: flow(function* (keyword: string, limit: number = 6, page: number = 0) {
      const spaceApi = new SpaceApi(api)
      const result = yield spaceApi.searchSpaces(keyword, limit, page)

      if (result.kind === "ok") {
        return result.spaces.sort((a, b) => compareCount(b, a))
      } else {
        return []
      }
    }),
    createSpace: flow(function* (
      token: string,
      title: string,
      capacity: string,
      password_protected: boolean = false,
      password: string = "",
      category: string,
      type: string,
      tags: Array<string>,
      dynamicTag: string = "",
      editingDirectTag: string = "",
    ) {
      const spaceApi = new SpaceApi(api)

      const newTagsArray = tags.slice()

      const index = newTagsArray.indexOf(editingDirectTag)
      if (index !== -1) {
        newTagsArray[index] = dynamicTag
      }

      const result = yield spaceApi.createSpace(
        token,
        title,
        parseInt(capacity) || 6,
        password_protected,
        password,
        category,
        type,
        newTagsArray,
      )
      if (result.kind === "ok") {
        return result.space
      } else {
        if (result.kind === "exceed") {
          return "exceed"
        } else {
          return false
        }
      }
    }),
    fetchSpacesByCategory: flow(function* (
      token: string,
      category: string,
      limit: number = 6,
      page: number = 0,
    ) {
      const spaceApi = new SpaceApi(api)
      const result = yield spaceApi.getSpacesByCategory(token, category, limit, page)

      if (result.kind === "ok") {
        return result.spaces
      } else {
        return []
      }
    }),
    fetchSpacesByTopic: flow(function* (
      token: string,
      topic: string,
      limit: number = 6,
      page: number = 0,
    ) {
      const spaceApi = new SpaceApi(api)
      const result = yield spaceApi.getSpacesByTopic(token, topic, limit, page)

      if (result.kind === "ok") {
        return result.spaces
      } else {
        return []
      }
    }),
    fetchSpaceByHubId: flow(function* (token: string, hub_id: string) {
      const spaceApi = new SpaceApi(api)
      const result = yield spaceApi.getSpaceByHubId(token, hub_id)

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),
  }))

type SpaceStoreType = Instance<typeof SpaceStoreModel>
export interface SpaceStore extends SpaceStoreType {}
type SpaceStoreSnapshotType = SnapshotOut<typeof SpaceStoreModel>
export interface SpaceStoreSnapshot extends SpaceStoreSnapshotType {}
