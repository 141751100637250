export const palette = {
  black: '#000000',
  white: '#ffffff',
  offWhite: '#e6e6e6',
  orange: '#FFA500',
  orangeDarker: '#EB9918',
  grey: '#e8e8e8',
  lightGrey: '#939AA4',
  lighterGrey: '#CDD4DA',
  angry: '#dd3333',
  deepPurple: '#5D2555',
  red: '#cb2026',
  blue: '#4287f5',
  lightPink: '#FFC0C0',
  darkBlue: '#007ab8',
  darkGray: '#e7e7e7',
  green: '#00ff00'
}
