/* eslint-disable no-use-before-define */
import React, { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { View, ScrollView } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { NavigatorParamList } from '../../navigators'
import { Screen, Button, Text } from '../../components'
import { color, spacing } from '../../theme'
import { useStores } from '../../models'
import { Education } from '../../models/education/education'
import { Career } from '../../models/career/career'
import { EducationsInput } from '../../components/educations-input/educations-input'
import { CareersInput } from '../../components/careers-input/careers-input'

export const EditMasterIntroScreen: FC<StackScreenProps<NavigatorParamList, 'editMasterIntro'>> = observer(({ route, navigation }) => {
  const goBack = () => navigation.goBack()
  const { educations, careers } = route.params
  const { authStore } = useStores()
  const [updating, setUpdating] = useState(false)
  const [newEducations, setNewEducations] = useState<Education[]>(educations)
  const [newCareers, setNewCareers] = useState<Career[]>(careers)

  const handleUpdate = async () => {
    setUpdating(true)
    await authStore.updateMasterIntro({ educations: newEducations, careers: newCareers })
    setUpdating(false)
    goBack()
  }

  return (
    <View style={{ flex: 1 }}>
      <Screen>
        <View style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: spacing[4]
        }}>
          <Button preset="link" onPress={() => { goBack() }} text={'취소'} />
          <Text text={'소개 수정'} preset={'bold'}/>
          <Button
            preset="link"
            onPress={handleUpdate}
            text={'완료'}
            textStyle={{ color: color.primary }}
            loading={updating}
          />
        </View>
        <View style={{ height: 1, backgroundColor: color.border }}/>
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ padding: spacing[4] }}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <Text text={'학력'} preset={'header1'}/>
          <EducationsInput educations={newEducations} setEducations={setNewEducations}/>
          <Text text={'경력'} preset={'header1'} style={{ marginTop: spacing[4] }}/>
          <CareersInput careers={newCareers} setCareers={setNewCareers}/>
        </ScrollView>
      </Screen>
    </View>
  )
})
