/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import {
  View,
  ViewStyle,
  ImageStyle,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native"
import { observer } from "mobx-react-lite"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { useStores } from "../../models"
import { Api } from "../../services/api"
import { ApiResponse } from "apisauce"
import { navigationRef } from "app/navigators"

const BOX_CONTAINER: ViewStyle = {
  flex: 1,
}
const IMAGE_BACKGROUND: ImageStyle = {
  flex: 1,
}
const BOX_TEXT_CONTAINER: ViewStyle = {
  width: 150,
}
const BOX_CONTAINER_CENTER: ViewStyle = {
  width: "48%",
  marginBottom: spacing[1],
}
const IMAGE_BACKGROUND_CENTER: ImageStyle = {
  width: "100%",
  height: 90,
}
const BOX_TEXT_CONTAINER_CENTER: ViewStyle = {
  width: "100%",
}
const BOX_COUNT_CONTAINER: ViewStyle = {
  position: "absolute",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: color.border,
  margin: spacing[1],
  padding: spacing[1],
  borderRadius: spacing[1],
}

export interface SpaceBoxProps {
  space?: any
  refetchSpaces?: () => void
  isCenter?: boolean
}

let theURL = ""
let theNewCount = 0
export const SpaceBox = observer(function SpaceBox(props: SpaceBoxProps) {
  const { space, refetchSpaces, isCenter } = props
  const { categoryStore, tagStore, spaceTypeStore, authStore } = useStores()
  const [loading, setLoading] = useState(false)

  const openLink = async (url: string) => {
    window.open(url, "_blank").focus()
  }

  const getSpaceUrl = async () => {
    const hubsApi = new Api()
    hubsApi.apisauce.setHeader("Authorization", `Bearer ${authStore.token}`)

    setLoading(true)
    try {
      const response: ApiResponse<any> = await hubsApi.apisauce.get(
        `/sotong_spaces/${space.space_id}`,
      )
      if (response.ok) {
        setLoading(false)
        theURL = encodeURI(
          `${response.data?.url}?default_mobile_material_quality=high&token=${authStore.token}`,
        )
        theNewCount = response.data?.count

        if (theNewCount < space?.capacity) {
          if (space?.protected) {
            let inputPassword = prompt("비밀번호를 입력하세요.")
            if (theURL && !inputPassword && space.hashed_password === inputPassword) {
              openLink(theURL)
            }
          } else {
            await openLink(theURL)
          }
        } else {
          alert("입장 인원이 초과되었습니다 :(")
        }
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <TouchableOpacity
      style={isCenter ? BOX_CONTAINER_CENTER : BOX_CONTAINER}
      onPress={() => {
        if (authStore.isAuthenticated) {
          getSpaceUrl()
        } else {
          if (confirm("해당 기능 사용을 위해 로그인이 필요합니다.")) {
            navigationRef.navigate("auth-stack")
          }
        }
      }}
    >
      <Image
        source={{ uri: spaceTypeStore.getThumbFromId(space?.type) }}
        style={isCenter ? IMAGE_BACKGROUND_CENTER : IMAGE_BACKGROUND}
        resizeMode="cover"
      ></Image>
      <View style={BOX_COUNT_CONTAINER}>
        <MaterialIcons name="person-outline" size={15} color={color.text} />
        <Text text={" " + space?.count + " / " + space?.capacity} preset="small" />
      </View>
      <View style={isCenter ? BOX_TEXT_CONTAINER_CENTER : BOX_TEXT_CONTAINER}>
        <View style={{ backgroundColor: color.pinkBackground, padding: spacing[1] }}>
          <Text
            text={categoryStore.getCategoryNameFromId(space?.category) + " : " + space?.title}
            preset="smallCenter"
            numberOfLines={1}
            ellipsizeMode={"tail"}
          />
        </View>
        <View style={{ flexDirection: "row", justifyContent: "center", padding: spacing[1] }}>
          {space?.tags.map((tag, index) => {
            return (
              <View key={index}>
                <Text
                  text={tagStore.getTagNameFromId(tag) ? " #" + tagStore.getTagNameFromId(tag) : ""}
                  preset="small"
                  numberOfLines={1}
                />
              </View>
            )
          })}
        </View>
      </View>
      {loading && <ActivityIndicator />}
    </TouchableOpacity>
  )
})
