/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, TextStyle, ImageStyle, View, Image } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Text, Button, TextField } from "../../components"
import { color, spacing } from "../../theme"
import { Controller, useForm } from "react-hook-form"
import { useStores } from "../../models"
import { Modal } from "react-native-paper"

const appLogo = require("../../../assets/images/logo-small.png")

const FULL: ViewStyle = {
  flex: 1,
}
const CONTAINER: ViewStyle = {
  backgroundColor: color.transparent,
  paddingHorizontal: spacing[6],
  paddingTop: spacing[9],
}
const TEXT_FIELD: ViewStyle = {
  marginVertical: spacing[4],
  padding: spacing[2],
}
const CENTER_INPUT: TextStyle = {
  textAlign: "center",
  padding: spacing[0],
}
const LOGIN_BUTTON: ViewStyle = {
  marginTop: spacing[8],
}
const TEXT_WRAP: ViewStyle = {
  marginVertical: spacing[8],
}
const FOOTER: ViewStyle = {
  backgroundColor: color.background,
  paddingVertical: spacing[6],
}
const APP_LOGO: ImageStyle = {
  alignSelf: "center",
  width: 64,
  height: 64,
  marginBottom: spacing[6],
}
const MODAL_BUTTON: ViewStyle = {
  flex: 1,
  backgroundColor: color.orange,
  margin: spacing[4],
}
const MODAL_BUTTON_CONTAINER: ViewStyle = {
  flexDirection: "row",
}

type FormData = {
  nickName: string
}

export const NickNameScreen: FC<StackScreenProps<NavigatorParamList, "nickName">> = observer(
  ({ navigation }) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<FormData>()
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)
    const [duplicationModalVisible, setDuplicationModalVisible] = useState(false)
    const [userNickName, setUserNickName] = useState("")

    const { authStore } = useStores()

    const onCheckNickName = (data: FormData) => {
      setUserNickName(data.nickName)
      setConfirmModalVisible(true)
      // setDuplicationModalVisible(true)
    }

    const onOkConfirmNickName = async () => {
      await authStore.updateProfile({ nick_name: userNickName })
    }

    useEffect(() => {
      return () => {
        authStore.resetStatus()
      }
    }, [authStore])

    return (
      <>
        <View style={FULL}>
          <Screen style={CONTAINER} preset="scroll">
            <Image source={appLogo} style={APP_LOGO} />
            <Text tx={"nickNameScreen.setNickName"} preset="center" />
            <Text style={TEXT_WRAP} preset="center">
              <Text text={authStore.profile?.one_id || ""} preset="boldCenter" />
              <Text tx={"nickNameScreen.welcome"} preset="boldCenter" />
            </Text>
            <Controller
              control={control}
              rules={{ required: true, maxLength: 9 }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  placeholderTx="common.nickName"
                  style={TEXT_FIELD}
                  inputStyle={CENTER_INPUT}
                />
              )}
              name="nickName"
            />
            {errors.nickName && errors.nickName.type === "required" && (
              <Text preset="warning" tx="common.needNickName" style={{ textAlign: "center" }} />
            )}
            {errors.nickName && errors.nickName.type === "maxLength" && (
              <Text
                preset="warning"
                text={"닉네임은 최대 9자 입니다"}
                style={{ textAlign: "center" }}
              />
            )}
            <View style={FOOTER}>
              <Button
                style={LOGIN_BUTTON}
                tx={"common.start"}
                onPress={handleSubmit(onCheckNickName)}
              />
            </View>
          </Screen>
        </View>
        <Modal
          visible={confirmModalVisible}
          onDismiss={() => {
            setConfirmModalVisible(confirmModalVisible)
          }}
          contentContainerStyle={{
            backgroundColor: color.background,
            padding: spacing[4],
            margin: spacing[4],
          }}
        >
          <Text preset="center">
            <Text text={userNickName} preset="center" />
            <Text tx={"nickNameScreen.askConfirnNickName"} preset="center" />
          </Text>
          <View style={MODAL_BUTTON_CONTAINER}>
            <Button
              tx={authStore.status === "pending" ? "common.loading" : "common.ok"}
              onPress={onOkConfirmNickName}
              style={MODAL_BUTTON}
              disabled={authStore.status === "pending"}
            />
            <Button
              tx={"common.cancel"}
              onPress={() => setConfirmModalVisible(false)}
              style={MODAL_BUTTON}
              disabled={authStore.status === "pending"}
            />
          </View>
        </Modal>
        <Modal
          visible={duplicationModalVisible}
          onDismiss={() => {
            setDuplicationModalVisible(duplicationModalVisible)
          }}
          contentContainerStyle={{
            backgroundColor: color.background,
            padding: spacing[4],
            margin: spacing[4],
          }}
        >
          <Text preset="center">
            <Text tx={"nickNameScreen.nickNameDuplicated"} preset="center" />
          </Text>
          <View style={MODAL_BUTTON_CONTAINER}>
            <Button
              tx={"common.ok"}
              onPress={() => setDuplicationModalVisible(false)}
              style={MODAL_BUTTON}
            />
          </View>
        </Modal>
      </>
    )
  },
)
