import { SpaceSnapshot } from '../../models/space/space'
import { AnnouncementSnapshot } from '../../models/announcement/announcement'
import { CategorySnapshot } from '../../models/category/category'
import { FaqSnapshot } from '../../models/faq/faq'
import { TagSnapshot } from '../../models/tag/tag'
import { SpaceTypeSnapshot } from '../../models/space-type/space-type'
import { UserSnapshot } from '../../models/user/user'
import { SlideSnapshot } from '../../models/slide/slide'
import { KeywordSnapshot } from '../../models/keyword/keyword'
import { NotificationSnapshot } from '../../models/notification/notification'
import { TopicSnapshot } from '../../models/topic/topic'
import { CounselingSnapshot } from '../../models/counseling/counseling'

export const convertAnnouncement = (raw: any): AnnouncementSnapshot => {
  return {
    announcement_id: raw.announcement_id,
    title: raw.title,
    content: raw.content,
    type: raw.type,
    attached: raw.attached,
  }
}

export const convertCategory = (raw: any): CategorySnapshot => {
  return {
    category_id: raw.category_id,
    name: raw.name,
    type: raw.type,
    thumb: raw.thumb,
  }
}

export const convertFaq = (raw: any): FaqSnapshot => {
  return {
    faq_id: raw.faq_id,
    question: raw.question,
    answer: raw.answer,
  }
}

export const convertTopic = (raw: any): TopicSnapshot => {
  return {
    topic_id: raw.topic_id,
    title: raw.title,
  }
}

export const convertTag = (raw: any): TagSnapshot => {
  return {
    tag_id: raw.tag_id,
    tag: raw.tag,
    type: raw.type,
  }
}

export const convertSpaceType = (raw: any): SpaceTypeSnapshot => {
  return {
    type_id: raw.type_id,
    name: raw.name,
    thumb: raw.thumb,
  }
}

export const convertSpace = (raw: any): SpaceSnapshot => {
  return {
    space_id: raw.space_id,
    title: raw.title,
    capacity: raw.capacity,
    count: raw.count,
    protected: raw.protected,
    live: raw.live,
    category: raw.category,
    type: raw.type,
    tags: raw.tags,
    hub_id: raw.hub_id,
    topic_id: raw.topic_id,
    hashed_password: raw.hashed_password,
    time_lock: raw.time_lock,
  }
}

export const convertUser = (raw: any): UserSnapshot => {
  return {
    profile_id: raw.profile_id,
    account_id: raw.account_id,
    one_id: raw.one_id,
    phone: raw.phone,
    name: raw.name,
    nick_name: raw.nick_name,
    avatar: raw.avatar,
    avatar_render: raw.avatar_render,
    interests: raw.interests,
    intro: raw.intro,
    point: raw.point,
    follower: raw.follower,
    following: raw.following,
    is_following: raw.is_following,
    is_liking: raw.is_liking,
    is_master: raw.is_master,
    master_category: raw.master_category,
    master_status: raw.master_status,
    master_address: raw.master_address,
    master_email: raw.master_email,
    master_phone_number: raw.master_phone_number,
    wallet_id: raw.wallet_id,
    wallet_address: raw.wallet_address,
    wallet_name: raw.wallet_name,
    wallet_password: raw.wallet_password,
    user_key: raw.user_key,
    ratings: raw.ratings,
    count: raw.count,
    educations: raw.educations,
    careers: raw.careers,
    last_review: raw.last_review,
    banned: raw.banned,
    reported_count: raw.reported_count,
  }
}

export const convertSlide = (raw: any): SlideSnapshot => {
  return {
    slide_id: raw.slide_id,
    url: raw.url,
    illustration: raw.illustration,
  }
}

export const convertKeyword = (raw: any): KeywordSnapshot => {
  return {
    keyword: raw.keyword,
    is_suggested: raw.is_suggested,
    state: raw.state,
  }
}

export const convertNotification = (raw: any): NotificationSnapshot => {
  return {
    notification_id: raw.notification_id,
    title: raw.title,
    content: raw.content,
    is_read: raw.is_read,
    type: raw.type,
    source_id: raw.source_id,
    target_id: raw.target_id,
    inserted_at: raw.inserted_at,
    updated_at: raw.updated_at,
    source_avatar: raw.source_avatar,
  }
}

export const convertCounseling = (raw: any): CounselingSnapshot => {
  return {
    counseling_id: raw.counseling_id,
    title: raw.title,
    days: raw.days,
    times: raw.times,
    period: raw.period,
    price: raw.price,
    description: raw.description,
    ratings: raw.ratings,
    count: raw.count,
    owner_id: raw.owner_id,
  }
}
