import { DEFAULT_API_CONFIG } from "./api-config"

export const upload = (file) => {
  try {
    const formData = new FormData()
    formData.append("media", file)
    formData.append("promotion_mode", "with_token")

    return fetch(DEFAULT_API_CONFIG.url + "/media", {
      method: "POST",
      body: formData,
    }).then((r) => r.json())
  } catch (error) {
    console.log(error)
    return null
  }
}
