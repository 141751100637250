// eslint-disable-next-line no-use-before-define
import * as React from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import RenderHTML from "react-native-render-html"
import { color, spacing } from "../../theme"

const CONTAINER: ViewStyle = {
  flex: 1,
}
const MODAL_CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.background,
  paddingHorizontal: spacing[4],
}

export interface TermsOfUseProps {
  style?: StyleProp<ViewStyle>
}

export const TermsOfUse = observer(function TermsOfUse(props: TermsOfUseProps) {
  const { style } = props
  const styles = Object.assign({}, CONTAINER, style)

  return (
    <View style={styles}>
      <View style={MODAL_CONTAINER}>
        <RenderHTML source={{ html: require("./html-loader.js")() }} />
      </View>
    </View>
  )
})
