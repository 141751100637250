import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { GetSpaceTypesResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { BASE_HOST } from "./api-config"
import { convertSpaceType } from "./convert"
import { SpaceType } from "../../models/space-type/space-type"

export class SpaceTypeApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getSpaceTypes(): Promise<GetSpaceTypesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/scenes")
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      const rawTypes = response.data

      const modifiedTypes = rawTypes.map((rawType) => {
        return {
          type_id: rawType.type_id,
          name: rawType.name,
          thumb:
            rawType.screenshot_owned_file && rawType.screenshot_owned_file.owned_file_uuid
              ? `${BASE_HOST}/files/${rawType.screenshot_owned_file.owned_file_uuid}`
              : null,
        }
      })

      const spaceTypes: SpaceType[] = modifiedTypes.map(convertSpaceType)
      return { kind: "ok", types: spaceTypes }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
