/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, SectionList, TouchableOpacity } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { color, spacing } from "../../theme"
import { useIsFocused } from "@react-navigation/native"
import { ChannelFilters, Channel, ChannelSort } from "stream-chat"
import { useStores } from "../../models"
import { chatClient } from "../../components/chat-client/chat-client"
import { Screen, Header, PageHeader, ChannelBox, ProfileAvatar, Text } from "../../components"
import { Transaction } from "../../models/transaction/transaction"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import moment from "moment"
import "moment/locale/ko"

let isMounted = false

export const ChatListScreen: FC<StackScreenProps<NavigatorParamList, "chatList">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()
    const [loading, setLoading] = useState(false)
    const { authStore, transactionStore } = useStores()
    const [channels, setChannels] = useState<Channel[]>([])
    const filter: ChannelFilters = {
      type: "messaging",
      members: { $in: [authStore.profile?.account_id!] },
    }
    const sort: ChannelSort = [{ last_message_at: -1 }]
    const isFocused = useIsFocused()
    const [transactions, setTransactions] = useState([])
    const [rawTransactions, setRawTransactions] = useState<Transaction[]>([])

    // test start
    // console.log('start', moment().add(3, 'minutes'))
    // console.log('end', moment().subtract(16, 'minutes'))
    // test end

    const fetchData = async () => {
      if (isMounted && isFocused) {
        setLoading(true)
        await Promise.all([
          chatClient.queryChannels(filter, sort, { limit: 30, member_limit: 2 }),
          transactionStore.fetchMyTransactions(authStore.token),
        ]).then((results) => {
          if (results[0].length > 0) {
            setChannels(results[0])
          } else {
            setChannels([])
          }
          setRawTransactions(results[1])
          setTransactions(
            results[1].map((result: any) => {
              return {
                ...result,
                start_time: moment(result.start_time),
                end_time: moment(result.end_time),
                showDetail: false,
              }
            }),
          )
        })
        setLoading(false)
      }
    }

    const openLink = async (url, transaction) => {
      window.open(url, "_blank").focus()
      const currentTransaction = rawTransactions.find(
        (tr) => tr.transaction_id === transaction.transaction_id,
      )
      if (currentTransaction) {
        if (!authStore.profile?.is_master) {
          navigation.navigate("transactionReview", { transaction: currentTransaction })
        } else {
          navigation.navigate("home")
        }
      }
    }

    const transactionsExpand = (item) => {
      const raw: any = [...transactions]
      setTransactions(
        raw.map((rawItem) => {
          if (rawItem.transaction_id === item.transaction_id) {
            return {
              ...rawItem,
              showDetail: !rawItem.showDetail,
            }
          } else {
            return {
              ...rawItem,
              showDetail: false,
            }
          }
        }),
      )
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      return () => {
        setLoading(false)
        isMounted = false
      }
    }, [authStore.isChatClientReady, isFocused])

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <PageHeader />
          <Header
            leftIcon="back"
            onLeftPress={goBack}
            headerText={"메세지"}
            settingsIcon={true}
            onSetingsIconPress={() => {
              navigation.navigate("notificationSettings")
            }}
          />
          <View style={{ height: 1, backgroundColor: color.border }} />
          <SectionList
            sections={[
              { title: "channels", data: channels },
              { title: "transactions", data: transactions as any[] },
            ]}
            keyExtractor={(item: any) => item.cid || item.transaction_id}
            renderItem={({ item, index }) => {
              if (item.cid) {
                return <ChannelBox channel={item} lastMessage={item.lastMessage()} key={index} />
              } else {
                const nick_name_desc = authStore.profile?.is_master
                  ? `${item.buyer_name} 과의 상담이 연결되었습니다.`
                  : `${item.seller_name} 마스터님과의 상담이 연결되었습니다.`
                const daysDiff = item.start_time.diff(moment(), "days")
                let compareStatus = ""
                let hightColor = color.primary
                let ableToEnter = false
                if (daysDiff === 0) {
                  if (item.end_time.isBefore(moment())) {
                    compareStatus = "상담 시간 지남"
                    hightColor = color.dim
                  } else {
                    if (item.start_time.diff(moment(), "minute") <= 5) {
                      ableToEnter = true
                      compareStatus = "스페이스 입장 가능"
                      hightColor = color.green
                    } else {
                      const totalMins = (item.start_time as moment.Moment).diff(moment(), "minutes")
                      const diffHours = Math.floor(totalMins / 60)
                      const diffMins = totalMins % 60
                      compareStatus =
                        diffHours !== 0 ? `${diffHours}시간 ${diffMins}분 전` : `${diffMins}분 전`
                      hightColor = color.primary
                    }
                  }
                } else {
                  if (daysDiff < 0) {
                    compareStatus = "상담일 지남"
                    hightColor = color.dim
                  } else {
                    compareStatus = `${daysDiff}일 뒤 입장 가능`
                    hightColor = color.primary
                  }
                }
                return (
                  <View>
                    <TouchableOpacity
                      style={{ flexDirection: "row", padding: spacing[4], alignItems: "center" }}
                      onPress={() => {
                        transactionsExpand(item)
                      }}
                    >
                      <ProfileAvatar avatar={""} size={48} isNotification={true} />
                      <View style={{ justifyContent: "center", marginLeft: spacing[4], flex: 1 }}>
                        <Text text={nick_name_desc} />
                        <Text
                          text={`[${compareStatus}]`}
                          style={{ color: hightColor }}
                          preset={"secondary"}
                        />
                      </View>
                      <MaterialIcons
                        name={item.showDetail ? "keyboard-arrow-up" : "keyboard-arrow-down"}
                        size={24}
                        color={color.text}
                      />
                    </TouchableOpacity>
                    {item.showDetail && (
                      <View
                        style={{
                          padding: spacing[4],
                          alignItems: "center",
                          backgroundColor: color.border,
                        }}
                      >
                        <Text
                          text={`[${item.title}]`}
                          style={{ marginTop: spacing[4] }}
                          preset={"center"}
                        />
                        <Text
                          text={`상담신청일자 ${item.start_time.format("M월 D일")}`}
                          style={{ marginTop: spacing[4] }}
                        />
                        <Text
                          text={`상담신청시간 ${item.start_time.format("a hh:mm")}`}
                          style={{ marginTop: spacing[4] }}
                        />
                        <TouchableOpacity
                          disabled={!ableToEnter}
                          onPress={async () => {
                            let inputPassword = prompt("비밀번호를 입력하세요.")
                            if (item && item.password === inputPassword) {
                              openLink(
                                encodeURI(
                                  `${item.url}?default_mobile_material_quality=high&token=${authStore.token}`,
                                ),
                                item,
                              )
                            }
                          }}
                          style={{
                            margin: spacing[4],
                            borderRadius: spacing[4],
                            backgroundColor: color.dim,
                            padding: spacing[4],
                          }}
                        >
                          <Text
                            text={`${encodeURI(
                              `${item.url}?default_mobile_material_quality=high&token=${authStore.token}`,
                            )}`}
                          />
                        </TouchableOpacity>
                        <Text text={"위 링크를 통해 5분 전부터 접속이 가능합니다."} />
                        <Text
                          text={`* 비밀번호 ${item.password}`}
                          style={{ marginTop: spacing[2] }}
                          preset={"bold"}
                        />
                      </View>
                    )}
                  </View>
                )
              }
            }}
            onRefresh={fetchData}
            refreshing={loading}
          />
        </Screen>
      </View>
    )
  },
)
