/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, FlatList, TouchableOpacity } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, Text, Button } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { Toast } from "react-native-toast-message/lib/src/Toast"

interface SettingItem {
  id: string
  text: string
  screen: any
  disabled: boolean
}

export const SettingsScreen: FC<StackScreenProps<NavigatorParamList, "settings">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()
    const { authStore } = useStores()

    const initialSettings = [
      {
        id: "0",
        text: "비밀번호 관리",
        screen: "passwordManagement",
        disabled: false,
      } as SettingItem,
      {
        id: "1",
        text: "통통지갑 연동",
        screen: "connectWallet",
        disabled: false,
      } as SettingItem,
      { id: "2", text: "알림", screen: "notificationSettings", disabled: false } as SettingItem,
      {
        id: "3",
        text: "상담내역",
        screen: authStore.profile?.is_master ? "transactionHistoryMaster" : "transactionHistory",
        disabled: false,
      } as SettingItem,
      {
        id: "4",
        text: "마스터 등록",
        screen: "masterRegistration",
        disabled: false,
      } as SettingItem,
    ]

    const handleDeleteUser = async () => {
      await authStore.deleteUser()
      await authStore.logout()
      Toast.show({
        type: "info",
        text1: "회원탈퇴가 완료되었습니다",
        position: "bottom",
      })
    }

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <Header leftIcon="back" onLeftPress={goBack} headerText={"설정"} />
          <View style={{ flex: 1, justifyContent: "space-between" }}>
            <View style={{ flex: 1 }}>
              <View style={{ height: 1, backgroundColor: color.border }} />
              <FlatList
                data={initialSettings}
                renderItem={({ item }) => {
                  if (
                    item.id === "4" &&
                    authStore.profile?.is_master &&
                    authStore.profile?.master_status === "approved"
                  ) {
                    return <></>
                  }
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        if (item.id !== "1") {
                          navigation.navigate(item.screen)
                        } else {
                          alert(
                            "웹 버전에서 지원하지 않는 기능입니다.\n해당기능을 앱에서 확인해 보십시오.",
                          )
                        }
                      }}
                      disabled={item.disabled}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingHorizontal: spacing[4],
                          paddingVertical: spacing[4],
                          alignItems: "center",
                        }}
                      >
                        <Text text={item.text} preset={"header1"} />
                        {item.disabled ? (
                          <MaterialCommunityIcons
                            name="checkbox-marked-circle-outline"
                            size={24}
                            color={color.green}
                          />
                        ) : (
                          <MaterialIcons name="keyboard-arrow-right" size={24} color={color.text} />
                        )}
                      </View>
                    </TouchableOpacity>
                  )
                }}
                keyExtractor={(item) => item.id}
              />
            </View>

            <Button
              text="회원탈퇴"
              style={{
                marginBottom: spacing[6],
                marginHorizontal: spacing[6],
              }}
              onPress={async () => {
                let inputPassword = prompt(
                  "회원탈퇴 시 저장된 프로필 정보, 게시물 등이 영구히 삭제되며 복구가 불가합니다.\n\n마스터 회원의 경우 정산 이전 일자에 회원탈퇴 신청 시 정산예정금액이 정산되지 않습니다.",
                )
                if (inputPassword.trim().length > 0) {
                  const res = await authStore.checkOnePassword(inputPassword)
                  if (res === true) {
                    handleDeleteUser()
                  } else {
                    alert("비밀번호가 맞지 않습니다.")
                  }
                }
              }}
              disabled={authStore.status === "pending"}
            />
          </View>
        </Screen>
      </View>
    )
  },
)
