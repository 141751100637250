/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types, cast } from "mobx-state-tree"
import { AuthApi } from "../../services/api/auth-api"
import { withStatus } from "../extensions/with-status"
import { UserModel, UserSnapshot } from "../user/user"
import { connectToReticulum } from "./phoenix-utils"
import { GetUserResult, api } from "../../services/api"
import { UserApi } from "../../services/api/user-api"
import { chatClient } from "../../components"
import json2ParseBigint from "../../utils/json_parse_bigint"
import Toast from "react-native-toast-message"
import uuid from "react-native-uuid"

export const AuthStoreModel = types
  .model("AuthStore")
  .props({
    isAuthenticated: types.optional(types.boolean, false),
    profile: types.maybeNull(UserModel),
    token: types.optional(types.string, ""),
    hasNickName: types.optional(types.boolean, false),
    avatarFromRPM: types.optional(types.string, ""),
    avatarRenderFromRPM: types.optional(types.string, ""),
    avatarUserId: types.optional(types.string, ""),
    hasAvatar: types.optional(types.boolean, false),
    isChatClientReady: types.optional(types.boolean, false),
    deviceToken: types.maybeNull(types.string),
    deviceType: types.maybeNull(types.string),
    tongtongWalletConnected: types.optional(types.boolean, false),
    tongtongWalletAddr: types.maybeNull(types.string),
    tongtongWalletBalance: types.maybeNull(types.string),
    toReviewTab: types.optional(types.boolean, false),
  })
  .extend(withStatus)
  .views((self) => ({
    getWalletBalanceTTCOIN: () => {
      if (self.tongtongWalletConnected && self.tongtongWalletBalance) {
        return parseFloat(JSON.parse(self.tongtongWalletBalance).TTCOIN)
      } else {
        return null
      }
    },
    getWalletBalanceTTM: () => {
      if (self.tongtongWalletConnected && self.tongtongWalletBalance) {
        return parseFloat(JSON.parse(self.tongtongWalletBalance).TTM)
      } else {
        return null
      }
    },
  }))
  .actions((self) => ({
    setAuthenticated(value: boolean) {
      self.isAuthenticated = value
    },
    saveProfile: (userSnapShot: UserSnapshot) => {
      self.profile = cast(userSnapShot)
    },
    setToken(value: string) {
      self.token = value
    },
    setNickNameHas(value: boolean) {
      self.hasNickName = value
    },
    setHasAvatar(value: boolean) {
      self.hasAvatar = value
    },
    setAvatarFromRPM(value: string) {
      self.avatarFromRPM = value
    },
    setAvatarRenderFromRPM(value: string) {
      self.avatarRenderFromRPM = value
    },
    setAvatarUserId(value: string) {
      self.avatarUserId = value
    },
    setChatClientReady(value: boolean) {
      self.isChatClientReady = value
    },
    setDeviceToken(value: string | null) {
      self.deviceToken = value
    },
    setDeviceType(value: string) {
      self.deviceType = value
    },
    setToReviewTab(value: boolean) {
      self.toReviewTab = value
    },
    setTongTongWalletConnected(value: boolean) {
      self.tongtongWalletConnected = value
    },
    setTongTongWalletAddr(value: string | null) {
      self.tongtongWalletAddr = value
    },
    setTongTongWalletBalance(value: string | null) {
      self.tongtongWalletBalance = value
    },
  }))
  .actions((self) => ({
    login: flow(function* (phone: string, password: string, nation: string = "KR") {
      self.setStatus("pending")
      const socket = yield connectToReticulum()
      const channel = socket.channel(`auth:${uuid.v4()}`)

      const signIn = new Promise<any>((resolve, reject) => {
        channel.onError(() => {
          channel.leave()
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        })

        channel
          .join()
          .receive("ok", () => {
            channel.on("auth_credentials", async ({ credentials: token, payload }) => {
              resolve({ token, payload })
            })

            channel.push("auth_request", {
              phone,
              password,
              nation,
              device_token: self.deviceToken,
              device_type: self.deviceType,
            })
          })
          .receive("error", reject)
      })

      signIn
        .then(({ token, payload }) => {
          try {
            const json = json2ParseBigint(payload)
            if (json.banned) {
              alert(
                `누적 신고 3회 이상으로 차단된 계정입니다.\n로그인을 원하시는 경우 sotong@etomato.com 으로 메일 발송이 가능합니다.`,
              )
              self.setStatus("done")
              self.setToken("")
              self.profile = null
              self.setNickNameHas(false)
              self.setHasAvatar(false)
              self.setAuthenticated(false)
            } else {
              json.educations = null
              json.careers = null
              self.setToken(token)
              self.setStatus("done")
              self.saveProfile(json)
              self.setNickNameHas(!(json.nick_name === "" || json.nick_name === null))
              self.setHasAvatar(!(json.avatar === "" || json.avatar === null))
              self.setAuthenticated(true)
            }
          } catch (e) {
            self.setStatus("error")
            self.setToken("")
            self.profile = null
            self.setNickNameHas(false)
            self.setHasAvatar(false)
            self.setAuthenticated(false)
          }
        })
        .catch((_) => {
          Toast.show({
            type: "error",
            text1: "로그인 정보가 잘못되었습니다",
            position: "bottom",
          })
          self.setStatus("error")
          self.setToken("")
          self.profile = null
          self.setNickNameHas(false)
          self.setHasAvatar(false)
          self.setAuthenticated(false)
        })
    }),

    logout: flow(function* () {
      if (chatClient.userID) {
        yield chatClient.disconnectUser()
        self.setChatClientReady(false)
      }
      self.setToken("")
      self.profile = null
      self.setNickNameHas(false)
      self.setHasAvatar(false)
      self.resetStatus()
      self.setAuthenticated(false)
      self.setDeviceToken(null)
    }),
  }))
  .actions((self) => ({
    fetchProfile: flow(function* () {
      if (self.profile?.profile_id) {
        self.setStatus("pending")

        const userApi = new UserApi(api)
        const result = yield userApi.fetchProfile(self.token, self.profile?.profile_id)

        if (result.kind === "ok") {
          self.setStatus("done")
          self.saveProfile(result.user)
          if (result.user.banned === true) {
            Toast.show({
              type: "error",
              text1: "누적 신고 3회 이상으로 차단된 계정입니다.",
              text2: "로그인을 원하시는 경우 sotong@etomato.com 으로 메일 발송이 가능합니다.",
              position: "bottom",
              visibilityTime: 10000,
            })
            self.logout()
          }
        } else {
          if (result.kind === "unauthorized") {
            Toast.show({
              type: "error",
              text1: "인증토큰이 만료 되었습니다",
              position: "bottom",
            })
            self.logout()
          } else {
            self.setStatus("error")
          }
        }
      }
    }),

    userFollow: flow(function* (accountId: string) {
      if (accountId) {
        const userApi = new UserApi(api)
        const result = yield userApi.userFollow(self.token, accountId)

        return result.kind === "ok"
      } else {
        return false
      }
    }),

    userUnfollow: flow(function* (accountId: string) {
      const userApi = new UserApi(api)
      const result = yield userApi.userUnfollow(self.token, accountId)

      return result.kind === "ok"
    }),

    userLike: flow(function* (accountId: string) {
      if (accountId) {
        const userApi = new UserApi(api)
        const result = yield userApi.userLike(self.token, accountId)

        return result.kind === "ok"
      } else {
        return false
      }
    }),

    userUnLike: flow(function* (likingId: string) {
      if (likingId) {
        const userApi = new UserApi(api)
        const result = yield userApi.userUnLike(self.token, likingId)

        return result.kind === "ok"
      } else {
        return false
      }
    }),

    fetchUserProfileById: flow(function* (profileId: string) {
      const userApi = new UserApi(api)
      const result = yield userApi.fetchProfile(self.token, profileId)

      if (result.kind === "ok") {
        return result.user
      } else {
        return null
      }
    }),

    fetchUserProfileByAccountId: flow(function* (accountId: string) {
      const userApi = new UserApi(api)
      const result = yield userApi.fetchAccount(self.token, accountId)

      if (result.kind === "ok") {
        return result.user
      } else {
        return null
      }
    }),

    updateProfile: flow(function* (payload) {
      self.setStatus("pending")

      const authenticationApi = new AuthApi(api)
      const result: GetUserResult = yield authenticationApi.updateProfile(
        self.profile?.profile_id,
        self.token,
        payload,
      )

      if (result.kind === "ok") {
        self.setStatus("done")
        self.saveProfile(result.user)
        self.setNickNameHas(self.profile?.nick_name !== null)
        self.setHasAvatar(self.profile?.avatar !== null)
        return true
      } else {
        self.setStatus("error")
        self.setNickNameHas(self.profile?.nick_name !== null)
        self.setHasAvatar(self.profile?.avatar !== null)
        return false
      }
    }),

    registerMaster: flow(function* (payload) {
      self.setStatus("pending")

      const authenticationApi = new AuthApi(api)
      const result: GetUserResult = yield authenticationApi.registerMaster(
        self.profile?.profile_id,
        self.token,
        payload,
      )

      if (result.kind === "ok") {
        self.setStatus("done")
        self.saveProfile(result.user)
        return true
      } else {
        self.setStatus("error")
        return false
      }
    }),

    fetchFollwers: flow(function* (accountId: string, limit: number = 6, page: number = 0) {
      const userApi = new UserApi(api)
      const result = yield userApi.getFollowers(self.token, limit, page, accountId)

      if (result.kind === "ok") {
        return result.users
      } else {
        return []
      }
    }),

    fetchFollowings: flow(function* (accountId: string, limit: number = 6, page: number = 0) {
      const userApi = new UserApi(api)
      const result = yield userApi.getFollowings(self.token, limit, page, accountId)

      if (result.kind === "ok") {
        return result.users
      } else {
        return []
      }
    }),

    fetchUserLikings: flow(function* (accountId: string, limit: number = 6, page: number = 0) {
      const userApi = new UserApi(api)
      const result = yield userApi.getUserLikings(self.token, limit, page, accountId)

      if (result.kind === "ok") {
        return result.users
      } else {
        return []
      }
    }),

    fetchUserLikingsAll: flow(function* (accountId: string) {
      const userApi = new UserApi(api)
      const result = yield userApi.getUserLikingsAll(self.token, accountId)

      if (result.kind === "ok") {
        return result.users
      } else {
        return []
      }
    }),

    changePassword: flow(function* (oldPassword: string, newPassword: string) {
      const authenticationApi = new AuthApi(api)
      const result = yield authenticationApi.changePassword(self.token, oldPassword, newPassword)

      if (result.kind === "ok") {
        return result.reason
      } else {
        return []
      }
    }),

    fetchEducationsAndCareers: flow(function* (account_id: string) {
      const userApi = new UserApi(api)
      const result = yield userApi.fetchEducationsAndCareers(self.token, account_id)

      if (result.kind === "ok") {
        return result.educations_results
      } else {
        return []
      }
    }),

    updateMasterIntro: flow(function* (payload: any) {
      const authenticationApi = new AuthApi(api)
      const result: GetUserResult = yield authenticationApi.updateMasterIntro(
        self.profile?.profile_id as string,
        self.token,
        payload,
      )

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),

    updateWalletInfo: flow(function* (payload: any) {
      const authenticationApi = new AuthApi(api)
      const result = yield authenticationApi.updateWalletInfo(
        self.token,
        self.profile?.profile_id as string,
        payload,
      )

      if (result.kind === "ok") {
        return result.result
      } else {
        return null
      }
    }),

    getWalletBalance: flow(function* () {
      const authenticationApi = new AuthApi(api)
      const result = yield authenticationApi.getWalletBalance(
        self.token,
        self.profile?.profile_id as string,
      )

      if (result.kind === "ok") {
        return result.result
      } else {
        return null
      }
    }),

    oneJoinCerti: flow(function* (phonenum: string, nation: string, code: string) {
      self.setStatus("pending")
      const authenticationApi = new AuthApi(api)
      const result = yield authenticationApi.oneJoinCerti(phonenum, nation, code)

      if (result.kind === "ok") {
        self.setStatus("done")
        return result.result
      } else {
        self.setStatus("error")
        return null
      }
    }),

    oneVerifyCode: flow(function* (userKey: string, code: string) {
      self.setStatus("pending")
      const authenticationApi = new AuthApi(api)
      const result = yield authenticationApi.oneVerifyCode(userKey, code)

      if (result.kind === "ok") {
        self.setStatus("done")
        return result.result
      } else {
        self.setStatus("error")
        return null
      }
    }),

    oneJoinRecerti: flow(function* (userKey: string) {
      self.setStatus("pending")
      const authenticationApi = new AuthApi(api)
      const result = yield authenticationApi.oneJoinRecerti(userKey)

      if (result.kind === "ok") {
        self.setStatus("done")
        return result.result
      } else {
        self.setStatus("error")
        return null
      }
    }),

    oneJoin: flow(function* (userKey: string, phonenum: string, password: string) {
      self.setStatus("pending")
      const authenticationApi = new AuthApi(api)
      const result = yield authenticationApi.oneJoin(userKey, phonenum, password)

      if (result.kind === "ok") {
        self.setStatus("done")
        return result.result
      } else {
        self.setStatus("error")
        return null
      }
    }),
    deleteUser: flow(function* () {
      self.setStatus("pending")
      const authenticationApi = new AuthApi(api)
      const result = yield authenticationApi.deleteUser(self.token)

      if (result.kind === "ok") {
        self.setStatus("done")
        return true
      } else {
        self.setStatus("error")
        return false
      }
    }),
    checkOnePassword: flow(function* (password: string) {
      self.setStatus("pending")
      const authenticationApi = new AuthApi(api)
      const result = yield authenticationApi.checkOnePassword(self.token, password)

      if (result.kind === "ok") {
        self.setStatus("done")
        return true
      } else {
        self.setStatus("error")
        return false
      }
    }),
  }))

type AuthStoreType = Instance<typeof AuthStoreModel>
export interface AuthStore extends AuthStoreType {}
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
