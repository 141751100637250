// const domain = "localhost:4000"
const domain = "sotong.com"

export const SOCKET_URL = `wss://${domain}`
export const BASE_HOST = `https://${domain}`

const DEFAULT_API_URL = BASE_HOST + "/api/v1"

export interface ApiConfig {
  url: string
  timeout: number
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: DEFAULT_API_URL,
  timeout: 10000,
}
