/* eslint-disable no-use-before-define */
import React, { FC, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { observer } from "mobx-react-lite"
import { Dimensions, ScrollView, TouchableOpacity, View } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Button, Header, Screen, Text, TextField } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { CountryPicker } from "react-native-country-codes-picker"
import Toast from "react-native-toast-message"
import { SCREEN_HEIGHT, SCREEN_WIDTH } from "app/utils/screen"

type FormData = {
  phone: string
}

export const RegisterPhoneScreen: FC<StackScreenProps<NavigatorParamList, "registerPhone">> =
  observer(({ navigation }) => {
    const goBack = () => navigation.goBack()
    const { authStore } = useStores()
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<FormData>()
    const [showCode, setShowCode] = useState(false)
    const [countryCode, setCountryCode] = useState("KR")
    const [countryDialCode, setCountryDialCode] = useState("+82")

    const onJoinCerti = async (data: FormData) => {
      const result = await authStore.oneJoinCerti(data.phone, countryCode, countryDialCode)
      if (result) {
        if (result.message && result.result === false) {
          const errorMessage = () => {
            if (result.message === "INVALID_PHONE") return "유효하지 않은 전화번호입니다"
            else if (result.message === "REGISTERED_PHONE") return "이미 회원가입 된 전화번호입니다"
            else if (result.message === "TWILIO_DAY_LIMIT") return "제한 초과"
            else return result.message
          }
          Toast.show({
            type: "error",
            text1: errorMessage(),
            position: "bottom",
          })
        } else {
          console.log(result.value.registerID)
          navigation.navigate("registerOTP", {
            userKey: result.value.userKey,
            phonenum: data.phone,
            nation: countryCode,
          })
        }
      } else {
        Toast.show({
          type: "error",
          text1: "인증번호 발송 실패",
          position: "bottom",
        })
      }
    }

    return (
      <View style={{ flex: 1 }}>
        <Screen
          style={{
            flex: 1,
            backgroundColor: color.transparent,
          }}
          preset="fixed"
        >
          <Header leftIcon="back" onLeftPress={goBack} headerText={"회원가입"} />
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              paddingHorizontal: spacing[6],
              justifyContent: "space-evenly",
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Text preset="headerCenter" text="토마토 One-ID" />
              <Text style={{ marginVertical: spacing[4] }}>
                <Text text="토마토 One-ID 로 " />
                <Text text="소통" preset="bold" />
                <Text text="을 이용하세요!" />
              </Text>
              <View
                style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowCode(true)
                    }}
                    style={{
                      marginRight: spacing[4],
                      borderWidth: 1,
                      padding: 6,
                      borderRadius: 5,
                    }}
                  >
                    <Text text={countryDialCode} preset="boldCenter" />
                  </TouchableOpacity>
                  <View style={{ flex: 1 }}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern: /^\d{10}$|^\d{11}$/,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          keyboardType="numeric"
                          placeholderTx="loginScreen.phone"
                          style={{ padding: spacing[2], marginVertical: spacing[2] }}
                          inputStyle={{ padding: spacing[0], textAlign: "center" }}
                          maxLength={11}
                        />
                      )}
                      name="phone"
                      defaultValue=""
                    />
                  </View>
                </View>
                {errors.phone && errors.phone.type === "required" && (
                  <Text preset="warning" tx="common.needPhone" />
                )}
                {errors.phone && errors.phone.type === "pattern" && (
                  <Text preset="warning" tx="common.invalidPhone" />
                )}
              </View>
            </View>
            <View style={{ alignItems: "center" }}>
              <Text text="인증 시 사용한 번호는 아이디가 됩니다" />
              <Button
                text={authStore.status === "pending" ? "로딩중..." : "인증번호 발송"}
                onPress={handleSubmit(onJoinCerti)}
                style={{ width: "100%", marginTop: spacing[4] }}
                disabled={authStore.status === "pending"}
              />
            </View>
            <CountryPicker
              lang="en"
              show={showCode}
              pickerButtonOnPress={(item) => {
                setCountryDialCode(item.dial_code)
                setCountryCode(item.code)
                setShowCode(false)
              }}
              style={{
                // Styles for whole modal [View]
                modal: {
                  height: (SCREEN_HEIGHT * 4) / 7,
                  width: SCREEN_WIDTH,
                  alignSelf: "center",
                },
                // Styles for modal backdrop [View]
                backdrop: {},
                // Styles for bottom input line [View]
                line: {},
                // Styles for list of countries [FlatList]
                itemsList: {},
                // Styles for input [TextInput]
                textInput: {},
                // Styles for country button [TouchableOpacity]
                countryButtonStyles: {},
                // Styles for search message [Text]
                searchMessageText: {},
                // Styles for search message container [View]
                countryMessageContainer: {},
                // Flag styles [Text]
                flag: {},
                // Dial code styles [Text]
                dialCode: {},
                // Country name styles [Text]
                countryName: {},
              }}
              onBackdropPress={() => {
                setShowCode(false)
              }}
            />
          </ScrollView>
        </Screen>
      </View>
    )
  })
