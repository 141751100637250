// eslint-disable-next-line no-use-before-define
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { AnnouncementApi } from "../../services/api/announcement-api"
import { AnnouncementModel, AnnouncementSnapshot } from "../announcement/announcement"
import { api } from "app/services/api"

export const AnnouncementStoreModel = types
  .model("AnnouncementStore")
  .props({
    announcements: types.optional(types.array(AnnouncementModel), []),
  })
  .actions((self) => ({
    saveAnnouncements: (announcementShaptshots: AnnouncementSnapshot[]) => {
      self.announcements.replace(announcementShaptshots)
    },
  }))
  .actions((self) => ({
    fetchAnnouncements: flow(function* () {
      const announcementsApi = new AnnouncementApi(api)
      const result = yield announcementsApi.getAnnouncements()

      if (result.kind === "ok") {
        self.saveAnnouncements(result.announcements)
      } else {
        if (result.kind === "not-found") {
          self.announcements.clear()
        }
      }
    }),
  }))

type AnnouncementStoreType = Instance<typeof AnnouncementStoreModel>
export interface AnnouncementStore extends AnnouncementStoreType {}
type AnnouncementStoreSnapshotType = SnapshotOut<typeof AnnouncementStoreModel>
export interface AnnouncementStoreSnapshot extends AnnouncementStoreSnapshotType {}
