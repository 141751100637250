import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { TtmiApi } from "../../services/api/ttmi-api"
import { api } from "app/services/api"

export const TtmiStoreModel = types
  .model("TtmiStore")
  .props({})
  .actions((self) => ({
    fetchTtmis: flow(function* (token: string) {
      const ttmiApi = new TtmiApi(api)
      const result = yield ttmiApi.getTtmis(token)

      if (result.kind === "ok") {
        return result.ttmis
      } else {
        return []
      }
    }),
  }))

type TtmiStoreType = Instance<typeof TtmiStoreModel>
export interface TtmiStore extends TtmiStoreType {}
type TtmiStoreSnapshotType = SnapshotOut<typeof TtmiStoreModel>
export interface TtmiStoreSnapshot extends TtmiStoreSnapshotType {}
