import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const TagModel = types
  .model('Tag')
  .props({
    tag_id: types.identifier,
    tag: types.maybeNull(types.string),
    type: types.maybeNull(types.string)
  })
  .views((self) => ({}))
  .actions((self) => ({}))

type TagType = Instance<typeof TagModel>
export interface Tag extends TagType {}
type TagSnapshotType = SnapshotOut<typeof TagModel>
export interface TagSnapshot extends TagSnapshotType {}
