/* eslint-disable no-use-before-define */
import * as React from "react"
import { StyleProp, View, ViewStyle, Image } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing } from "../../theme"
import { User } from "../../models/user/user"
import { TouchableOpacity } from "react-native-gesture-handler"
import { ProfileAvatar } from "../profile-avatar/profile-avatar"
import { Text } from "../text/text"
import { useStores } from "../../models"
import { navigationRef } from "app/navigators"

const placeHolder = require("../../../assets/images/place-holder.png")

const CONTAINER: ViewStyle = {
  backgroundColor: "#F1F1F1",
  borderRadius: spacing[4],
  width: 120,
  height: 120,
  justifyContent: "center",
}

export interface GeneralUserBoxSquareProps {
  style?: StyleProp<ViewStyle>
  user: User
  onPress: () => void
}

export const GeneralUserBoxSquare = observer(function GeneralUserBoxSquare(
  props: GeneralUserBoxSquareProps,
) {
  const { style, user, onPress } = props
  const styles = Object.assign({}, CONTAINER, style)
  const { categoryStore, authStore } = useStores()

  const UserCategoires = () => {
    if (user.interests?.length !== 0) {
      return (
        <View style={{ flexDirection: "row" }}>
          {user.interests?.map((item) => {
            const userCategory = categoryStore.categories.find(
              (category) => category.category_id === item,
            )
            if (userCategory !== undefined) {
              return (
                <View key={item} style={{ marginTop: spacing[2], marginHorizontal: spacing[1] }}>
                  {userCategory && userCategory.thumb ? (
                    <Image source={{ uri: userCategory.thumb }} style={{ width: 24, height: 24 }} />
                  ) : (
                    <Image source={placeHolder} style={{ width: 24, height: 24 }} />
                  )}
                </View>
              )
            } else {
              return <View key={item}></View>
            }
          })}
        </View>
      )
    } else {
      return <></>
    }
  }

  return (
    <TouchableOpacity
      onPress={() => {
        if (authStore.isAuthenticated) {
          onPress()
        } else {
          if (confirm("해당 기능 사용을 위해 로그인이 필요합니다.")) {
            navigationRef.navigate("auth-stack")
          }
        }
      }}
    >
      <View style={styles}>
        <View style={{ alignItems: "center" }}>
          <ProfileAvatar
            avatar={user.avatar_render || ""}
            size={64}
            isMaster={user.is_master || false}
          />
          <Text
            text={user.nick_name || ""}
            style={{ marginTop: spacing[2], marginHorizontal: spacing[4] }}
            numberOfLines={1}
          />
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
          <UserCategoires />
        </View>
      </View>
    </TouchableOpacity>
  )
})
