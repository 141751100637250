import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { GetSettingsResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"

export class SettingsApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getSettings(): Promise<GetSettingsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/app_configs")

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", settings: response.data }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }
}
