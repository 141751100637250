import { Platform } from "react-native"

export const customFontsToLoad = {
  "KoPubDotum Medium": require("../../assets/fonts/KoPubDotumMedium.ttf"),
  "KoPubDotum Bold": require("../../assets/fonts/KoPubDotumBold.ttf"),
}

export const typography = {
  primary: Platform.select({ ios: "KoPubDotumMedium", android: "KoPubDotum Medium" }),
  secondary: Platform.select({ ios: "KoPubDotumBold", android: "KoPubDotum Bold" }),
}
