/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, TouchableOpacity } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, Text } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { Switch } from "react-native-paper"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import Accordion from "react-native-collapsible/Accordion"

const SETTING_INNER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  margin: spacing[1],
}

const notificationSettingsSection = [
  {
    title: "스페이스 초대",
    key: "spaceInvite",
  },
  {
    title: "팔로우",
    key: "follow",
  },
  {
    title: "채팅",
    key: "chatting",
  },
  {
    title: "결제",
    key: "payment",
  },
  {
    title: "상담",
    key: "consulting",
  },
]

export const NotificationSettingsScreen: FC<
  StackScreenProps<NavigatorParamList, "notificationSettings">
> = observer(({ navigation }) => {
  const goBack = () => navigation.goBack()
  const { notificationSettingStore, authStore } = useStores()
  const [activeSections, setActivieSections] = useState<[] | null>([])

  const [notification_invite_from_follower, set_notification_invite_from_follower] = useState(
    notificationSettingStore.notification_invite_from_follower,
  )
  const [notification_invite_from_following, set_notification_invite_from_following] = useState(
    notificationSettingStore.notification_invite_from_following,
  )
  const [notification_wish_follow, set_notification_wish_follow] = useState(
    notificationSettingStore.notification_wish_follow,
  )
  const [notification_message_chat, set_notification_message_chat] = useState(
    notificationSettingStore.notification_message_chat,
  )

  const setSections = (sections) => {
    setActivieSections(
      sections.includes(undefined) || sections.includes(3) || sections.includes(4) ? [] : sections,
    )
  }

  const handleNotificationSettingsUpdate = async (sIndex: number) => {
    if (sIndex === 0 || sIndex === 1 || sIndex === 2 || sIndex === 3) {
      await notificationSettingStore
        .updateNotificationSettings(authStore.token, authStore.profile?.account_id, {
          notification_invite_from_follower:
            sIndex === 0 ? !notification_invite_from_follower : notification_invite_from_follower,
          notification_invite_from_following:
            sIndex === 1 ? !notification_invite_from_following : notification_invite_from_following,
          notification_wish_follow:
            sIndex === 2 ? !notification_wish_follow : notification_wish_follow,
          notification_message_chat:
            sIndex === 3 ? !notification_message_chat : notification_message_chat,
        })
        .then(() => {
          set_notification_invite_from_follower(
            notificationSettingStore.notification_invite_from_follower,
          )
          set_notification_invite_from_following(
            notificationSettingStore.notification_invite_from_following,
          )
          set_notification_wish_follow(notificationSettingStore.notification_wish_follow)
          set_notification_message_chat(notificationSettingStore.notification_message_chat)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const renderNotificationSettingsListHeader = (section, _, isActive) => {
    return (
      <View>
        <View
          style={{
            padding: spacing[4],
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text text={section.title} style={{ flex: 8, marginRight: spacing[2] }} />
          <View>
            {isActive ? (
              <MaterialIcons name="keyboard-arrow-up" size={18} color={color.text} />
            ) : (
              <MaterialIcons name="keyboard-arrow-down" size={18} color={color.text} />
            )}
          </View>
        </View>
        <View style={{ height: 1, backgroundColor: color.border }} />
      </View>
    )
  }

  const renderNotificationSettingsListContent = (section, _, isActive) => {
    return (
      <View>
        <View style={{ padding: spacing[4], justifyContent: "center" }}>
          {section.key === "spaceInvite" && (
            <View>
              <View style={SETTING_INNER}>
                <Text text={"나의 팔로워의 스페이스 초대"} />
                <Switch
                  value={notification_invite_from_follower}
                  onValueChange={() => handleNotificationSettingsUpdate(0)}
                />
              </View>
              <View style={SETTING_INNER}>
                <Text text={"내가 팔로우하는 사용자의 스페이스 초대"} />
                <Switch
                  value={notification_invite_from_following}
                  onValueChange={() => handleNotificationSettingsUpdate(1)}
                />
              </View>
            </View>
          )}
          {section.key === "follow" && (
            <View style={SETTING_INNER}>
              <Text text={"찜/팔로우"} />
              <Switch
                value={notification_wish_follow}
                onValueChange={() => handleNotificationSettingsUpdate(2)}
              />
            </View>
          )}
          {section.key === "chatting" && (
            <View style={SETTING_INNER}>
              <Text text={"1:1 대화"} />
              <Switch
                value={notification_message_chat}
                onValueChange={() => handleNotificationSettingsUpdate(3)}
              />
            </View>
          )}
        </View>
        <View style={{ height: 1, backgroundColor: color.border }} />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        <Header leftIcon="back" onLeftPress={goBack} headerText={"알림 설정"} />
        <View style={{ flex: 1 }}>
          <View style={{ height: 1, backgroundColor: color.border }} />
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Accordion
              activeSections={activeSections || []}
              sections={notificationSettingsSection}
              touchableComponent={TouchableOpacity}
              expandMultiple={false}
              renderHeader={renderNotificationSettingsListHeader}
              renderContent={renderNotificationSettingsListContent}
              onChange={setSections}
              renderAsFlatList={true}
            />
          </View>
        </View>
      </Screen>
    </View>
  )
})
