import { ApiResponse } from 'apisauce'
import { FaqSnapshot } from '../../models/faq/faq'
import { Api } from './api'
import { getGeneralApiProblem } from './api-problem'
import { GetFaqsResult } from './api.types'
import { convertFaq } from './convert'

export class FaqApi {
  private api: Api

  constructor (api: Api) {
    this.api = api
  }

  async getFaqs (token: string): Promise<GetFaqsResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get('/sotong_faqs')

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const faqs: FaqSnapshot[] = response.data.map(convertFaq)
      return { kind: 'ok', faqs: faqs }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
