/* eslint-disable no-use-before-define */
import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { observer } from 'mobx-react-lite'
import { color, spacing } from '../../theme'
import { Text } from '../text/text'
import { Space } from '../../models/space/space'
import { FlatGrid } from 'react-native-super-grid'
import { SpaceBox } from '../space-box/space-box'

export interface SpacesMoreProps {
  moreTitle?: string
  spaces: Space[]
  onPressAll?(): void
  onRefreshSpaces?(): void
}

export const SpacesMore = observer(function SpacesMore (props: SpacesMoreProps) {
  const { spaces, moreTitle, onPressAll, onRefreshSpaces } = props

  return (
    <View>
      <View style={{
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottomColor: color.border,
        borderBottomWidth: 1,
        padding: spacing[4]
      }}>
        <Text text={moreTitle}/>
        <TouchableOpacity onPress={onPressAll}>
          <Text text={'더보기'} preset="secondaryLabel"/>
        </TouchableOpacity>
      </View>
      {spaces.length > 0
        ? (<FlatGrid
            keyboardShouldPersistTaps="handled"
            data={spaces}
            horizontal={true}
            style={{ height: 150 }}
            spacing={spacing[4]}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => (
              <SpaceBox
                space={item}
                refetchSpaces={onRefreshSpaces}
              />
            )}
        />
          )
        : (<></>)}
    </View>
  )
})
