/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import { View, Image } from "react-native"
import { observer } from "mobx-react-lite"
import { color } from "../../theme"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"

export interface ProfileAvatarProps {
  avatar: string
  size: number
  isMaster?: boolean
  isNotification?: boolean
}

const logo = require("../../../assets/images/logo-small.png")

export const ProfileAvatar = observer(function ProfileAvatar(props: ProfileAvatarProps) {
  const { avatar, size, isMaster = false, isNotification = false } = props
  const [loading, setLoading] = useState(false)

  const NotificationAvatar = () => {
    if (isNotification) {
      return (
        <Image
          source={logo}
          style={{
            backgroundColor: color.background,
            width: size,
            height: size,
            borderColor: color.text,
            borderRadius: size,
            borderWidth: 1,
          }}
        />
      )
    } else {
      return <MaterialIcons name="account-circle" size={size} color={color.text} />
    }
  }

  return (
    <View>
      {avatar === null || avatar === "" ? (
        <NotificationAvatar />
      ) : (
        <View>
          <Image
            source={{ uri: avatar }}
            style={{
              backgroundColor: color.background,
              width: size,
              height: size,
              borderColor: color.text,
              borderRadius: size,
              borderWidth: 1,
            }}
            onLoadStart={() => setLoading(true)}
            onLoadEnd={() => setLoading(false)}
            loadingIndicatorSource={{ uri: avatar }}
          />
          {isMaster && (
            <View
              style={{
                position: "absolute",
                backgroundColor: color.background,
                borderRadius: 12,
                left: 0,
                top: 0,
              }}
            >
              <MaterialIcons name="check-circle-outline" size={18} color={color.blue} />
            </View>
          )}
        </View>
      )}
    </View>
  )
})
