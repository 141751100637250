import App from "./app/app.tsx"
import React from "react"
import { registerRootComponent } from "expo"
import { Platform } from "react-native"
import { PaperProvider } from "react-native-paper"
import * as SplashScreen from "expo-splash-screen"

SplashScreen.preventAutoHideAsync()

function IgniteApp() {
  return (
    <PaperProvider>
      <App hideSplashScreen={SplashScreen.hideAsync} />
    </PaperProvider>
  )
}

if (Platform.OS !== "web") {
  registerRootComponent(IgniteApp)
}

export default IgniteApp
