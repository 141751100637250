/* eslint-disable no-use-before-define */
import * as React from 'react'
import { View, TouchableOpacity, Image, Linking } from 'react-native'
import { observer } from 'mobx-react-lite'
import { color, spacing } from '../../theme'
import { Text } from '../text/text'

export interface HomeFooterProps {
  onTermsPress?(): void
  onPrivacyPress?(): void
}

const appLogo = require('../../../assets/images/logo-small.png')

export const HomeFooter = observer(function HomeFooter(props: HomeFooterProps) {
  const { onTermsPress, onPrivacyPress } = props

  return (
    <View
      style={{
        padding: spacing[4],
        backgroundColor: '#2e2e2e',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <View style={{}}>
        <View style={{ flexDirection: 'row', marginBottom: spacing[4] }}>
          <TouchableOpacity onPress={onTermsPress}>
            <Text text={'이용 약관'} preset="bold" style={{ color: color.background }} />
          </TouchableOpacity>
          <TouchableOpacity onPress={onPrivacyPress}>
            <Text
              text={'개인정보 처리방침'}
              preset="bold"
              style={{ color: color.background, marginLeft: spacing[6] }}
            />
          </TouchableOpacity>
        </View>
        <Text>
          <Text text={'대표이사: '} preset="small" style={{ color: color.background }} />
          <Text text={'고승완'} preset="small" style={{ color: color.background }} />
        </Text>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Text text={'고객센터: '} preset="small" style={{ color: color.background }} />
          <TouchableOpacity
            onPress={() => {
              if (Linking.canOpenURL('mailto:sotong@etomato.com')) {
                Linking.openURL('mailto:sotong@etomato.com')
              }
            }}
          >
            <Text text={'sotong@etomato.com'} style={{ color: color.background }} />
          </TouchableOpacity>
        </View>
        <Text>
          <Text text={'주소: '} preset="small" style={{ color: color.background }} />
          <Text
            text={'서울특별시 마포구 양화진 4길 32 이토마토 빌딩'}
            preset="small"
            style={{ color: color.background }}
          />
        </Text>
      </View>
      <View>
        <Image
          source={appLogo}
          style={{
            width: 54,
            height: 54,
          }}
        />
      </View>
    </View>
  )
})
