/* eslint-disable no-use-before-define */
import * as React from "react"
import { StyleProp, View, ViewStyle, Image, Alert } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { User } from "../../models/user/user"
import { TouchableOpacity } from "react-native-gesture-handler"
import { ProfileAvatar } from "../profile-avatar/profile-avatar"
import { Text } from "../text/text"
import { Rating } from "react-native-ratings"
import { useStores } from "../../models"
import { navigationRef } from "app/navigators"

const CONTAINER: ViewStyle = {
  backgroundColor: "#F1F1F1",
  borderRadius: spacing[4],
  marginHorizontal: spacing[4],
  marginVertical: spacing[2],
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
}

export interface MasterBoxProps {
  style?: StyleProp<ViewStyle>
  master: User
  onPress: () => void
  rank?: number
}

const placeHolder = require("../../../assets/images/place-holder.png")

const rankBox = (rank) => {
  if (rank === 1) {
    return (
      <View
        style={{ padding: spacing[1], backgroundColor: color.orange, borderRadius: spacing[1] }}
      >
        <Text text={`${rank}위`} />
      </View>
    )
  } else {
    return (
      <View
        style={{ padding: spacing[1], backgroundColor: color.background, borderRadius: spacing[1] }}
      >
        <Text text={`${rank}위`} />
      </View>
    )
  }
}

export const MasterBox = observer(function MasterBox(props: MasterBoxProps) {
  const { style, master, onPress, rank } = props
  const styles = Object.assign({}, CONTAINER, style)
  const { categoryStore, authStore } = useStores()
  const selectedCategory = categoryStore
    .getMasterCategories()
    .find((category) => category.category_id === master.master_category)

  return (
    <View style={styles}>
      <View style={{ flexDirection: "row" }}>
        <ProfileAvatar avatar={master.avatar_render || ""} size={64} isMaster={true} />
        <View style={{ flex: 1, justifyContent: "space-around", marginLeft: spacing[4] }}>
          <View
            style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
          >
            <Text text={master.nick_name || ""} preset="header1" />
            {rank && rankBox(rank)}
          </View>
          <Text text={master.intro || ""} numberOfLines={1} />
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginTop: spacing[2],
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
          <Text text={`${Math.round(master.ratings)} / 5`} preset="bold" />
          <Text
            text={`(${master.count})`}
            preset="secondaryLabel"
            style={{ marginHorizontal: spacing[2] }}
          />
          <Rating
            imageSize={24}
            readonly={true}
            startingValue={Math.round(master.ratings)}
            tintColor={"#F1F1F1"}
          />
        </View>
        <TouchableOpacity
          onPress={() => {
            if (authStore.isAuthenticated) {
              authStore.setToReviewTab(true)
              onPress()
            } else {
              if (confirm("해당 기능 사용을 위해 로그인이 필요합니다.")) {
                navigationRef.navigate("auth-stack")
              }
            }
          }}
        >
          <Text text={"더보기"} />
        </TouchableOpacity>
      </View>
      {master.last_review && (
        <View
          style={{
            backgroundColor: color.background,
            borderRadius: spacing[4],
            flexDirection: "row",
            marginTop: spacing[4],
            padding: spacing[2],
          }}
        >
          {selectedCategory && selectedCategory.thumb ? (
            <Image source={{ uri: selectedCategory.thumb }} style={{ width: 24, height: 24 }} />
          ) : (
            <Image source={placeHolder} style={{ width: 24, height: 24 }} />
          )}
          <Text text={master.last_review || ""} style={{ marginLeft: spacing[2], flex: 1 }} />
        </View>
      )}
    </View>
  )
})
