/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import { StyleProp, View, ViewStyle, TouchableOpacity, Image } from "react-native"
import { observer } from "mobx-react-lite"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { User } from "../../models/user/user"
import { ProfileAvatar } from "../profile-avatar/profile-avatar"
import { useStores } from "../../models"
import { navigationRef } from "app/navigators"

const placeHolder = require("../../../assets/images/place-holder.png")

const CONTAINER: ViewStyle = {
  flex: 1,
  flexDirection: "row",
  marginVertical: spacing[1],
  marginHorizontal: spacing[4],
  backgroundColor: color.border,
  padding: spacing[2],
  borderRadius: spacing[2],
}

export interface GeneralUserBoxProps {
  user: User
  style?: StyleProp<ViewStyle>
  avatarSize?: number
  onPress?: () => void
  needUnfollow?: boolean
  onAfterUnfollow?: () => void
  needDelete?: boolean
  onAfterDelete?: () => void
}

export const GeneralUserBox = observer(function GeneralUserBox(props: GeneralUserBoxProps) {
  const {
    user,
    style,
    avatarSize = 48,
    needUnfollow = false,
    needDelete = false,
    onPress,
    onAfterUnfollow,
    onAfterDelete,
  } = props
  const styles = Object.assign({}, CONTAINER, style)
  const { authStore, categoryStore } = useStores()
  const [updating, setUpdating] = useState(false)
  const [showUnfollow, setShowUnfollow] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const handleUnFollow = async () => {
    setUpdating(true)
    await authStore.userUnfollow(user.account_id as string)
    if (onAfterUnfollow) {
      onAfterUnfollow()
    }
    setUpdating(false)
  }

  const handleUnLike = async () => {
    setUpdating(true)
    await authStore.userUnLike(user.account_id as string)
    if (onAfterDelete) {
      onAfterDelete()
    }
    setUpdating(false)
  }

  React.useEffect(() => {
    return () => {
      setUpdating(false)
    }
  }, [])

  return (
    <TouchableOpacity
      style={{ flex: 1 }}
      onPress={() => {
        if (authStore.isAuthenticated) {
          onPress()
        } else {
          if (confirm("해당 기능 사용을 위해 로그인이 필요합니다.")) {
            navigationRef.navigate("auth-stack")
          }
        }
      }}
      onLongPress={() => {
        if (needUnfollow) {
          setShowUnfollow(!showUnfollow)
        }
        if (needDelete) {
          setShowDelete(!showDelete)
        }
      }}
    >
      <View style={styles}>
        <ProfileAvatar
          avatar={user.avatar_render || ""}
          size={avatarSize}
          isMaster={user.is_master as boolean}
        />
        <View style={{ flex: 1, justifyContent: "center", marginHorizontal: spacing[4] }}>
          <Text text={user.nick_name || ""} preset="bold" numberOfLines={1} />
          <Text
            text={user.intro?.includes("/") ? user.intro?.split("/")[0] : user.intro || ""}
            preset="secondary"
            numberOfLines={1}
          />
        </View>
        <View
          style={{ flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}
        >
          {user.interests?.length !== 0 && !showUnfollow && !showDelete ? (
            user.interests?.map((item) => {
              const selectedCategory = categoryStore.categories.find(
                (category) => category.category_id === item,
              )
              if (selectedCategory !== undefined) {
                return (
                  <View key={item} style={{ marginLeft: spacing[1] }}>
                    {selectedCategory && selectedCategory.thumb ? (
                      <Image
                        source={{ uri: selectedCategory.thumb }}
                        style={{ width: 24, height: 24 }}
                      />
                    ) : (
                      <Image source={placeHolder} style={{ width: 24, height: 24 }} />
                    )}
                  </View>
                )
              } else {
                return <View key={item}></View>
              }
            })
          ) : (
            <></>
          )}
          {showUnfollow && (
            <TouchableOpacity
              style={{ alignItems: "center" }}
              onPress={handleUnFollow}
              onLongPress={() => {
                setShowUnfollow(!showUnfollow)
              }}
              disabled={updating}
            >
              <MaterialIcons name="close" size={24} color={color.primary} />
              <Text text={"팔로잉"} style={{ color: color.primary }} preset="bold" />
            </TouchableOpacity>
          )}
          {showDelete && (
            <TouchableOpacity
              style={{ alignItems: "center" }}
              onPress={handleUnLike}
              onLongPress={() => {
                setShowDelete(!showDelete)
              }}
              disabled={updating}
            >
              <MaterialCommunityIcons name="heart" size={32} color={color.primary} />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
})
