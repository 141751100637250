/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect, useCallback, useContext } from "react"
import { observer } from "mobx-react-lite"
import { View, ActivityIndicator } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Header, Text } from "../../components"
import { spacing, color } from "../../theme"
import { useStores } from "../../models"
import {
  GiftedChat,
  InputToolbar,
  Actions,
  Send,
  Bubble,
  Message,
  MessageText,
  MessageImage,
} from "react-native-gifted-chat"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { utcToKrTime } from "../../utils/date_time"
import { ChatContext } from "app/services/stream/chat-context"

export const ChatScreen: FC<StackScreenProps<NavigatorParamList, "chat">> = observer(
  ({ route, navigation }) => {
    const { accountId } = route.params
    const goBack = () => navigation.goBack()
    const { authStore } = useStores()
    const [messages, setMessages] = useState([])
    const [sending, setSending] = useState<boolean>(false)
    const [peerProfileId, setPeerProfileId] = useState("")
    const [peerName, setPeerName] = useState("")
    const [peerAvatarRender, setPeerAvatarRender] = useState("")
    const { channel } = useContext(ChatContext)

    const asyncForEach = async (array, callback) => {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    }

    const getMessage = ({ id, user, text, created_at, attachments }) => {
      if (attachments && attachments.length > 0) {
        const data = {
          _id: id,
          text: text,
          createdAt: new Date(created_at),
          user: {
            _id: user.id,
            name: user.name,
          },
          image: attachments[0].asset_url,
        }

        return {
          message: data,
        }
      } else {
        const data = {
          _id: id,
          text: text,
          createdAt: new Date(created_at),
          user: {
            _id: user.id,
            name: user.name,
          },
        }

        return {
          message: data,
        }
      }
    }

    const chooseImageFromLibrary = () => {
      let input = document.createElement("input")
      input.type = "file"
      input.onchange = (_) => {
        let files = Array.from(input.files)
        if (files.length === 1 && files[0].type.includes("image")) {
          setSending(true)
          channel
            .sendImage(files[0])
            .then((result) => {
              channel?.sendMessage({
                text: "",
                attachments: [
                  {
                    type: "image",
                    asset_url: result.file,
                  },
                ],
              })
              setSending(false)
            })
            .catch(() => {
              setSending(false)
            })
        }
      }
      input.click()
    }

    const renderCustomView = (props) => {
      return (
        <Text
          text={utcToKrTime(props.currentMessage.createdAt)}
          preset={
            props.currentMessage.user._id === authStore.profile?.account_id
              ? "smallRight"
              : "smallLef"
          }
        />
      )
    }

    const onSend = useCallback(async (messages = []) => {
      if (messages.length > 0 && channel !== undefined) {
        if (messages[0].text.trim().length > 0) {
          setSending(true)
          await channel.sendMessage({ text: messages[0].text })
          setSending(false)
        }
      }
    }, [])

    const renderBubble = (props) => {
      return (
        <Bubble
          {...props}
          containerStyle={{}}
          wrapperStyle={{
            left: { backgroundColor: color.transparent },
            right: { backgroundColor: color.transparent },
          }}
          bottomContainerStyle={{}}
          tickStyle={{}}
          usernameStyle={{}}
          containerToNextStyle={{}}
          containerToPreviousStyle={{}}
        />
      )
    }

    const renderMessage = (props) => (
      <Message
        {...props}
        containerStyle={{
          left: { margin: spacing[1] },
          right: { margin: spacing[1] },
        }}
      />
    )

    const renderMessageText = (props) => (
      <MessageText
        {...props}
        containerStyle={{
          left: {
            backgroundColor: color.leftBubble,
            borderRadius: spacing[5],
            padding: spacing[1],
            alignItems: "flex-start",
            alignSelf: "flex-start",
          },
          right: {
            backgroundColor: color.rightBubble,
            borderRadius: spacing[5],
            padding: spacing[1],
            alignItems: "flex-end",
            alignSelf: "flex-end",
          },
        }}
        textStyle={{
          left: { color: color.leftText },
          right: { color: color.rightText },
        }}
        linkStyle={{
          left: { color: color.orange },
          right: { color: color.orange },
        }}
      />
    )

    const renderMessageImage = (props) => (
      <MessageImage {...props} imageStyle={{ resizeMode: "contain" }} />
    )

    const renderInputToolbar = (props) => (
      <InputToolbar
        {...props}
        containerStyle={{ borderTopWidth: 0 }}
        primaryStyle={{ borderRadius: 8, borderWidth: 2, marginHorizontal: 8 }}
      />
    )

    const renderActions = (props) => {
      if (sending) {
        return null
      } else {
        return (
          <Actions
            {...props}
            containerStyle={{
              alignItems: "center",
              justifyContent: "center",
            }}
            icon={() => <MaterialIcons name="attach-file" size={24} color={color.text} />}
            options={{
              "사진 라이브러리": async () => {
                chooseImageFromLibrary()
              },
              Cancel: () => {},
            }}
          />
        )
      }
    }

    const renderSend = (props) => (
      <Send
        {...props}
        disabled={!props.text || sending}
        containerStyle={{
          alignItems: "center",
          justifyContent: "center",
          marginHorizontal: spacing[1],
        }}
        alwaysShowSend={true}
      >
        {!sending ? (
          <MaterialIcons name="send" color={color.text} size={32} />
        ) : (
          <ActivityIndicator style={{ flex: 1, justifyContent: "center", alignItems: "center" }} />
        )}
      </Send>
    )

    useEffect(() => {
      if (channel === undefined) goBack()
      const setupInitialMessages = async () => {
        if (channel !== undefined) {
          setSending(true)
          await channel.stopWatching()
          const channelState = await channel.watch({ state: true, presence: true })
          if (channelState) {
            await asyncForEach(channelState.messages, async (msg) => {
              const { message } = getMessage(msg)
              setMessages((previousMessages) => GiftedChat.append(previousMessages, message as any))
            })

            channel.on("message.new", (event) => {
              if (channel.initialized && authStore.isChatClientReady) {
                const { message } = getMessage(event.message as any)
                setMessages((previousMessages) =>
                  GiftedChat.append(previousMessages, message as any),
                )
              }
            })
          }
          setSending(false)
        }
      }

      const fetchPeerInfo = async () => {
        try {
          const peer = await authStore.fetchUserProfileByAccountId(accountId)
          setPeerProfileId(peer.profile_id)
          setPeerName(peer.nick_name)
          setPeerAvatarRender(peer.avatar_render)
        } catch (error) {
          setPeerProfileId("")
          setPeerName("소통")
          setPeerAvatarRender(
            "https://raw.githubusercontent.com/danielwang24139212/fonts/master/logo-small.png",
          )
        }
      }

      if (accountId) {
        fetchPeerInfo()
      }
      setMessages([])
      setupInitialMessages()

      return () => {
        setMessages([])
        setSending(false)
      }
    }, [])

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <View
          style={{
            justifyContent: "flex-start",
            alignItems: "stretch",
            height: "100%",
            width: "100%",
            paddingTop: useSafeAreaInsets().top,
          }}
        >
          <Header
            leftIcon="back"
            onLeftPress={goBack}
            style={{ paddingHorizontal: spacing[4] }}
            headerType={"userChatProfile"}
            peerAvatarRender={peerAvatarRender}
            peerName={peerName}
            onPeerPress={() => {
              if (peerProfileId && accountId) {
                navigation.navigate("userDetail", {
                  profileId: peerProfileId,
                  accountId: accountId,
                } as never)
              }
            }}
          />
          {authStore.isChatClientReady ? (
            <GiftedChat
              messages={messages}
              onSend={(messages) => onSend(messages)}
              user={{
                _id: authStore?.profile?.account_id || "",
                name: authStore?.profile?.nick_name || "",
              }}
              placeholder={"메시지를 입력하세요"}
              alwaysShowSend={true}
              showAvatarForEveryMessage={true}
              isLoadingEarlier={true}
              renderAvatar={() => {
                return null
              }}
              renderInputToolbar={renderInputToolbar}
              renderActions={renderActions}
              renderSend={renderSend}
              renderBubble={renderBubble}
              renderMessage={renderMessage}
              renderMessageText={renderMessageText}
              renderCustomView={renderCustomView}
              renderMessageImage={renderMessageImage}
              onLongPress={() => {
                return null
              }}
              renderDay={() => {
                return null
              }}
              renderTime={() => {
                return null
              }}
              keyboardShouldPersistTaps="never"
            />
          ) : (
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <Text text="로딩중..." />
            </View>
          )}
        </View>
      </View>
    )
  },
)
