/* eslint-disable camelcase */
import { flow, Instance, types } from "mobx-state-tree"
import { QueryApi } from "../../services/api/query-api"
import { api } from "app/services/api"

export const QueryStoreModel = types
  .model("QueryStore")
  .props({})
  .actions((self) => ({
    addQuery: flow(function* (token: string, payload: any) {
      const notificationApi = new QueryApi(api)
      const result = yield notificationApi.createQuery(token, payload)

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),

    fetchQueries: flow(function* (token: string) {
      const notificationApi = new QueryApi(api)
      const result = yield notificationApi.fetchQueries(token)

      if (result.kind === "ok") {
        return result.queries
      } else {
        return false
      }
    }),
  }))

type QueryStoreType = Instance<typeof QueryStoreModel>
export interface NotificationStore extends QueryStoreType {}
