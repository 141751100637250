import { Instance, types, SnapshotOut } from 'mobx-state-tree'

export const CareerModel = types
  .model('Career')
  .props({
    careerId: types.identifier,
    title: types.maybeNull(types.string),
    titleAndPosition: types.maybeNull(types.string),
    startYear: types.maybeNull(types.string),
    endYear: types.maybeNull(types.string)
  })

type CareerType = Instance<typeof CareerModel>
export interface Career extends CareerType {}
type CareerSnapshotType = SnapshotOut<typeof CareerModel>
export interface CareerSnapshot extends CareerSnapshotType {}
