/* eslint-disable no-use-before-define */
import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { View, Image } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { NavigatorParamList } from '../../navigators'
import { Button, Screen, Text } from '../../components'
import { color, spacing } from '../../theme'
import { useStores } from '../../models'

const appLogo = require('../../../assets/images/logo-small.png')

export const RegisterSuccessScreen: FC<StackScreenProps<NavigatorParamList, 'registerSuccess'>> =
  observer(({ route }) => {
    const { authStore } = useStores()
    const { phone, password, nation } = route.params
    const handleSotongLogin = async () => {
      await authStore.login(phone, password, nation)
    }

    return (
      <Screen
        style={{
          justifyContent: 'center',
          backgroundColor: color.transparent,
          paddingHorizontal: spacing[6],
        }}
        preset="fixed"
      >
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Image source={appLogo} style={{ width: 96, height: 96 }} />
          <Text text="회원가입이 완료되었습니다." />
        </View>
        <View style={{ flex: 1 }}>
          <Button
            text={authStore.status === 'pending' ? '로딩중...' : '프로필 만들러가기'}
            onPress={handleSotongLogin}
            style={{ marginTop: spacing[8] }}
            disabled={authStore.status === 'pending'}
          />
        </View>
      </Screen>
    )
  })
