import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"

const firebaseConfig = {
  apiKey: "AIzaSyB2VSxse9kAKqDK5_Mq26JTApWi74uPhXc",
  authDomain: "sotong-d5498.firebaseapp.com",
  projectId: "sotong-d5498",
  storageBucket: "sotong-d5498.appspot.com",
  messagingSenderId: "723640646653",
  appId: "1:723640646653:web:d28f98aacf0d261138f794",
  measurementId: "G-P0Z0F14QJG",
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export const requestForToken = async () => {
  return getToken(messaging, {
    vapidKey: `BPwn4GgxoZV8pRDwg4aojWFMjfcK-KPEPVFXoYCwaBejCS0z5rxHNpXqCvbvbeMS2WLeZN34-u6RHOpyX4eQfyg`,
  })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
