import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { FaqApi } from "../../services/api/faq-api"
import { FaqModel, FaqSnapshot } from "../faq/faq"
import { api } from "app/services/api"

export const FaqStoreModel = types
  .model("FaqStore")
  .props({
    faqs: types.optional(types.array(FaqModel), []),
  })
  .actions((self) => ({
    saveFaqs: (faqSnapshots: FaqSnapshot[]) => {
      self.faqs.replace(faqSnapshots)
    },
  }))
  .actions((self) => ({
    fetchFaqs: flow(function* (token: string) {
      const faqApi = new FaqApi(api)
      const result = yield faqApi.getFaqs(token)

      if (result.kind === "ok") {
        self.saveFaqs(result.faqs)
      } else {
        if (result.kind === "not-found") {
          self.faqs.clear()
        }
      }
    }),
  }))

type FaqStoreType = Instance<typeof FaqStoreModel>
export interface FaqStore extends FaqStoreType {}
type FaqStoreSnapshotType = SnapshotOut<typeof FaqStoreModel>
export interface FaqStoreSnapshot extends FaqStoreSnapshotType {}
