/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { CategoryApi } from "../../services/api/category-api"
import { CategoryModel, CategorySnapshot } from "../category/category"
import { api } from "app/services/api"

export const CategoryStoreModel = types
  .model("CategoryStore")
  .props({
    categories: types.optional(types.array(CategoryModel), []),
  })
  .views((self) => ({
    getCategoryNameFromId: (category_id: string) =>
      self.categories.find((category) => category.category_id === category_id)?.name,
    getCategory: (category_id: string) =>
      self.categories.find((category) => category.category_id === category_id),
    getMasterCategories: () => self.categories.filter((category) => category.type === "master"),
    getCommunityCategories: () =>
      self.categories.filter((category) => category.type === "community"),
  }))
  .actions((self) => ({
    saveCategories: (categorySnapshots: CategorySnapshot[]) => {
      self.categories.replace(categorySnapshots)
    },
  }))
  .actions((self) => ({
    fetchCategories: flow(function* () {
      const categoryApi = new CategoryApi(api)
      const result = yield categoryApi.getCategories()

      if (result.kind === "ok") {
        self.saveCategories(result.categories)
      } else {
        if (result.kind === "not-found") {
          self.categories.clear()
        }
      }
    }),
  }))

type CategoryStoreType = Instance<typeof CategoryStoreModel>
export interface CategoryStore extends CategoryStoreType {}
type CategoryStoreSnapshotType = SnapshotOut<typeof CategoryStoreModel>
export interface CategoryStoreSnapshot extends CategoryStoreSnapshotType {}
