/* eslint-disable no-use-before-define */
import * as React from "react"
import { StyleProp, View, ViewStyle, Image, ImageStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { useStores } from "../../models"
import { Button } from "../button/button"
import { Transaction } from "../../models/transaction/transaction"
import Toast from "react-native-toast-message"
import moment from "moment"
import "moment/locale/ko"

const CONTAINER: ViewStyle = {
  backgroundColor: "#F1F1F1",
  borderRadius: spacing[4],
  marginVertical: spacing[2],
  marginHorizontal: spacing[4],
  padding: spacing[4],
}
const CATEGORY_IMAGE: ImageStyle = {
  width: 48,
  height: 48,
  marginBottom: spacing[1],
}

export interface TransactionBoxProps {
  style?: StyleProp<ViewStyle>
  transaction: Transaction
  onPressReview: () => void
  afterCancel: () => void
}

const placeHolder = require("../../../assets/images/place-holder.png")

export const TransactionBox = observer(function TransactionBox(props: TransactionBoxProps) {
  const { style, transaction, onPressReview, afterCancel } = props
  const styles = Object.assign({}, CONTAINER, style)
  const { categoryStore, transactionStore, authStore } = useStores()
  const category = categoryStore.getCategory(transaction?.counseling_category as string)
  const [loading, setLoading] = React.useState(false)

  const status = () => {
    if (transaction?.status === "accepted" && !transaction?.expired && !transaction.is_reviewed) {
      return { disabled: true, text: "상담예정" }
    } else {
      if (transaction.is_reviewed) {
        return { disabled: true, text: "작성완료" }
      } else {
        return { disabled: false, text: "리뷰쓰기" }
      }
    }
  }

  const backgroundColor = () => {
    switch (status().text) {
      case "상담예정":
        return color.orange
      case "작성완료":
        return color.background
      default:
        return color.primary
    }
  }

  const textColor = () => {
    if (status().disabled) {
      return color.text
    } else {
      return color.background
    }
  }

  const handleCancelTransaction = async () => {
    setLoading(true)
    transactionStore
      .updateTransaction(authStore.token, transaction.transaction_id, {
        status: "canceled",
      })
      .then((result) => {
        setLoading(false)
        if (result === true) {
          alert(
            "환불 신청이 완료되었습니다.\n2-3 영업일 내에 회원님의\n통통지갑으로 환불됩니다.\n(수수료 제외)",
          )
          afterCancel()
        } else {
          Toast.show({
            type: "error",
            text1: "상담 취소에 실패했습니다.",
            position: "bottom",
          })
        }
      })
      .catch((error) => {
        Toast.show({
          type: "error",
          text1: "상담 취소에 실패했습니다.",
          position: "bottom",
        })
        setLoading(false)
        console.log(error)
      })
  }

  const handleOnPressBox = () => {
    if (status().text === "상담예정" && !authStore.profile?.is_master) {
      const diffInHours = moment(transaction.start_time).diff(moment(), "hours")
      if (diffInHours > 1) {
        if (
          confirm(
            `예약된 상담을 취소할까요?\n${moment(transaction.start_time).format(
              "M월 D일 a hh시 mm분",
            )}\n${transaction.master_name} 마스터`,
          )
        ) {
          handleCancelTransaction()
        }
      } else {
        alert(
          `1시간 이내에 진행 예정인 상담은 취소가 불가능합니다.\n${moment(
            transaction.start_time,
          ).format("M월 D일 a hh시 mm분")}\n${transaction.master_name} 마스터`,
        )
      }
    }
  }

  return (
    <TouchableOpacity style={styles} onPress={handleOnPressBox} disabled={loading}>
      <View
        style={{ flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}
      >
        {category?.thumb ? (
          <Image source={{ uri: category?.thumb || "" }} style={CATEGORY_IMAGE} />
        ) : (
          <Image source={placeHolder} style={CATEGORY_IMAGE} />
        )}
        <Text
          text={transaction.counseling_title as string}
          numberOfLines={2}
          style={{ marginLeft: spacing[4], flex: 1 }}
        />
      </View>
      <View
        style={{ flexDirection: "row", alignItems: "flex-end", justifyContent: "space-between" }}
      >
        <Text text={`${Math.round(transaction.ratings || 0)} / 5 (${transaction.count || 0})`} />
        <Button
          onPress={() => {
            if (!status().disabled) onPressReview()
          }}
          text={status().text}
          style={{
            borderRadius: spacing[4],
            backgroundColor: backgroundColor(),
          }}
          textStyle={{
            color: textColor(),
          }}
          disabled={status().disabled}
        />
      </View>
    </TouchableOpacity>
  )
})
