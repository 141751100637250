import { Instance, types, SnapshotOut } from 'mobx-state-tree'

export const EducationModel = types
  .model('Education')
  .props({
    educationId: types.identifier,
    school: types.maybeNull(types.string),
    majorAndDegree: types.maybeNull(types.string),
    startYear: types.maybeNull(types.string),
    endYear: types.maybeNull(types.string)
  })

type EducationType = Instance<typeof EducationModel>
export interface Education extends EducationType {}
type EducationSnapshotType = SnapshotOut<typeof EducationModel>
export interface EducationSnapshot extends EducationSnapshotType {}
