/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, TouchableOpacity, Image } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, Text } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import Accordion from "react-native-collapsible/Accordion"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { SCREEN_WIDTH } from "../../utils/screen"
import RenderHTML from "react-native-render-html"

let isMounted = false

export const NoticeScreen: FC<StackScreenProps<NavigatorParamList, "notice">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()
    const { announcementStore, authStore } = useStores()
    const [activeSections, setActivieSections] = useState<[] | null>([])
    const setSections = (sections) => {
      setActivieSections(sections.includes(undefined) ? [] : sections)
    }

    const fetchData = async () => {
      if (isMounted) {
        await announcementStore.fetchAnnouncements()
      }
    }

    useEffect(() => {
      isMounted = true
      fetchData()
      return () => {
        isMounted = false
      }
    }, [])

    const renderAnnouncementListHeader = (section, _, isActive) => {
      return (
        <View>
          <View
            style={{
              padding: spacing[4],
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View style={{ flex: 8, marginRight: spacing[2] }}>
              {section.type === "event" ? (
                <Text text={"[이벤트] " + section.title} />
              ) : (
                <Text text={"[광고] " + section.title} />
              )}
            </View>
            <View>
              {isActive ? (
                <MaterialIcons name="keyboard-arrow-up" size={18} color={color.text} />
              ) : (
                <MaterialIcons name="keyboard-arrow-down" size={18} color={color.text} />
              )}
            </View>
          </View>
          <View style={{ height: 1, backgroundColor: color.border }} />
        </View>
      )
    }

    const renderAnnouncementListContent = (section, _, isActive) => {
      return (
        <View style={{ flex: 1 }}>
          <Image
            source={{ uri: section?.attached || "" }}
            style={{
              height: (SCREEN_WIDTH * 3) / 4,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <RenderHTML contentWidth={SCREEN_WIDTH} source={{ html: section?.content || "" }} />
        </View>
      )
    }

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <Header leftIcon="back" onLeftPress={goBack} headerText={"공지사항"} />
          <View style={{ flex: 1 }}>
            <View style={{ height: 1, backgroundColor: color.border }} />
            <Accordion
              activeSections={activeSections || []}
              sections={announcementStore.announcements.map((announcement) => ({
                title: announcement.title,
                content: announcement.content,
                type: announcement.type,
                attached: announcement.attached,
              }))}
              touchableComponent={TouchableOpacity}
              expandMultiple={false}
              renderHeader={renderAnnouncementListHeader}
              renderContent={renderAnnouncementListContent}
              duration={400}
              onChange={setSections}
              renderAsFlatList={true}
            />
          </View>
        </Screen>
      </View>
    )
  },
)
