/* eslint-disable camelcase */
import { ApiResponse } from "apisauce"
import { UserSnapshot } from "../../models/user/user"
import { Api } from "./api"
import { getGeneralApiProblem } from "./api-problem"
import { GetUserResult, GetUsersResult, GetFollowResult } from "./api.types"
import { convertUser } from "./convert"

export class UserApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async userFollow(token: string, account_id: string): Promise<GetFollowResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_followers/follow/${account_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok" }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async userLike(token: string, account_id: string): Promise<GetFollowResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_likes/like/${account_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok" }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async userUnLike(token: string, liking_id: string): Promise<GetFollowResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_likes/unlike/${liking_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok" }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async userUnfollow(token: string, account_id: string): Promise<GetFollowResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_followers/unfollow/${account_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok" }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async fetchProfile(token: string, profile_id: string): Promise<GetUserResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_profiles/${profile_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const user = convertUser(raw)

      return { kind: "ok", user: user }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async fetchAccount(token: string, account_id: string): Promise<GetUserResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_profiles/by_account_id/${account_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const user = convertUser(raw)

      return { kind: "ok", user: user }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getUsers(
    limit: number,
    page: number,
    filter: string = "all",
    condition: string = "all",
  ): Promise<GetUsersResult> {
    try {
      // this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_profiles", {
        limit: limit,
        offset: page * limit,
        filter: filter,
        condition: condition,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const users: UserSnapshot[] = raw.map(convertUser)

      return { kind: "ok", users: users }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getUsersByInterests(
    token: string,
    limit: number = 6,
    page: number = 0,
  ): Promise<GetUsersResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/get_sotong_profiles_by_interests/${limit}/${page * limit}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const users: UserSnapshot[] = raw.map(convertUser)

      return { kind: "ok", users: users }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async searchUsers(keyword: string, limit: number = 6, page: number = 0): Promise<GetUsersResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_profiles", {
        keyword: keyword,
        limit: limit,
        offset: page * limit,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const users: UserSnapshot[] = raw.map(convertUser)

      return { kind: "ok", users: users }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getMastersByCategory(
    token: string,
    category: string,
    limit: number,
    page: number,
    condition: string = "all",
  ): Promise<GetUsersResult> {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get("/sotong_profiles", {
        category: category,
        limit: limit,
        offset: page * limit,
        condition: condition,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const users: UserSnapshot[] = raw.map(convertUser)

      return { kind: "ok", users: users }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getFollowers(token: string, limit: number, page: number, accountId: string) {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_followers/get_followers/${accountId}/${limit}/${page * limit}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const users: UserSnapshot[] = raw.map(convertUser)

      return { kind: "ok", users: users }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getFollowings(token: string, limit: number, page: number, accountId: string) {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_followers/get_followings/${accountId}/${limit}/${page * limit}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const users: UserSnapshot[] = raw.map(convertUser)

      return { kind: "ok", users: users }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getUserLikings(token: string, limit: number, page: number, accountId: string) {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_likes/get_likings/${accountId}/${limit}/${page * limit}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const users: UserSnapshot[] = raw.map(convertUser)

      return { kind: "ok", users: users }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getUserLikingsAll(token: string, accountId: string) {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_likes/get_likings_all/${accountId}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const users: UserSnapshot[] = raw.map(convertUser)

      return { kind: "ok", users: users }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async getNotificationSettings(token: string, account_id) {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_notification_settings/${account_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", settings: response.data }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async updateNotificationSettings(token: string, account_id, payload: any) {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.patch(
        `/sotong_notification_settings/${account_id}`,
        JSON.stringify({ notification_setting: payload }),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", settings: response.data }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }

  async fetchEducationsAndCareers(token: string, account_id) {
    try {
      this.api.apisauce.setHeader("Authorization", `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/sotong_profiles/get_educations_careers/${account_id}`,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", educations_results: response.data }
    } catch (error) {
      return { kind: "bad-data" }
    }
  }
}
