/* eslint-disable camelcase */
import { ApiResponse } from 'apisauce'
import { Api } from './api'
import { DeleteNotificationResult, GetNotificationsResult, UpdateNotificationResult } from './api.types'
import { getGeneralApiProblem } from './api-problem'
import { convertNotification } from './convert'
import { NotificationSnapshot } from '../../models/notification/notification'

export class NotificationApi {
  private api: Api

  constructor (api: Api) {
    this.api = api
  }

  async getNotifications (token: string, limit: number = 6, page: number = 0): Promise<GetNotificationsResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get('/sotong_notifications', {
        limit: limit,
        offset: page * limit
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const raw = response.data
      const notifications: NotificationSnapshot[] = raw.map(convertNotification)

      return { kind: 'ok', notifications: notifications }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async deleteNotification (token: string, notification_id: string): Promise<DeleteNotificationResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.delete(`/sotong_notifications/${notification_id}`)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async updateNotification (token: string, notification_id: string): Promise<UpdateNotificationResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.patch(`/sotong_notifications/${notification_id}`)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
