// eslint-disable-next-line no-use-before-define
import * as React from 'react'
import { Text as ReactNativeText } from 'react-native'
import { presets } from './text.presets'
import { TextProps } from './text.props'
import { translate } from '../../i18n'

export function Text (props: TextProps) {
  const { preset = 'default', tx, txOptions, text, children, style: styleOverride, ...rest } = props
  const i18nText = tx && translate(tx, txOptions)
  const content = i18nText || text || children

  const style = presets[preset] || presets.default
  const styles = [style, styleOverride]

  return (
    <ReactNativeText {...rest} style={styles}>
      {content}
    </ReactNativeText>
  )
}
