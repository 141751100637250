export function numberWithCommas(x: number): string {
  try {
    const parts = x.toString().split('.')
    const numberPart = parts[0]
    const decimalPart = parts[1]
    const thousands = /\B(?=(\d{3})+(?!\d))/g
    return numberPart.replace(thousands, ',') + (decimalPart ? '.' + decimalPart : '')
  } catch (error) {
    return '0'
  }
}
