/* eslint-disable no-use-before-define */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, TouchableOpacity, ViewStyle, ScrollView } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, PageHeader, Button, Text } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { TabView } from "react-native-tab-view"
import RenderHTML from "react-native-render-html"
import { SCREEN_WIDTH } from "../../utils/screen"

const TAB_BAR_STYLE_INACTIVE: ViewStyle = {
  flex: 1,
  width: "100%",
  justifyContent: "center",
  borderColor: color.text,
  backgroundColor: color.background,
  borderWidth: 1,
  borderRadius: spacing[4],
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[1],
  marginHorizontal: spacing[1],
}

const TAB_BAR_STYLE_ACTIVE: ViewStyle = {
  flex: 1,
  width: "100%",
  justifyContent: "center",
  borderColor: color.text,
  backgroundColor: color.text,
  borderWidth: 1,
  borderRadius: spacing[4],
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[1],
  marginHorizontal: spacing[1],
}

export const HomeRegistrationHelpScreen: FC<
  StackScreenProps<NavigatorParamList, "homeRegistrationHelp">
> = observer(({ navigation }) => {
  const goBack = () => navigation.goBack()
  const { settingStore } = useStores()
  const [index, setIndex] = useState(0)
  const initialLayout = { width: SCREEN_WIDTH }
  const [routes] = useState([
    { key: "registrationTab", title: "마스터 등록방법" },
    { key: "usageTab", title: "소통 사용방법" },
  ])

  const RegistrationRoute = (jumpTo) => {
    return (
      <ScrollView
        style={{ flex: 1, marginHorizontal: spacing[4] }}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <RenderHTML
          contentWidth={SCREEN_WIDTH}
          source={{ html: settingStore.getMasterRegistrationGuide() }}
        />
      </ScrollView>
    )
  }

  const UsageRoute = (jumpTo) => {
    return (
      <ScrollView
        style={{ flex: 1, marginHorizontal: spacing[4] }}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <RenderHTML
          contentWidth={SCREEN_WIDTH}
          source={{ html: settingStore.getSotongUsageGuide() }}
        />
      </ScrollView>
    )
  }

  const renderScene = ({ route, jumpTo }) => {
    switch (route.key) {
      case "registrationTab":
        return <RegistrationRoute jumpTo={jumpTo} />
      case "usageTab":
        return <UsageRoute jumpTo={jumpTo} />
      default:
        return <RegistrationRoute jumpTo={jumpTo} />
    }
  }

  const handleIndexChange = (index: number) => {
    setIndex(index)
  }

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        <PageHeader />
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: spacing[4],
          }}
        >
          <Button preset="link" onPress={goBack}>
            <MaterialIcons name="arrow-back" size={32} color={color.text} />
          </Button>
          <View style={{ flexDirection: "row", flex: 1, justifyContent: "center" }}>
            <TouchableOpacity
              onPress={() => handleIndexChange(0)}
              style={index === 0 ? TAB_BAR_STYLE_ACTIVE : TAB_BAR_STYLE_INACTIVE}
            >
              <Text
                text={"마스터 등록방법"}
                preset="small"
                numberOfLines={1}
                style={{ color: index === 0 ? color.background : color.text, textAlign: "center" }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => handleIndexChange(1)}
              style={index === 1 ? TAB_BAR_STYLE_ACTIVE : TAB_BAR_STYLE_INACTIVE}
            >
              <Text
                text={"소통 사용방법"}
                preset="small"
                numberOfLines={1}
                style={{ color: index === 1 ? color.background : color.text, textAlign: "center" }}
              />
            </TouchableOpacity>
          </View>
        </View>
        <TabView
          swipeEnabled={true}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          renderTabBar={() => null}
          onIndexChange={handleIndexChange}
          initialLayout={initialLayout}
        />
      </Screen>
    </View>
  )
})
