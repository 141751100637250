/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { TransactionApi } from "../../services/api/transaction-api"
import { api } from "app/services/api"

export const TransactionStoreModel = types
  .model("TransactionStore")
  .props({})
  .views((self) => ({}))
  .actions((self) => ({
    fetchTransactions: flow(function* (token: string, limit: number = 6, page: number = 0) {
      const transactionApi = new TransactionApi(api)
      const result = yield transactionApi.getTransactionHistory(token, limit, page)

      if (result.kind === "ok") {
        return result.transactions
      } else {
        return []
      }
    }),

    addTransaction: flow(function* (token: string, payload: any) {
      const counselingApi = new TransactionApi(api)
      const result = yield counselingApi.addTransaction(token, payload)

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),

    updateTransaction: flow(function* (token: string, transaction_id: string, payload: any) {
      const transactionApi = new TransactionApi(api)
      const result = yield transactionApi.updateTransaction(token, transaction_id, payload)

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),

    fetchReviews: flow(function* (token: string, account_id: string) {
      const transactionApi = new TransactionApi(api)
      const result = yield transactionApi.getReviews(token, account_id)

      if (result.kind === "ok") {
        return result.reviews
      } else {
        return []
      }
    }),

    fetchMyTransactions: flow(function* (token: string) {
      const transactionApi = new TransactionApi(api)
      const result = yield transactionApi.getMyTransactions(token)

      if (result.kind === "ok") {
        return result.transactions
      } else {
        return []
      }
    }),

    fetchMasterHoldTransactions: flow(function* (
      token: string,
      limit: number = 6,
      page: number = 0,
    ) {
      const transactionApi = new TransactionApi(api)
      const result = yield transactionApi.getMasterHoldTransactions(token, limit, page)

      if (result.kind === "ok") {
        return result.transactions
      } else {
        return []
      }
    }),

    fetchMasterCompleteTransactions: flow(function* (
      token: string,
      limit: number = 6,
      page: number = 0,
    ) {
      const transactionApi = new TransactionApi(api)
      const result = yield transactionApi.getMasterCompleteTransactions(token, limit, page)

      if (result.kind === "ok") {
        return result.transactions
      } else {
        return []
      }
    }),
  }))

type TransactionStoreType = Instance<typeof TransactionStoreModel>
export interface TransactionStore extends TransactionStoreType {}
type TransactionStoreSnapshotType = SnapshotOut<typeof TransactionStoreModel>
export interface TransactionStoreSnapshot extends TransactionStoreSnapshotType {}
