/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, ScrollView, TouchableOpacity, Modal } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { Screen, Button, Header, Text } from "../../components"
import { color, spacing } from "../../theme"
import { TabView, TabBar } from "react-native-tab-view"
import { getDayOfWeekIndex } from "../../utils/date_time"
import { SCREEN_WIDTH } from "../../utils/screen"
import { useStores } from "../../models"
import { NavigatorParamList } from "../../navigators"
import { Dialog } from "react-native-paper"
import CalendarPicker from "react-native-calendar-picker"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import Toast from "react-native-toast-message"
import uuid from "react-native-uuid"
import Picker from "rc-picker"
import koKR from "rc-picker/lib/locale/ko_KR"
import generateConfig from "rc-picker/es/generate/moment"
import "rc-picker/assets/index.css"
import moment, { Moment } from "moment"
import "moment/locale/ko"

const timeRoutes = [
  { key: "morningTab", title: "오전" },
  { key: "afternoonTab", title: "오후" },
]

export const BookCounselingScreen: FC<StackScreenProps<NavigatorParamList, "bookCounseling">> =
  observer(({ route, navigation }) => {
    const goBack = () => navigation.goBack()
    const { counseling, master_name } = route.params
    const [day, setDay] = useState<moment.Moment | null>(null)
    const daysArrayIndex = counseling.days?.map((day) => getDayOfWeekIndex(day))
    const [index, setTabIndex] = useState(0)
    const [routes] = useState(timeRoutes)
    const initialLayout = { width: SCREEN_WIDTH }
    const [loading, setLoading] = useState(false)
    const [startTime, setStartTime] = useState<moment.Moment>(moment())
    const [showStartTimePicker, setShowStartTimePicker] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const { authStore, transactionStore } = useStores()
    const [splitTimes, setSplitTimes] = useState(
      counseling.times
        ? counseling.times.map((time) => {
            const start = moment(JSON.parse(time as string).start)
            const end = moment(JSON.parse(time as string).end)
            return {
              uuid: `${uuid.v4()}`,
              start: start.format("hh:mm"),
              end: end.format("hh:mm"),
              selected: false,
              morning: start.format("a") === "오전",
              disabled: false,
              oStart: start,
              oEnd: end,
            }
          })
        : [],
    )

    const onChangeDate = (date: moment.Moment) => {
      setDay(date)
    }

    const onSelectTime = (item) => {
      const raw = [...splitTimes]
      setSplitTimes(
        raw.map((rawItem) => {
          if (rawItem.uuid === item.uuid) {
            if (!rawItem.selected) {
              setStartTime(moment(rawItem.oStart.toDate()))
              setShowStartTimePicker(true)
            }
            return {
              ...rawItem,
              selected: !rawItem.selected,
            }
          } else {
            return {
              ...rawItem,
              selected: false,
              start_time: null,
            }
          }
        }),
      )
    }

    const handleBook = async () => {
      const time = {
        start: splitTimes.filter((time) => time.selected === true)[0].oStart,
        end: splitTimes.filter((time) => time.selected === true)[0].oEnd,
      }

      time.start = moment(day!.format("YYYY-MM-DD") + " " + time.start.format("HH:mm:ss"))
      time.end = moment(day!.format("YYYY-MM-DD") + " " + time.end.format("HH:mm:ss"))

      setLoading(true)
      const result = await transactionStore.addTransaction(authStore.token, {
        buyer_id: authStore.profile?.account_id,
        seller_id: counseling.owner_id,
        counseling_id: counseling.counseling_id,
        status: "booked",
        day: day!.toISOString(),
        time: JSON.stringify(time),
        start_time: moment(day!.format("YYYY-MM-DD") + " " + startTime.format("HH:mm:ss")),
        buyer_name: authStore.profile?.nick_name,
        seller_name: master_name,
        counseling_title: counseling.title,
        counseling_description: counseling.description,
        counseling_period: counseling.period,
        counseling_price: counseling.price,
      })
      setLoading(false)
      if (result) {
        alert(`신청성공\n마스터의 수락시 채팅창을 통해 스페이스와 비밀번호를 알려드립니다.`)
        navigation.goBack()
      } else {
        Toast.show({
          type: "error",
          text1: "상담 신청 실패",
          position: "bottom",
        })
      }
    }

    const MorningRoute = (jumpTo) => {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            paddingVertical: spacing[2],
            paddingHorizontal: spacing[4],
            alignItems: "center",
          }}
        >
          {splitTimes
            ?.filter((item) => item.morning === true)
            .map((item) => {
              return (
                <TouchableOpacity
                  key={item.uuid}
                  style={{
                    width: "100%",
                    marginVertical: spacing[2],
                    padding: spacing[2],
                    borderRadius: spacing[2],
                    backgroundColor: item.selected ? color.primary : color.border,
                  }}
                  onPress={() => onSelectTime(item)}
                >
                  {item.selected ? (
                    <Text
                      text={`${startTime.format("a hh:mm")} 선택 완료`}
                      preset={"boldCenter"}
                      style={{ color: color.background }}
                    />
                  ) : (
                    <Text
                      text={`${item.start} ~ ${item.end}`}
                      preset={"boldCenter"}
                      style={{ color: color.dim }}
                    />
                  )}
                </TouchableOpacity>
              )
            })}
        </ScrollView>
      )
    }

    const AfternoonRoute = (jumpTo) => {
      return (
        <ScrollView contentContainerStyle={{ padding: spacing[4], alignItems: "center" }}>
          {splitTimes
            ?.filter((item) => item.morning === false)
            .map((item) => {
              return (
                <TouchableOpacity
                  key={item.uuid}
                  style={{
                    width: "100%",
                    marginVertical: spacing[2],
                    padding: spacing[2],
                    borderRadius: spacing[2],
                    backgroundColor: item.selected ? color.primary : color.border,
                  }}
                  onPress={() => onSelectTime(item)}
                >
                  {item.selected ? (
                    <Text
                      text={`${startTime.format("a hh:mm")} 선택 완료`}
                      preset={"boldCenter"}
                      style={{ color: color.background }}
                    />
                  ) : (
                    <Text
                      text={`${item.start} ~ ${item.end}`}
                      preset={"boldCenter"}
                      style={{ color: color.dim }}
                    />
                  )}
                </TouchableOpacity>
              )
            })}
        </ScrollView>
      )
    }

    const renderScene = ({ route, jumpTo }) => {
      switch (route.key) {
        case "morningTab":
          return <MorningRoute jumpTo={jumpTo} />
        case "afternoonTab":
          return <AfternoonRoute jumpTo={jumpTo} />
        default:
          return <></>
      }
    }

    const renderTabBar = (props) => (
      <TabBar
        {...props}
        indicatorStyle={{ backgroundColor: color.primary }}
        style={{ backgroundColor: color.background }}
        activeColor={color.primary}
        inactiveColor={color.dim}
        renderIcon={({ route }) => {
          switch (route.key) {
            case "morningTab":
              return <Text text={"오전"} preset={"headerCenter1"} />
            case "afternoonTab":
              return <Text text={"오후"} preset={"headerCenter1"} />
            default:
              return <></>
          }
        }}
        renderLabel={() => <></>}
      />
    )

    const updateSplitTimes = (selectedDuration: any) => {
      const raw = [...splitTimes]
      setSplitTimes(
        raw.map((rawItem) => {
          if (selectedDuration && rawItem.uuid === selectedDuration?.uuid) {
            return {
              ...rawItem,
              selected: true,
            }
          } else {
            return {
              ...rawItem,
              selected: false,
            }
          }
        }),
      )
    }

    return (
      <View style={{ flex: 1, backgroundColor: color.background }}>
        <Screen>
          <Header
            leftIcon="back"
            onLeftPress={goBack}
            questionIcon={true}
            onPressQuestionIcon={() => {
              setShowHelp(true)
            }}
            headerText={"상담 신청하기"}
          />
          <View style={{ height: 1, backgroundColor: color.border }} />
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={{ margin: spacing[4] }}>
              <CalendarPicker
                onDateChange={onChangeDate}
                weekdays={["일", "월", "화", "수", "목", "금", "토"]}
                months={[
                  "1월",
                  "2월",
                  "3월",
                  "4월",
                  "5월",
                  "6월",
                  "7월",
                  "8월",
                  "9월",
                  "10월",
                  "11월",
                  "12월",
                ]}
                previousComponent={
                  <MaterialIcons name="arrow-back-ios" size={24} color={color.text} />
                }
                nextComponent={
                  <MaterialIcons name="arrow-forward-ios" size={24} color={color.text} />
                }
                selectedDayColor={color.primary}
                selectedDayTextColor={color.background}
                disabledDates={(date: moment.Moment) => {
                  return (
                    !daysArrayIndex?.includes(date.day()) || !date.isSameOrAfter(moment(), "day")
                  )
                }}
                width={SCREEN_WIDTH - spacing[4] * 2}
                monthYearHeaderWrapperStyle={{ flexDirection: "row-reverse" }}
                selectMonthTitle={""}
                selectYearTitle={""}
              />
            </View>
            <TabView
              swipeEnabled={true}
              navigationState={{ index, routes }}
              renderScene={renderScene}
              renderTabBar={renderTabBar}
              onIndexChange={setTabIndex}
              initialLayout={initialLayout}
            />
            <View
              style={{ flexDirection: "row", justifyContent: "center", marginVertical: spacing[4] }}
            >
              <Button
                text={"신청완료"}
                onPress={handleBook}
                loading={loading}
                disabled={
                  day === null || splitTimes.filter((time) => time.selected === true).length === 0
                }
                style={{
                  backgroundColor:
                    day === null || splitTimes.filter((time) => time.selected === true).length === 0
                      ? color.border
                      : color.primary,
                }}
                textStyle={{
                  color:
                    day === null || splitTimes.filter((time) => time.selected === true).length === 0
                      ? color.dim
                      : color.background,
                }}
              />
            </View>
          </ScrollView>
        </Screen>
        <Dialog
          visible={showHelp}
          onDismiss={() => {
            setShowHelp(false)
          }}
          style={{ backgroundColor: color.background }}
        >
          <Dialog.Content>
            <Text text={"아래의 경우"} style={{ marginVertical: spacing[2] }} />
            <Text text={"고객센터 메뉴를 통해"} style={{ marginVertical: spacing[2] }} />
            <Text text={"“환불 신청“ 글을 작성하시면"} style={{ marginVertical: spacing[2] }} />
            <Text
              text={"환불 처리가 가능합니다."}
              style={{ marginVertical: spacing[2] }}
              preset={"bold"}
            />
            <View style={{ marginVertical: spacing[2] }} />
            <Text text={"설정된 상담일 후 3일 이내에"} style={{ marginVertical: spacing[2] }} />
            <Text text={"환불신청을 해주셔야 처리가"} style={{ marginVertical: spacing[2] }} />
            <Text text={"가능합니다."} style={{ marginVertical: spacing[2] }} />
            <View style={{ marginVertical: spacing[2] }} />
            <Text
              text={"- 마스터의 미입장으로 상담이 이루어 지지 않은 경우"}
              style={{ marginVertical: spacing[2], alignSelf: "flex-start" }}
            />
            <Text
              text={"- 시스템 오류로 인해 상담이 이뤄지지 않은 경우"}
              style={{ marginVertical: spacing[2], alignSelf: "flex-start" }}
            />
            <Text
              text={"- 이외 협의된 시간 내 상담이 이뤄지지 않은 경우"}
              style={{ marginVertical: spacing[2], alignSelf: "flex-start" }}
            />
            <View style={{ marginVertical: spacing[2] }} />
            <Text text={"※ 상담 시작 1시간 이전"} style={{ marginVertical: spacing[2] }} />
            <Text text={"환불을 요청하는 경우"} style={{ marginVertical: spacing[2] }} />
            <Text text={"(*이 경우 최초 전송 수수료"} style={{ marginVertical: spacing[2] }} />
            <Text text={"제외되며 상담 내역에서 직접"} style={{ marginVertical: spacing[2] }} />
            <Text text={"환불 신청하시면 됩니다.)"} style={{ marginVertical: spacing[2] }} />
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                setShowHelp(false)
              }}
              tx="common.ok"
            />
          </Dialog.Actions>
        </Dialog>
        <Dialog
          visible={showStartTimePicker}
          onDismiss={() => {
            setShowStartTimePicker(false)
          }}
          style={{ backgroundColor: color.background }}
        >
          <Dialog.Title>{"시작시간을 선택해 주세요"}</Dialog.Title>
          <Dialog.Content>
            <Picker<Moment>
              generateConfig={generateConfig}
              locale={koKR}
              picker="time"
              value={startTime}
              onSelect={(newValue: Moment) => {
                setShowStartTimePicker(false)
                const selectedDuration = splitTimes.find((sTime) => sTime.selected)
                if (
                  selectedDuration &&
                  newValue.isBetween(
                    selectedDuration.oStart,
                    selectedDuration.oEnd,
                    undefined,
                    "[]",
                  )
                ) {
                  setStartTime(newValue)
                  updateSplitTimes(selectedDuration as any)
                } else {
                  alert("범위 내의 시간을 선택해 주세요.")
                  updateSplitTimes(null)
                }
              }}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                updateSplitTimes(null)
                setShowStartTimePicker(false)
              }}
            >
              {"취소"}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </View>
    )
  })
