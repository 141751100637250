/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, FlatList, ActivityIndicator } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, Header, PageHeader, Text } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { User } from "../../models/user/user"
import { GeneralUserBox } from "../../components/general-user-box/general-user-box"

let isMounted = false

export const HomeUsersByCategoryScreen: FC<
  StackScreenProps<NavigatorParamList, "homeUsersByCategory">
> = observer(({ navigation }) => {
  const goBack = () => navigation.goBack()
  const { userStore, authStore } = useStores()

  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [noMore, setNoMore] = useState(false)
  let onEndReachedCalledDuringMomentum = false

  const fetchData = async () => {
    if (isMounted) {
      setLoading(true)
      let result = []
      result = await userStore.fetchUsersByInterests(authStore.token, 8, page)
      setNoMore(result.length === 0)
      setUsers(users.concat(result))
      setPage(page + 1)
      setLoading(false)
    }
  }

  useEffect(() => {
    isMounted = true
    fetchData()
    return () => {
      setLoading(false)
      isMounted = false
    }
  }, [])

  const handleLoadMore = () => {
    fetchData()
  }

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        <PageHeader />
        <Header
          leftIcon="back"
          onLeftPress={goBack}
          headerText={`${authStore.profile?.nick_name}님께 추천하는 친구`}
        />
        <View style={{ flex: 1, marginTop: spacing[2] }}>
          <Text
            text={"* 관심 카테고리가 일치하는 친구를 추천드려요"}
            style={{ marginHorizontal: spacing[4], marginBottom: spacing[2] }}
            preset={"warningCenter"}
          />
          <FlatList
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            data={users}
            initialNumToRender={10}
            onMomentumScrollBegin={() => {
              onEndReachedCalledDuringMomentum = false
            }}
            onEndReached={() => {
              if (!onEndReachedCalledDuringMomentum) {
                handleLoadMore()
                onEndReachedCalledDuringMomentum = true
              }
            }}
            onEndReachedThreshold={0.1}
            keyExtractor={(item) => item.profile_id}
            renderItem={({ item }) => (
              <View key={item.profile_id}>
                <GeneralUserBox
                  user={item}
                  onPress={() => {
                    navigation.navigate("userDetail", {
                      profileId: item.profile_id,
                      accountId: item.account_id,
                    })
                  }}
                />
              </View>
            )}
            ListFooterComponent={
              loading && !noMore ? (
                <ActivityIndicator
                  style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
                />
              ) : (
                <></>
              )
            }
          />
        </View>
      </Screen>
    </View>
  )
})
