/* eslint-disable no-use-before-define */
import * as React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import { spacing } from '../../theme'
import { Text } from '../text/text'
import { Transaction } from '../../models/transaction/transaction'
import { ProfileAvatar } from '../profile-avatar/profile-avatar'
import { Icon } from '../icon/icon'
import { numberWithCommas } from '../../utils/number'
import 'moment/locale/ko'
import moment from 'moment'

const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: '#F1F1F1',
  borderRadius: spacing[4],
  marginVertical: spacing[2],
  marginHorizontal: spacing[4],
  padding: spacing[4],
}

export interface TransactionHoldMasterBoxProps {
  style?: StyleProp<ViewStyle>
  transaction: Transaction
}

export const TransactionHoldMasterBox = observer(function TransactionHoldMasterBox(
  props: TransactionHoldMasterBoxProps,
) {
  const { style, transaction } = props
  const styles = Object.assign({}, CONTAINER, style)

  return (
    <View style={styles}>
      <View style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <ProfileAvatar avatar={transaction?.buyer_avatar_render || ''} size={42} isMaster={false} />
        <View style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: spacing[2] }}>
          <View
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text text={transaction?.buyer_name as string} preset={'bold'} />
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Icon
                icon="ttc"
                style={{
                  width: 18,
                  height: 18,
                  margin: 1,
                  marginRight: spacing[1],
                }}
              />
              <Text text={numberWithCommas(transaction?.counseling_price as number)} />
            </View>
          </View>
          <View
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              marginTop: spacing[1],
            }}
          >
            <Text
              text={`신청시간 : ${moment(transaction.start_time).format('M월 D일 a hh:mm')}`}
              style={{ marginVertical: spacing[1] }}
            />
            <Text text={`[${transaction.counseling_title as string}]`} numberOfLines={1} />
          </View>
        </View>
      </View>
    </View>
  )
})
