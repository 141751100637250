module.exports = function () {
  return `
  <!DOCTYPE html>
  <html lang="ko">
  <style>
      body {
          background-color: white;
          color: black;
      }
      
      p {
          font-size: 32pt;
      }
  
      pre {
          font-size: 32pt;
      }
  
      h2 {
          font-size: 36pt;
      }
  </style>
  
  <body>
    <p>소통은 모든 유저가 물리적 한계를 넘어 서로 연결되며 무엇이든 만들어낼 수 있는 플랫폼입니다. 저희가 여러분의 창의성, 표현의 자유, 의미있는 연결을 뒷받침하기 위해 제공하는 수많은 기능을 통해 소통 커뮤니티가 발전할 수 있기를 바랍니다.  소통은 여러분의 플레이는 더 강력하게, 메타버스 세상은 더 가깝게 만들고 있습니다.</p>
    <br>
    <p>한편으로, 여러분에게 안전한 환경을 제공하기 위해서는 단호한 기준을 적용합니다. 소통은 다른 유저의 안전을 침해하는 콘텐츠와 행위를 허용하지 않습니다.</p>
    <br>
    <p>소통 커뮤니티 가이드라인은 다음과 같은 소통의 콘텐츠, 활동, 커뮤니케이션에 적용됩니다: ID, 프로필을 비롯해 소통의 모든 제품과 기능, 그리고 유저들이 접하는 외부 사이트와 외부 링크 저희는 안전성, 포용성, 다양성, 존엄성, 창의성이라는 주요 정책적 가치에 기반해 소통 커뮤니티 가이드라인을 투명하고 공정하며 확고하게 실행하기 위해 최선을 다하고 있습니다. 소통 커뮤니티 가이드라인의 내용과 그 의도를 존중해주시기를 여러분께 부탁드립니다.</p>
    <br>
    <p>커뮤니티 가이드라인은 여기에서 확인할 수 있습니다.</p>
    <br>
    <br>
    <h2>미성년자의 안전</h2>
    <p>소통에게 미성년자의 안전과 보호는 그 무엇보다 중요합니다. 미성년자 안전 정책을 위반하는 것은 소통 커뮤니티 가이드라인을 치명적으로 위반하는 일입니다. 소통은 18세 미만의 미성년자 또는 미성년자로 보이는 누군가를 (디지털로 제작된 소통 아바타 포함) 끌어들인 외설적, 폭력적 콘텐츠를 금지합니다.</p>
    <br>
    <p>저희는 아동 성착취 및 성적학대(CSEA)에 관여하는 계정을 즉시 영구 정지시키며, 아동 성착취, 그루밍, 불법 콘텐츠 사례를 당국에 보고할 것입니다.</p>
    <br>
    <p>또한 저희는 소통 외부에서 미성년자를 상대로 유해한 행위를 했다는 신빙성 있는 증거를 포함해 유저의 커뮤니티 가이드라인 위반 여부를 검토할 것입니다.</p>
    <br>
    <h2>소통이 금지하는 행위:</h2>
    <br>
    <br>
    <p>미성년자를 폭행, 착취, 또는 위험하게 하는 행동을 묘사하는 콘텐츠(애니메이션 또는 디지털로 제작된 미디어 포함)의 게시, 공유, 또는 권유</p>
    <p>어떤 방식으로든 미성년자를 성적으로 취급하거나, 소아 성애를 미화 또는 정상적으로 취급하는 행위</p>
    <p>성적으로 노골적인 대화에 미성년자를 관여시키거나 관여시키려 시도하는 행위</p>
    <p>미성년자를 플랫폼 밖으로 유인하거나 미성년자의 개인정보를 요청하는 행위</p>
    <p>약탈 또는 그루밍에 관여하는 행위</p>
    <p>아동 성착취 자료를 게시하는 외부 사이트의 링크를 게시, 공유, 또는 요청하는 행위</p>
    <p>미성년자를 대상으로 이루어지는 사이버 학교 폭력 혹은 괴롭힘을 무감각하거나 조롱하는 식으로 묘사한 콘텐츠를 공유하는 행위</p>
    <p>미성년자를 대상으로 위험한 행동이나 장난을 조장하는 콘텐츠로써, 미성년자에게 물리적, 정신적, 또는 감정적 피해를 입힐 수 있는 콘텐츠를 공유하는 행위</p>
    <p>규제대상 물품(예: 알코올, 약물, 액상 전자담배, 담배)에 대한 미성년자의 소비 및 소지를 묘사, 홍보, 일반화, 또는 미화하는 행위</p>
    <br>
    <br>
    <h2>성적 행위 및 나체 노출</h2>
    <p>소통은 서로를 존중하며, 포용적이고 안전한 환경을 조성하고자 합니다. 따라서 성적으로 노골적인 콘텐츠는 허용하지 않습니다. 이러한 규정은 현실 세계의 콘텐츠, 디지털 형태의 콘텐츠, 그리고 소통 아바타를 활용한 콘텐츠에 모두 적용됩니다.</p>
    <br>
    <h2>성착취 및 성적 학대</h2>
    <br>
    <p>동의 없는 성행위, 성폭력, 또는 성매매를 홍보, 일반화, 또는 미화하는 콘텐츠의 공유를 엄격하게 금지합니다. 저희는 동의 없이 촬영되고 공유된 성적 콘텐츠를 게시 및 공유하는 모든 계정을 즉각적으로 영구 제재할 것입니다.</p>
    <br>
    <h2>나체 노출과 성인의 성적 행위</h2>
    <br>
    <p>음란물, 성인물, 노골적이거나 성적으로 선정적인 콘텐츠의 게시를 금지합니다. 일부 예술, 교육, 의학 콘텐츠에 한해 예외를 적용할 수 있습니다.</p>
    <br>
    <h2>소통이 금지하는 행위:</h2>
    <br>
    <br>
    <h2>자살 및 자해</h2>
    <p>저희는 유저, 크리에이터, 개발자를 포함한 모든 소통 커뮤니티 구성원의 건강과 건강한 삶에 깊은 관심을 가지고 있습니다. 저희는 정신 건강에 대한 인식을 높이는 일이 얼마나 중요한지 인지하고 있으며, 자해 기도와 관련한 이야기를 나눔으로써 누구나 필요한 지원을 받게끔 도울 수 있다는 것도 압니다.</p>
    <br>
    <p>하지만 자살 또는 자해와 관련된 콘텐츠가 그 콘텐츠를 보는 이들에게 미칠 영향을 고려해, 의도치 않게 자살 또는 자해를 조장할 수 있는 콘텐츠는 제한하고 있습니다.</p>
    <br>
    <p>저희는 자살을 ‘스스로의 생명을 끊는 행위’라고 정의합니다. 자해는 ‘스스로를 해치려는 시도와 섭식 장애를 포함하는 행위’라고 정의합니다.</p>
    <br>
    <p>저희는 노골적인 이미지나 디지털 표현을 포함해, 위험한 행동을 묘사, 조장, 일상화, 또는 미화함으로써 자살, 자해, 또는 장애로 유도할 수 있는 콘텐츠를 허용하지 않습니다. 자살 또는 자해의 피해자 및 생존자를 대상으로 민감하거나 상처를 주는 콘텐츠 또한 허용하지 않습니다.</p>
    <br>
    <p>여러분이 자살, 자해에 대한 생각으로 어려움을 겪고 있거나 자살을 심각하게 고민하는 사람을 알고 있다면, 현지의 긴급 출동 서비스 또는 자살방지기관에 즉각 연락하실 것을 권합니다. 저희 또한 자살 또는 자해가 이루어질 수 있는 긴급 상황의 증거를 확인할 경우, 직접 현지 긴급출동서비스에 알릴 수 있습니다. 유저가 자살 또는 자해를 고려하고 있음을 확인할 경우에는 이들을 저희의 자살/자해 방지 서포트 페이지로 안내할 수 있습니다.</p>
    <br>
    <h2>소통이 금지하는 행위:</h2>
    <br>
    <br>
    <h2>폭력</h2>
    <p>저희에게 소통 커뮤니티를 위해 포용적인 환경을 조성하는 일은 너무나 중요합니다. 이에, 현실 세계에서의 피해를 방지하기 위해 위협이나 폭력을 선동하는 행위는 허용하지 않습니다. 콘텐츠를 보는 누군가에게 충격이나 혐오감을 줄 수 있는 잔혹하고 불쾌한 콘텐츠 또한 허용하지 않습니다.</p>
    <br>
    <h2>폭력 위협 및 폭력 행위</h2>
    <br>
    <p>소통은 폭력 또는 폭력 행위에 대한 위협, 선동, 또는 미화를 허용하지 않습니다. 타인의 안전을 해치거나 위협하는 콘텐츠 또는 행동도 허용하지 않습니다. 현실 세계의 피해로 이어질 수 있는 폭력 위협의 증거를 확인할 경우, 저희가 직접 사법기관에 알릴 수 있습니다.</p>
    <br>
    <h2>폭력적이고, 민감하고, 불쾌한 콘텐츠</h2>
    <br>
    <p>소통은 충격, 잔인함, 극도의 폭력, 심각한 학대, 또는 괴롭힘의 요소가 포함되어 지나치게 유혈이 낭자하거나 불쾌하고 폭력적인 콘텐츠는 허용하지 않습니다.</p>
    <br>
    <h2>소통이 금지하는 행위:</h2>
    <br>
    <br>
    <h2>위험한 행위 및 위험한 조직 결성</h2>
    <p>저희는 소통 안과 밖에서의 커뮤니티 안전을 중시합니다. 따라서 위험한 행위, 극단주의적 이념 및 조직 결성을 조장하는 콘텐츠는 허용하지 않습니다.</p>
    <br>
    <h2>위험한 행위 및 시도</h2>
    <br>
    <p>저희는 심각한 부상, 사망, 또는 재산상의 피해로 이어질 수 있는 위험한 행위(아마추어 스턴트, 위험한 도전, 또는 모험)를 조장, 묘사, 권장, 또는 미화하는 콘텐츠를 허용하지 않습니다. 건강에 상당한 위험을 초래할 가능성이 있는 건강 관련 허위 정보 또한 허용하지 않습니다.</p>
    <br>
    <h2>테러리즘 및 극단주의</h2>
    <br>
    <p>저희는 폭력, 테러리즘, 또는 극단주의를 조장하거나 그에 관여하는 플랫폼 상의 조직, 개인, 또는 콘텐츠에 대해 무관용 원칙을 고수합니다. 특정 콘텐츠와 무관하게 테러 조직, 혐오 단체, 범죄 조직, 갱 또한 허용하지 않습니다.</p>
    <br>
    <h2>소통이 금지하는 행위:</h2>
    <br>
    <br>
    <h2>혐오 행위</h2>
    <p>저희는 자기 표현, 의미 있는 연결, 그리고 창의성을 펼칠 수 있는 안전한 공간을 제공하기 위해 최선을 다합니다. 그리고 예의, 존중, 포용성에 기반해 관점의 다양성이라는 가치를 지지합니다.</p>
    <br>
    <p>소통은 혐오 행위를 하는 공간이 아닙니다. 저희는 혐오 행위를 ‘아래와 같이 보호 받아야 하는 특성을 기반으로 타인을 공격, 비하, 품위 훼손, 폭력 선동, 배척하거나 비인간적으로 대하는 콘텐츠, 발언, 또는 행동’으로 정의합니다.</p>
    <br>
    <p>나이, 인종, 민족, 출신 국가, 장애, 종교, 사회경제적 위상/계급, 성/젠더, 성적 취향, 성정체성, 중대한 질병 및 의학적 상태, 이민 상태, 가족 상황, 체중, 임신 여부</p>
    <h2>소통이 금지하는 행위:</h2>
    <br>
    <br>
    <h2>괴롭힘 및 따돌림</h2>
    <p>저희는 수치심을 주거나, 타인을 조롱, 모욕, 희롱, 위협하거나, 신상을 파헤치겠다고 협박하거나 따돌리거나 겁을 줌으로써 자유로운 발언을 막고 소통의 기능을 이용하는 데 제약을 주는 콘텐츠 및 행위를 허용하지 않습니다.</p>
    <h2>본인의 아바타를 이용해 타인을 희롱하거나 스토킹하지 마세요.</h2>
    <br>
    <p>거부한다는 것은 정말로 원하지 않는다는 의미입니다. 이는 직접적이든 간접적이든, 말로든 몸짓으로든, 어떻게 표현했는지 여부와 무관하게 적용됩니다. 월드에서 다른 유저가 당신에게 그만하라고 하거나, 당신을 차단하거나, 당신으로부터 도망간다면, 명확한 거부로 간주하고 하던 행위를 중단해야 합니다.</p>
    <br>
    <p>저희는 공익적인 사안에 대해 공인을 언급하거나 토론하는 것은 허용하나, 공인에 대한 심각한 위협 또는 모욕적 발언(성희롱 등)은 허용하지 않습니다.</p>
    <br>
    <h2>소통이 금지하는 행위:</h2>
    <br>
    <br>
    <h2>불법 행위, 규제 물품 및 서비스</h2>
    <p>소통에서 불법, 규제 물품과 서비스를 거래, 홍보 또는 권유하는 행위를 제재합니다. 저희는 소통이 서비스되는 모든 지역의 법을 존중하고 준수하며, 소통 커뮤니티 또한 그러하기를 요청합니다. 소통에게 승인받지 않은 상품, 서비스의 판매 및 거래는 적법성과 무관하게 제한될 수 있습니다.</p>
    <br>
    <p>정책에 따라 제한되는 물품, 서비스 및 항목의 목록은 아래와 같으나, 이에 한정되는 것은 아닙니다.</p>
    <br>
    <p>마약 및 규제 약물</p>
    <p>의약품</p>
    <p>알코올/담배(전자담배 포함)</p>
    <p>위조된 물품 및 서비스</p>
    <p>인신매매, 인간 착취</p>
    <p>멸종위기종</p>
    <p>퇴폐 영업</p>
    <p>도난 물품</p>
    <p>사기 행위</p>
    <p>무기, 화기, 탄약, 폭발물, 폭탄 및 폭탄 제조법. 소통 월드에서는 일부 경우에 한해 무기 속성의 아이템을 허용할 수 있습니다</p>
    <p>개인정보 및 개인식별정보(이름, 전화번호, 거주지 주소, 메일 주소, 주민등록번호 또는 기타 개인식별정보)</p>
    <br>
    <br>
    <h2>플랫폼의 건전성</h2>
    <p>소통은 아바타 기반의 서비스를 통해 가상 공간 속 정체성의 자유를 제공합니다. 하지만 자유에는 책임이 따릅니다. 소통은 플랫폼의 안정성과 유저의 안전을 저해할 수 있는 그 어떤 콘텐츠, 행위, 또는 활동도 허용하지 않습니다.</p>
    <br>
    <h2>사칭</h2>
    <br>
    <p>소통은 실제 여러분과 닮았을 수도, 닮지 않았을 수도 있는 아바타를 통해, 현실적 한계의 제약에서 벗어나 여러분이 원하는 무엇이든 되어보는 경험을 제공합니다. 하지만 학대, 약탈, 또는 금전적 의도를 갖고 타인을 해치거나, 속이거나, 기만하는 사칭 행위만큼은 허용하지 않습니다.</p>
    <br>
    <p>미성년자를 그루밍하거나, 속이거나, 소통 밖으로 유인하려는 부적절하고 기만적인 교류 활동 또한 엄격하게 금지합니다. 특정한 개인, 소통 직원을 사칭하거나 허위로 특정 조직에 소속된 척 활동하는 계정 또한 허용하지 않습니다. 단, 유명인의 팬 계정이나 유명인을 패러디하는 계정은 프로필과 계정 이름에 명시한다는 전제 하에 허용됩니다.</p>
    <br>
    <h2>인기도 및 반응 조작</h2>
    <br>
    <p>인위적이거나 허위적인 방법으로 인기도와 반응을 늘리려는 그 어떤 콘텐츠나 활동도 허용하지 않습니다.</p>
    <br>
    <h2>거짓 정보 유포</h2>
    <br>
    <p>혐오를 조장하거나, 공포를 유발하거나, 선거나 시민 참여 과정에서 커뮤니티 구성원들에게 거짓 정보를 유포하거나, 신체 건강을 훼손하거나, 과거에 발생한 비극적 사건을 부인하거나, 유해하고 악의적일 수 있는 허위 정보를 허용하지 않습니다. 잘못된 정보를 담은 콘텐츠가 유해한 영향을 끼칠 경우, 저희는 의도와 무관하게 해당 콘텐츠를 삭제합니다.</p>
    <br>
    <h2>스팸, 사기, 속임수</h2>
    <br>
    <p>타인을 속이거나 강요함으로써 현금 등을 보내도록 종용하거나, 소통을 이용하는 데 부정적인 영향을 주는 속임수, 스팸, 또는 사기 행위를 허용하지 않습니다.</p>
    <h2>보안 위협</h2>
    <br>
    <p>소통 플랫폼의 보안, 안정성, 안전한 사용자 경험을 해치려는 시도를 금지합니다. 해킹, 바이러스나 유해한 콘텐츠가 포함된 파일 또는 링크 배포, 자동화 수단(매크로, 로봇, 봇 등)을 활용해 사용자 경험을 방해하고 소통 계정을 타인과 공유하는 행위가 포함됩니다.</p>
    <br>
    <h2>소통이 금지하는 행위:</h2>
    <br>
    <br>
    <h2>저작권</h2>
    <p>타인의 저작권, 상표, 기타 법적 권리를 존중해주시기를 바랍니다. 저희는 저작권 및 상표를 침해하는 콘텐츠를 삭제합니다. 저작권/상표 침해는 고객센터 문의를 통해 신고해주세요.</p>
    <br>
    <br>
    <hr>
    <br>
    <br>
    <p>소통 커뮤니티 가이드라인, 서비스 약관, 스튜디오 콘텐츠 가이드라인, 또는 기타 정책을 위반하는 행위나 콘텐츠를 발견했을 경우, 저희에게 신고해주시기 바랍니다. 커뮤니티 가이드라인을 위반한 콘텐츠는 저희가 즉시 삭제하겠습니다 위반 수준에 따라 계정을 일시적으로 제재하는 경우도 있습니다.</p>
    <p>소통 커뮤니티 가이드라인을 반복적으로 위반하는 계정은 영구적으로 정지 처리하고 있습니다. 저희는 일부 위반 행위에 한해 무관용 정책을 고수합니다. 한 번 위반하면 해당 계정은 즉각적이고 영구적으로 정지된다는 것을 의미합니다. 해당 사례로는 아동 성착취물 (CSAM), 동의 없이 유포된 음란 이미지 및 영상, 실제로 현실 세계에서 물리적 상해를 가할 수 있는 폭력적 위협 또는 테러 콘텐츠 등이 포함됩니다. 이 외에도 다양한 사례들이 포함될 수 있습니다.</p>
    <br>
    <p>저희가 여러분의 계정에 조치한 내용에 이의를 제기하고 싶은 경우, 검토 요청을 제출할 수 있습니다.  여러분의 요청을 신중하게 검토한 후 결과를 안내해드리겠습니다.</p>
    <br>
    <p>저희는 소통 커뮤니티를 더 안전하게 보호할 수 있도록 안전 관련 파트너 및 전문가들과의 협의를 통해 소통 커뮤니티 가이드라인을 지속적으로 개발할 것입니다. 가이드라인을 업데이트할 때마다 최선을 다해 여러분에게 안내해드리겠습니다. 또한 여러분이 커뮤니티 가이드라인을 숙지하고 준수함으로써 안전한 소통을 만들기 위한 노력을 저희와 함께 해주시기를 부탁드립니다.</p>
  </body>
  
  </html>
`
}
