import { ApiResponse } from 'apisauce'
import { Api } from './api'
import { getGeneralApiProblem } from './api-problem'
import { PostQueryResult, GetQueriesResult } from './api.types'

export class QueryApi {
  private api: Api

  constructor (api: Api) {
    this.api = api
  }

  async createQuery (token: string, payload: any): Promise<PostQueryResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.post('/sotong_queries', { query: payload })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }

  async fetchQueries (token: string): Promise<GetQueriesResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get('/sotong_queries')

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', queries: response.data }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
