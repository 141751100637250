module.exports = function () {
  return `
<!DOCTYPE html>
<html>
<style>
    body {
        background-color: white;
        color: black;
    }

    p {
        font-size: 32pt;
    }

    pre {
        font-size: 32pt;
    }

    h2 {
        font-size: 36pt;
    }
</style>

<body>
    <p>본 이용약관(이하 "이용약관")은 ㈜토마토패스 의 소통 앱 및 관련하여 제공하는 프로그램, 소프트웨어 등(이하 "서비스" 또는 "소통") 및 본 이용약관의 적용을 받으며 소통 앱에서 업로드 및 다운로드,
        공유되는 모든 정보 및 텍스트, 그래픽, 사진, 기타 자료(이하 통칭 "콘텐츠")에 대한 접근 및 이용에 관한 사항을 규정합니다. 본 이용약관에 동의하지 않는 경우 소통을 이용할 수 없으므로, 사용 전
        신중하게 확인한 후 동의하여 주시기 바랍니다.</p>
    <h2>1. ㈜토마토패스와의 계약</h2>
    <p>1) 준거법: 사용자는 토마토패스 주식회사와 계약을 체결하였으며, 법 조항의 상충 여부와 관계없이 대한민국 법의 적용을 받습니다.</p>
    <p>2) 토마토패스 주식회사에 제공한 모든 정보는 개인정보의 수집 및 이용에 관한 회사의 개인정보 취급방침의 적용을 받습니다. 사용자는 서비스를 이용하는 것으로 관련 법률상 개인정보 보호 정책에 따라
        사용자의 개인정보의 수집 및 이용에 동의합니다. 본 서비스의 일환으로 회사는 사용자에게 서비스 알림 및 정보성 메시지를 전송할 수 있으며, 이는 서비스의 일부이므로 수신 거부할 수 없습니다.</p>
    <p>3) 토마토패스 주식회사는 ‘가입신청자’의 회원가입신청에 소통 서비스 시스템에의 기계적 접근 또는 계정도용, 거짓된 정보의 제공 및 ‘가입신청자’의 ‘서비스’ 어뷰징 내역 확인 등의 사유가 있어
        토마토패스 주식회사가 ‘가입신청자’의 신청을 승낙하는 것이 적절하지 않은 경우 그 승낙을 유보 또는 거부하거나, 사후에 이용계약을 해지할 수 있고, 필요한 경우 일정기간 동안 서비스 이용을 제한할 수
        있습니다.</p>
    <h2>2. 약관의 게시와 개정</h2>
    <p>1) 토마토패스 주식회사는 이 약관의 내용을 사용자가 쉽게 알 수 있도록 소통 서비스 초기화면 내 또는 별도의 연결화면에 게시하거나 팝업화면 등으로 제공합니다.</p>
    <p>2) 토마토패스 주식회사는 정보통신망이용촉진및정보보호등에관한법률(이하 ‘정보통신망법’), 전자상거래등에서의소비자보호에관한법률, 약관의규제에관한법률 등 관련 법을 위반하지 않는 범위에서 이 약관을 개정할
        수 있습니다.</p>
    <p>3) 토마토패스 주식회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 그 변경
        내용이 법적으로 사용자에게 불리한 것일 경우, 그 적용일자 30일 이전부터 동일한 내용을 ‘서비스’를 통해 공지할 뿐만 아니라, 해당 내용을 사용자의 이메일 등으로 개별적으로 통지합니다.</p>
    <p>4) 토마토패스 주식회사가 전항에 따라 개정약관을 공지 또는 통지하면서 사용자에게 7일 또는 30일 기간 내에 거부의 의사표시를 하지 않으면 약관 개정에 동의한 것으로 간주한다는 뜻을 명확하게 공지 또는
        통지하였음에도 사용자가 명시적으로 거부의 의사표시를 하지 아니한 경우 사용자가 개정약관에 동의한 것으로 봅니다.</p>
    <p>5) 사용자가 개정약관의 적용에 동의하지 않는 경우 소통 개정약관의 내용을 적용할 수 없으며, 이 경우 사용자는 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는
        경우에는 토마토패스 주식회사는 이용계약을 해지할 수 있습니다.</p>
    <h2>3. 소통 콘텐츠 및 사용자 콘텐츠</h2>
    <p>1) "소통 콘텐츠"는 달리 정하지 않는 한, ‘서비스’에서 사용자에게 제공하는 텍스트, 그래픽, 이미지, 삽화, 디자인, 아이콘, 사진, 동영상, 기타 문구를 의미합니다(시각적으로 확인 가능한 자료들을
        대상으로 하며, 컴퓨터 등 정보처리장치 내에서 직, 간접적으로 사용되는 프로그램, 소프트웨어 등은 제외). “소통 콘텐츠”의 선택과 배치 등을 비롯하여 서비스에 포함된 모든 자료는 저작권, 상표권,
        트레이드 드레스, 특허권 및/또는 기타 지적 재산권은 소통에게 있으며, 관련 법률에 의해 보호받습니다.</p>
    <p>2) 사용자는 “소통 콘텐츠”를 이용하여 사진, 동영상, 텍스트, 그래픽, 아이템 및 기타 자료(이하 “사용자 콘텐츠”)를 제작하고 게시하며, 전송 및 저장하는 등 적극적인 상호작용을 할 수 있습니다.
        토마토패스 주식회사는 사용자 간의 적극적인 상호작용을 장려하기 위하여 소통에서 하는 활동에 기초하여 사용자가 관심을 가질만한 '콘텐츠', 기능을 제안하거나 추천할 수 있으며, 사용자에게 소통을 경험하는
        방법을 제안하기도 합니다. 물론, 사용자는 개인정보 설정에 따라 본인의 “사용자 콘텐츠”를 볼 수 있는 대상을 제한할 수 있습니다.</p>
    <p>3) 사용자가 제작한 “사용자 콘텐츠”는 타인이 조회할 수 있으며, 사용자는 개인정보 설정 기능을 통해 콘텐츠에 대한 접근을 관리할 수 있습니다. 회사의 직원은 소통에 업로드 된 "사용자 콘텐츠"를
        확인할 수 있습니다. 회사는 사용자의 개인 콘텐츠를 안전하게 보호하기 위해 합당한 조치를 취하고 있으나, 100% 안전은 보장할 수 없으며, 제3자가 무단으로 보안을 뚫고 개인 콘텐츠에 접근하는 상황이
        발생할 수도 있음을 양해하여 주시기 바랍니다. 이와 더불어 때때로 업데이트되는 회사의 커뮤니티 가이드라인도 반드시 준수해야 합니다.</p>
    <p>4) “사용자 콘텐츠”에 대한 모든 저작권 및 기타 지적재산권은 본인에게 있으며, 다만 “소통 콘텐츠”에 대한 저작권 기타 지적재산권의 귀속에는 영향이 없고, 본 서비스 이용으로 인해 발생하는 모든
        데이터 사용료는 사용자의 부담입니다. “사용자 콘텐츠”를 소통에 게시함으로써 사용자는 현재 또는 추후 개발되는 모든 미디어 및 배포 매체에 해당 사용자 콘텐츠를 사용(기계 학습 및 토마토패스 주식회사
        단독 또는 계열사와 합작 R&D 목적으로 얼굴 이미지, 사진, 동영상을 이용하는 것도 포함) 권한을 회사에 부여한 것으로 간주됩니다. 이때, 사용자는 개인정보 설정에 따라 본인의 사용자 콘텐츠를 볼 수
        있는 대상을 제한할 수 있습니다.</p>
    <p>5) 사용자는 “사용자 콘텐츠” 및 그로 인해 발생하는 일체의 손해배상에 대해 전적으로 책임을 부담하며, 소통은 이에 대해 어떠한 책임도 지지 않습니다. 비록 의무는 아니나, 회사는 독자적 결정에 따라
        언제든지 사용자 콘텐츠를 검토, 감독 및 삭제할 수 있습니다.</p>
    <p>6) 사용자는 자신이 제작한 “사용자 콘텐츠”를 상업적으로 이용하기 위해서는 사전에 소통에 통지 절차를 거쳐야 하며, 사전 통지 없이 “소통 콘텐츠”가 포함된 “사용자 콘텐츠”를 상업적으로 사용하는 것이
        확인되는 경우 소통은 이러한 사용을 제한할 수 있습니다.</p>
    <h2>4. 커뮤니티 가이드라인</h2>
    <p>회사의 커뮤니티 가이드라인은 모든 소통 사용자가 마음껏 자신을 표현하는 동시에 회사의 서비스를 안전하고 즐겁게 이용할 수 있도록 마련된 규정입니다.
        수신을 원치 않는 대상에게 메시지, 특히 불쾌감을 줄 수 있는 내용의 전송은 삼가시기 바랍니다.
        전송하는 메시지 내용과 대상을 신중히 고려해야 합니다. 누구나 화면을 캡처할 수는 있지만, 이를 저장하거나 친구들과 공유해서는 안 됩니다.
        합법적으로 이용하도록 합니다. 소통을 통해 불법한 행위를 시도해서는 안 되며, 아래의 콘텐츠를 조회하거나 서로 주고받지 않아야 합니다.</p>
    <p>&emsp;음란물</p>
    <p>&emsp;노출 사진 및 미성년자(만18세 미만)와 관련된 선정적인 내용의 콘텐츠</p>
    <p>&emsp;미성년자가 신체적으로 위험하고 유해한 행위에 가담하는 내용의 콘텐츠</p>
    <p>&emsp;사생활 침해</p>
    <p>&emsp;위협</p>
    <p>&emsp;괴롭힘 및 따돌림</p>
    <p>&emsp;사칭</p>
    <p>&emsp;자해</p>
    <p>상기 규정을 위반하는 경우, 콘텐츠 삭제 및 계정 일시 정지, 향후 소통 이용이 제한될 수 있습니다. 본 규정이 마련된 본래의 취지를 주의 깊게 고려한 후, 철저히 준수하여 주시기 바랍니다. 회사는 소통
        사용자 커뮤니티와 함께 의견을 공유하며 규정을 변경하거나 추가할 수 있으며, 언제나 공정하고 일관된 방법으로 규칙을 시행하고, 합리적인 판단에 따라 상황에 가장 적절한 조치를 취할 수 있도록 최선을
        다하고 있습니다.</p>
    <h2>5. 피드백</h2>
    <p>사용자가 이메일 또는 기타 형식으로 토마토패스 주식회사 또는 서비스에 대하여 제공한 피드백, 제안, 아이디어 및 기타 정보(이하 "피드백")는 기밀 자료에 해당하지 않으며, 토마토패스 주식회사의 고유한
        자산으로 간주됩니다. 즉, 회사는 제공 사용자에게 어떠한 통보나 보상을 제공하지 않고도 상업적 또는 여타의 목적으로 해당 피드백을 제한 없이 이용하고 배포할 수 있으며, 사용자는 이에 대해 저작권 및
        저작인격권을 포함하여 어떠한 권리도 행사할 수 없습니다. 회사는 언제나 사용자의 의견에 귀 기울이고 있으나, 이에 대한 소유권 및 기타 권리나 금전적 보상을 요구하는 문의는 삼가 주시기 바랍니다.
    </p>
    <h2>6. 금지 행위</h2>
    <p>사용자는 본 이용약관에서 명시한 제한 사항과 더불어 다음의 행위를 삼가는 데 동의합니다. 본 이용약관에서 명시적으로 허용하는 경우를 제외하고, 토마토패스 주식회사의 사전 서면 동의 없이 소통 콘텐츠 및
        서비스를 이용하는 행위는 엄격히 금지되며, 이러한 행위가 발생할 경우, 본 이용약관에서 부여한 라이선스 사용 권한이 종료됩니다.</p>
    <p>&emsp;불법 또는 본래 이용범위를 벗어난 목적으로 또는 본 이용약관 및 특정 서비스에 관한 제3자 이용약관의 금지 조항을 위반하여 서비스를 이용하는 행위</p>
    <p>&emsp;다른 사용자의 서비스 이용을 방해, 중단 및 제한하고, 이에 부정적인 영향을 주거나, 서비스 기능에 손상, 장애, 과부하, 파손을 초래하는 방식으로 서비스를 이용하는 행위</p>
    <p>&emsp;서비스 보안을 훼손하는 행위</p>
    <p>&emsp;원치 않는 광고, 스팸, 권유, 판촉 자료를 동의 없이 전송하는 행위</p>
    <p>&emsp;회사에서 제공하지 않은 로봇, 스파이더, 크롤러, 스크래퍼 및 기타 자동화된 수단이나 인터페이스를 사용하여 서비스에 접근하거나 데이터를 추출하는 행위</p>
    <p>&emsp;서비스 일부를 역설계하거나 서비스의 영역, 콘텐츠, 코드에 대한 접근을 방지하고 제한하기 위해 설계된 장치를 우회하거나 회피하고, 소스 코드를 찾아내려는 행위</p>
    <p>&emsp;다른 사용자의 계정을 무단으로 사용하거나 이를 시도하는 행위</p>
    <p>&emsp;회사에서 사용하는 콘텐츠 필터링 기술을 우회하거나 접근 권한이 없는 서비스의 영역 및 기능에 접근을 시도하는 행위</p>
    <p>&emsp;서비스에서 명시적으로 허용하는 경우를 제외하고, 소통 콘텐츠 및 서비스, 그에 포함된 일체의 정보를 다운로드하는 행위(페이지 캐싱 제외)</p>
    <p>&emsp;무단으로 또는 제3자 고객과 함께 소통 API에 접근하는 행위</p>
    <p>&emsp;회사와의 관계를 드러내거나 회사의 명시적인 서면 동의 없이 제품 및 서비스를 홍보하는 행위</p>
    <p>&emsp;괴롭힘, 협박, 위협 및 스토킹 등에 가담하는 행위</p>
    <p>&emsp;타인이나 기관 또는 그 관계를 사칭하는 행위</p>
    <p>&emsp;동의 없이 사진을 촬영하는 등 타인의 초상권, 사생활, 정보보호 권리를 침해하는 행위</p>
    <p>&emsp;타인의 특허권, 상표권, 영업 비밀, 저작권, 기타 지적 재산권, 소유권을 침해하는 행위</p>
    <p>&emsp;금전을 대가로 소통의 사전 서면 동의 없이 소통 계정, 메시지, 사용자명, 친구 링크에 대한 접근 권한을 매매, 임대, 대여, 기타 방식으로 판매하는 행위</p>
    <p>&emsp;회사의 사전 서면 동의 없이 사용자 콘텐츠 또는 서비스와 상호작용할 수 있는 제3자 앱을 개발하는 행위</p>
    <p>&emsp;불법 또는 무단으로 서비스를 이용하거나 본 이용약관을 위반하는 행위를 권장 또는 촉진하는 행위</p>
    <p>iTunes 앱 스토어 서비스 이용약관 및 안드로이드 마켓 서비스 이용약관 등 소통에 대한 리뷰를 게시할 때 또는 본 서비스에 적용되는 제3자 이용약관을 준수해야 합니다. 앱 스토어 리뷰 작성 시 소통
        사용자명을 노출하는 것은 엄격히 금지되며, 이를 위반할 경우 소통 계정이 삭제될 수도 있습니다.</p>
    <h2>7. 서비스 이용 라이선스</h2>
    <p>토마토패스 주식회사는 사용자에게 서비스의 일부로 제공되는 소프트웨어에 대한 개인적이고, 전 세계적이며, 양도 불가능하며, 사용료 없이 이용 가능한 비독점적 라이선스 사용 권한을 부여합니다. 해당
        라이선스는 본 이용약관에서 허용하는 범위 내에서 회사 서비스의 혜택을 누리기 위한 목적으로만 제공됩니다.</p>
    <p>1) 계정 정보: 서비스를 이용하려면 소통 계정을 생성해야 합니다. 로그인 자격증명에 대한 보안 및 계정을 통해 이루어진 모든 행위에 대한 책임은 본인에게 있습니다.</p>
    <p>사용자는 소통 서비스와 관련한 프로그램, 소프트웨어의 전체 또는 일부를 복제, 재생산, 재게시, 변형, 다운로드, 전송, 수정, 전시, 역설계, 판매 및 판촉, 임대, 대여, 양도, 분배, 라이선스,
        서브라이선스, 부당 이용 등의 행위를 할 수 없습니다.</p>
    <h2>8. 토마토패스 주식회사의 권리</h2>
    <p>본 서비스에 대한 모든 소유권 및 권리, 지분(사용자가 제공한 콘텐츠는 제외)은 토마토패스 주식회사 및 회사 라이선스의 독점적 재산입니다. 서비스는 저작권, 상표권 및 기타 법률의 보호를 받으며, 본
        이용약관의 어떠한 조항도 토마토패스 주식회사의 상호 및 상표권, 로고, 도메인명, 기타 고유한 브랜드 특징을 이용할 수 있는 권한을 부여하지 않습니다. 토마토패스 주식회사 및 서비스에 관한 피드백,
        의견 및 제안은 사용자의 자발적 의지로 제공되는 것이며, 회사에서 적합하다고 판단할 경우, 제공 회사자에게 통지하지 않고도 해당 의견, 피드백 및 제안을 자유롭게 사용할 수 있습니다.</p>
    <h2>9. 광고의 게재</h2>
    <p>토마토패스 주식회사는 토마토패스 주식회사 서비스의 운영과 관련하여 서비스 화면에 직접, 네이버 또는 제휴 관계에 있는 제3자를 통해 광고를 게재할 수 있습니다.</p>
    <h2>10. 유료서비스의 내용 등의 표시</h2>
    <p>1) 토마토패스 주식회사는 다음 사항을 해당 유료서비스의 이용초기화면이나 FAQ 등에 사용자가 알기 쉽게 표시합니다.</p>
    <p>&emsp;유료서비스의 명칭 또는 제호</p>
    <p>&emsp;유료서비스 주체의 성명(법인인 경우는 법인의 명칭), 주소, 전화번호</p>
    <p>&emsp;유료서비스의 내용, 이용방법, 이용료, 기타 이용조건</p>
    <p>2) 토마토패스 주식회사의 유료서비스의 이용가능기기 및 이용에 필요한 최소한의 기술사항은 권장사양정보에 따릅니다.</p>
    <p>3) 토마토패스 주식회사는 유료서비스를 제공함에 있어 유료서비스의 교환·반품·보증과 그 대금 환불의 조건 및 절차에 관한 사항을 제공합니다.</p>
    <p>4) 토마토패스 주식회사의 정책 및 제1항의 결제방법 제공자가 정한 기준에 따라 사용자의 월별 누적 결제액 및 유료서비스 구매한도가 제한될 수 있습니다. 해당 기준을 초과한 경우 유료서비스의 추가 이용은
        불가능할 수 있습니다.</p>
    <h2>11. 사용기간 등</h2>
    <p>유료서비스의 사용기간 또는 유효기간은 계약 당시 표시한 바에 따릅니다. 해당 기간이 경과한 유료서비스는 이용권한이 소멸될 수 있습니다.</p>
    <h2>12. 재화등의 하자에 의한 피해보상</h2>
    <p>유료서비스의 하자 등에 의한 사용자 피해보상은 콘텐츠이용자보호지침에서 정한 바에 따릅니다.</p>
    <h2>13. 저작권 정책</h2>
    <p>1) 본인의 콘텐츠가 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 관련법이 정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, 토마토패스 주식회사는
        관련법에 따라 조치를 취하여야 합니다.</p>
    <p>2) 토마토패스 주식회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 콘텐츠에 대해 임시조치 등을
        취할 수 있으며, 이에 대해 어떠한 책임도 지지 않습니다. 토마토패스 주식회사는 필요한 경우, 여러 차례 반복해서 저작권을 침해하는 사용자가 있다면, 해당 계정을 종료시킬 수 있습니다.</p>
    <p>3) 본 조에 따른 세부절차는 "정보통신망법" 및 "저작권법"이 규정한 범위 내에서 토마토패스 주식회사가 정한 게시중단요청서비스에 따릅니다.</p>
    <h2>14. 면책 조항</h2>
    <p>1) 서비스 이용 과정에서 불쾌하고, 선정적이며, 모욕적인 자료에 노출될 수 있으며, 서비스에 접근하고 이를 이용함으로써 이러한 위험 요소를 받아들이는 것에 동의합니다. 회사의 웹사이트 또는 서비스에
        노출되는 콘텐츠는 회사의 의견을 반영한 것이 아닐 수도 있으며, 서비스의 사용자가 게시한 어떠한 콘텐츠도 지원하거나 지지하지 않습니다.</p>
    <p>2) 회사는 사용자의 콘텐츠를 안전하게 보호하기 위해 합당한 조치를 시행하고 있으나, 해당 콘텐츠의 노출로 인해 발생할 수 있는 모든 위험을 100% 완벽하게 예방할 수는 없습니다.</p>
    <p>3) 면책 조항: 관련 법률에서 허용하는 최대 범위 내에서 토마토패스 주식회사의 서비스 및 웹사이트, 앱, 콘텐츠, 기타 모든 자료는 어떠한 명시적, 묵시적, 법적 보증 없이 "있는 그대로" 사용자에게
        제공됩니다. 토마토패스 주식회사는 (1) 서비스 및 자료가 사용자의 요구사항에 부합하거나 지속적으로 중단 없이, 적시에, 안전하게, 오류 없이 이용 가능하며, (2) 웹사이트, 서비스, 자료의 이용을
        통해 획득한 결과가 효과적이며, 정확하고, 신뢰할 수 있으며, (3) 웹사이트, 서비스, 자료에서 발견되는 모든 오류 및 결함을 즉시 해결할 수 있음을 보증하지 않습니다.</p>
    <h2>15. 책임 제한</h2>
    <p>법률에서 금지하는 경우를 제외하고, 토마토패스 주식회사 및 회사의 공급업체, 각각의 임원, 이사, 직원, 대리인(이하 "토마토패스 주식회사 측")은 (1) 서비스 이용 및 이용 불능, (2) 서비스 제공
        및 서비스를 통해 이용 가능한 자료, (3) 서비스 사용자의 행위로 말미암아 발생하는 간접적, 특별적, 징벌적, 부수적, 모범적, 결과적 손해에 대하여 어떠한 책임도 지지 않습니다.</p>
    <h2>16. 토마토패스 주식회사와의 분쟁 해결</h2>
    <p>1) 서면으로 달리 정하지 않는 한, 토마토패스 주식회사와 분쟁이 발생하는 경우, 제소 당시의 사용자의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시
        사용자의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.</p>
    <p>2) 해외에 주소나 거소가 있는 사용자의 경우 토마토패스 주식회사와 사용자간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.</p>
    <p>3) 면책 조항: 토마토패스 주식회사와 사용자는 국제 물품 매매 계약에 관한 유엔 협약상 책임과 권리를 부인하며, 본 서비스의 사용에 있어 이를 적용하지 않기로 합의합니다.</p>
    <h2>17. 종료</h2>
    <p>1) 사용자는 언제든지 서비스 이용을 중단할 수 있습니다.</p>
    <p>2) 회사는 서비스의 기능을 언제든지 추가, 수정 및 삭제할 수 있습니다. 또한, 서비스의 제공을 일시 또는 영구적으로 중단하거나 새로운 제한 사항을 추가할 수도 있습니다.</p>
    <p>3) 사용자 계정의 사용이 종료되더라도, 지불 의무는 그대로 유효합니다.</p>
    <p>4) 서비스가 종료 또는 중단되는 경우, 회사는 사용자에게 이를 알리기 위해 합당한 노력을 기울이고, 사용자의 콘텐츠를 복구할 수 있도록 협조합니다.</p>
    <h2>18. 일반 조항</h2>
    <p>1) 분리 조항: 법원의 판결을 통해 본 이용약관의 일부 조항이 집행이 불가능하거나 실효되더라도 나머지 조항은 유효하게 적용됩니다.</p>
    <p>2) 권리불포기: 회사가 본 이용약관을 집행하지 않거나 집행을 유예하였더라도 이는 본 약관의 집행 권리를 포기하는 것으로 간주되지 않습니다.</p>
    <p>3) 양도: 토마토패스 주식회사의 서면 동의 없이 사용자는 본 이용약관에서 정한 권리 및 의무를 타인에게 양도할 수 없습니다. 단, 인수 합병 및 서비스 매각이 발생하는 경우, 토마토패스 주식회사는
        사용자의 동의 없이 사용자에 가지는 회사의 권리 및 의무를 양도할 수 있습니다.</p>
    <p>4) 완전합의: 본 이용약관은 서비스에 관한 토마토패스 주식회사와 사용자 간의 완전하고 독점적인 합의를 나타내며, 서비스와 관련하여 이전에 체결된 모든 계약을 대체합니다. 회사는 때때로 본 이용약관을
        수정할 수 있으며, 독자적 결정에 의거 수정 사항이 중대하다고 판단하는 경우, 웹페이지 배너 또는 통지 유형에 따라 적절한 수단을 사용하여 사용자에게 고지합니다. 사용자가 수정 사항이 적용된 후에도
        서비스를 계속 이용하는 경우, 변경 후의 이용약관에 동의를 한 것으로 간주됩니다.</p>
    <br>
    <h2>부칙</h2>
    <p>이 약관은 2022년 3월 31일부터 적용됩니다.</p>
</body>

</html>
`
}
