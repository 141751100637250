import { ApiResponse } from 'apisauce'
import { Api } from './api'
import { GetTtmisResult } from './api.types'
import { getGeneralApiProblem } from './api-problem'

export class TtmiApi {
  private api: Api

  constructor (api: Api) {
    this.api = api
  }

  async getTtmis (token: string): Promise<GetTtmisResult> {
    try {
      this.api.apisauce.setHeader('Authorization', `Bearer ${token}`)
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/sotong_ttmis'
      )
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      const ttmis = response.data
      return { kind: 'ok', ttmis: ttmis }
    } catch (e) {
      return { kind: 'bad-data' }
    }
  }
}
