import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const TopicModel = types
  .model('Topic')
  .props({
    topic_id: types.identifier,
    title: types.maybeNull(types.maybe(types.string))
  })

type TopicType = Instance<typeof TopicModel>
export interface Topic extends TopicType {}
type TopicSnapshotType = SnapshotOut<typeof TopicModel>
export interface TopicSnapshot extends TopicSnapshotType {}
