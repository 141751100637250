/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { NotificationApi } from "../../services/api/notification-api"
import { api } from "app/services/api"

export const NotificationStoreModel = types
  .model("NotificationStore")
  .props({})
  .views((self) => ({}))
  .actions((self) => ({}))
  .actions((self) => ({
    fetchNotifications: flow(function* (token: string, limit: number = 6, page: number = 0) {
      const notificationApi = new NotificationApi(api)
      const result = yield notificationApi.getNotifications(token, limit, page)

      if (result.kind === "ok") {
        return result.notifications
      } else {
        return []
      }
    }),

    deleteNotification: flow(function* (token: string, notification_id: string) {
      const notificationApi = new NotificationApi(api)
      const result = yield notificationApi.deleteNotification(token, notification_id)

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),

    updateNotification: flow(function* (token: string, notification_id: string) {
      const notificationApi = new NotificationApi(api)
      const result = yield notificationApi.updateNotification(token, notification_id)

      if (result.kind === "ok") {
        return true
      } else {
        return false
      }
    }),
  }))

type NotificationStoreType = Instance<typeof NotificationStoreModel>
export interface NotificationStore extends NotificationStoreType {}
type NotificationStoreSnapshotType = SnapshotOut<typeof NotificationStoreModel>
export interface NotificationStoreSnapshot extends NotificationStoreSnapshotType {}
