/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React from 'react'
import { View, FlatList, ActivityIndicator, RefreshControl } from 'react-native'
import { TransactionHoldMasterBox, TransactionCompleteMasterBox } from '../../components'
import { spacing } from '../../theme'

function TransactoinMasterRoute({
  transactions,
  fetchTransactions,
  refreshing,
  loading,
  noMore,
  hold = false,
}) {
  let onEndReachedCalledDuringMomentum = false
  return (
    <FlatList
      contentContainerStyle={{
        paddingVertical: spacing[2],
      }}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
      data={transactions}
      initialNumToRender={6}
      onMomentumScrollBegin={() => {
        onEndReachedCalledDuringMomentum = false
      }}
      onEndReached={() => {
        if (!onEndReachedCalledDuringMomentum) {
          fetchTransactions()
          onEndReachedCalledDuringMomentum = true
        }
      }}
      onEndReachedThreshold={0.1}
      keyExtractor={(item) => item.transaction_id}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={() => {
            fetchTransactions(true)
          }}
        />
      }
      renderItem={({ item, index }) => (
        <View key={index}>
          {hold ? (
            <TransactionHoldMasterBox transaction={item} />
          ) : (
            <TransactionCompleteMasterBox transaction={item} />
          )}
        </View>
      )}
      ListFooterComponent={
        loading && !noMore ? (
          <ActivityIndicator
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              marginVertical: spacing[4],
            }}
          />
        ) : (
          <></>
        )
      }
    />
  )
}

export const MemoTransactoinMasterRoute = React.memo(TransactoinMasterRoute)
