import {
  DrawerActions,
  NavigationContainer,
  getFocusedRouteNameFromRoute,
} from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import {
  DrawerContentScrollView,
  createDrawerNavigator,
  useDrawerStatus,
} from "@react-navigation/drawer"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { navigationRef, useBackButtonHandler } from "./navigationUtilities"
import { Announcement } from "app/models/announcement/announcement"
import { Category } from "app/models/category/category"
import { Education } from "app/models/education/education"
import { Career } from "app/models/career/career"
import { Transaction } from "app/models/transaction/transaction"
import { Counseling } from "app/models/counseling/counseling"
import { User } from "app/models/user/user"
import { color, spacing } from "app/theme"
import { useStores } from "app/models"
import {
  AddCounselingScreen,
  AnnouncementDetailScreen,
  AvatarCreateScreen,
  AvatarCreateScreenInit,
  BookCounselingScreen,
  ChatListScreen,
  ChatScreen,
  CounselingDetailScreen,
  CreateSpaceScreen,
  EditMasterIntroScreen,
  EditProfileScreen,
  FollowerFollowingScreen,
  GuideScreen,
  HomeCommunityAllScreen,
  HomeCommunityScreen,
  HomeMastersAllScreen,
  HomeMastersByCategoryScreen,
  HomeMastersScreen,
  HomeRegistrationHelpScreen,
  HomeScreen,
  HomeSearchScreen,
  HomeSpacesByCategoryScreen,
  HomeUsersByCategoryScreen,
  LoginScreen,
  MasterProfileScreen,
  MasterRegistrationScreen,
  NickNameScreen,
  NoticeScreen,
  NotificaitionListScreen,
  NotificationSettingsScreen,
  PasswordManagementScreen,
  QuestionAnswerScreen,
  RegisterOTPScreen,
  RegisterPasswordScreen,
  RegisterPhoneScreen,
  RegisterSuccessScreen,
  ServiceCenterScreen,
  SettingsScreen,
  ShopWebScreen,
  TermsAndPolicyScreen,
  TransactionHistoryMasterScreen,
  TransactionHistoryScreen,
  TransactionReviewScreen,
  UserDetailScreen,
  UserProfileScreen,
} from "app/screens"
import { ActivityIndicator, TouchableOpacity, View } from "react-native"
import { Button, ProfileAvatar, Text, chatClient } from "app/components"
import Toast from "react-native-toast-message"

export type NavigatorParamList = {
  "main-drawer": undefined
  "auth-stack": undefined
  "app-tab": undefined
  "home-stack": undefined
  "shop-stack": undefined
  login: undefined
  registerPhone: undefined
  registerOTP: { userKey: string; phonenum: string; nation: string }
  registerPassword: { userKey: string; phonenum: string; nation: string }
  registerSuccess: { phone: string; password: string; nation: string }
  nickName: undefined
  avatarCreate: undefined
  avatarCreateInit: undefined
  home: undefined
  space: undefined
  shopWeb: undefined
  createSpace: undefined
  announcementDetail: { announcement: Announcement }
  homeMasters: undefined
  homeMastersAll: {
    type: string
  }
  homeCommunity: undefined
  homeCommunityAll: {
    type: string
  }
  homeSpacesByCategory: {
    category: Category
  }
  homeMastersByCategory: {
    category: Category
  }
  homeSearch: undefined
  homeUsersByCategory: undefined
  homeRegistrationHelp: undefined
  termsAndPolicy: {
    isTerms: boolean
  }
  userProfile: undefined
  masterProfile: undefined
  editProfile: undefined
  editMasterIntro: {
    educations: Education[]
    careers: Career[]
  }
  serviceCenter: undefined
  notice: undefined
  settings: undefined
  passwordManagement: undefined
  notificationSettings: undefined
  transactionHistory: undefined
  transactionHistoryMaster: undefined
  masterRegistration: undefined
  transactionReview: {
    transaction: Transaction
  }
  addCounseling: {
    counseling?: Counseling
  }
  counselingDetail: {
    counseling: Counseling
    masterCategory?: Category
    master: User
  }
  bookCounseling: {
    counseling: Counseling
    master_name: string
  }
  masterDetailScreen: undefined
  userDetail: {
    profileId: string
    accountId: string
  }
  notificaitonList: undefined
  chatList: undefined
  chat: {
    accountId: string
  }
  followerFollowing: {
    accountId: string
  }
  questionAnswer: undefined
  guide: undefined
}

const exitRoutes = ["home", "login"]
const Stack = createNativeStackNavigator<NavigatorParamList>()
const Drawer = createDrawerNavigator<NavigatorParamList>()
const Tab = createBottomTabNavigator<NavigatorParamList>()

const HomeStack = observer(function HomeStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animation: "none",
      }}
      initialRouteName="home"
    >
      <Stack.Screen name="home" component={HomeScreen} />
      <Stack.Screen name="announcementDetail" component={AnnouncementDetailScreen} />
      <Stack.Screen name="homeMasters" component={HomeMastersScreen} />
      <Stack.Screen name="homeMastersAll" component={HomeMastersAllScreen} />
      <Stack.Screen name="homeCommunity" component={HomeCommunityScreen} />
      <Stack.Screen name="homeCommunityAll" component={HomeCommunityAllScreen} />
      <Stack.Screen name="createSpace" component={CreateSpaceScreen} />
      <Stack.Screen name="homeMastersByCategory" component={HomeMastersByCategoryScreen} />
      <Stack.Screen name="homeSpacesByCategory" component={HomeSpacesByCategoryScreen} />
      <Stack.Screen name="homeSearch" component={HomeSearchScreen} />
      <Stack.Screen name="homeUsersByCategory" component={HomeUsersByCategoryScreen} />
      <Stack.Screen name="homeRegistrationHelp" component={HomeRegistrationHelpScreen} />
      <Stack.Screen name="termsAndPolicy" component={TermsAndPolicyScreen} />
      <Stack.Group>
        <Stack.Screen name="notificaitonList" component={NotificaitionListScreen} />
        <Stack.Screen name="guide" component={GuideScreen} />
      </Stack.Group>
      <Stack.Screen name="followerFollowing" component={FollowerFollowingScreen} />
      <Stack.Group>
        <Stack.Screen name="chatList" component={ChatListScreen} />
        <Stack.Screen name="chat" component={ChatScreen} />
      </Stack.Group>
      <Stack.Group>
        <Stack.Screen name="userProfile" component={UserProfileScreen} />
        <Stack.Screen name="masterProfile" component={MasterProfileScreen} />
        <Stack.Screen name="userDetail" component={UserDetailScreen} />
        <Stack.Screen name="editProfile" component={EditProfileScreen} />
        <Stack.Screen name="editMasterIntro" component={EditMasterIntroScreen} />
        <Stack.Group>
          <Stack.Screen name="addCounseling" component={AddCounselingScreen} />
          <Stack.Screen name="counselingDetail" component={CounselingDetailScreen} />
          <Stack.Screen name="bookCounseling" component={BookCounselingScreen} />
        </Stack.Group>
        <Stack.Screen name="avatarCreate" component={AvatarCreateScreen} />
        <Stack.Screen name="serviceCenter" component={ServiceCenterScreen} />
        <Stack.Screen name="questionAnswer" component={QuestionAnswerScreen} />
        <Stack.Screen name="notice" component={NoticeScreen} />
        <Stack.Screen name="settings" component={SettingsScreen} />
        <Stack.Group>
          <Stack.Screen name="passwordManagement" component={PasswordManagementScreen} />
          <Stack.Screen name="notificationSettings" component={NotificationSettingsScreen} />
          <Stack.Screen name="transactionHistory" component={TransactionHistoryScreen} />
          <Stack.Screen
            name="transactionHistoryMaster"
            component={TransactionHistoryMasterScreen}
          />
          <Stack.Screen name="masterRegistration" component={MasterRegistrationScreen} />
          <Stack.Screen name="transactionReview" component={TransactionReviewScreen} />
        </Stack.Group>
      </Stack.Group>
    </Stack.Navigator>
  )
})

const ShopStack = observer(function ShopStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animation: "none",
      }}
      initialRouteName="shopWeb"
    >
      <Stack.Screen name="shopWeb" component={ShopWebScreen} />
    </Stack.Navigator>
  )
})

const CustomDrawerContent = observer(function CustomDrawerContent(props) {
  const { authStore } = useStores()
  const isDrawerOpen = useDrawerStatus() === "open"
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fecthProfile = async () => {
      setLoading(true)
      await authStore.fetchProfile()
      setLoading(false)
    }

    if (isDrawerOpen === true && authStore.isAuthenticated) {
      fecthProfile()
    }

    return () => {
      setLoading(false)
    }
  }, [isDrawerOpen])

  return (
    <DrawerContentScrollView {...props}>
      {loading ? (
        <ActivityIndicator style={{ marginTop: spacing[4] }} />
      ) : (
        <View>
          <View style={{ padding: spacing[4], borderBottomWidth: 1, marginBottom: spacing[2] }}>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View style={{ flexDirection: "column", alignItems: "flex-start" }}>
                <ProfileAvatar
                  avatar={authStore.profile?.avatar_render || ""}
                  size={48}
                  isMaster={authStore.profile?.is_master || false}
                />
                <Text
                  text={authStore.profile?.nick_name || ""}
                  style={{ marginVertical: spacing[4], maxWidth: 180 }}
                  preset="header"
                />
              </View>
              <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
                <Button
                  preset="link"
                  onPress={() => {
                    navigationRef.navigate("notificaitonList" as never)
                  }}
                >
                  <MaterialIcons name={"notifications-none"} size={32} color={color.text} />
                </Button>
                <Button
                  preset="link"
                  style={{ marginLeft: spacing[2] }}
                  onPress={() => {
                    if (authStore.isChatClientReady && authStore.profile?.account_id) {
                      navigationRef.navigate("chatList" as never)
                    }
                  }}
                >
                  <MaterialIcons name={"send"} size={32} color={color.text} />
                </Button>
              </View>
            </View>
            <TouchableOpacity
              style={{ flexDirection: "row" }}
              onPress={() => {
                navigationRef.navigate("home-stack", {
                  screen: "followerFollowing",
                  params: { accountId: authStore.profile?.account_id },
                } as never)
              }}
            >
              <Text
                preset="bold"
                text={authStore.profile?.follower ? authStore.profile?.follower + "" : "0"}
              />
              <Text text={" 팔로워 "} />
              <Text
                preset="bold"
                text={authStore.profile?.following ? authStore.profile?.following + "" : "0"}
              />
              <Text text={" 팔로잉 "} />
            </TouchableOpacity>
          </View>
          <View>
            <Button
              onPress={() => {
                navigationRef.navigate("home-stack", {
                  screen: authStore.profile?.is_master ? "masterProfile" : "userProfile",
                } as never)
              }}
              preset="link"
              style={{ marginVertical: spacing[4], marginHorizontal: spacing[4] }}
            >
              <Text text="프로필" preset="header1" />
            </Button>
            <Button
              onPress={() => {
                navigationRef.navigate("home-stack", { screen: "serviceCenter" } as never)
              }}
              preset="link"
              style={{ marginVertical: spacing[4], marginHorizontal: spacing[4] }}
            >
              <Text text="고객센터" preset="header1" />
            </Button>
            <Button
              onPress={() => {
                navigationRef.navigate("home-stack", { screen: "notice" } as never)
              }}
              preset="link"
              style={{ marginVertical: spacing[4], marginHorizontal: spacing[4] }}
            >
              <Text text="공지사항" preset="header1" />
            </Button>
            <Button
              onPress={() => {
                navigationRef.navigate("home-stack", { screen: "settings" } as never)
              }}
              preset="link"
              style={{ marginVertical: spacing[4], marginHorizontal: spacing[4] }}
            >
              <Text text="설정" preset="header1" />
            </Button>
            <Button
              onPress={() => {
                if (confirm("로그아웃 하시겠습니까?")) {
                  navigationRef.dispatch(DrawerActions.closeDrawer())
                  authStore.logout()
                }
              }}
              preset="link"
              style={{ marginVertical: spacing[4], marginHorizontal: spacing[4] }}
            >
              <Text text="로그아웃" preset="header1" style={{ color: color.primary }} />
            </Button>
          </View>
        </View>
      )}
    </DrawerContentScrollView>
  )
})

const MainDrawerNav = observer(function MainDrawerNav() {
  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
        drawerPosition: "left",
        drawerType: "back",
      }}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen name="app-tab" component={TabStackNav} />
    </Drawer.Navigator>
  )
})

const TabStackNav = observer(function TabStackNav() {
  return (
    <Tab.Navigator
      screenOptions={({ route }) =>
        ({
          tabBarInactiveTintColor: color.text,
          tabBarActiveTintColor: color.primary,
          headerShown: false,
          tabBarShowLabel: false,
          tabBarIcon: ({ color, size }) => {
            let iconName
            switch (route.name) {
              case "home-stack":
                iconName = "all-inclusive"
                break
              case "shop-stack":
                iconName = "storefront"
                break
              default:
                iconName = "home"
                break
            }

            return <MaterialIcons name={iconName} size={size} color={color} />
          },
          tabBarStyle: (() => {
            const routeNmae = getFocusedRouteNameFromRoute(route) || ""
            if (routeNmae === "chat") {
              return { display: "none" }
            }
            return { display: "flex" }
          })(),
        } as any)
      }
      initialRouteName="home-stack"
    >
      <Tab.Screen name="home-stack" component={HomeStack} />
      <Tab.Screen name="shop-stack" component={ShopStack} />
    </Tab.Navigator>
  )
})

const AuthStackNav = observer(function AuthStackNav() {
  const { authStore } = useStores()

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="login"
    >
      {authStore.isAuthenticated ? (
        authStore.hasNickName ? (
          authStore.hasAvatar ? (
            <Stack.Screen name="main-drawer" component={MainDrawerNav} />
          ) : (
            <Stack.Screen name="avatarCreateInit" component={AvatarCreateScreenInit} />
          )
        ) : (
          <Stack.Screen name="nickName" component={NickNameScreen} />
        )
      ) : (
        <>
          <Stack.Screen name="login" component={LoginScreen} />
          <Stack.Screen name="registerPhone" component={RegisterPhoneScreen} />
          <Stack.Screen name="registerOTP" component={RegisterOTPScreen} />
          <Stack.Screen name="registerPassword" component={RegisterPasswordScreen} />
          <Stack.Screen name="registerSuccess" component={RegisterSuccessScreen} />
        </>
      )}
    </Stack.Navigator>
  )
})

const AppStack = observer(function AppStack() {
  const { authStore, settingStore, notificationSettingStore, notificationStore, spaceStore } =
    useStores()

  const getUserNotificationSettings = async () => {
    await notificationSettingStore.fetchNotificationSettings(
      authStore.token,
      authStore.profile?.account_id,
    )
  }

  const getWalletBalance = async () => {
    authStore
      .getWalletBalance()
      .then((result) => {
        if (result) {
          authStore.setTongTongWalletBalance(JSON.stringify(result))
        } else {
          authStore.setTongTongWalletBalance(null)
        }
      })
      .catch((error) => {
        authStore.setTongTongWalletBalance(null)
      })
  }

  const setupClient = async () => {
    if (
      authStore &&
      authStore.profile?.account_id &&
      authStore.hasNickName &&
      authStore.deviceToken &&
      !chatClient.userID
    ) {
      try {
        await chatClient.connectUser(
          {
            id: authStore.profile?.account_id!,
            name: authStore.profile?.nick_name!,
            image: authStore.profile?.avatar_render || null,
          },
          chatClient.devToken(authStore.profile?.account_id!),
        )
        await chatClient.addDevice(
          authStore.deviceToken!!,
          "firebase",
          authStore.profile?.account_id!,
          "sotong_stream_notification",
        )
        authStore.setChatClientReady(true)
      } catch (error) {
        Toast.show({
          type: "error",
          text1: "채팅클라이언트 등록 실패",
          position: "bottom",
        })
        authStore.setChatClientReady(false)
      }
    }
  }

  useEffect(() => {
    settingStore.fetchSettings()
    if (authStore && authStore.token && authStore.profile && authStore.profile.account_id) {
      authStore.fetchProfile()
      getUserNotificationSettings()
      getWalletBalance()
    }
    if (authStore.deviceToken) {
      setupClient()
    }
    authStore.setChatClientReady(!!chatClient.userID)
  }, [authStore.profile?.account_id, authStore.hasNickName])

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animation: "none",
      }}
      initialRouteName="main-drawer"
    >
      <Stack.Screen name="main-drawer" component={MainDrawerNav} />
      <Stack.Screen name="auth-stack" component={AuthStackNav} />
    </Stack.Navigator>
  )
})

export interface NavigationProps
  extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = observer(function AppNavigator(props: NavigationProps) {
  useBackButtonHandler((routeName) => exitRoutes.includes(routeName))

  return (
    <NavigationContainer ref={navigationRef} {...props}>
      <AppStack />
    </NavigationContainer>
  )
})
