import { Space } from '../models/space/space'
import { User } from '../models/user/user'

export function compareCount (a: Space, b: Space) {
  return a.count! - b.count!
}

export function compareRating (a: User, b: User) {
  return a.ratings! - b.ratings!
}
