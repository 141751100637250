/* eslint-disable no-use-before-define */
import React from "react"
import { StyleProp, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing, color } from "../../theme"
import { Text } from "../text/text"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { FlatGrid } from "react-native-super-grid"
import uuid from "react-native-uuid"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
}

export interface AttachDocumentsProps {
  style?: StyleProp<ViewStyle>
  attachments: any[]
  setAttachments: (value: any[]) => void
}

export const AttachDocuments = observer(function AttachDocuments(props: AttachDocumentsProps) {
  const { style, attachments, setAttachments } = props
  const styles = Object.assign({}, CONTAINER, style)

  const handleAttach = async () => {
    let input = document.createElement("input")
    input.type = "file"
    input.onchange = (_) => {
      let files = Array.from(input.files)
      if (files.length === 1 && files[0].type.includes("image")) {
        let asset = { file: null, uuid: null }
        asset.file = files[0] as any
        asset.uuid = `${uuid.v4()}`
        setAttachments(attachments.concat(asset))
      }
    }
    input.click()
  }

  return (
    <View style={styles}>
      {attachments.length > 0 && (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FlatGrid
            keyboardShouldPersistTaps="handled"
            data={attachments}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => (
              <View key={index} style={{ backgroundColor: color.text }}>
                <img
                  alt="not found"
                  width={"240px"}
                  height={"100%"}
                  src={URL.createObjectURL(item.file)}
                />
                <TouchableOpacity
                  style={{ position: "absolute", right: 0, top: 0 }}
                  onPress={() => {
                    setAttachments(
                      attachments.filter((attachment) => attachment.uuid !== item.uuid),
                    )
                  }}
                >
                  <MaterialIcons name="close" size={32} color={color.background} />
                </TouchableOpacity>
              </View>
            )}
          />
        </View>
      )}
      <TouchableOpacity
        onPress={handleAttach}
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderRadius: spacing[2],
          borderWidth: 1,
          borderColor: color.dim,
          paddingVertical: spacing[1],
          paddingHorizontal: spacing[4],
          margin: spacing[4],
        }}
      >
        <MaterialIcons name="attach-file" size={24} color={color.text} />
        <Text text={"파일 첨부"} />
      </TouchableOpacity>
      <Text tx={"attachmentDocument.limitation"} preset="small" style={{ color: color.dim }} />
    </View>
  )
})
