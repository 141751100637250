/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, FlatList } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Screen, PageHeader, MasterBox, Button, CategorySlideSelection } from "../../components"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { User } from "../../models/user/user"
import MaterialIcons from "@expo/vector-icons/MaterialIcons"
import { Category } from "../../models/category/category"
import SelectDropdown from "react-native-select-dropdown"
import { compareRating } from "../../utils/sort"

enum SearchMode {
  ReviewHighToLow = 0,
  ReviewLowToHigh,
}
const SortTypes: { mode: SearchMode; text: string }[] = [
  {
    mode: SearchMode.ReviewHighToLow,
    text: "리뷰 많은 순",
  },
  {
    mode: SearchMode.ReviewLowToHigh,
    text: "리뷰 적은 순",
  },
]
let isMounted = false

export const HomeMastersByCategoryScreen: FC<
  StackScreenProps<NavigatorParamList, "homeMastersByCategory">
> = observer(({ route, navigation }) => {
  const { category } = route.params
  const goBack = () => navigation.goBack()
  const { userStore } = useStores()
  const [sortMode, setSortMode] = useState<SearchMode>(SearchMode.ReviewHighToLow)
  const [currentCategory, setCurrentCategory] = useState<Category>(category)

  const [masters, setMasters] = useState<User[]>([])

  const fetchData = async () => {
    if (isMounted) {
      let result: User[] = []
      result = await userStore.fetchMasters(1000, 0)
      setMasters(masters.concat(result))
    }
  }

  useEffect(() => {
    isMounted = true
    fetchData()
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <View style={{ flex: 1, backgroundColor: color.background }}>
      <Screen>
        <PageHeader />
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: spacing[4],
          }}
        >
          <Button preset="link" onPress={goBack}>
            <MaterialIcons name="arrow-back" size={32} color={color.text} />
          </Button>
          <CategorySlideSelection
            onPressCategory={(category) => {
              setCurrentCategory(category)
            }}
            selectedCategory={currentCategory}
            isForMaster={true}
          />
        </View>
        <View style={{ margin: spacing[4], marginBottom: spacing[2] }}>
          <SelectDropdown
            data={SortTypes}
            onSelect={(selectedItem) => {
              setSortMode(selectedItem.mode)
            }}
            buttonTextAfterSelection={(selectedItem) => selectedItem.text}
            rowTextForSelection={(selectedItem) => selectedItem.text}
            defaultButtonText={SortTypes[0].text}
            buttonStyle={{
              width: "100%",
              backgroundColor: color.background,
              borderWidth: 1,
              borderColor: color.border,
              borderRadius: 4,
              height: 36,
            }}
            buttonTextStyle={{ fontSize: 14, textAlign: "left" }}
            dropdownStyle={{
              backgroundColor: color.background,
              borderRadius: 4,
            }}
            rowStyle={{ height: 36 }}
            rowTextStyle={{ fontSize: 14, textAlign: "center" }}
            renderDropdownIcon={() => (
              <MaterialIcons name="arrow-drop-down" size={24} color={color.text} />
            )}
          />
        </View>
        <View style={{ flex: 1 }}>
          <FlatList
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            data={masters
              .filter((item) => item.master_category === currentCategory.category_id)
              .sort((a, b) =>
                sortMode === SearchMode.ReviewHighToLow ? compareRating(b, a) : compareRating(a, b),
              )}
            keyExtractor={(item) => item.profile_id}
            renderItem={({ item }) => (
              <View key={item.profile_id}>
                <MasterBox
                  onPress={() => {
                    navigation.navigate("userDetail", {
                      profileId: item.profile_id,
                      accountId: item.account_id,
                    } as never)
                  }}
                  master={item}
                />
              </View>
            )}
          />
        </View>
      </Screen>
    </View>
  )
})
