/* eslint-disable no-use-before-define */
import * as React from "react"
import { StyleProp, View, ViewStyle, TouchableOpacity, Image, ImageStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing } from "../../theme"
import { Text } from "../text/text"
import { useStores } from "../../models"
import { SCREEN_WIDTH } from "app/utils/screen"

const CONTAINER: ViewStyle = {
  flex: 1,
  marginVertical: spacing[8],
  justifyContent: "center",
  alignItems: "center",
}
const IMAGE_STYLE: ImageStyle = {
  width: SCREEN_WIDTH - spacing[5] * 2,
  height: ((SCREEN_WIDTH - spacing[5] * 2) * 3) / 4,
}

export interface HomeFooterBannerProps {
  style?: StyleProp<ViewStyle>
  onPressBanner: () => void
}

const bannerImageLocal = require("../../../assets/images/banner.png")

export const HomeFooterBanner = observer(function HomeFooterBanner(props: HomeFooterBannerProps) {
  const { style, onPressBanner } = props
  const styles = Object.assign({}, CONTAINER, style)
  const { settingStore } = useStores()

  const bannerImage = () => {
    if (settingStore.getAppBanner() === "") {
      return <Image source={bannerImageLocal} style={IMAGE_STYLE} />
    } else {
      return <Image source={{ uri: settingStore.getAppBanner() }} style={IMAGE_STYLE} />
    }
  }

  return (
    <View style={styles}>
      <Text text={"마스터와 1:1 학습을 지금 시작하세요!"} preset="boldCenter" />
      <Text
        text={"아래 배너를 클릭해서 설명을 확인해 보세요"}
        preset="secondaryLabel"
        style={{ textAlign: "center", marginVertical: spacing[2] }}
      />
      <TouchableOpacity onPress={onPressBanner}>{bannerImage()}</TouchableOpacity>
    </View>
  )
})
